/* eslint-disable */
import React, {
  useMemo, useState, useEffect,
} from 'react';
import MaintenanceTableListing from './MaintenanceTableListing';
import CancelModal from '../Modals/CancelModal';
import './Tables.scss';
import moment from 'moment';
import { NUMBER_ZERO, NUMBER_TEN,DATE_FORMAT } from '../../utils/constants';
import { Button, Message } from 'semantic-ui-react';
import { dataFilterByColumn } from '../../utils/common';


const Status = ({ values }) => {
  if (values === 'Urgent' || values === 'urgent') {
    return (
      <Message
        compact
        size="mini"
        color="red"
        style={{
          color: 'white', textTransform: 'capitalize', minWidth: '70px', maxWidth: '70px',
        }}
      >
        {values}
      </Message>
    );
  }
  if (values === 'Medium' || values === 'medium' ||values === 'Standard' ) {
    return (
      <Message
        compact
        size="mini"
        color="orange"
        style={{
          color: 'white', textTransform: 'capitalize', minWidth: '70px', maxWidth: '70px',
        }}
      >
        {values}
      </Message>
    );
  }
  if (values === 'Low' || values === 'low') {
    return (
      <Message
        compact
        size="mini"
        color="blue"
        style={{
          color: 'white', textTransform: 'capitalize', minWidth: '70px', maxWidth: '70px',
        }}
      >
        {values}
      </Message>
    );
  }
};
export function Table(props) {
  const [filterInput, setFilterInput] = useState('');
  const [currentPageIndex, setPageIndex] = useState(NUMBER_ZERO);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [multiFilterData, setMultiFilterData] = useState([]);

  const multipleColumnsSearch = (inputValue, data) => {
    const filteredData = data.filter((value) => (
      value.node.requestedBy.firstName.toLowerCase().includes(inputValue.toLowerCase())
          || value.node.requestedBy.lastName.toLowerCase().includes(inputValue.toLowerCase())
    ));
    return filteredData;
  };


  const handleFilterChange = (e) => {
    const value = e.target.value || '';
    const filteredData = multipleColumnsSearch(value, props.data.requests);
    setData(filteredData);
    setFilterInput(value);
    if (value === '') {
      const startRow = 30 * currentPageIndex;
      const endRow = startRow + 30;
      setData(props.data.requests.slice(startRow, endRow));
    }
    setTimeout(() => {
      setFilterApplied(true);
      setMultiFilterData(filteredData);
    }, 2000);
  };

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    return (
      ''
    );
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  /* eslint-disable */
  const columns = useMemo(
    () => [

      {
        Header: 'Id',
        accessor: 'node.id',
      },
      {
        Header: 'First',
        accessor: 'node.requestedBy.firstName',
      },
      {
        Header: 'Last',
        accessor: 'node.requestedBy.lastName',
      },
      {
        Header: 'Priority',
        accessor: 'node.priority',
        Cell: ({ cell: { value } }) => (value ? <Status values={value} /> : ''),
      },
      {
        Header: 'Unit',
        accessor: 'node.unit',
      },
      {
        Header: 'Assigned To',
        accessor: 'node.assigned.lastName',
      },
      {
        Header: 'Submitted',
        accessor: 'node.created',
        Cell: ({ value }) => moment(value).format(DATE_FORMAT),
      },
      {
        Header: 'Status',
        accessor: 'node.status',
      },
      {
        Header: 'View',
        accessor: (item) => <Button icon="eye" size="mini" onClick={() => props.view(item)} />,
      },
      {
        Header: 'Cancel',
        accessor: (cancelItem) => <CancelModal icon="cancel" size="mini" refreshTable={props.getRequest} user={props.user} getRequest={props.getRequest} props={cancelItem} />,
      },

    ],
    [],
  );

  const [data, setData] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [lastPage, setLastPage] = useState();

  useEffect(() => {
    const result = props.data.requests.length / 30;
    setLastPage(Math.ceil(result));
    setData(props.data.requests);
  }, [props.data.requests]);
  /* eslint-enable */

  return (
    <div className="table-container">
      <MaintenanceTableListing
        columns={columns}
        filteredValue={filterInput}
        data={data}
        view={props.redirectToView}
        loading={loading}
        lastPage={lastPage}
        defaultColumn={defaultColumn}
        onChange={handleFilterChange}
        isFilterApplied={isFilterApplied}
        pageCount={lastPage}
      />
    </div>
  );
}