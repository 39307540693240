import React, { Component } from "react";
import {
  Button,
  Modal,
  Header,
  Grid,
  Segment,
  List,
  Table,
  Card,
} from "semantic-ui-react";
import { withApollo } from "react-apollo";
import "react-toastify/dist/ReactToastify.css";
import { ACCOUNTING } from "../../utils/constants";
import { getClient } from "../../init-apollo-googleFn";
import moment from "moment";
import { InvoiceInfoAccounting } from '../../store/person/accounting';


const ledgerClient = getClient(ACCOUNTING);

class ReferenceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      modalOpen: false,
      secondOpen: false,
      thirdOpen: false,
      invoice:''
    };
  }

  handleClose = () => this.setState({ modalOpen: false });
  setSecondOpen = (data) => this.setState({secondOpen:data})
  setThirdOpen = (data) => this.setState({thirdOpen:data})

  handleInvoice =() => {
this.setSecondOpen(true)
this.getInvoice()
  }
  getInvoice = () => {
    try {
      ledgerClient
        .query({
          query: InvoiceInfoAccounting,
          variables: {
            location: this.props.location &&this.props.location.propertyId,
            id: this.props.RefNum.Transactions[0].InvoiceId,
          },
        })
        .then((res) => {
          if (res.data) {
            this.setState({invoice:res.data.slLocationInvoice && res.data.slLocationInvoice})
          }
        })
        .catch((error) => {
          console.log(error)
        });
    } catch (e) {

    }
  };

  render() {
    const {invoice} =this.state
    
    return (
      <>
        <Modal
          className="semanticModal"
          open={this.props.modalOpen}
          size="medium"
          closeOnEscape={true}
          closeOnRootNodeClick={true}
        >
          <Modal.Content>
              <Card.Group itemsPerRow="4">
            <Card>
              <Card.Content>
                <Card.Header>Entry Type</Card.Header>
                <Card.Description>
                  {this.props.RefNum&&this.props.RefNum.entryType}
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Card.Header>Reference</Card.Header>
                <Card.Description>
                  {this.props.RefNum&&this.props.RefNum.reference}
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Card.Header>Transaction Date</Card.Header>
                <Card.Description>
                  {moment(this.props.RefNum&&this.props.RefNum.Transactions[0].transactionDate).format('ll')}
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Card.Header>Posted Date</Card.Header>
                <Card.Description>
                  {moment(this.props.RefNum&&this.props.RefNum.Transactions[0].postedDate).format('ll')}
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Card.Header>Journal Status</Card.Header>
                <Card.Description>
                  {this.props.RefNum&&this.props.RefNum.status}
                </Card.Description>
              </Card.Content>
            </Card>
           
            <Card>
              <Card.Content>
                <Card.Header>Location</Card.Header>
                <Card.Description>
                {this.props.RefNum&&this.props.RefNum.Transactions[0].Location&&this.props.RefNum.Transactions[0].Location.name}
                </Card.Description>
              </Card.Content>
            </Card>

            <Card>
              <Card.Content>
                <Card.Header>Currency</Card.Header>
                <Card.Description>
                  USD
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Card.Header>Customer</Card.Header>
                <Card.Description>
                  {/* eslint-disable */}
                  <a onClick={() => this.setThirdOpen(true)}>{this.props.RefNum&&this.props.RefNum.Transactions[0].Agent&&this.props.RefNum.Transactions[0].Agent.name}</a>
                  {/* eslint-enable */}
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Card.Header>Invoice</Card.Header>
                <Card.Description>
                  {/* eslint-disable */}
                  <a onClick={() => this.handleInvoice()}> {this.props.RefNum&&this.props.RefNum.Transactions[0].Invoice&&this.props.RefNum.Transactions[0].Invoice.number}</a>
                  {/* eslint-enable */}
                </Card.Description>
              </Card.Content>
            </Card>

              </Card.Group>
              <Table celled>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Account Name</Table.HeaderCell>
        <Table.HeaderCell>Description</Table.HeaderCell>
        <Table.HeaderCell>Debit</Table.HeaderCell>
        <Table.HeaderCell>Credit</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
{
    this.props.RefNum.Transactions&&this.props.RefNum.Transactions.map(actions=> {
        return (

    <Table.Body>
      <Table.Row>
        <Table.Cell>{actions.LedgerAccount.name}</Table.Cell>
        <Table.Cell>{actions.description}</Table.Cell>
        <Table.Cell>${actions.debit}</Table.Cell>
        <Table.Cell>${actions.credit}</Table.Cell>
      </Table.Row>
    </Table.Body>
        )
    })
}
  </Table>
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              type="button"
              onClick={this.props.handleClose}
              content="Close"
            />
          </Modal.Actions>
          <Modal
          className="semanticModal"
          onClose={() => this.setSecondOpen(false)}
          open={this.state.secondOpen}
          size='small'
        >
          <Modal.Header>Invoice:</Modal.Header>
          <Modal.Content>
          <Header textAlign="center">Invoice</Header>
      <br />
      <Grid columns={2}>
        <Grid.Column>
          <Table compact>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{invoice &&invoice.location&&invoice.location.name}</Table.Cell>
                <Table.Cell>{invoice &&invoice.location&&invoice.location.contact&&invoice.location.contact.phone}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{invoice &&invoice.location&&invoice.location.addresses&&invoice.location.addresses.street}</Table.Cell>
                <Table.Cell>{invoice &&invoice.location&&invoice.location.contact&&invoice.location.contact.email}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {invoice &&invoice.location&&invoice.location.addresses&&invoice.location.addresses.city}, 
                  {invoice &&invoice.location&&invoice.location.addresses&&invoice.location.addresses.state}, 
                  {invoice &&invoice.location&&invoice.location.addresses&&invoice.location.addresses.zip}</Table.Cell>
                <Table.Cell>{"Insert Website"}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
      <br />
      <Segment>
        <Table compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Invoice Details</Table.HeaderCell>
              <Table.HeaderCell>Customer Details</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>Number: {invoice &&invoice.invoice&&invoice.invoice.number} </Table.Cell>
              <Table.Cell>Customer: {invoice &&invoice.customer&&invoice.customer.nameUpper}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Invoice Date: {invoice &&invoice.invoice&&invoice.invoice.invoiceDate} </Table.Cell>
              <Table.Cell>Address: {invoice &&invoice.customer&&invoice.customer.addresses&&invoice.customer.addresses.city}, 
                {invoice &&invoice.customer&&invoice.customer.addresses&&invoice.customer.addresses.state}, 
                {invoice &&invoice.customer&&invoice.customer.addresses&&invoice.customer.addresses.zip}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Due: {invoice &&invoice.invoice&&invoice.invoice.dueDate} </Table.Cell>
              <Table.Cell>Email: {invoice &&invoice.customer&&invoice.customer.email}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
      <br />

      <Table padded="very" compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Qty</Table.HeaderCell>
            <Table.HeaderCell>Tax</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>Total</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {invoice &&invoice.items&&
            invoice.items.map((inv) => {
              return (
                <Table.Row>
                  <Table.Cell>{inv.description}</Table.Cell>
                  <Table.Cell>{inv.quantity}</Table.Cell>
                  <Table.Cell>${inv.taxAmount}</Table.Cell>
                  <Table.Cell>${inv.subtotal}</Table.Cell>
                  <Table.Cell>${inv.total}</Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
      <Grid  columns={2}>
        <Grid.Column>
          <Header>Notes</Header>
           
        </Grid.Column>
        <Grid.Column>
          <Table padded="very" compact>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{"Subtotal"}</Table.Cell>
                <Table.Cell textAlign="right">$ </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{"Sales Tax"}</Table.Cell>
                <Table.Cell textAlign="right">$ </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Total</Table.Cell>
                <Table.Cell textAlign="right">$ </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button
              icon='check'
              content='Close'
              onClick={() => this.setSecondOpen(false)}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          onClose={() => this.setThirdOpen(false)}
          open={this.state.thirdOpen}
          size='mini'
          className="semanticModal"
        >
          <Modal.Header>{this.props.RefNum&&this.props.RefNum.Customer&&this.props.RefNum.Customer.first_name}{" "}{this.props.RefNum&&this.props.RefNum.Customer&&this.props.RefNum.Customer.last_name}</Modal.Header>
          <Modal.Content>
          <List horizontal>
    <List.Item>
      <List.Icon name='mail' />
      <List.Content>
        <a href={`mailto:${this.props.RefNum&&this.props.RefNum.Customer&&this.props.RefNum.Customer.email}`}>{this.props.RefNum&&this.props.RefNum.Customer&&this.props.RefNum.Customer.email}</a>
      </List.Content>
    </List.Item>
    <List.Item>
      <List.Icon name='phone' />
      <List.Content>
        <a href={`mailto:${this.props.RefNum&&this.props.RefNum.Customer&&this.props.RefNum.Customer.phone_mobile}`}>{this.props.RefNum&&this.props.RefNum.Customer&&this.props.RefNum.Customer.phone_mobile}</a>
      </List.Content>
    </List.Item>
  </List>
          </Modal.Content>
          <Modal.Actions>
            <Button
              icon='check'
              content='Close'
              onClick={() => this.setThirdOpen(false)}
            />
          </Modal.Actions>
        </Modal>

        </Modal>
      </>
    );
  }
}
export default withApollo(ReferenceModal);
