import React from 'react'
import { Button, Form, Modal,Dropdown, Table, TextArea, Icon, Input } from 'semantic-ui-react'
import {ChargeAccounting,BillCreateMutation,LedgerAccounting,VendorsAccounting,BillAccounting, updateSLBillMutation} from "../../store/person/accounting";
import { ACCOUNTING,INVOICE_ACCOUNTING_PAYMENT } from "../../utils/constants";
import { getClient } from "../../init-apollo-googleFn";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import get from "lodash/get";
import moment from 'moment';
import {  toastFailMsg,toastSuccessMsg } from '../../utils/common';
import mixpanel from 'mixpanel-browser';
const accountingClient = getClient(ACCOUNTING );
const paymentClient = getClient(INVOICE_ACCOUNTING_PAYMENT)
 
function NewBillsModal(props) {
    const [open, setOpen] = React.useState(false)
    const [vendor, setVendor] = React.useState(false)
    const [notes, setNotes] = React.useState("")
    const [accountNumber, setAccountNumber] = React.useState(false)
    const [accountName, setAccountName] = React.useState(false)
    const [vendorName, setVendorName] = React.useState(false)

    const [billId, setBillId] = React.useState(false)
    
    const [invoiceDate, setInvoiceDate] = React.useState(false)
    const [postingDate, setPostingDate] = React.useState(false)
    const [dueDate, setDueDate] = React.useState(false)
    const [accountArray, setAccountArray] = React.useState([])
    const [vendorArray, setVendorArray] = React.useState([])

    const [chargeData, chargeCodeData] = React.useState([]);
    const [charges, setChargesData] = React.useState(false);

    // Sets the chosen Charge code to the array
    function SetData(e, { name, value, text }) {
    const resultArray = {
      quantity: value.quantity,
      amount: value.amountDefault ? parseInt(value.amountDefault) : 0,
      description: value.desc,
      LedgerAccountId: parseInt(value.extLedgerId),
    };
    const Arry = [...chargeData, resultArray];
    chargeCodeData(Arry);
  }

  // Removes the charge code from the list
      const removeCharge = (chargeItem) => {
        const newCharges = chargeData.filter(charge => charge !== chargeItem)
        chargeCodeData(newCharges)
      }
      // Creates the bill
    const createBill = (e) => {
      if(props.bill){
        const newLine = chargeData.map((charge) => ({
          description: charge.description,
          amount: charge.amount,
          quantity: charge.quantity,
          taxAmount: charge.taxAmount,
          LedgerAccountId:charge.LedgerAccountId,
          TaxCodeId:charge.TaxCodeId
        }));
        try {
          mixpanel.track('Manager Accounting Action', {
            'sub': 'Update Bill'
          });
          accountingClient
            .mutate({
              mutation: updateSLBillMutation,
              variables: {
                input: {
                  VendorId:vendor,
                  location:props.location.propertyId, 
                  LocationId:props.location.staticId, 
                  Id:billId,
                  APAccountId:accountNumber,
                  billLineItems:newLine,
                  notes:notes,
                  invoiceDate:invoiceDate,
                  postingDate:postingDate,
                  dueDate:dueDate,
                },
              },
            })
            .then((response) => {
              setOpen(false);
              if(response.data.updateSlBill.response.includes("error")) {
                toastFailMsg(response.data.updateSlBill.response)
              }
              else {
                toastSuccessMsg("Bill Edited Successfully")
                setTimeout(() => {
                  props.location.getLedger()
                }, 2000)
                
              }
            })
            .catch((error) => {
              console.log(error)
            });
        } catch (e) {alert(e)}
      }
      else {
        try {
          mixpanel.track('Manager Accounting Action', {
            'sub': 'Create Bill'
          });
          accountingClient
            .mutate({
              mutation: BillCreateMutation,
              variables: {
                input: {
                  VendorId:vendor,
                  location:props.propertyId, 
                  LocationId:props.id, 
                  billLineItems:chargeData,
                  notes:notes,
                  invoiceDate:invoiceDate.toString(),
                  postingDate:postingDate.toString(),
                  dueDate:dueDate.toString(),
                  APAccountId:accountNumber,

                },
              },
            })
            .then((response) => {
              if(response.data.createSlBill.response.includes("error")) {
                toastFailMsg(response.data.createSlBill.response)
              }
              else {
                toastSuccessMsg("Bill Successfully Added")
                setTimeout(() => {
                  props.getLedger()
                }, 2000)
                setOpen(false);
              }
            })
            .catch((error) => {
              console.log(error)
            });
        } catch (e) {alert(e)}
      }
      };

      // Adds the amounts in the array of charge codes
      const addFunction = () => {
        if(chargeData){
          const sum = chargeData.reduce(add, 0);
          function add(accumulator, a) {
            return accumulator += parseFloat(a.amount);
          }
          return sum

        }
      }

      // Adds the quantity set from the array of charge codes
      const addFunctionQuant = (item) => {
        const sum = item.reduce(add, 0);
        function add(accumulator, a) {
          return accumulator += parseFloat(a.quantity);
        }
        return sum
      }

      const changeValue = (e, value) => {
        const newArry = chargeData.map((item, index) => {
          if(index === value){
            item.amount = e.target.value ? parseFloat(e.target.value):0
            return item
          }
          else return item
        })
        addFunction()
        chargeCodeData(newArry)
      }
      // Full state clear
const clearState = () => {
  setVendor(false)
  setNotes("")
  setAccountNumber(false)
  setAccountName(false)
  setVendorName(false)
  setInvoiceDate(false)
  setPostingDate(false)
  setDueDate(false)
  setAccountArray([])
  setVendorArray([])
  chargeCodeData([])
}

// Gets the available ledgers
      const getLedger = () => {
        console.log(props)
        clearState()
        
        try {
          accountingClient
            .query({
              query: ChargeAccounting,
              variables: {
                location: props.bill ? props.location.propertyId:props.propertyId, 
              },
            })
            .then((res) => {
              
              getLedgerAccount()
              const chargeData = get(res, "data.chargeCodes.edges", null)
              if (res.data) {
                setChargesData(chargeData);
              }
              if(props.bill){
                getFullBill(chargeData)
              }
            })
            .catch((error) => {

            });
        } catch (e) {

        }
      };

      // Gets full bill information
      const getFullBill = (chargeData) => {
          try{
            paymentClient
            .query({
              query: BillAccounting,
              variables: {
                location: props.location.propertyId, 
                BillId:props.bill._id
              },
            })
            .then((res) => {
              const theBill = res.data.slLocationBills
              setFields(theBill, chargeData)
            })
            .catch((error) => {
            });
          }catch (e) {alert(e)}
      }

      // Upon edit this sets the fields
      const setFields = (bill, chargeData) => {
        if(bill){
         
          setBillId(bill._id)
          setVendor(bill.VendorId)
          setVendorName(bill.Vendor.name)
          setNotes(bill.notes)
          setAccountNumber(bill.APAccountId)
          setAccountName(bill.APAccount)
          setInvoiceDate(bill.invoiceDate)
          setPostingDate(bill.postingDate)
          setDueDate(bill.dueDate)
          chargeCodeData(bill.billLineItems)
        }
      }

      const chargeOptions =
        charges &&
        charges.map((charge) => ({
      key: charge.nodename,
      text: charge.node.name,
      value: charge.node,
    }));

    // Get all the ledger items
    const getLedgerAccount = () => {
        accountingClient
        .query({
          query: LedgerAccounting,
          variables: {
            location: props.bill ? props.location.propertyId:props.propertyId, 
            offset: 0,
            limit: 1000
          },
        })
        .then((res) => {
            getVendors()
            const array = res.data.slLocationLedgerAccounts.data
            const filteredArray = array.filter(item => item.type === "Expense")
            setAccountArray(filteredArray)
        })
        .catch((error) => {
          console.log(error);

        });
    }

    // Get all the vendors
    const getVendors = () => {
        accountingClient
          .query({
            query: VendorsAccounting,
            variables: {
              location:  props.bill ? props.location.propertyId:props.propertyId, 
            },
          })
          .then((res) => {
            
            if (res.data) {
                const array = res.data.slLocationVendors.data 
                setVendorArray(array)
            }
          })
          .catch((error) => {
            console.log(error);

          });
    }
    const validation = (currentDate) => {
      return currentDate.isBefore(moment().add(1, 'days'));
    };

    return (
      <Modal
      className="semanticModal"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size="large"
        trigger={
          
        <Button className="noPrint" icon onClick={() => getLedger()}>{props.bill?<Icon name='cog' />:"Add Bill"}</Button>
        }
      >
        <Modal.Header>{props.bill?"Edit Bill":"Create Bill"}</Modal.Header>
        <Modal.Content style={{margin:"15px"}}>
        <Form>
        <Form.Group widths='equal'>
            <Form.Field required>
                <label>Vendor</label>
                <Dropdown
                onChange={(e, {value}) => setVendor(value)}
                placeholder={props.bill ? vendorName:"Vendors"}
                selection
                value={props.bill && vendor}
                options={vendorArray &&
                    vendorArray.map((vend) => ({
                key: vend._id,
                text: vend.name,
                value: vend._id,
                }))}
                />
            </Form.Field>
            <Form.Field required>
                <label>Invoice Date</label>
                <Datetime isValidDate={validation} className="bills-times" value={invoiceDate && moment(invoiceDate).format("MM/DD/YYYY")} timeFormat={false} onChange={(e)=>setInvoiceDate(e._d)}/>
            </Form.Field>
            <Form.Field required>
                <label>Posting Date</label>
                <Datetime className="bills-times" value={postingDate && moment(postingDate).format("MM/DD/YYYY")} timeFormat={false} onChange={(e)=>setPostingDate(e._d)}/>
            </Form.Field>
        </Form.Group>

        <Form.Group widths='equal'>
            {/* <Form.Field>
                <label>Location</label>
                <input placeholder='Name' onChange={(e)=>setVendor(e.target.value)} />
            </Form.Field> */}
            <Form.Field required>
                <label>Due Date</label>
                <Datetime className="bills-times" value={dueDate && moment(dueDate).format("MM/DD/YYYY")} timeFormat={false} onChange={(e)=>setDueDate(e._d)}/>
            </Form.Field>
            {/* <Form.Field>
                <label>Currency</label>
                <input placeholder='Name on Check' onChange={(e)=>setReference(e.target.value)} />
            </Form.Field> */}
            <Form.Field required>
                <label>AP Account</label>
                <Dropdown
                onChange={(e,{value})=>setAccountNumber(value)}
                placeholder={props.bill ? accountName.name:"Accounts"}
                selection
                value={props.bill && accountNumber}
                options={accountArray &&
                    accountArray.map((account) => ({
                key: account.number,
                text: account.name,
                value: account._id,
                }))}
                />
            </Form.Field>
        </Form.Group>
        <Form.Group widths='equal'>
        <Form.Field required>
                      <label>Charge Code</label>
                      <Dropdown
                        onChange={SetData}
                        placeholder="Charge Codes"
                        search
                        selection
                        // value={chargeData}
                        options={chargeOptions}
                      />
                    </Form.Field>
                    <Form.Field>
                    <label>Notes</label>
               
                     <TextArea placeholder='Notes' value={notes} style={{ minHeight: 100 }} onChange={(e)=>setNotes(e.target.value)} />
                   
                    </Form.Field>
                    </Form.Group>
                    {/* <Form.Field> */}

                    
                    <Table celled>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width='3'>Action</Table.HeaderCell>
        <Table.HeaderCell width='4'>Description</Table.HeaderCell>
        <Table.HeaderCell width='3'>Ledger Id</Table.HeaderCell>
        <Table.HeaderCell width='3'>Quantity</Table.HeaderCell>
        <Table.HeaderCell width='3'>Amount</Table.HeaderCell>

      </Table.Row>
    </Table.Header>
        </Table>

        <div style={{maxHeight:'200px', overflowY:'auto', width:'100%'}}>
        <Table celled>
      <Table.Body>
      {chargeData &&
                  chargeData.map((charge, index) => {
                    return (
                      <>
        <Table.Row >
          <Table.Cell  width='3'><Button icon onClick={() => removeCharge(charge)}> <Icon name='cancel' /></Button></Table.Cell>
          <Table.Cell  width='4'>{charge.description}</Table.Cell>
          <Table.Cell  width='3'>{charge.LedgerAccountId}</Table.Cell>
          <Table.Cell  width='3'>{charge.quantity}</Table.Cell>
          <Table.Cell  width='3'>
          <Input iconPosition='left' style={{width:'100px'}} placeholder='Amount'>
            <Icon name='dollar' />
            <input  onChange={(event) => changeValue(event, index)} type="number" value={charge.amount}/>
          </Input>
          </Table.Cell>
        </Table.Row>
        </>
                    )
                  })}
      </Table.Body>

        </Table>
  </div>
        <Table celled>
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell  width='3'></Table.HeaderCell>
        <Table.HeaderCell  width='4'></Table.HeaderCell>
        <Table.HeaderCell  width='3'>Total</Table.HeaderCell>
        <Table.HeaderCell  width='3'> {addFunctionQuant(chargeData)}</Table.HeaderCell>
        <Table.HeaderCell  width='3'>$ {addFunction()}</Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  </Table>
            
            {/* <Grid columns="4" divided >

                        <Grid.Column style={{maxWidth:'421px'}}> </Grid.Column>
                        <Grid.Column style={{maxWidth:'421px'}} textAlign="center">Total</Grid.Column>
                        <Grid.Column style={{maxWidth:'421px'}} textAlign="center">{chargeData.reduce((prev,next) => prev + next.quantity,0)}</Grid.Column>
                        <Grid.Column style={{maxWidth:'421px'}} textAlign="center">$ {chargeData.reduce((prev,next) => prev + next.amount,0)}</Grid.Column>
</Grid>  */}
        </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Submit"
            onClick={() => createBill()}
            positive
            disabled={chargeData.length === 0 || !accountNumber}
          />
        </Modal.Actions>
      </Modal>
    )
  }

export default NewBillsModal