import React, {
  useMemo, useState, useEffect, useCallback,
} from 'react';
import Moment from 'react-moment';
import {
  Checkbox, Button,Label, Dimmer
} from 'semantic-ui-react';
import ApplicationTable from '../Application/ApplicationTable';
import { NUMBER_ZERO, NUMBER_TEN, DATE_FORMAT, ROUTES,BLUEMOON_API, LEASE_MANAGER } from '../../utils/constants';
import { dataFilterByColumn } from '../../utils/common';
import '../Leads/LeadsExtra.scss';
import { leaseSign } from '../../store/bluemoon/bluemoon';
import { getClient } from '../../store/auth';
import { leaseDocuments } from '../../store/person/leases';
import { toastFailMsg, toastSuccessMsg } from '../../utils/common';
import { PageLoader } from "../../components/Loader/PageLoader";
import mixpanel from 'mixpanel-browser';
const bluemoonApi = getClient(BLUEMOON_API);
const leaseManager = getClient(LEASE_MANAGER);

const SetDateFormat = ({ date }) => (<Moment format={DATE_FORMAT}>{date}</Moment>);
const SetSignType = (type) => {
  if(type.sign){
    if(type.sign ==="bluemoon"){
      return <Label size="mini"style={{background:"#A2CDCD", color:"white", width:'100%', textAlign:"center"}}>Bluemoon</Label>
    }
    if(type.sign === "docusign"){
      return <Label size="mini"style={{background:"#A2CDCD", color:"white", width:'100%', textAlign:"center"}}>DocuSign</Label>
    }
    else {
      return <Label size="mini"style={{background:"grey", color:"white", width:'100%', textAlign:"center"}}>None</Label>
    }
  }
  else {
    return ''
  }
}
const SetSign = ( date ) => {
  if(date.sign) {
  if(date.sign.status === "initial"){
    return <Label size="mini"style={{background:"#A2CDCD", color:"white", width:'100%', textAlign:"center"}}>Initial</Label>
  }
  if(date.sign.status  === "signed"){
    return <Label size="mini"style={{background:"#A2CDCD", color:"white", width:'100%', textAlign:"center"}}>Signed</Label>
  }
  if(date.sign.status  === "in_progress"){
    return <Label size="mini"style={{background:"#A2CDCD", color:"white", width:'100%', textAlign:"center"}}>In Progress</Label>
  }
  if(date.sign.status  === "completed"){
    return <Label size="mini"style={{background:"#C6D57E", color:"white", width:'100%', textAlign:"center"}}>Complete</Label>
  }
  if(date.sign.status  === "expired"){
    return <Label size="mini"style={{background:"#D57E7E", color:"white", width:'100%', textAlign:"center"}}>Expired</Label>
  }
  if(date.sign.status  === "delivered"){
    return <Label size="mini"style={{background:"#A2CDCD", color:"white", width:'100%', textAlign:"center"}}>Delivered</Label>
  }
  if(date.sign.status  === "declined"){
    return <Label size="mini"style={{background:"#D57E7E", color:"white", width:'100%', textAlign:"center"}}>Declined</Label>
  }
  if(date.sign.status  === "completed"){
    return <Label size="mini"style={{background:"#C6D57E", color:"white", width:'100%', textAlign:"center"}}>Complete</Label>
  }
  if(date.sign.status  === "partial"){
    return <Label size="mini"style={{background:"#FFE1AF", color:"white", width:'100%', textAlign:"center"}}>Partial</Label>
  }
  else{
    return <Label size="mini"style={{background:"grey", color:"white", width:'100%', textAlign:"center"}}>Not Started</Label>
  }
}
else {
  return <Label size="mini"style={{background:"grey", color:"white", width:'100%', textAlign:"center"}}>Not Started</Label>
}
};

export default function Table(props) {
  const [filterInput, setFilterInput] = useState('');
  const [multiFilterData, setMultiFilterData] = useState([]);
  const [data, setData] = useState([]);
  /* eslint-disable */
  const [statusFilter, setStatusFilter] = React.useState([]);
  const [unitNumberFilter, setUnitNumberFilter] = React.useState([]);
  /* eslint-enable */
  const [currentPageSize, setPageSize] = useState(NUMBER_TEN);
  const [currentPageIndex, setPageIndex] = useState(NUMBER_ZERO);
  const [pageCount, setPageCount] = React.useState(0);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [setDimmer, setDimmerDim] = useState(false);
  const {
    lease, multipleHandling, selectedAppsForBulkEdit, onViewDetails, history,
  } = props;

  const multipleColumnsSearch = (inputValue, data) => {
    const filteredData = data.filter((value) => (
      value.node.person.firstName.toLowerCase().includes(inputValue.toLowerCase())
          || value.node.person.lastName.toLowerCase().includes(inputValue.toLowerCase())
    ));
    return filteredData;
  };

  const CapitalizeFirstLetter = ({ value }) => {
    if(value === 'signed'){
      return <p style={{color:'black'}}>Initiated</p>
    }
    return <p style={{textTransform:'capitalize',color:'black'}}>{value}</p>;
  };

  const handleFilterChange = (e) => {
    const value = e.target.value || '';
    const filteredData = multipleColumnsSearch(value, lease);
    setData(filteredData);
    setFilterInput(value);
    setPageCount(filteredData.length);
    if (value === '') {
      const startRow = currentPageSize * currentPageIndex;
      const endRow = startRow + currentPageSize;
      setData(lease.slice(startRow, endRow));
    }
    setTimeout(() => {
      setFilterApplied(true);
      setMultiFilterData(filteredData);
    }, 2000);
  };

  const filterByColumn = (filteredData, filter, column, arrayToFilter, filteredArray, key) => {
    filter[0].value.forEach((ele) => {
      filteredData = dataFilterByColumn(ele || '', arrayToFilter, column, key);
      filteredData.forEach((ele) => {
        filteredArray.push(ele);
        return null;
      });
    });
    setData(filteredArray);
    setMultiFilterData(filteredArray);
  };

  /* function to check the filtered data on the basis of the column on which filter is applied */
  const updateFilterByPreviousValue = () => {
    const filteredData = [];
    const statusFilterData = [];
    const unitNumberFilterData = [];
    let isStatusFilter = false;
    if (statusFilter.length) {
      isStatusFilter = true;
      filterByColumn(filteredData, statusFilter, 'status', lease, statusFilterData);
    }
    if (unitNumberFilter.length) {
      let dataToSend = [];
      if (isStatusFilter) {
        dataToSend = statusFilterData;
      } else {
        dataToSend = lease;
      }
      filterByColumn(filteredData, unitNumberFilter, 'unit', dataToSend, unitNumberFilterData, 'number');
    }
  };

  /* function to check if the filter is applied, then calls updateFilterByPreviousValue
      else sets the data to the original one */
  const handleMultipleFiltering = () => {
    if (statusFilter.length || unitNumberFilter.length) {
      setFilterApplied(true);
      updateFilterByPreviousValue();
    } else {
      setData(props.lease);
      setMultiFilterData([]);
    }
  };

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    return (
      ''
    );
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  /* eslint-disable */
  const columns = useMemo(
    () => [
      {
        Header: 'Select',
        accessor: (item) => (
          <Checkbox
            onChange={(e) => multipleHandling(item, e)}
            checked={selectedAppsForBulkEdit.includes(item.node.id)}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'node.status',
        Cell: ({ cell: { value } }) => (value ? <CapitalizeFirstLetter value={value} /> : ''),
        // Filter: SelectStatusFilter('status'),
        filter: 'includes',
      },
      {
        Header: 'First Name',
        accessor: 'node.person.firstName',
        // Cell: ({ cell: { value } }) => (value ? <CapitalizeFirstLetter value={value} /> : ''),
      },
      {
        Header: 'Last Name',
        accessor: 'node.person.lastName',
        // Cell: ({ cell: { value } }) => (value ? <CapitalizeFirstLetter value={value} /> : ''),
      },
      {
        Header: 'Amount',
        accessor: 'node.amount',
        Cell: ({ cell: { value } }) => (value.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")),
      },
      {
        Header: 'Frequency',
        accessor: 'node.leaseFrequency',
        // Cell: ({ cell: { value } }) => (value ? <CapitalizeFirstLetter value={value} /> : ''),
      },
      {
        Header: 'Unit',
        accessor: 'node.unit.number',
        filter: 'includes',
      },
      {
        Header: 'Start Date',
        accessor: 'node.startDate',
        Cell: ({ cell: { value } }) => <SetDateFormat date={value} />,
      },
      {
        Header: 'End Date',
        accessor: 'node.endDate',
        Cell: ({ cell: { value } }) => <SetDateFormat date={value} />,
      },
      {
        Header: 'Sign Status',
        accessor: 'node.extLeaseInfo',
        Cell: ({ cell: { value } }) => <SetSign sign={value} />,
      },
      {
        Header: 'Type',
        accessor: 'node.extLeaseInfo.type',
        Cell: ({ cell: { value } }) => <SetSignType sign={value} />,
      },
      {
        Header: 'View',
        accessor: (item) => <Button className="dark-button"  icon="eye" size="mini" onClick={() => onViewDetails(item)} />,
      },
      {
        Header: 'Edit',
        accessor: (item) => (
          <Button size="mini" icon="edit" className="dark-button" disabled={item.node.status !== 'created'} onClick={() => history.push(ROUTES.editLease.replace(':leaseId', item.node.id))} />
        ),
        disableSortBy: true,
      },
      {
        Header: 'Sign',
        accessor: (item) => 
        {
          if(item.node.lgCompleted === true&&
            item.node.insuranceCompleted === true){
              if(item.node.extLeaseInfo) {
                if(!item.node.extLeaseInfo.status)
                {
                  return <Button positive onClick={() => signLease(item)} style={{fontSize:'.8rem' , padding:"5px 10px", textTransform:'capitalize', height:'auto'}} >Begin Signing</Button>
                }
                if(item.node.extLeaseInfo.status){
                  if(item.node.extLeaseInfo.status ==='signed'){
                    return <Button style={{fontSize:'.8rem' , padding:"5px 10px", textTransform:'capitalize', height:'auto'}} positive onClick={() => finalizeLease(item)}>Finalize Lease</Button>
                  }
                  if(item.node.extLeaseInfo.status ==='partial'){
                    return <Label size="mini"  className="dark-button" style={{background:"#A9A9A9.",fontSize:'.8rem' , padding:"5px 10px", textTransform:'capitalize', height:'auto'}}>Check Email</Label>
                  }
                  if(item.node.extLeaseInfo.status ==='initial'||item.node.extLeaseInfo.status ==='expired'||item.node.extLeaseInfo.status ==='sent'){
                    return <Button positive onClick={() => signLease(item)} style={{fontSize:'.8rem' , padding:"5px 10px", textTransform:'capitalize', height:'auto'}} >Begin Signing</Button>
                  }
                  else{
                   return ''
                  }
                }
              }
              else return ''
            } 
        }
        ,
        disableSortBy: true,
      },
    ],
    [props, selectedAppsForBulkEdit],
  );
  /* eslint-disable */

  const signLease = (item) => {
    mixpanel.track('Manager Lease Action', {
      'sub': 'Send Email to Sign Lease'
    });
    setDimmerDim(true)
      leaseManager.mutate({
        mutation: leaseDocuments,
        variables: {
          input: {
            lease: {
              leaseId: item.node.id,
            }
          }
        },
      }).then(() => {
        toastSuccessMsg('Email has been sent, the lease signing has begun.');
        props.getLease()
        setDimmerDim(false)
      }).catch(() => {
        toastFailMsg('Failed to sign.');
        setDimmerDim(false)
      });
  }

  const fetchIdRef = React.useRef(0);
  const [loading, setLoading] = React.useState(false);
  const [startRowIndex, setStartRowIndex] = useState(0);
  const [endRowIndex, setEndRowIndex] = useState(0);
  const [lastPage, setLastPage] = useState();

  const calculateLastPage = ({ pageSize, controlledPageCount }) => {
    const result = controlledPageCount / pageSize;
    setLastPage(Math.ceil(result));
  };
   const finalizeLease = (item) => {
    setDimmerDim(true)
    mixpanel.track('Manager Lease Action', {
      'sub': 'Finalize Lease'
    });
    bluemoonApi.mutate({
      mutation: leaseSign,
      variables: {
        input: {
          leaseId: item.node.id,
        }
      },
    }).then(() => {
      toastSuccessMsg('Lease Signing Finalized.');
      setTimeout(props.getLease() , 3000);
      setTimeout(setDimmerDim(false) , 3000);
    }).catch(() => {
      toastFailMsg('Failed to sign.');
      setDimmerDim(false)
    });
  }

  /* eslint-disable */
  const fetchData = useCallback(({ pageSize, pageIndex, filteredValue }) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
    const fetchId = ++fetchIdRef.current;
    setLoading(true);
    setFilterApplied(false);
    setTimeout(() => {
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = lease.length;
        if (statusFilter.length || unitNumberFilter.length || filteredValue) {
          setData(multiFilterData.slice(startRow, endRow));
          setPageCount(multiFilterData.length);
        } else {
          setData(lease.slice(startRow, endRow));
          setPageCount(lease.length);
        }
        setStartRowIndex(startRow);
        setEndRowIndex(endRow);
        setLoading(false);
      }
    }, 2000);
  }, [multiFilterData, lease]);
  /* eslint-enable */

  useEffect(() => {
    setData(lease);
  }, [lease]);

  return (
    <div className="table-container">
    {setDimmer && <Dimmer active inverted>
           <PageLoader text/>
          </Dimmer>}
      <ApplicationTable
        filteredValue={filterInput}
        startRow={startRowIndex}
        defaultColumn={defaultColumn}
        endRow={endRowIndex}
        onChange={handleFilterChange}
        columns={columns}
        data={data}
        loading={loading}
        fetchData={fetchData}
        pageCount={pageCount}
        completeData={lease}
        calculateLastPage={calculateLastPage}
        lastPage={lastPage}
        isFilterApplied={isFilterApplied}
        handleMultipleFiltering={handleMultipleFiltering}
      />
    </div>
  );
}