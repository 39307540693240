import React from 'react';
import { Header, Modal } from 'semantic-ui-react';
import { get, startCase } from 'lodash';
import { UNIT_AVAILABLITY_STATUS } from '../../../utils';
import { pluralize } from '../../../utils/common';

const UnitInfoModal = ({
  isOpen,
  onClose,
  unit,
  getAmenities,
}) => {
  const unitStatus = React.useMemo(() => {
    let text = startCase(unit.status).replace(' ', '');
    if (unit.status === 'off_the_market') text = 'OffTheMarket';
    return UNIT_AVAILABLITY_STATUS.find((u) => u.value === text) || {};
  }, [unit.status]);

  return (
    <Modal
      className="semanticModal custom-modal amenitie-modal"
      onClose={onClose}
      closeIcon
      open={isOpen}
      size="mini"
    >
      <Modal.Header>
        <Header>Detail</Header>
      </Modal.Header>
      <Modal.Content>
        {unit.unitImg && (
          <div className="img-box">
            <img src={unit.unitImg} alt="renter-bg" />
          </div>
        )}
        <div className="aminites-header-block">
          <div>
            <p className="unit-name">{`Unit ${unit.number}`}</p>
            <div className="small-text">
              <span>{`${Number(unit.bedrooms)} ${pluralize(unit.bedrooms, 'Bed')}`}</span>
              {' '}
              <div className="dot" />
              <span>{`${Number(unit.bathrooms)} ${pluralize(unit.bathrooms, 'Bath')}`}</span>
              {' '}
              <div className="dot" />
              {' '}
              <span>{`${Number(unit.sqft)} sq. ft.`}</span>
            </div>
          </div>
          <p className="bold-text">{`$${unit.price.toLocaleString()} /month`}</p>

        </div>
        <h5>Amenities</h5>
        <p className="ameniti-text">{getAmenities(unit.amenities)}</p>
        <div className="floor-item-row">
          <div className="row-item">
            <div className="head">Status</div>
            <div className="text">{unitStatus.text || 'NA'}</div>
          </div>
          <div className="row-item">
            <div className="head">Floor Number</div>
            <div className="text">{unit.level}</div>
          </div>
          <div className="row-item">
            <div className="head">Floor Plan</div>
            <div className="text">{get(unit, 'unitType.type', 'NA')}</div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default UnitInfoModal;
