import React from 'react';

const PropertyImages = ({
  title,
  images,
  setImages,
  tag,
  setDeletedImages,
  isMultiple = true,
}) => {
  const handleChange = (event) => {
    const { files } = event.target;
    const filesData = Object.values(files).map((file) => {
      // eslint-disable-next-line no-param-reassign
      file.previewUrl = URL.createObjectURL(file);
      return file;
    });
    setImages((prev) => ([...prev, ...filesData]));
  };

  const setDeletedImgArr = (updated, imgIndex) => {
    const data = updated.filter((file, index) => index === imgIndex);
    /* eslint-disable */
    const files = data.map((img) => {
      if (!img.previewUrl.includes('blob')) return img.previewUrl;
    });
    /* eslint-enable */
    files[0] !== undefined ? setDeletedImages((file) => ([...file, ...files])) : setDeletedImages((file) => ([...file]));
  };

  const onDelete = (imgIndex) => {
    setImages((prev) => {
      const updated = [...prev];
      tag === 'unit' && setDeletedImgArr(updated, imgIndex);
      URL.revokeObjectURL(updated.previewUrl);
      updated.splice(imgIndex, 1);
      return updated;
    });
  };

  return (
    <>
      <div className="upload-photo-container">
        <input type="file" name="property-images" accept="image/*" multiple={isMultiple} onChange={handleChange} />
        <div className="inner-section">
          <img src="assets/img/img-default.svg" alt="img-default" />
          <div className="normal-text">{title || 'Upload photos of your property'}</div>
          <div className="hint">You can upload images in .jpg, .png format.</div>
        </div>
      </div>
      <div className="preview-box">
        {images.map((img, index) => (
          <div key={index.toString()} className="img-box">
            <img src={img.previewUrl} alt="frontend-feature-cards-1" />
            <div className="overlay-box" />
            <span className="white-delete-icon cursor-pointer" onClick={() => onDelete(index)}>
              <img src="assets/img/white-delete.svg" alt="delete-btn" />
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default PropertyImages;
