/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  Grid, Icon, Popup, Dropdown,
} from 'semantic-ui-react';
import { camelCase, get } from 'lodash';
import { getClient } from '../../../init-apollo-googleFn';
import {
  LOCATIONAPI, NUMERIC_REGEX, NUMERIC_REGEX_DECIMAL, PRICE_REGEX, BLUEMOON_API, BLUEMOON_PATH,
} from '../../../utils/constants';
import { bluemoonInfo, bluemoonProperties } from '../../../store/bluemoon/bluemoon';
import { getAuthToken } from '../../../store/auth/authUtility';
import { parseGraphQLErrors, toastFailMsg, toastSuccessMsg } from '../../../utils/common';
import BluemoonSettings from './Settings';

const bluemoonApi = getClient(BLUEMOON_API);

const prepareDepositMutation = ({ depositRequired, depositAmount, depositNonrefundableAmount }) => {
  if (depositRequired === 'Yes') {
    return `depositAmount: ${depositAmount},
            depositRequired: true,
            depositRefundable: true,
            depositNonrefundableAmount: ${depositNonrefundableAmount}`;
  }
  return `depositRequired: false,
          depositRefundable: false`;
};

const PriceInput = ({
  name,
  label,
  placeholder,
  amount,
  innerRef,
}) => {
  const [isFocused, setIsFocused] = React.useState(false);
  return (
    <div className="has-float-label  with-doller">
      {(amount || isFocused) && <span className="doller-sign">$</span>}
      <input
        className="form-control"
        type="number"
        name={name}
        id={name}
        placeholder={placeholder}
        autoComplete="nope"
        min="0"
        onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
        onFocus={() => setIsFocused(true)}
        onBlur={() => !amount && setIsFocused(false)}
        ref={innerRef}
      />
      <label htmlFor={name}>{label}</label>
    </div>
  );
};

const PaymentAndPoliciesForm = ({
  depositRules,
  details,
  locationId,
  chargeCodes,
  user,
  handleSubmitting,
  onNextStep,
  onCancelClick,
  history,
  onSave,
  propertyData,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    getValues,
  } = useForm({ mode: 'onChange', defaultValues: { depositRequired: 'Yes' } });

  const [addedDocumentsPath, setAddedDocumentsPaths] = React.useState({});
  const [bluemoonData, setBluemoonData] = React.useState({});
  const [toggleSwitch, setToggleSwitch] = React.useState((details.esginType || propertyData.esginType) === 'bluemoon');
  const [bluemoonPropertiesData, setBluemoonProperties] = React.useState([]);
  const [bmLocationId, setBmLocationId] = React.useState(details.bmLocationId || propertyData.esginType || '');
  // const [esginType, setBmesginType] = React.useState(details.esginType || '');
  const [bluemoonName, setBluemoonName] = React.useState([]);

  // bluemoon settings
  // const openApplicationForm = () => {
  //   localStorage.setItem('bluemoonData', JSON.stringify(bluemoonData));
  //   history.push({
  //     pathname: '/bluemoonSettings',
  //     data: bluemoonData,
  //   });
  // };

  const storeData = async () => {
    await localStorage.setItem('bluemoonData', JSON.stringify(bluemoonData));
  };

  const fetchCredentialDetail = async () => {
    await bluemoonApi.query({
      query: bluemoonInfo,
    }).then((res) => {
      const data = res.data.bluemoonInfo.edges[0].node;
      localStorage.setItem('bluemoonData', JSON.stringify(data));
      setBluemoonData(data);
    }).catch((error) => {
      toastFailMsg(parseGraphQLErrors(error));
    });
  };

  const fetchBluemoonProperties = async () => {
    await bluemoonApi.query({
      query: bluemoonProperties,
    }).then((res) => {
      const data = res.data.bluemoonProperties.edges;
      const finalData = [];
      setBluemoonName(data[0].node.name);
      setBmLocationId(data[0].node.id);
      data.map((ele) => {
        if (ele.node.id === (details.bmLocationId || propertyData.bmLocationId)) {
          setBluemoonName(ele.node.name);
        }
        finalData.push({ key: ele.node.id, text: ele.node.name, value: ele.node.name });
        return null;
      });
      setBluemoonProperties(finalData);
    }).catch((error) => {
      toastFailMsg(parseGraphQLErrors(error));
    });
  };

  const {
    depositRequired,
    depositAmount,
    depositNonrefundableAmount,
    HOA,
    petPolicy,
    paymentPolicy,
    hoaPolicy,
    distributionPolicy,
    cancellationPolicy,
    esginType,
    applicationPolicy,
    leasePolicy,
  } = watch();

  const initializeEditForm = (data) => {
    Object.entries(data).forEach(([key, value]) => {
      const fieldName = camelCase(key);
      switch (fieldName) {
        case 'depositRequired':
        case 'leaseGuarantee':
        case 'flMonthRequired':
          setValue(fieldName, value ? 'Yes' : 'No');
          break;
        case 'hoa':
          setValue('HOA', value ? 'Yes' : 'No');
          if (value) setValue('hoaChargeCode', Number(data.hoa_charge_code));
          break;
        case 'hoaChargeCode':
          setValue(fieldName, Number(value));
          break;
        case 'esginType':
        case 'petPolicyDocPath':
        case 'paymentPolicyDocPath':
        case 'cancellationPolicyDocPath':
        case 'hoaPolicyDocPath':
        case 'distributionNoticeDocPath':
        case 'applicationPolicyDocPath':
        case 'leasePolicyDocPath':
          setAddedDocumentsPaths((prev) => ({ ...prev, [fieldName]: value }));
          break;
        default:
          setValue(fieldName, value);
          break;
      }
    });
  };

  /* eslint-disable */
  React.useEffect(() => {
    if (propertyData && Object.keys(propertyData).length !== 0) {
      initializeEditForm(propertyData);
    } else if (depositRules) initializeEditForm(depositRules);
  }, [depositRules, propertyData]);

  React.useEffect(() => {
    if (!bluemoonData.id) fetchCredentialDetail();
  }, []);

  React.useEffect(() => {
    fetchBluemoonProperties();
  }, [toggleSwitch]);
  /* eslint-enable */

  const onSubmit = (data) => {
    if (toggleSwitch && !bmLocationId) {
      toastFailMsg('Select Bluemoon Property');
    } else {
      const {
        leaseGuarantee,
        flMonthRequired,
        applicationCreditScore,
        incomeXTimeRent,
        petPolicy: petPolicyFile,
        paymentPolicy: paymentPolicyFile,
        cancellationPolicy: cancellationPolicyFile,
        hoaPolicy: hoaPolicyFile,
        distributionPolicy: distributionPolicyFile,
        applicationPolicy: applicationPolicyFile,
        leasePolicy: leasePolicyFile,
        hoaChargeCode,
      } = data;
      const formData = new FormData();
      if (esginType === 'bluemoon') {
        formData.append('query', `mutation uploadPolicies{
          uploadPolicies(policies:{
            id: "${locationId}",
            depositRules: {
             ${prepareDepositMutation(data)},
             leaseGuarantee: ${leaseGuarantee === 'Yes'},
             flMonthRequired: ${flMonthRequired === 'Yes'},
             HOA: ${data.HOA === 'Yes'},
             applicationCreditScore: "${applicationCreditScore}",
             hoaChargeCode: "${hoaChargeCode}",
             incomeXTimeRent: "${incomeXTimeRent}",
            },
            bmLocationId: "${bmLocationId}",
            esginType: bluemoon,
          }) {
            response
          }
        }`);
      } else if (esginType === 'docusign') {
        formData.append('query', `mutation uploadPolicies{
          uploadPolicies(policies:{
            id: "${locationId}",
            depositRules: {
             ${prepareDepositMutation(data)},
             leaseGuarantee: ${leaseGuarantee === 'Yes'},
             flMonthRequired: ${flMonthRequired === 'Yes'},
             HOA: ${data.HOA === 'Yes'},
             applicationCreditScore: "${applicationCreditScore}",
             hoaChargeCode: "${hoaChargeCode}",
             incomeXTimeRent: "${incomeXTimeRent}",
            },
            esginType: docusign,
          }) {
            response
          }
        }`);
      } else {
        formData.append('query', `mutation uploadPolicies{
          uploadPolicies(policies:{
            id: "${locationId}",
            depositRules: {
             ${prepareDepositMutation(data)},
             leaseGuarantee: ${leaseGuarantee === 'Yes'},
             flMonthRequired: ${flMonthRequired === 'Yes'},
             HOA: ${data.HOA === 'Yes'},
             applicationCreditScore: "${applicationCreditScore}",
             hoaChargeCode: "${hoaChargeCode}",
             incomeXTimeRent: "${incomeXTimeRent}",
            },
          }) {
            response
          }
        }`);
      }
      if (petPolicyFile && petPolicyFile.length) formData.append('petPolicy', petPolicyFile[0]);
      if (paymentPolicyFile && paymentPolicyFile.length) formData.append('paymentPolicy', paymentPolicyFile[0]);
      if (cancellationPolicyFile && cancellationPolicyFile.length) formData.append('cancellationPolicy', cancellationPolicyFile[0]);
      if (hoaPolicyFile && hoaPolicyFile.length) formData.append('hoaPolicy', hoaPolicyFile[0]);
      if (distributionPolicyFile && distributionPolicyFile.length) formData.append('distributionPolicy', distributionPolicyFile[0]);
      if (applicationPolicyFile && applicationPolicyFile.length) formData.append('applicationPolicy', applicationPolicyFile[0]);
      if (leasePolicyFile && leasePolicyFile.length) formData.append('leasePolicy', leasePolicyFile[0]);

      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: getAuthToken(),
        },
        body: formData,
      };

      handleSubmitting(true);
      fetch(LOCATIONAPI, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.data) onNextStep();
        })
        .catch((e) => toastFailMsg(parseGraphQLErrors(e)))
        .finally(() => handleSubmitting(false));
    }
  };

  const onRemoveFile = (name) => {
    const updatedFiles = { ...addedDocumentsPath };
    delete updatedFiles[name];
    setAddedDocumentsPaths(updatedFiles);
  };

  const propertyOnChangeHandler = (event, { value }) => {
    bluemoonPropertiesData.map((ele) => {
      if (ele.value === value) {
        setBmLocationId(ele.key);
        setBluemoonName(ele.value);
        // setBmesginType(value);
      }
      return null;
    });
  };

  const saveAsDraft = () => {
    const data = getValues();
    toastSuccessMsg('Saved');
    if (esginType === 'bluemoon') {
      data.bmLocationId = bmLocationId;
      data.esginType = 'bluemoon';
    } else if (esginType === 'docusign') {
      data.esginType = 'docusign';
    }
    onSave(data);
  };

  return (
    <>
      <div className="form-section m-t-3">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="white-block">
                <div className="head">Deposit Required</div>
                <div className="radio-container">
                  <label className="custom-radio">
                    Yes
                    <input type="radio" name="depositRequired" defaultChecked value="Yes" ref={register({ required: 'Please select one option' })} />
                    <span className="checkmark" />
                  </label>
                  <label className="custom-radio">
                    No
                    <input type="radio" name="depositRequired" value="No" ref={register({ required: 'Please select one option' })} />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <ErrorMessage errors={errors} name="depositRequired" as="span" className="error-msg" />
            </Grid.Column>
            {depositRequired === 'Yes' && (
              <>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <div className="form-group">
                    <PriceInput
                      name="depositAmount"
                      placeholder="Deposit Amount"
                      label="Deposit Amount"
                      amount={depositAmount}
                      innerRef={register({
                        required: 'Deposit amount is required.',
                        valueAsNumber: true,
                        pattern: {
                          value: PRICE_REGEX,
                          message: 'Please enter valid number.',
                        },
                      })}
                    />
                    <ErrorMessage errors={errors} name="depositAmount" as="span" className="error-msg" />
                  </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <div className="form-group">
                    <PriceInput
                      name="depositNonrefundableAmount"
                      placeholder="Non-Refundable Deposit"
                      label="Non-Refundable Deposit"
                      amount={depositNonrefundableAmount}
                      innerRef={register({
                        required: 'Non-Refundable Deposit is required.',
                        valueAsNumber: true,
                        validate: (value) => Number(value) <= Number(depositAmount) || 'Non-Refundable Deposit must be less than Deposit Required',
                      })}
                    />
                    <ErrorMessage errors={errors} name="depositNonrefundableAmount" as="span" className="error-msg" />
                  </div>
                </Grid.Column>
              </>
            )}
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="white-block">
                <div className="head">Lease Guarantee</div>
                <div className="radio-container">
                  <label className="custom-radio">
                    Yes
                    <input
                      type="radio"
                      name="leaseGuarantee"
                      value="Yes"
                      ref={register({ required: 'Please select one option' })}
                    />
                    <span className="checkmark" />
                  </label>
                  <label className="custom-radio">
                    No
                    <input
                      type="radio"
                      name="leaseGuarantee"
                      value="No"
                      ref={register({ required: 'Please select one option' })}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <ErrorMessage errors={errors} name="leaseGuarantee" as="span" className="error-msg" />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="white-block">
                <div className="head">First and Last Month Rent Required</div>
                <div className="radio-container">
                  <label className="custom-radio">
                    Yes
                    <input type="radio" name="flMonthRequired" value="Yes" ref={register({ required: 'Please select one option' })} />
                    <span className="checkmark" />
                  </label>
                  <label className="custom-radio">
                    No
                    <input type="radio" name="flMonthRequired" value="No" ref={register({ required: 'Please select one option' })} />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <ErrorMessage errors={errors} name="flMonthRequired" as="span" className="error-msg" />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="form-group">
                <div className="has-float-label">
                  <input
                    className="form-control"
                    type="text"
                    name="applicationCreditScore"
                    id="applicationCreditScore"
                    placeholder="Applicant Credit Score"
                    autoComplete="nope"
                    ref={register({
                      required: 'Credit Score is required.',
                      pattern: {
                        value: NUMERIC_REGEX,
                        message: 'Please enter valid number',
                      },
                    })}
                  />
                  <label htmlFor="applicationCreditScore">Applicant Credit Score</label>
                </div>
                <ErrorMessage errors={errors} name="applicationCreditScore" as="span" className="error-msg" />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="form-group">
                <div className="has-float-label">
                  <input
                    className="form-control"
                    type="text"
                    name="incomeXTimeRent"
                    id="incomeXTimeRent"
                    placeholder="Income x times of rent"
                    autoComplete="nope"
                    ref={register({
                      required: 'Income is required.',
                      pattern: {
                        value: NUMERIC_REGEX_DECIMAL,
                        message: 'Please enter valid number',
                      },
                    })}
                  />
                  <label htmlFor="incomeXTimeRent">
                    Income x times of rent
                    <Popup
                      trigger={<Icon corner="top right" fitted name="question circle" className="px-1" />}
                      content="Income x times of rent"
                      size="mini"
                    />
                  </label>
                </div>
                <ErrorMessage errors={errors} name="incomeXTimeRent" as="span" className="error-msg" />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="white-block">
                <div className="head">
                  HOA
                  <Popup
                    trigger={<Icon corner="top right" fitted name="question circle" className="px-1" />}
                    content="House owner association?"
                    size="mini"
                  />
                </div>
                <div className="radio-container">
                  <label className="custom-radio">
                    Yes
                    <input type="radio" name="HOA" value="Yes" ref={register({ required: 'Please select one option' })} />
                    <span className="checkmark" />
                  </label>
                  <label className="custom-radio">
                    No
                    <input type="radio" name="HOA" value="No" ref={register({ required: 'Please select one option' })} />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <ErrorMessage errors={errors} name="HOA" as="span" className="error-msg" />
            </Grid.Column>
            {HOA === 'Yes' && (
              <>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  {addedDocumentsPath.distributionNoticeDocPath ? (
                    <div className="white-block policy-wrap">
                      <h3 className="m-t-4">Distribution Notice</h3>
                      <div className="policy-col">
                        <p>
                          <i className="fa fa-file" aria-hidden="true" />
                          <a href={addedDocumentsPath.distributionNoticeDocPath} target="_blank" rel="noopener noreferrer">View Distribution Notice</a>
                        </p>
                        <Icon name="cancel" className="cursor-pointer" onClick={() => onRemoveFile('distributionNoticeDocPath')} />
                      </div>
                    </div>
                  ) : (
                    <div className="form-group">
                      <div className="white-block textarea-label fileupload-rw">
                        <label>Distribution of notices</label>
                        <div className="file-upload">
                          <input className="form-control" type="file" name="distributionPolicy" ref={register({ required: 'Please upload distribution policy.' })} />
                          <label>{get(distributionPolicy, '[0].name') || 'upload distribution notice file'}</label>
                        </div>
                      </div>
                      <ErrorMessage errors={errors} name="distributionPolicy" as="span" className="error-msg" />
                    </div>
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  {addedDocumentsPath.hoaPolicyDocPath ? (
                    <div className="white-block policy-wrap">
                      <h3 className="m-t-4">HOA Policy</h3>
                      <div className="policy-col">
                        <p>
                          <i className="fa fa-file" aria-hidden="true" />
                          <a href={addedDocumentsPath.hoaPolicyDocPath} target="_blank" rel="noopener noreferrer">View HOA Policy</a>
                        </p>
                        <Icon name="cancel" className="cursor-pointer" onClick={() => onRemoveFile('hoaPolicyDocPath')} />
                      </div>
                    </div>
                  ) : (
                    <div className="form-group">
                      <div className="white-block textarea-label fileupload-rw">
                        <label>HOA Policies</label>
                        <div className="file-upload">
                          <input className="form-control" type="file" name="hoaPolicy" ref={register({ required: 'Please upload HOA policy.' })} />
                          <label>{get(hoaPolicy, '[0].name') || 'upload HOA policies file'}</label>
                        </div>
                      </div>
                      <ErrorMessage errors={errors} name="hoaPolicy" as="span" className="error-msg" />
                    </div>
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <div className="form-group">
                    <div className="has-float-label">
                      <input
                        className="form-control"
                        type="number"
                        name="hoaChargeCode"
                        id="hoaChargeCode"
                        placeholder="HOA due charge code"
                        autoComplete="nope"
                        min="0"
                        ref={register({
                          required: 'HOA due charge code is required.',
                          pattern: {
                            value: NUMERIC_REGEX,
                            message: 'Please enter valid number',
                          },
                        })}
                      />
                      <label htmlFor="hoaChargeCode">HOA due charge code</label>
                    </div>
                    <ErrorMessage errors={errors} name="hoaChargeCode" as="span" className="error-msg" />
                  </div>
                </Grid.Column>
              </>
            )}
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="white-block">
                <div className="head">
                  Application, Lease, and Policy Documents
                </div>
                <div className="radio-container">
                  <label className="custom-radio">
                    Upload your own application and lease documents
                    <input type="radio" name="esginType" value="docusign" defaultChecked={details.esginType === 'docusign' || propertyData.esginType === 'docusign'} ref={register({ required: 'Please select one option' })} />
                    <span className="checkmark" />
                  </label>
                  <label className="custom-radio">
                    Use automated form creation by Bluemoon
                    <input
                      type="radio"
                      name="esginType"
                      value="bluemoon"
                      defaultChecked={details.esginType === 'bluemoon' || propertyData.esginType === 'bluemoon'}
                      onChange={() => setToggleSwitch(true)}
                      ref={register({ required: 'Please select one option' })}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <ErrorMessage errors={errors} name="esginType" as="span" className="error-msg" />
            </Grid.Column>
            {esginType === 'docusign' && (
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <div className="connect-bluemoon">
                  <div className="left-wrap">
                    <p>
                      Please ensure that the policy documents that you are uploading have designated
                      placeholders for renters and property owners signature.
                      This is to ensure that both parties know exactly where to sign on different
                      policy documents.
                    </p>
                  </div>
                  <a href="/assets/multipleSample.pdf" target="_blank">Example</a>
                </div>
              </Grid.Column>
            )}
            {esginType === 'bluemoon' && (
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="connect-bluemoon">
                <div className="left-wrap">
                  <h6>Connect Bluemoon</h6>
                  <p>
                    Please connect your Bluemoon account from the settings section and reveal the benefits of
                    features like e-signature and others.
                    <div className="benefits-txt-wrap">
                      <span data-toggle="modal" data-target="#BenefitsModal">View Benefits</span>
                      {' '}
                      |
                        <span data-toggle="modal" data-target="#InstructionsModal">See Instructions</span>
                    </div>
                  </p>
                </div>
                <button type="button" onClick={() => storeData()} data-toggle="modal" data-target="#CredentialsModal">Setup Bluemoon</button>
                {/* <button type="button" onClick={openApplicationForm}>Go to Settings</button> */}
              </div>
              {bluemoonData.id && (
              <div className="enable-bluemoon">
                <div className="top-header">
                  <div className="left-data">
                    <h6>Enable Bluemoon</h6>
                    {/* <p>

                    </p> */}
                  </div>
                  {/* <div className="toggle-button-cover" data-toggle="modal" data-target="#EnableModal">
                    <div className="button-cover">
                      <div className="button r" id="button-1">
                        <input type="checkbox" className="checkbox" checked={toggleSwitch} onChange={() => setToggleSwitch(!toggleSwitch)} />
                        <div className="knobs" />
                        <div className="layer" />
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="has-float-label select-float-label">
                  <Dropdown
                    fluid
                    disabled={!toggleSwitch}
                    selection
                    value={bluemoonName}
                    options={bluemoonPropertiesData}
                    onChange={propertyOnChangeHandler}
                  />
                  {/* )} */}
                  {/* /> */}
                  <label htmlFor="locationTypeId">Bluemoon Properties</label>
                </div>
              </div>
              )}
            </Grid.Column>
            )}
            <div className="modal fade benefits-modal" id="BenefitsModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <img src="/assets/img/bluemoon-logo.png" alt="bluemoon-logo" />
                    <h5>Benefits of bluemoon</h5>
                    <div className="content-wrap">
                      <ul>
                        <li>
                          <p>The “Blue Moon” program is a national technology platform that allows customers to access NAA Click & Lease, TAA Click & Lease and GAA Forms Online (Georgia only).  This solution is an optional benefit to members of the appropriate local apartment association.  To learn more about available forms and options, please reach out to Blue Moon Software</p>
                        </li>
                        <li>
                          <p>There would be a potentially incremental cost for the larger properties.</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <a href={BLUEMOON_PATH} target="_blank" rel="noopener noreferrer" type="button" className="btn btn-primary">Register on Bluemoon</a>
                  </div>
                </div>
              </div>
            </div>
            {/* Instruction Modal */}
            <div className="modal fade benefits-modal instruction-modal" id="InstructionsModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <img src="/assets/img/bluemoon-logo.png" alt="bluemoon-logo" />
                    <h5>Create account on Bluemoon</h5>
                    <div className="content-wrap">
                      <ul>
                        <li>
                          <p>The “Blue Moon” program is a national technology platform that allows customers to access NAA Click & Lease, TAA Click & Lease and GAA Forms Online (Georgia only).  This solution is an optional benefit to members of the appropriate local apartment association.  To learn more about available forms and options, please reach out to Blue Moon Software</p>
                        </li>
                        <li>
                          <p>There would be a potentially incremental cost for the larger properties.</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <a href={BLUEMOON_PATH} target="_blank" rel="noopener noreferrer" type="button" className="btn btn-primary">Register on Bluemoon</a>
                  </div>
                </div>
              </div>
            </div>
            {/* Enable Bluemoon Modal */}
            <div className="modal fade enable-modal" id="EnableModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="left-data">
                      <img src="/assets/img/icons-info.svg" alt="info" />
                      <div className="content-data">
                        <h5>Upload policy documents on Bluemoon separately</h5>
                        <p>After enabling Bluemoon you have to submit your policy documents on Bluemoon portal separately. You can still upload documents on Leasera as well for your future references.</p>
                      </div>
                    </div>
                    <div className="right-data">
                      <button type="button" data-dismiss="modal" aria-label="Close">Got It</button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            {esginType === 'docusign' && (
              <Grid.Column mobile={16} tablet={16} computer={16}>
                {addedDocumentsPath.applicationPolicyDocPath ? (
                  <div className="white-block policy-wrap">
                    <h3 className="m-t-4">Application Policy</h3>
                    <div className="policy-col">
                      <p>
                        <i className="fa fa-file" aria-hidden="true" />
                        <a href={addedDocumentsPath.applicationPolicyDocPath} target="_blank" rel="noopener noreferrer">View Application Policy</a>
                      </p>
                      <Icon name="cancel" className="cursor-pointer" onClick={() => onRemoveFile('applicationPolicyDocPath')} />
                    </div>
                  </div>
                ) : (
                  <div className="form-group">
                    <div className="white-block textarea-label fileupload-rw">
                      <label>Upload application </label>
                      <div className="file-upload">
                        <input className="form-control" type="file" name="applicationPolicy"  />
                        <label>{get(applicationPolicy, '[0].name') || 'upload application policy file'}</label>
                      </div>
                    </div>
                    <ErrorMessage errors={errors} name="applicationPolicy" as="span" className="error-msg" />
                  </div>
                )}
              </Grid.Column>
            )}
            {esginType === 'docusign' && (
              <>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  {addedDocumentsPath.leasePolicyDocPath ? (
                    <div className="white-block policy-wrap">
                      <h3 className="m-t-4">
                        Lease Policy
                      </h3>
                      <div className="policy-col">
                        <p>
                          <i className="fa fa-file" aria-hidden="true" />
                          <a href={addedDocumentsPath.leasePolicyDocPath} target="_blank" rel="noopener noreferrer">View Lease Policy</a>
                        </p>
                        <Icon name="cancel" className="cursor-pointer" onClick={() => onRemoveFile('leasePolicyDocPath')} />
                      </div>
                    </div>
                  ) : (
                    <div className="form-group">
                      <div className="white-block textarea-label fileupload-rw">
                        <label>Upload lease</label>
                        <div className="file-upload">
                          <input className="form-control" type="file" name="leasePolicy" ref={esginType === 'docusign' && register({ required: 'Lease policy is required.' })} />
                          <label>{get(leasePolicy, '[0].name') || 'upload lease policy file'}</label>
                        </div>
                      </div>
                      <ErrorMessage errors={errors} name="leasePolicy" as="span" className="error-msg" />
                    </div>
                  )}
                </Grid.Column>

                <Grid.Column mobile={16} tablet={16} computer={16}>
                  {addedDocumentsPath.petPolicyDocPath ? (
                    <div className="white-block policy-wrap">
                      <h3 className="m-t-4">Pet Policy</h3>
                      <div className="policy-col">
                        <p>
                          <i className="fa fa-file" aria-hidden="true" />
                          <a href={addedDocumentsPath.petPolicyDocPath} target="_blank" rel="noopener noreferrer">View Pet Policy</a>
                        </p>
                        <Icon name="cancel" className="cursor-pointer" onClick={() => onRemoveFile('petPolicyDocPath')} />
                      </div>
                    </div>
                  ) : (
                    <div className="form-group">
                      <div className="white-block textarea-label fileupload-rw">
                        <label>Pet Policy</label>
                        <div className="file-upload">
                          <input className="form-control" type="file" name="petPolicy"  />
                          <label>{get(petPolicy, '[0].name') || 'upload pet policy file'}</label>
                        </div>
                      </div>
                      <ErrorMessage errors={errors} name="petPolicy" as="span" className="error-msg" />
                    </div>
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  {addedDocumentsPath.paymentPolicyDocPath ? (
                    <div className="white-block policy-wrap">
                      <h3 className="m-t-4">Payment Policy</h3>
                      <div className="policy-col">
                        <p>
                          <i className="fa fa-file" aria-hidden="true" />
                          <a href={addedDocumentsPath.paymentPolicyDocPath} target="_blank" rel="noopener noreferrer">View Payment Policy</a>
                        </p>
                        <Icon name="cancel" className="cursor-pointer" onClick={() => onRemoveFile('paymentPolicyDocPath')} />
                      </div>
                    </div>
                  ) : (
                    <div className="form-group">
                      <div className="white-block textarea-label fileupload-rw">
                        <label>Payment Policy</label>
                        <div className="file-upload">
                          <input className="form-control" type="file" name="paymentPolicy"  />
                          <label>{get(paymentPolicy, '[0].name') || 'upload payment policy file'}</label>
                        </div>
                      </div>
                      <ErrorMessage errors={errors} name="paymentPolicy" as="span" className="error-msg" />
                    </div>
                  )}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  {addedDocumentsPath.cancellationPolicyDocPath ? (
                    <div className="white-block policy-wrap">
                      <h3 className="m-t-4">Cancellation Policy</h3>
                      <div className="policy-col">
                        <p>
                          <i className="fa fa-file" aria-hidden="true" />
                          <a href={addedDocumentsPath.cancellationPolicyDocPath} target="_blank" rel="noopener noreferrer">View Cancellation Policy</a>
                        </p>
                        <Icon name="cancel" className="cursor-pointer" onClick={() => onRemoveFile('cancellationPolicyDocPath')} />
                      </div>
                    </div>
                  ) : (
                    <div className="form-group">
                      <div className="white-block textarea-label fileupload-rw">
                        <label>Cancellation Policy</label>
                        <div className="file-upload">
                          <input className="form-control" type="file" name="cancellationPolicy"  />
                          <label>{get(cancellationPolicy, '[0].name') || 'upload cancellation policy file'}</label>
                        </div>
                      </div>
                      <ErrorMessage errors={errors} name="cancellationPolicy" as="span" className="error-msg" />
                    </div>
                  )}
                </Grid.Column>
              </>
            )}
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="btn-block">
                <div>
                  <button type="button" className="btn cancel-btn" onClick={onCancelClick}>Cancel</button>
                </div>
                <div>
                  <button type="button" className="btn next-btn" onClick={saveAsDraft}>Save</button>
                </div>
                <div>
                  <button type="submit" className="btn next-btn">Proceed to step 4</button>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </form>
        {/* <div>
        <button type="button" className="btn next-btn" onClick={saveAsDraft}>Save</button>
      </div> */}
      </div>
      {/* Credentials Modal */}
      <div className="modal fade benefits-modal credentials-modal" id="CredentialsModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h5>Bluemoon Credentials</h5>
              <BluemoonSettings user={user} bluemoonData={bluemoonData} renderForm fetchCrDetails={fetchCredentialDetail} fetchBmProperties={fetchBluemoonProperties} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentAndPoliciesForm;
