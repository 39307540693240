import React, { Component } from 'react'
import {  Form,   Button, Modal , TextArea, Header } from 'semantic-ui-react';
import { withApollo } from "react-apollo";
import {  updateMaintenance } from "../../store/person/maintenance"
import { MAINTENANCE_URL } from "../../utils/constants";
import { getClient } from "../../init-apollo-googleFn";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import mixpanel from 'mixpanel-browser';


const maintenanceClient = getClient(MAINTENANCE_URL);
class CancelModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reason: '',
      modalOpen: false
    }
  }
  handleClose = () => this.setState({ modalOpen: false })
  handleCancelSubmit = (e) => {
    const {reason}= this.state
    try {
      mixpanel.track('Manager Maintenance Action', {
        'sub': 'Cancel Maintenance Request'
      });
      maintenanceClient.mutate({
        mutation: updateMaintenance,
        variables: {
          input: {
            maintenance:{
              role:"manager",
              id: this.props.props.node.id,
              location: this.props.props.node.location,
              status:"Canceled",
              reason: reason.toString(),
            }
          }
        }
      })
        .then((res) => {
          this.success()
          this.props.refreshTable(this.props.props.node.id)
          this.handleClose()
          this.setState({ showModal: false });
        })
        .catch(error => {
          this.props.refreshTable(this.props.props.node.id)
          console.log(error)
        });
    } catch (e) {
      console.log(e);
    }
  };
  success = () => toast.success('Canceled', {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });


  handleChange = (name, e) => {
    const { value } = e.target;
    this.setState({
      [name]: value,
    });
  }


  inviteForm = () => (
    <Form>
      <TextArea
        placeholder='Please let us know why you are canceling this request.'
        name='reason'
        className="height-90"
        onChange={(event) => { this.handleChange('email', event) }}
      />
    </Form>
  )
  render() {
    return (
      <>
        <Modal
          className="semanticModal add-lead-modal"
          onClose={() => this.handleClose()}
          onOpen={() => this.setState({ modalOpen: true })}
          open={this.state.modalOpen}
          size="tiny"
          trigger={<Button compact size="tiny" icon='cancel' /> }
        >
          <Modal.Header textalign="center" className="modal-header-bg">
            <Header className="modal-heading-custom position-sticky" textAlign="left">Cancel Request</Header>
          </Modal.Header>

          <Modal.Content >
            <this.inviteForm />
          </Modal.Content>
          <Modal.Actions>
            <Button positive className="modal-close-button" onClick={() => this.handleClose()}>
              Cancel
              </Button>
            <Button type='button' positive className="modal-save-button" content='Submit' onClick={this.handleCancelSubmit}>Submit</Button>
          </Modal.Actions>
        </Modal>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}

        />
      </>
    )
  }
}
export default withApollo(CancelModal)
