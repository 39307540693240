import React, { useState } from 'react';
import {
  Button, Modal, Dropdown, Header,
} from 'semantic-ui-react';
import './moveLeadModal.scss';

export default function BulkEditModal(props) {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState('');

  const changeStatus = (e) => {
    let keyName = '';
    if (e.target.textContent === 'Move In') {
      keyName = 'MoveIn';
    } else if (e.target.textContent === 'Move Out') {
      keyName = 'MoveOut';
    } else {
      keyName = e.target.textContent;
    }
    const value = e.target.textContent.includes('Change Status') ? '' : keyName;
    setStatus(value);
  };

  return (
    <Modal
      className="semanticModal modal-ui modal-radius overflow-y-visible"
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button disabled={props.showButton} className="btn-primary-light btn-med">Bulk Edit</Button>}
    >
      <Modal.Header textAlign="left" className="modal-header-bg position-sticky">
        <Header className="modal-heading-custom position-sticky" textAlign="left">Change Status</Header>
      </Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          <Dropdown
            fluid
            text={status || 'Change Status'}
            selection
            options={props.values}
            onChange={(e) => changeStatus(e)}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="modal-close-button"
          content="Close"
          onClick={() => setOpen(false)}
          positive
        />
        { props.sending
          ? <Button>Loading</Button>
          : (
            <Button
              className="modal-save-button"
              content="Update"
              disabled={!status}
              onClick={() => props.updateBulkEdit(status)}
              positive
            />
          )}
      </Modal.Actions>
    </Modal>
  );
}
