import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Grid, Button, Icon } from 'semantic-ui-react';
import { getClient } from '../../../init-apollo-googleFn';
import { AlterLocation } from '../../../store/person/properties';
import { parseGraphQLErrors, toastFailMsg, toastSuccessMsg } from '../../../utils/common';
import {
  LOCATIONAPI, WEBSITE_REGEX, EMAIL_REGEX, PHONE_REGEX,
} from '../../../utils';

const locationClient = getClient(LOCATIONAPI);

const ScheduleSetup = ({
  register,
  errors,
  onRemoveHours,
  days,
}) => (
  <div className="grey-block">
    <div className="head align-items-center d-flex justify-content-between">
      Business Hours
      <Button
        type="button"
        className="cursor-pointer link-btn"
        icon
        onClick={() => onRemoveHours(false)}
      >
        <Icon name="cancel" className="text-danger" />
        <span className="text-danger">Remove</span>
      </Button>
    </div>
    <p>Set hours of operation</p>
    <div className="form-row">
      <Grid className="input-section">
        <Grid.Column computer={16}>
          {days.map((k, i) => (
            !days[i][0]
                  && (
                  <div onClick={() => days[i][1](!days[i][0])} className="business-hours-toggle" style={{ display: 'inline', paddingRight: '10px' }}>
                    <Icon name="undo" className="text-success" />
                    <span className="text-danger">{`${days[i][2].replace(days[i][2].charAt(0), days[i][2].charAt(0).toUpperCase())}'s Hours`}</span>
                  </div>
                  )
          ))}
        </Grid.Column>
        {days.map((k, i) => (
          days[i][0]
                && (
                <>
                  <Grid.Column computer={4}>
                    <h1>{days[i][2].replace(days[i][2].charAt(0), days[i][2].charAt(0).toUpperCase())}</h1>
                  </Grid.Column>
                  <Grid.Column computer={5}>
                    <label htmlFor="fromTime">From</label>
                    <input
                      className="form-control"
                      type="time"
                      name={`schedule.${days[i][2]}From`}
                      ref={register({ required: 'Please select a time, or select closed' })}
                    />
                    <ErrorMessage errors={errors} name={`schedule.${days[i][2]}From`} as="span" className="error-msg" />
                  </Grid.Column>
                  <Grid.Column computer={5}>
                    <label htmlFor="toTime">To</label>
                    <input
                      className="form-control"
                      type="time"
                      name={`schedule.${days[i][2]}To`}
                      ref={register({ required: 'Please select a time, or select closed' })}
                    />
                    <ErrorMessage errors={errors} name={`schedule.${days[i][2]}To`} as="span" className="error-msg" />
                  </Grid.Column>
                  <Grid.Column
                    computer={2}
                    className="business-hours-toggle"
                    onClick={() => days[i][1](!days[i][0])}
                  >
                    <Icon name="cancel" className="text-danger" />
                    <span className="text-danger">Closed</span>
                  </Grid.Column>
                </>
                )
        ))}
      </Grid>
    </div>
  </div>
);

const MarketingForm = ({
  details,
  locationId,
  onNextStep,
  handleSubmitting,
  orgDetails,
  onCancelClick,
  onSave,
  marketingData,
}) => {
  const formMethods = useForm({ mode: 'onChange' });
  const {
    register, handleSubmit, errors, setValue, getValues,
  } = formMethods;
  const [addingHours, setAddingHours] = React.useState(false);
  const [schedule, setSchedule] = React.useState([{
    mondayFrom: '',
    mondayTo: '',
    tuesdayFrom: '',
    tuesdayTo: '',
    wednesdayFrom: '',
    wednesdayTo: '',
    thursdayFrom: '',
    thursdayTo: '',
    fridayFrom: '',
    fridayTa: '',
    saturdayFrom: '',
    saturdayTo: '',
    sundayFrom: '',
    sundayTo: '',
  }]);

  const [monday, setMonday] = useState(true);
  const [tuesday, setTuesday] = useState(true);
  const [wednesday, setWednesday] = useState(true);
  const [thursday, setThursday] = useState(true);
  const [friday, setFriday] = useState(true);
  const [saturday, setSaturday] = useState(true);
  const [sunday, setSunday] = useState(true);
  const days2D = [
    [monday, setMonday, 'monday'],
    [tuesday, setTuesday, 'tuesday'],
    [wednesday, setWednesday, 'wednesday'],
    [thursday, setThursday, 'thursday'],
    [friday, setFriday, 'friday'],
    [saturday, setSaturday, 'saturday'],
    [sunday, setSunday, 'sunday'],
  ];

  const setScheduleData = (schedule) => {
    days2D.forEach((k, i) => days2D[i][1](false));
    Object.entries(schedule).forEach(([key, value]) => {
      if (value === '') {
        delete schedule[key];
      }
      switch (key) {
        case 'mondayFrom':
          days2D[0][1](true);
          setAddingHours(Boolean(value.length));
          setValue('schedule.mondayFrom', value);
          setSchedule(value);
          break;
        case 'mondayTo':
          setAddingHours(Boolean(value.length));
          setValue('schedule.mondayTo', value);
          setSchedule(value);
          break;
        case 'tuesdayFrom':
          days2D[1][1](true);
          setAddingHours(Boolean(value.length));
          setValue('schedule.tuesdayFrom', value);
          setSchedule(value);
          break;
        case 'tuesdayTo':
          setAddingHours(Boolean(value.length));
          setValue('schedule.tuesdayTo', value);
          setSchedule(value);
          break;
        case 'wednesdayFrom':
          days2D[2][1](true);
          setAddingHours(Boolean(value.length));
          setValue('schedule.wednesdayFrom', value);
          setSchedule(value);
          break;
        case 'wednesdayTo':
          setAddingHours(Boolean(value.length));
          setValue('schedule.wednesdayTo', value);
          setSchedule(value);
          break;
        case 'thursdayFrom':
          days2D[3][1](true);
          setAddingHours(Boolean(value.length));
          setValue('schedule.thursdayFrom', value);
          setSchedule(value);
          break;
        case 'thursdayTo':
          setAddingHours(Boolean(value.length));
          setValue('schedule.thursdayTo', value);
          setSchedule(value);
          break;
        case 'fridayFrom':
          days2D[4][1](true);
          setAddingHours(Boolean(value.length));
          setValue('schedule.fridayFrom', value);
          setSchedule(value);
          break;
        case 'fridayTo':
          setAddingHours(Boolean(value.length));
          setValue('schedule.fridayTo', value);
          setSchedule(value);
          break;
        case 'saturdayFrom':
          days2D[5][1](true);
          setAddingHours(Boolean(value.length));
          setValue('schedule.saturdayFrom', value);
          setSchedule(value);
          break;
        case 'saturdayTo':
          setAddingHours(Boolean(value.length));
          setValue('schedule.saturdayTo', value);
          setSchedule(value);
          break;
        case 'sundayFrom':
          days2D[6][1](true);
          setAddingHours(Boolean(value.length));
          setValue('schedule.sundayFrom', value);
          setSchedule(value);
          break;
        case 'sundayTo':
          setAddingHours(Boolean(value.length));
          setValue('schedule.sundayTo', value);
          setSchedule(value);
          break;
        default:
          setValue(key, value);
          break;
      }
    });
  };

  /* eslint-disable */
  React.useEffect(() => {
    if (details.contact !== null) {
      const {
        socials, schedule, phone, email,
      } = JSON.parse(details.contact);
      if(socials){
        Object.entries(socials).forEach(([key, value]) => {
          switch (key) {
            case 'facebook':
              setValue('facebook', value);
              break;
            case 'twitter':
              setValue('twitter', value);
              break;
            case 'linkedIn':
              setValue('linkedIn', value);
              break;
            case 'instagram':
              setValue('instagram', value);
              break;
            case 'yelp':
              setValue('yelp', value);
              break;
            default:
              setValue(key, value);
              break;
          }
        });
        
      }
      setValue('phone', phone);
      setValue('email', email);
      if (schedule) {
        setScheduleData(schedule);
      }
    }
    if (marketingData && Object.keys(marketingData).length !== 0) {
      const {
        email, phone, twitter, yelp, facebook, instagram, linkedIn, schedule,
      } = marketingData;
      setValue('facebook', facebook);
      setValue('instagram', instagram);
      setValue('yelp', yelp);
      setValue('linkedIn', linkedIn);
      setValue('twitter', twitter);
      setValue('phone', phone);
      setValue('email', email);
      if (schedule) {
        setScheduleData(schedule);
      }
    }
  }, [orgDetails, marketingData]);
  /* eslint-enable */

  const onSubmit = (data) => {
    const { schedule, phone, email } = data;
    const socials = Object.entries(data).filter((a) => WEBSITE_REGEX.test(a[1])).map((k, i) => k);
    const socialsKeys = socials.map((k, i) => k[0]);
    const socialsValues = socials.map((k, i) => k[1]);
    const socialsObj = socialsValues.reduce((socialsObj, field, index) => {
      socialsObj[socialsKeys[index]] = field;
      return socialsObj;
    }, {});
    handleSubmitting(true);
    try {
      locationClient.mutate({
        mutation: AlterLocation,
        variables: {
          input: {
            id: locationId,
            contact: JSON.stringify({
              socials: socialsObj, schedule, email, phone,
            }),
          },
        },
      }).then((response) => {
        if (response.data) onNextStep();
      }).catch((e) => toastFailMsg(parseGraphQLErrors(e)))
        .finally(() => handleSubmitting(false));
    } catch (e) {
      console.log(e);
    }
  };

  const onToggleHours = (status) => {
    if (status) {
      setAddingHours(true);
    } else {
      setSchedule([]);
      setAddingHours(false);
    }
  };

  const socialsArr = [
    'yelp',
    'twitter',
    'facebook',
    'linkedIn',
    'instagram',

  ];

  const saveAsDraft = () => {
    const data = getValues();
    toastSuccessMsg('Saved');
    onSave(data);
  };

  return (
    <>
      <div className="form-section m-t-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="head align-items-center d-flex justify-content-between" style={{ fontWeight: 'bold' }}>
            Location Contact Info
          </div>
          <Grid>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="form-group">
                <div className="has-float-label">

                  <input
                    className="form-control"
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder="Phone"
                    ref={register({
                      required: 'Property Phone is required.',
                      pattern: {
                        value: PHONE_REGEX,
                        message: 'Please enter 10 digits',
                      },
                    })}
                    autoComplete="off"
                  />
                  <label htmlFor="phone">Phone</label>
                </div>
                <ErrorMessage errors={errors} name="phone" as="span" className="error-msg" />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="form-group">
                <div className="has-float-label">

                  <input
                    className="form-control"
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email"
                    ref={register({
                      required: 'Property Email is required.',
                      pattern: {
                        value: EMAIL_REGEX,
                        message: 'Please enter the email as Example@example.com',
                      },
                    })}
                    autoComplete="off"
                  />
                  <label htmlFor="email">Email</label>
                </div>
                <ErrorMessage errors={errors} name="email" as="span" className="error-msg" />
              </div>
            </Grid.Column>
          </Grid>
          <div className="head align-items-center d-flex justify-content-between" style={{ fontWeight: 'bold' }}>
            Social Media
          </div>
          <Grid>
            {socialsArr.map((k, i) => (
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <div className="form-group">
                  <div className="has-float-label">

                    <input
                      className="form-control"
                      type="text"
                      id={socialsArr[i]}
                      name={socialsArr[i]}
                      placeholder={socialsArr[i].replace(socialsArr[i].charAt(0), socialsArr[i].charAt(0).toUpperCase())}
                      ref={register({
                        pattern: {
                          value: WEBSITE_REGEX,
                          message: `Please follow the example format or leave blank. Example: ${socialsArr[i]}.com/my-property`,
                        },
                      })}
                      autoComplete="off"
                    />

                    <label htmlFor={socialsArr[i]}>{socialsArr[i].replace(socialsArr[i].charAt(0), socialsArr[i].charAt(0).toUpperCase())}</label>
                  </div>
                  <ErrorMessage errors={errors} name={socialsArr[i]} as="span" className="error-msg" />
                </div>
              </Grid.Column>
            ))}

            <Grid.Column mobile={16} tablet={16} computer={16}>
              {addingHours ? (
                <ScheduleSetup
                  {...formMethods}
                  schedule={schedule}
                  onRemoveHours={onToggleHours}
                  days={days2D}
                />
              ) : (
                <div className="d-flex">
                  <Button
                    type="button"
                    className="cursor-pointer link-btn"
                    icon
                    onClick={() => onToggleHours(true)}
                  >
                    <Icon name="add" className="text-priamry" />
                    <span className="text-priamry">Add Business Hours</span>
                  </Button>
                </div>
              )}
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="btn-block">
                <div>
                  <button type="button" className="btn cancel-btn" onClick={onCancelClick}>Cancel</button>
                </div>
                <div>
                  <button type="button" className="btn next-btn" onClick={saveAsDraft}>Save</button>
                </div>
                <div>
                  <button type="submit" className="btn next-btn">Proceed to Final Step</button>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default MarketingForm;
