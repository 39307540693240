import React, { Component } from "react";
import { withApollo } from "react-apollo"
import mixpanel from 'mixpanel-browser';
import { qpFormWizard } from "../../utils/misc";
import { NUMBERS, NUM_ARR } from "../../utils/constants";
import UnitInformation from "../../components/Property/UnitInformation"
import "./AddProperty.scss";
import moment from 'moment'
import get from 'lodash/get'
import FlashMessage from "../../components/Messages/FlashMessage";

const unitInfo = ['unitNum', 'floorNum', 'availDate', 'latestCarbonMonoxTest', 'latestSmokeDetectTest', 'description', 'sqFeet', 'maxOccupancy', 'rent', 'beds', 'baths']
const fields = [...unitInfo]

class AddUnit extends Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = fields.reduce((output, field) => (
      { ...output, [field]: '' }),
      {
        errors: {},
        formSubmit: false,
        edit: false,
        petsPermitted: false,
        message: '',
        isError: false,
        submitting: false
      }
    )
  }

  componentDidMount() {
    qpFormWizard();
    this.setState({
      beds: NUM_ARR[0],
      baths: NUM_ARR[0],
      maxOccupancy: NUM_ARR[0],
      petsPermitted: false
    })
    mixpanel.track('Manager Page Load', {
      'sub': 'Add Unit'
     });

  }

  setEditForm = (response) => {
    const propertyUnit = get(response, 'data.properties.edges', null)[0].node
    const { place, location } = propertyUnit
    this.setState({
      description: place.description.replace(/(?:\r\n|\r|\n)/g, ' '),
      rent: place.price,
      sqFeet: place.size,
      beds: place.bedrooms,
      baths: place.bathrooms,
      maxOccupancy: place.maxOccupancy,
      petsPermitted: place.petsPermitted,
      unitNum: location.unitNum,
      floorNum: location.floor,
      availDate: place.available,
      latestCarbonMonoxTest: place.carbonMonoxTest,
      latestSmokeDetectTest: place.smokeDetectTest
    })
  }

  handleDayClick = (day, { selected }, event) => {
    const name = event.props.id
    const formattedDate = moment(day).format("YYYY-MM-DD")
    this.setState(state => ({
      [name]: formattedDate,
      errors: { ...state.errors, [name]: this.validateField(name, formattedDate) },
    }))
  }

  handleChange(e) {
    const isChecked = e.target.checked;
    this.setState({ petsPermitted: isChecked });
  }

  numbersList(arr) {
    let list = [];
    arr.map((number) => {
      list.push(<option key={number} value={number}>{number}</option>)
      return null
    })
    return list
  }

  onFormFieldChange = event => {
    const target = event.target
    const name = target.id
    const value = target.value

    this.setState(state => ({
      [name]: value,
      errors: { ...state.errors, [name]: this.validateField(name, value) },
    }))
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    this.setState({
      formSubmit: true
    })
    if (this.isValid()) {
      this.createPropObj()
      this.submitNewPropReq()
    }
  }

  

  onSubmit = () => {this.setState({submitting: false})}

  validateField = (name, value) => {
    const dName = name.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ').toLowerCase();
    if (value.length === 0) {
      return dName+ ' is missing'
    } else if (value === null || value === undefined) {
      return dName+ ' is invalid'
    }

    switch (name) {
      case 'rent':
      case 'sqFeet':
      case 'floorNum': {
        if (!value.match(NUMBERS)) {
          return dName+ ' is invalid'
        }
        break
      }
      default:
        break
    }
  }

  isValid = () => {
    return fields.reduce((a, f) => a && this.state[f] && !this.state.errors[f], true)
  }

  closeMessage = () => {
    this.setState({message: ''})
  }

  createPropObj = () => {
    const {
      unitNum,
      floorNum,
      maxOccupancy,
      sqFeet,
      rent,
      availDate,
      beds,
      baths,
      petsPermitted,
      latestCarbonMonoxTest,
      latestSmokeDetectTest,
      description,
      edit
    } = this.state
    let obj = {
      unitNum,
      floorNum,
      maxOccupancy,
      sqFeet,
      rent,
      availDate,
      beds,
      baths,
      petsPermitted,
      latestCarbonMonoxTest,
      latestSmokeDetectTest,
      description
    }
    if (edit) {
      obj.unitId= this.props.match.params.unitId
    } else {
      obj.primaryLocationId = this.props.match.params.locationId
    }
    return obj
  }
  render() {
    const {
      errors,
      maxOccupancy,
      sqFeet,
      rent,
      beds,
      baths,
      floorNum,
      unitNum,
      latestCarbonMonoxTest,
      latestSmokeDetectTest,
      formSubmit,
      description,
      petsPermitted,
      availDate,
      edit,
      message,
      isError,
      submitting
    } = this.state
    return (
      <main className="main-content p-2" role="main">
        <button type="button" className="btn btn-with-icons btn-primary" title="Back" onClick={() => this.props.history.push(`/location/${this.props.match.params.locationId}`)}>
          <i className="batch-icon batch-icon-arrow-left"></i>
        </button>
        <div className="add-property-container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="heading heading-md">{edit ? `Unit# ${unitNum}` : 'New Unit'}</h2>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  Unit Details
                <div className="progress">
                    <div
                      className="progress-bar progress-bar-sm bg-gradient"
                      role="progressbar"
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: "0%" }}
                    />
                  </div>
                </div>
                <div className="card-form-wizard">
                  <div className="row">
                    <div className="col-lg-12">
                      <div id="rootwizard-1">
                        <ul className="nav nav-pills wizard-header">
                          <li>
                            <a href="#tab1" data-toggle="tab" id="information">
                              Unit Information
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content clearfix pt-0">
                          <div className="tab-pane px-2" id="tab1">
                            <UnitInformation
                              formSubmit={formSubmit}
                              errors={errors}
                              onChange={this.onFormFieldChange}
                              unitNum={unitNum}
                              floorNum={floorNum}
                              rent={rent}
                              sqFeet={sqFeet}
                              beds={beds}
                              baths={baths}
                              maxOccupancy={maxOccupancy}
                              petsPermitted={petsPermitted}
                              availDate={availDate}
                              description={description}
                              latestCarbonMonoxTest={latestCarbonMonoxTest}
                              latestSmokeDetectTest={latestSmokeDetectTest}
                              numbersList={this.numbersList(NUM_ARR)}
                              handleDayClick={this.handleDayClick}
                              handleChange={this.handleChange}
                            />
                          </div>

                          <ul className="pager wizard">
                            <li className="next">
                              {submitting ? <p>Submitting...</p> : <a className="btn btn-primary" href="#!" onClick={(e) => this.handleSubmit(e)}>
                              {!edit ? `Create Unit` : `Update Unit`}</a>} 
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FlashMessage message={message} closeMessage={this.closeMessage} isError={isError}/>
      </main>
    );
  }
}

export default withApollo(AddUnit);
