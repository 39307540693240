import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from 'mixpanel-browser';
import { LedgerAccounting } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from '../../components/Loader/Loader';
import { Table } from "../../components/Tables/AccountingData";
import { ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
const ledgerClient = getClient(ACCOUNTING);



class Ledger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      ledgerCache: [],
      offset: 0,
      view: false,
    };
  }

  componentDidMount() {
    this.getLedger();
    mixpanel.track('Manager Page Load', {
      'sub': 'Ledger'
     });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }

  getLedger = (data) => {
    try {
      if (data === undefined) {
        this.setState({ loading: true });
        ledgerClient
          .query({
            query: LedgerAccounting,
            variables: {
              location: this.props.selectedPrimary.node.id,
              offset: this.state.offset,
              limit: 1000,
            },
          })
          .then((res) => {
            if (res.data) {
              this.setState({
                ledgerCache: get(res, "data.slLocationLedgerAccounts.data", null),
                totalItems: get(res, "data.slLocationLedgerAccounts.totalItems"),
              });
              if (res.data.slLocationLedgerAccounts.data.length === 1000) {
                this.setState({
                  offset: this.state.offset + 1000
                });
              }
            }
            this.setState({ loading: false });
          })
          .catch((error) => {
            console.log(error);
            this.setState({ loading: false });
          });
      }
      else {
        ledgerClient
          .query({
            query: LedgerAccounting,
            variables: {
              location: this.props.selectedPrimary.node.id,
              offset: this.state.offset,
              limit: 1000,
            },
          })
          .then((res) => {
            if (res.data) {
              const value = [data, ...get(res, "data.slLocationLedgerAccounts.data", [])];
              this.setState({ledgerCache: value});
              if (res.data.slLocationLedgerAccounts.data.length === 1000) {
                this.setState({
                  offset: this.state.offset + 1000
                });
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  dataPush = (response) => {
    const node = response.data.createSlLedgerAccount.slLedgerAccount;
    this.setState((prevState) => ({
      ledgerCache: [...prevState.ledgerCache, node],
    }));
  };

  updateLedger = (update, ledgerId) => {
    const { ledgerCache } = this.state;
    const array = [];
    if (update === "Delete") {
      this.setState({
        ledgerCache: ledgerCache.filter((ledger) => ledger._id !== ledgerId),
      });
    } else {
      ledgerCache.forEach((ledger) => {
        if (
          ledger._id === update.data.updateSlLedgerAccount.slLedgerAccount._id
        ) {
          ledger = update.data.updateSlLedgerAccount.slLedgerAccount;
          array.push(ledger);
        } else {
          array.push(ledger);
        }
      });
      this.setState({ ledgerCache: array });
    }
  };
  hideAdd = (e) => {
    console.log(e);
  };

  render() {
    const { loading } = this.state;
    return (
      <>
       <main className="main-content" role="main">
        <div className="roll-container">
        {loading ? (
          <Loader text inTable/>
        ) : (
          <>
            <Table
              updateLedger={this.updateLedger}
              hideAdd={this.hideAdd}
              dataPush={this.dataPush}
              totalItems={this.state.totalItems && this.state.totalItems}
              getLedger={this.getLedger}
              user={this.props.user}
              propertyData={this.props.selectedPrimary && this.props.selectedPrimary}
              ledgerCache={this.state.ledgerCache && this.state.ledgerCache}
              propertyId={
                this.props.selectedPrimary.node &&
                this.props.selectedPrimary.node.id
              }
            />
          </>
        )}
        </div>
        </main>
      </>
    );
  }
}

export default withApollo(Ledger);
