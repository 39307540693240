import React from 'react';
import {
  Button,
  Header,
  Modal,
} from 'semantic-ui-react';
import get from 'lodash/get';
import PropertyImages from './PropertyImages';
import { UNITAPI } from '../../../utils/constants';
import { parseGraphQLErrors, parsePhotosUrl, toastFailMsg } from '../../../utils/common';
import { getAuthToken } from '../../../store/auth/authUtility';

const UnitImages = ({
  isOpen,
  onClose,
  unitNumber,
  name,
  setValue,
  locationId,
  existingImages,
}) => {
  const [images, setImages] = React.useState([]);
  const [deletedImages, setDeletedImages] = React.useState([]);
  const [isUploading, setIsUploading] = React.useState();

  React.useEffect(() => {
    if (Array.isArray(existingImages)) setImages(existingImages.map((img) => ({ previewUrl: img })));
  }, [existingImages]);

  const onSubmit = () => {
    if (images.length || deletedImages.length) {
      setIsUploading(true);
      const formData = new FormData();
      const existingPhotos = Array.isArray(existingImages) && existingImages.length ? `existingPhotos: ${JSON.stringify(existingImages)},` : '';
      const deletedPhotos = Array.isArray(deletedImages) && deletedImages.length ? `deletedPhotos: ${JSON.stringify(deletedImages)},` : '';
      formData.append('query', `mutation uploadImages{
        uploadImages(unit: {
          locationId:  "${locationId}",
          ${existingPhotos},
          ${deletedPhotos}
        }){
        response
          unit{
            photos
          }
        }
      }`);

      images.forEach((img) => {
        if (img instanceof File) formData.append('', img);
      });

      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: getAuthToken(),
        },
        body: formData,
      };

      fetch(UNITAPI, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          const photos = get(result, 'data.uploadImages.unit.photos');
          setDeletedImages([]);
          if (photos) {
            setValue(name, parsePhotosUrl(photos), { shouldValidate: false, shouldDirty: false });
            onClose();
          }
        })
        .catch((error) => toastFailMsg(parseGraphQLErrors(error)))
        .finally(() => setIsUploading());
    }
  };

  return (
    <Modal
      className="semanticModal custom-modal"
      onClose={onClose}
      closeIcon
      open={isOpen}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        <Header>
          Upload media for unit
          {unitNumber}
        </Header>
        <p>Please upload all the media for this units.</p>
      </Modal.Header>
      <Modal.Content>
        <PropertyImages title="Upload images of your Unit" images={images} setImages={setImages} setDeletedImages={setDeletedImages} tag="unit" />
        <Button
          loading={isUploading}
          positive
          type="button"
          className="modal-save-button"
          onClick={() => onSubmit()}
        >
          Upload Media
        </Button>
      </Modal.Content>
    </Modal>
  );
};

export default UnitImages;
