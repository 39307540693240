import React, { Component, Fragment } from 'react';
import { withApollo } from 'react-apollo';
import { NavLink } from 'react-router-dom';
import { Breadcrumb, Button, Divider, Feed, Header, TextArea } from 'semantic-ui-react';
import moment from 'moment';
import { get } from 'lodash';
import EditComment from '../../components/Leads/EditComment';
import { PageLoader } from '../../components/Loader/PageLoader';
import { getClient } from '../../store/auth';
import { leaseDetails, getLeaseComments, createLeaseComment, updateLeaseComment, deleteLeaseComment, getLeaseClaim } from '../../store/person/leases';
import { leaseSign } from '../../store/bluemoon/bluemoon';
import { LEASE_COMMENT, LEASE_MANAGER, BLUEMOON_API, ROUTES } from '../../utils';
import { toastFailMsg, toastSuccessMsg } from '../../utils/common';
import mixpanel from 'mixpanel-browser';

const leaseManager = getClient(LEASE_MANAGER);
const leaseComment = getClient(LEASE_COMMENT);
const bluemoonApi = getClient(BLUEMOON_API);

const transformMoney = (amount) => (amount ? `$${amount}` : '');

const Cell = ({ label = '', value = '' }) => (
  <div className="form-data-col">
    <p className="form-data-label">{label}</p>
    <p className="form-data-text">{value || 'NA'}</p>
  </div>
);

const PersonInfo = ({ person = {} }) => (
  <>
    <Breadcrumb className="custom-breadcrumb">
      <Breadcrumb.Section link>
        <NavLink exact to="/lease">
          Lease
        </NavLink>
      </Breadcrumb.Section>
      <Breadcrumb.Divider />
      <Breadcrumb.Section active> {person.lastName || "Lease Details"}</Breadcrumb.Section>
    </Breadcrumb>
    <div className="lead-profile-head">
      <Header as="h1">
        {`${person.lastName}, ${person.firstName}`}
      </Header>
    </div>

    <div className="lead-profile-body">
      <div className="lead-profile-body-img">
        <i aria-hidden="true" class="user icon"></i>
      </div>
      <div className="lead-profile-info">
        <Header as='h3'>
          <a href={"tel:" + person.phoneMobile} title={person.phoneMobile}>
            <i aria-hidden="true" class="phone icon"></i>
            {person.phoneMobile}
          </a>
        </Header>
        <Header as='h3'>
          <a href={"mailto:" + person.email} title={person.email}>
            <i aria-hidden="true" class="mail icon"></i>
            {person.email}
          </a>
        </Header>
      </div>
    </div>
    <Divider className="lead-details-divider" />
  </>
);

const CommentsHistory = ({ user, commentHistory, onUpdateComment, onDeleteComment }) => {
  if (!commentHistory.length) return null;
  return (
    <Feed className="history-feed">
      {commentHistory.map(logs => {
        const { node } = logs;
        const commentCreator = get(node, 'person.id');
        const canEditDeleteComment = user.ndbId === commentCreator;
        return (
          <Feed.Event key={node.id}>
            <Feed.Content>
              <Feed.Date content={moment(node.created).local().format('lll')} />
              {canEditDeleteComment && node.commentType === "comment" && (
                <span
                  title="Delete"
                  className="delete-icon"
                  onClick={() => onDeleteComment(logs)}
                />
              )}
              {node.person && (
                <Feed.Summary>
                  {node.subject} by {node.person.firstName}{' '}
                  {node.person.lastName}
                </Feed.Summary>
              )}
              {!node.person && <Feed.Summary>{node.subject}</Feed.Summary>}
              <Feed.Extra text content={node.message} />
            </Feed.Content>
            {canEditDeleteComment && node.commentType === "comment" && (
              <Feed.Label>
                <EditComment comment={logs} updateComment={onUpdateComment} />
              </Feed.Label>
            )}
          </Feed.Event>
        );
      })}
    </Feed>
  );
};

class LeaseDetails extends Component {
  constructor(props) {
    super(props);
    const { leaseId } = props.match.params;
    this.state = {
      leaseId,
      isLoading: false,
      details: {},
      newComment: '',
      commentHistory: [],
      leaseClaimed: false,
      showSignButton: true,
    }
  }

  componentDidMount() {
    this.fetchLeaseDetails();
    this.fetchCommentsHistory();
    mixpanel.track('Manager Page Load', {
      'sub': 'Lease Details'
     });
  }

  fetchLeaseDetails = () => {
    const { leaseId } = this.state;
    this.setState({ isLoading: true });
    leaseManager.query({
      query: leaseDetails,
      variables: { leaseId },
    }).then((response) => {
      const details = response.data.lease.edges[0].node;
      this.setState({ details });
    }).catch(() => {
      toastFailMsg('Failed to get lease details.');
    });
  }

  leaseClaim = () => {
    const { leaseId } = this.state;
    this.setState({ isLoading: true });
    leaseManager.query({
      query: getLeaseClaim,
      variables: { leaseId },
    }).then((response) => {
      if (response.errors) {
        toastFailMsg(response.errors[0].message)
      } else if (response.data.leaseClaim) {
        this.setState({ leaseClaimed: true });
      }
    }).catch(() => {
      toastFailMsg('Failed to claim');
    }).finally(() => {
      this.setState({ isLoading: false });
    })
  }

  fetchCommentsHistory = () => {
    const { leaseId } = this.state;
    this.setState({ isLoading: true });
    leaseComment.query({
      query: getLeaseComments,
      variables: { leaseId },
    }).then((response) => {
      const commentHistory = get(response, "data.comments.edges", []);
      this.setState({ commentHistory, isLoading: false });
    }).catch(() => {
      toastFailMsg('Failed to get lease comments.');
    }).finally(() => {
      this.setState({ isLoading: false });
    });
  }

  addLeaseComment = () => {
    const { leaseId, newComment } = this.state;
    this.setState({ isLoading: true });
    mixpanel.track('Manager Lease Action', {
      'sub': 'Create Lease Comment'
    });
    leaseComment.mutate({
      mutation: createLeaseComment,
      variables: {
        input: {
          comment: {
            lease: leaseId,
            message: newComment
          }
        }
      },
    }).then(() => {
      toastSuccessMsg('Comment added successfully.');
      this.setState({ newComment: '' }, () => this.fetchCommentsHistory());
    }).catch(() => {
      toastFailMsg('Failed to add lease comments.');
      this.setState({ isLoading: false });
    });
  }

  onUpdateComment = ({ existingId, newComment }) => {
    this.setState({ isLoading: true });
    mixpanel.track('Manager Lease Action', {
      'sub': 'Update Lease Comment'
    });
    leaseComment.mutate({
      mutation: updateLeaseComment,
      variables: {
        input: {
          comment: {
            comment: existingId,
            message: newComment
          }
        }
      },
    }).then(() => {
      toastSuccessMsg('Comment updated successfully.');
      this.setState({ newComment: '' }, () => this.fetchCommentsHistory());
    }).catch(() => {
      toastFailMsg('Failed to update lease comments.');
      this.setState({ isLoading: false });
    });
  }

  onDeleteComment = ({ node }) => {
    this.setState({ isLoading: true });
    mixpanel.track('Manager Lease Action', {
      'sub': 'Delete Lease Comment'
    });
    leaseComment.mutate({
      mutation: deleteLeaseComment,
      variables: {
        input: {
          comment: {
            comment: node.id,
          }
        }
      },
    }).then(() => {
      toastSuccessMsg('Comment deleted successfully.');
      this.fetchCommentsHistory();
    }).catch(() => {
      toastFailMsg('Failed to delete lease comments.');
      this.setState({ isLoading: false });
    });
  }

  signLease = () => {
    const { leaseId } = this.state;
    this.setState({ isLoading: true });
    mixpanel.track('Manager Lease Action', {
      'sub': 'Set Bluemoon Sign Lease'
    });
    bluemoonApi.mutate({
      mutation: leaseSign,
      variables: {
        input: {
          leaseId: leaseId,
        }
      },
    }).then(() => {
      toastSuccessMsg('Lease Signed successfully.');
      this.setState({ isLoading: false, showSignButton: false });
    }).catch(() => {
      toastFailMsg('Failed to sign.');
      this.setState({ isLoading: false });
    });
  }

  editLeaseButton = () => {
    this.props.history.push(ROUTES.editLease.replace(':leaseId', this.state.leaseId));
  }

  // signLeaseButton = () => {

  // }

  render() {
    const { user } = this.props;
    const { isLoading, newComment, commentHistory, details = {}, leaseClaimed, showSignButton } = this.state;
    const {
      startDate,
      endDate,
      person,
      amount,
      leaseFrequency,
      nonRefundable,
      depositChargeCode,
      unitProRata,
      petProRata,
      isProRata,
      vehicleProRata,
      firstLastInvoice,
      guarantee,
      unitAmount,
      billOnDate,
      unitQuantity,
      moveInDate,
      deposit,
      petAmount,
      petQuantity,
      vehicleAmount,
      vehicleQuantity,
      unit = {},
      unitChargeCode = {},
      petChargeCode = {},
      vehicleChargeCode = {},
      extraCharges = [],
      lgState,
      extLeaseInfo = [],
    } = details;
    const disableComment = !newComment.trim().length;
    if (isLoading) return <PageLoader text />
    return (
      <>
        <PersonInfo person={person} />
        {!leaseClaimed && guarantee && lgState === 'order_rent_guard' ? <Button positive className="update-button" onClick={this.leaseClaim}>
            Lease Claim
        </Button> : guarantee && lgState === 'order_rent_guard' && leaseClaimed && <h4>Lease Claimed</h4>}
        {extLeaseInfo && extLeaseInfo.document_url && <Button href={extLeaseInfo.document_url} target="_blank" rel="noopener noreferrer" >Document</Button>}
        {extLeaseInfo && (extLeaseInfo.status === 'signed' || extLeaseInfo.status === 'in_progress') && showSignButton && <Button positive onClick={this.signLease}>
            Sign Lease
        </Button>}
        {/* {
          <Button disabled={} onClick={this.signLeaseButton}>
            Sign Lease
          </Button>
        } */}
        <Button positive disabled={details.status !== "created" ||details.extLeaseInfo} onClick={this.editLeaseButton}>Edit Lease</Button>
        <Header className="comments-head">Lease Details</Header>
        <div className="form-data-wrapper">
          <Cell label="Start date" value={moment(startDate).local().format('lll')} />
          <Cell label="End date" value={moment(endDate).local().format('lll')} />
          <Cell label="Move date" value={moveInDate && moment(moveInDate).local().format('lll')} />
          {billOnDate && <Cell label="Bill date" value={moment(billOnDate).local().format('lll')} />}
          <Cell label="Pro Rate" value={isProRata?"Yes":"No"} />
          <Cell label="Unit Type" value={unit.unitType ? unit.unitType.type : 'NA'} />
          <Cell label="Unit Number" value={unit.number} />
          <Cell label="Frequency" value={leaseFrequency} />
          <Cell label="Unit Charge Code" value={unitAmount ? `${unitChargeCode.name} - ${transformMoney(unitAmount)}` : 'NA'} />
          <Cell label="Unit Amount" value={transformMoney(unitAmount)} />
          <Cell label="Unit Quantity" value={unitQuantity} />
          <Cell label="Pet Charge Code" value={petAmount ? `${petChargeCode.name} - ${transformMoney(petAmount)}` : 'NA'} />
          <Cell label="Pet Amount" value={transformMoney(petAmount)} />
          <Cell label="Pet Quantity" value={petQuantity} />
          <Cell label="Vehicle Charge Code" value={vehicleAmount ? `${vehicleChargeCode.name} - ${transformMoney(vehicleAmount)}` : 'NA'} />
          <Cell label="Vehicle Amount" value={transformMoney(vehicleAmount)} />
          <Cell label="Vehicle Quantity" value={vehicleQuantity} />
          <Cell label="Deposit Charge Code" value={depositChargeCode ? depositChargeCode.name : 'NA'} />
          <Cell label="Deposit Amount" value={transformMoney(deposit ? deposit : 'NA')} />
          <Cell label="Non Refundable Amount" value={transformMoney(nonRefundable)} />
          {extraCharges&&extraCharges.map((charge, index) => (
            <Fragment key={charge.charge_code_id}>
              <Cell label={`Extra Charge Code ${index + 1}`} value={charge.name || `Extra Charge Code ${index + 1} - ${transformMoney(charge.amount)}`} />
              <Cell label={`Extra Charge Amount ${index + 1}`} value={transformMoney(charge.amount)} />
              <Cell label={`Extra Charge Quantity ${index + 1}`} value={charge.quantity} />
            </Fragment>
          ))}
          {
            isProRata &&
            <Fragment >
              <Cell label="Pet ProRata" value={petProRata} />
              <Cell label="Vehicle ProRata" value={vehicleProRata} />
              <Cell label="Rent ProRata" value={unitProRata} />
            </Fragment>
          }
          <Cell label="Total Amount" value={transformMoney(amount)} />
          <Cell label="First and Last Invoice" value={!firstLastInvoice ? 'No' : 'Yes'} />
          <Cell label="Guarantee" value={!guarantee ? 'No' : 'Yes'} />
        </div>
        <Header className="comments-head">Comments/History</Header>
        <div className="comments-textarea-wrapper">
          <TextArea
            className="comments-textarea"
            name="commentField"
            id="commentField"
            placeholder="Add Comments"
            value={newComment}
            onChange={(event) => this.setState({ newComment: event.target.value })}
          />
          <Button disabled={disableComment || !this.state.newComment.trim().length} positive className="update-button" onClick={this.addLeaseComment}>
            <span className="send-icon" />
          </Button>
        </div>
        <CommentsHistory
          user={user}
          commentHistory={commentHistory}
          onUpdateComment={this.onUpdateComment}
          onDeleteComment={this.onDeleteComment}
        />
      </>
    );
  }
}

export default withApollo(LeaseDetails);
