import React from "react";
import {
  Button,
  Grid,
  Table,
  Header,
} from "semantic-ui-react";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import RepayModal from "../Modals/RepayModal";

export default function ViewInvoiceDetails(props) {
  console.log(props)
  return (
    <>
    {
      !props.invoice &&
      <Button floated="left" onClick={props.ledgerView}>
        Go Back
      </Button>
    }
      <Header textAlign="center">Invoice</Header>
      <br />
      <Grid columns={2}>
        <Grid.Column>
          <Table compact>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {props.invoice && props.invoice.location&&props.invoice.location.name}
                </Table.Cell>
                <Table.Cell>
                  {props.invoice && props.invoice.location&&props.invoice.location.contact.phone}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {props.invoice && props.invoice.location&&props.invoice.location.addresses.street}
                </Table.Cell>
                <Table.Cell>
                  {props.invoice && props.invoice.location&&props.invoice.location.contact.email}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {props.invoice &&
                    props.invoice.location &&
                    props.invoice.location.addresses &&
                    props.invoice.location.addresses.city}
                  ,{" "}
                  {props.invoice &&
                    props.invoice.location &&
                    props.invoice.location.addresses &&
                    props.invoice.location.addresses.state}
                  ,{" "}
                  {props.invoice &&
                    props.invoice.location &&
                    props.invoice.location.addresses &&
                    props.invoice.location.addresses.zip}
                </Table.Cell>
                <Table.Cell>{"Insert Website"}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column>
        <Header>Notes</Header>
          {props.invoices && props.invoices.props.notes}
        </Grid.Column>
      </Grid>
        <Table compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Bill to</Table.HeaderCell>
              <Table.HeaderCell>Invoice Details</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>
                {props.invoice &&
                  props.invoice.customer &&
                  props.invoice.customer.nameUpper}
              </Table.Cell>
              <Table.Cell>
                Number#:{" "}
                {props.invoice &&
                  props.invoice.customer &&
                  props.invoice.invoice.number}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {props.invoice &&
                  props.invoice.customer &&
                  props.invoice.customer.addresses &&
                  props.invoice.customer.addresses.street}
              </Table.Cell>
              <Table.Cell>
                Invoice Date:{" "}
                {props.invoice &&
                  props.invoice.invoice &&
                  props.invoice.invoice.invoiceDate}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {props.invoice &&
                  props.invoice.customer &&
                  props.invoice.customer.addresses &&
                  props.invoice.customer.addresses.city}{" "}
                {props.invoice &&
                  props.invoice.customer &&
                  props.invoice.customer.addresses &&
                  props.invoice.customer.addresses.state}{" "}
                {props.invoice &&
                  props.invoice.customer &&
                  props.invoice.customer.addresses &&
                  props.invoice.customer.addresses.zip}
              </Table.Cell>
              <Table.Cell> Due:{" "}
                {props.invoices &&
                  props.invoices.props &&
                  props.invoices.props.dueDate}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {props.invoice &&
                  props.invoice.customer &&
                  props.invoice.customer.email}
              </Table.Cell>
              <Table.Cell>
               
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      <Table padded="very" compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Qty</Table.HeaderCell>
            <Table.HeaderCell>Tax</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>Total</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {props &&
            props.invoice &&props.invoice.items&&
            props.invoice.items.map((inv) => {
              return (
                <Table.Row>
                  <Table.Cell>{inv.description}</Table.Cell>
                  <Table.Cell>{inv.quantity}</Table.Cell>
                  <Table.Cell>${inv.taxAmount}</Table.Cell>
                  <Table.Cell>${inv.subtotal}</Table.Cell>
                  <Table.Cell>${inv.total}</Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
      
        <Grid columns={2}>  
        <Grid.Column></Grid.Column>
        <Grid.Column>
          <Table padded="very" compact>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{"Subtotal"}</Table.Cell>
                <Table.Cell textAlign="right">
                  ${props.invoice &&props.invoice.invoice&& props.invoice.invoice.subtotal}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>{"Sales Tax"}</Table.Cell>
                <Table.Cell textAlign="right">
                  ${props.invoice && props.invoice.invoice&&props.invoice.invoice.tax}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Total</Table.Cell>
                <Table.Cell textAlign="right">
                  ${props.invoice && props.invoice.invoice&&props.invoice.invoice.amount}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
      {/* <Grid columns={1}>
        <Grid.Column>
        <Table compact style={{ overflowY: "auto" }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Transaction Type</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Transaction Date</Table.HeaderCell>
                <Table.HeaderCell>Method</Table.HeaderCell>
                <Table.HeaderCell>Amount</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body style={{ overflowY: "auto", maxHeight:'200px'}}>
              {props.invoice.payments &&
                props.invoice.payments.map((payment) => {
                  let tTime = payment.checkNumber!==null&&payment.checkNumber.split('+')
                  let minutes = moment().diff(
                    moment(tTime[0]),
                    "minutes"
                  );
                  return (
                    <Table.Row>
                      <Table.Cell>{payment.type === "refund"?"Refund":"Payment"}</Table.Cell>
                      <Table.Cell>{payment.memo}</Table.Cell>
                      <Table.Cell>{moment(payment.paymentDate).format('L')}</Table.Cell>
                      <Table.Cell>{payment.notes&&payment.notes.split(".").pop() === "ACH"?"Bank":"Credit Card"}</Table.Cell>
                      <Table.Cell>{payment.amount}</Table.Cell>
                      <Table.Cell textAlign="center">
                        {payment.status === "refund"  && "Refunded"}
                        {payment.status === "voided" && "Voided"}
                        {payment.notes&&payment.notes.split(".").pop() === "ACH" && minutes > 30 &&""}
                        {payment.status !== 'voided'&&payment.status !== "refund" && payment.notes&&payment.notes.split(".").pop() !== "ACH" &&<RepayModal user={props.user} payment={payment} postedTime={tTime[0]&&tTime[0]} closeTime={tTime[1]&&tTime[1]}  />}
                        {payment.status !== 'voided' &&payment.status !== "refund"&& payment.notes&&payment.notes.split(".").pop() === "ACH"  && minutes < 30 &&minutes!==0&&<RepayModal user={props.user} payment={payment} postedTime={tTime[0]&&tTime[0]} closeTime={tTime[1]&&tTime[1]}  />}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </Grid.Column>
        </Grid> */}
    </>
  );
}
