import React, {
  useMemo, useState, useEffect, useCallback,
} from 'react';
import moment from 'moment';
import TenantsTable from './TenantsTable';
import { multipleColumnsSearch } from '../../utils/common';
import { DATE_FORMAT } from '../../utils/constants';
import { Button } from 'semantic-ui-react';

export function Table(props) {
  const [filterInput, setFilterInput] = useState('');
  const {
    view,
  } = props;

  const handleFilterChange = (e) => {
    const value = e.target.value || '';
    const filteredData = multipleColumnsSearch(value, props.tenantsCache.tenantsCache);
    setData(filteredData);
    setFilterInput(value);
  };

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    return (
      ''
    );
  }
  const showData = (item) => (
    <div>
      <h6>{`${item.node&&item.node.person.firstName} ${item.node&&item.node.person.lastName}`}</h6>
      <h5>{item.node&&item.node.person.email}</h5>
    </div>
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  /* eslint-disable */
  const columns = useMemo(
    () => [

      {
        Header: 'Resident Information',
        accessor: (item) => showData(item),
      },

      {
        Header: 'Unit',
        accessor: 'node.unit.number',
      },
      {
        Header: 'Phone Number',
        accessor: 'node.person.phoneMobile',
      },
      {
        Header: 'Start Date',
        accessor: 'node.startDate',
        Cell: ({ value }) => moment(value).format(DATE_FORMAT),
      },
      {
        Header: 'End Date',
        accessor: 'node.endDate',
        Cell: ({ value }) => moment(value).format(DATE_FORMAT),
      },
      {
        Header: 'Amount',
        accessor: 'node.amount',
        Cell: ({ value }) => value?"$" + value.toLocaleString():'',
      },
      {
        Header: 'Action',
        accessor: (item) => <Button icon="eye" size="mini" onClick={() => view(item)} />,
      },

    ],
    [],
  );
  /* eslint-enable */

  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const [loading, setLoading] = React.useState(false);
  const [startRowIndex, setStartRowIndex] = useState(0);
  const [endRowIndex, setEndRowIndex] = useState(0);
  const [lastPage, setLastPage] = useState();

  const calculateLastPage = ({ pageSize, controlledPageCount }) => {
    const result = controlledPageCount / pageSize;
    setLastPage(Math.ceil(result));
  };

  /* eslint-disable */
  const fetchData = useCallback(({ pageSize, pageIndex }) => {
    const fetchId = ++fetchIdRef.current;
    setLoading(true);
    setTimeout(() => {
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = 30;
        setData(props.tenantsCache && props.tenantsCache.tenantsCache.slice(startRow, endRow));
        setPageCount(props.tenantsCache.tenantsCache.length);
        setStartRowIndex(startRow);
        setEndRowIndex(endRow);
        setLoading(false);
      }
    }, 2000);
  }, [props.tenantsCache.tenantsCache]);
  /* eslint-enable */

  const fetchResData = () =>
    // Run requery here
    setTimeout(() => {
      const startRow = endRowIndex;
      let endRow = startRow + 30;
      if (pageCount <= endRow) endRow = pageCount;
      setData([...data, ...props.ledgerCache.slice(startRow, endRow)]);
      setStartRowIndex(startRow);
      setEndRowIndex(endRow);
    }, 1500);
  useEffect(() => {
    setData(props.tenantsCache.tenantsCache);
  }, [props.tenantsCache.tenantsCache]);

  return (
    // <div className="table-container">
      <TenantsTable
        value={filterInput}
        startRow={startRowIndex}
        defaultColumn={defaultColumn}
        endRow={endRowIndex}
        onChange={handleFilterChange}
        columns={columns}
        data={data}
        loading={loading}
        fetchData={fetchData}
        pageCount={pageCount}
        calculateLastPage={calculateLastPage}
        lastPage={lastPage}
        update={fetchResData}
      />
    // </div>
  );
}
