import React from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import omit from 'lodash/omit';
import { Controller } from 'react-hook-form';

const PropertyAddress = ({
  placeholder,
  control,
  setAddressDetails,
  setValue,
}) => {
  const handleAddressSelect = async (data) => {
    try {
      const results = await geocodeByAddress(data);
      const { address_components: addressResults, place_id: googlePlaceId } = results[0];
      const addressObj = {
        googlePlaceId,
        streetOne: `${addressResults[0].long_name}, ${addressResults[1].long_name}`,
      };
      addressResults.forEach((({ long_name: longName, types }) => {
        switch (types[0]) {
          case 'locality':
            addressObj.city = longName;
            break;
          case 'administrative_area_level_1':
            addressObj.state = longName;
            break;
          case 'country':
            addressObj.country = longName;
            break;
          case 'postal_code':
            addressObj.zip = longName;
            break;
          default:
            break;
        }
      }));
      // setAddress(Object.values(omit(addressObj, 'googlePlaceId')).join(', '));
      setValue('address', Object.values(omit(addressObj, 'googlePlaceId')).join(', '));
      setAddressDetails(addressObj);
    } catch (error) {
      console.error('Error', error);
    }
  };
  const searchOptions = {
    componentRestrictions: {country: "us"},
    types: ['address']
  }

  return (
    <Controller
      name="address"
      control={control}
      defaultValue=""
      rules={{ required: 'Address is required.' }}
      render={(props) => (
        <PlacesAutocomplete
          value={props.value}
          onChange={props.onChange}
          onSelect={handleAddressSelect}
          searchOptions={searchOptions}

        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <>
              <input
                type="text"
                name="address"
                id="address"
                placeholder={placeholder || 'Address'}
                {...getInputProps({
                  className: 'form-control',
                })}
              />
              <label htmlFor="address">Address</label>

              <div
                className="autocomplete-dropdown-container"
                style={{
                  zIndex: '10', position: 'absolute', background: 'white', border: '2px solid #F2FCFB', borderRadius: '5px',
                }}
              >
                {suggestions.map((suggestion, index) => {
                  const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                  const style = suggestion.active
                    ? { backgroundColor: '#4C158A', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      key={`${suggestion.placeId}-${index}`}
                      style={{ position: 'absolute' }}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </PlacesAutocomplete>
      )}
    />
  );
};

export default PropertyAddress;
