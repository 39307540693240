import React, { Component } from 'react';
import { Form, Button, Image, Label, Icon} from 'semantic-ui-react';

class PropertyData extends Component{

    
    saveAndContinue = (e) => {
        e.preventDefault();
        this.props.nextStep();
    }

    back  = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    render(){
        const { values } = this.props
        return(
        <Form color='blue' >
            <h1 className="ui centered">Enter Property Data</h1>
            <Form.Field>
                <label>Custom Id</label>
                <input placeholder='Insert Property Id'
                onChange={this.props.handleChange('customId')}
                defaultValue={values.customId}
                />
            </Form.Field>
            <Form.Field>
                <label>Total Units</label>
                <input placeholder='Insert Amount of Units'
                disabled={window.location.toString().includes("location=")}
                onChange={this.props.handleChange('totalUnits')}
                defaultValue={values.totalUnits}
                />
            </Form.Field>
            <Form.Input
        placeholder='Photos'
        name='photos'
        type="file"
        ref={this.props.photoUpload}
        onChange={(e) => this.props.fileUpload(e)}
      />
       <Image.Group size="tiny">
        {
          this.props.values.photos &&
          this.props.values.preview.map((photo, index) => {
            return (
              <Image size='small' src={photo} >
                <Label size="mini"  floating onClick={() => this.props.removePhoto(index)} ><Icon name="cancel"/></Label>
                <Image src={photo} />
              </Image>
            )
          })
        }
      </Image.Group>
            <Button onClick={this.back}>Back</Button>
            <Button onClick={this.saveAndContinue}>Save And Continue </Button>
        </Form>
        )
    }
}

export default PropertyData;