import React, { useMemo, useState, useEffect, useCallback } from "react";
import AccountingTable from "./AccountingTable";
import { multipleColumnsSearch } from "../../utils/common";
import TaxCreationAccounting from "../Modals/TaxCreationAccounting";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export function Table(props) {
  const [filterInput, setFilterInput] = useState("");

  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    const filteredData = multipleColumnsSearch(value, props.taxCache);
    setData(filteredData);
    setFilterInput(value);
  };

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    return "";
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  /* eslint-disable */
  const columns = useMemo(
    () => [
      {
        Header: "Ledger",
        accessor: "LedgerAccount.name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Code",
        accessor: "code",
      },
      {
        Header: "Rate",
        accessor: "rate",
      },

      {
        Header: "Edit",
        accessor: (item) => <TaxCreationAccounting success={success} propertyId={props.propertyId} updateInvoice={props.updateInvoice} props={item} />,
      },
    ],
    []
  );
  /* eslint-enable */

  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const [loading, setLoading] = React.useState(false);
  const [startRowIndex, setStartRowIndex] = useState(0);
  const [endRowIndex, setEndRowIndex] = useState(0);
  const [lastPage, setLastPage] = useState();

  const calculateLastPage = ({ pageSize, controlledPageCount }) => {
    const result = controlledPageCount / pageSize;
    setLastPage(Math.ceil(result));
  };

  /* eslint-disable */
  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      setTimeout(() => {
        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current) {
          const totalRecords = props.taxCache && props.totalItems;
          const startRow = pageSize * pageIndex;
          const endRow = totalRecords < 30 ? totalRecords : 30;
          setData(props.taxCache.concat());
          setPageCount(totalRecords);
          setStartRowIndex(startRow);
          setEndRowIndex(endRow);
          setLoading(false);
        }
      }, 2000);
    },
    [props.taxCache.taxCache]
  );
  /* eslint-enable */

  useEffect(() => {
    setData(props.taxCache);
  }, [props.taxCache]);
  const success = () => toast.success('Success!', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const fetchTransData = () => {
    // Run requery here
    const startRow = endRowIndex;
    props.getLedger(startRow)
    return setTimeout(() => {
      let endRow = startRow + 30;
      if (pageCount <= endRow) endRow = pageCount;
      setEndRowIndex(endRow);
      setStartRowIndex(startRow);
      if(endRow !== pageCount){
        setData([...props.taxCache]);
      }
    }, 1500);
  };

  return (
    <div className="">
       <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}

        />
      <AccountingTable
        value={filterInput}
        startRow={startRowIndex}
        defaultColumn={defaultColumn}
        endRow={endRowIndex}
        onChange={handleFilterChange}
        columns={columns}
        data={data}
        loading={loading}
        fetchData={fetchData}
        pageCount={pageCount}
        calculateLastPage={calculateLastPage}
        lastPage={lastPage}
        update={fetchTransData}
      />
    </div>
  );
}
