import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from 'mixpanel-browser';
import { BillsAccounting } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from '../../components/Loader/Loader';
import { Table } from "../../components/Tables/InvoiceData";
import { ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import "./Accounting.scss"

const invoiceClient = getClient(ACCOUNTING);

class Bills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      invoiceCache: [],
    };
  }

  componentDidMount() {
    this.getLedger();
    mixpanel.track('Manager Page Load', {
      'sub': 'Bills'
     });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }

  getLedger = (data) => {
    if(!data) {
      this.setState({ loading: true });
    }
        invoiceClient
          .query({
            query: BillsAccounting,
            variables: {
              location: this.props.selectedPrimary.node.id,
            },
          })
          .then((res) => {
            if (res.data) {
              this.setState({
                invoiceCache: get(res, "data.slLocationBills.data", []),
                totalItems: get(res, "data.slLocationBills.totalItems"),
              });
            }
            this.setState({ loading: false });
          })
          .catch((error) => {
            console.log(error);
            this.setState({ loading: false });
          });
        }




  dataPush = (response) => {
    this.setState((prevState) => ({
      invoiceCache: [
        response.data.createSlInvoice.slInvoice,
        ...prevState.invoiceCache,
      ],
    }));
  };
  updateInvoice = (status, invoiceId) => {
    const { invoiceCache } = this.state;
    const array = [];
    if (status === "Delete") {
      this.setState({ invoiceCache: invoiceCache.filter(invoice => invoice._id !== invoiceId) })
    }
    else {
      invoiceCache.forEach((invoice) => {
        if (invoice._id === invoiceId) {
          invoice.status = status;
          array.push(invoice);
        } else {
          array.push(invoice);
        }
      });
      this.setState({ invoiceCache: array });
    }
  };

  render() {
    const { loading, } = this.state;

    return (
      <>
        {loading ? (
          <Loader text inTable/>
        ) : (
          <Table
            updateInvoice={this.updateInvoice}
            getNewData={this.getNewData}
            user={this.props.user}
            property={this.props.selectedPrimary && this.props.selectedPrimary.node && this.props.selectedPrimary.node.customId}
            dataPush={this.dataPush}
            getLedger={this.getLedger}
            bills
            invoiceCache={this.state.invoiceCache && this.state.invoiceCache}
            totalItems={this.state.totalItems && this.state.totalItems}
            propertyId={this.props.selectedPrimary.node && this.props.selectedPrimary.node.id}
            staticId={this.props.selectedPrimary.node && this.props.selectedPrimary.node.slLocationId}
          />
        )}
      </>
    );
  }
}

export default withApollo(Bills);
