import React, { useMemo, useState, useEffect } from 'react';
import ReconcileTable from './ReconcileTable';
import { ACCOUNTING } from '../../utils/constants';
import { toastFailMsg } from '../../utils/common';
import ReferenceModal from '../Modals/ReferenceModal';
import { getClient } from '../../init-apollo-googleFn';
import moment from "moment";
import { LocationJournal } from '../../store/person/accounting';

const ledgerClient = getClient(ACCOUNTING);

export function Table(props) {
  const [modal, modalOpen] = useState(false);
  const [RefNum, SelectedRef] = useState(false);
  const [data, setData] = useState([]);
  const [lastPage, setLastPage] = useState();

  const defaultColumn = React.useMemo(() => ({ Filter: '' }), []);

  const OpenModal = (ref) => {
    modalOpen(true);
    ledgerClient
      .query({
        query: LocationJournal,
        variables: {
          journal: ref,
          location: props.propertyId,
        },
      })
      .then((res) => {
        if (res.data) {
          SelectedRef(res.data.slLocationAccountJournal);
        }
      })
      .catch((error) => {
        console.log(error);
        toastFailMsg(error);
      });
  };
  const DateFormat = ({ values }) => {
    return moment(values).format('l')
     };

  /* eslint-disable */
  const columns = useMemo(
    () => [
      {
        Header: 'Journal Number',
        accessor: (item) => (
          <a
            style={{ color: 'blue' }}
            onClick={() => OpenModal(item.JournalId)}
          >
            {item.Journal.number}
          </a>
        ),
      },
      {
        Header: 'Status',
        accessor: 'Journal.status',
      },
      {
        Header: 'ID',
        accessor: '_id',
      },
      {
        Header: 'Ledger',
        accessor: 'Journal.sourceLedger',
      },
      {
        Header: ' Account Number',
        accessor: 'LedgerAccount_number',
      },

      {
        Header: 'Account Type',
        accessor: 'LedgerAccount_type',
      },
      {
        Header: 'Account SubType',
        accessor: 'LedgerAccount_subtype',
      },
      {
        Header: 'PX Date',
        accessor: 'transactionDate',
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : ''),
      },
      {
        Header: 'Post Date',
        accessor: 'postedDate',
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : ''),
      },
      {
        Header: 'Reference',
        accessor: 'Journal.reference',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },

      {
        Header: 'Debit',
        accessor: 'debit',
        Cell: ({ cell: { value } }) => value && "$" + value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
      },
      {
        Header: 'Credit',
        accessor: 'credit',
        Cell: ({ cell: { value } }) => value && "$" + value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
      },
    ],
    [],
  );
  /* eslint-enable */

  const { transCache, totalItems, ReconcileAccount } = props;

  /* eslint-disable */
  useEffect(() => {
    const result = totalItems / 30;
    setLastPage(Math.ceil(result));
    transCache && setData(transCache);
  }, [transCache && transCache])
  /* eslint-enable */

  return (
    <>
      <ReferenceModal
        key="modal1"
        modalOpen={modal}
        location={props}
        RefNum={RefNum}
        handleClose={() => modalOpen(false)}
      />
        <main className="main-content" role="main">
        <div className="reconcile-container">
        <div className="table-container">
      <ReconcileTable
        lastPage={lastPage}
        defaultColumn={defaultColumn}
        columns={columns}
        data={data}
        totalItems={totalItems}
        update={ReconcileAccount}
      />
      </div>
      </div>
      </main>
      </>

  );
}
