/* eslint-disable */
import React, { useEffect, useState } from "react";
import { leaseDetails } from '../../store/person/leases';
import { pmSingleApplicationGql } from '../../store/person/applications';
import { getClient } from '../../init-apollo-googleFn';
import { LEASE_MANAGER, APPLICATION_MANAGER } from '../../utils';
import { PageLoader } from "../../components/Loader/PageLoader";
import userIcon from '../../assets/images/user-icon.svg';
import GiftPointsModal from '../../components/Modals/GiftPointsModal';
import mixpanel from 'mixpanel-browser';


function TenantDetail(props) {
  const [tenant, setTenant] = useState(null);
  const [primaryApplicant, setPrimaryApplicant] = useState(null);
  const [tenantResponse, setTenantResponse] = useState(false);
  const [personResponse, setPersonResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const leaseManager = getClient(LEASE_MANAGER);
  const applicationManager = getClient(APPLICATION_MANAGER);

  const fetchLeaseDetails = () => {
    const leaseId = props.match.params.tenantId.slice(1, props.match.params.tenantId.length);
    leaseManager.query({
      query: leaseDetails,
      variables: { leaseId },
    }).then((response) => {
      setTenant(response.data.lease.edges[0].node);
      setTenantResponse(true);
    }).catch((err) => {
      console.log(err);
    });
  }

  const fetchPersonDetails = () => {
    const applicationId = props.match.params.applicationId.slice(1, props.match.params.applicationId.length);
    applicationManager.query({
      query: pmSingleApplicationGql,
      variables: { applicationId },
    }).then((response) => {
      setPrimaryApplicant(response.data.application.edges[0].node.primaryApplicant);
      setPersonResponse(true);
    }).catch((err) => {
      console.log(err);
    });
  }

  const createdAtFormat = "DD-MM-YYYY";
  const leaseDate = new Date();
  const createdAtDate = tenant !== null && moment(leaseDate).format(createdAtFormat);

  useEffect(() => {
    fetchLeaseDetails();
    fetchPersonDetails();
    mixpanel.track('Manager Page Load', {
      ndbId: props.user.ndbId,
      sub: 'Tenant Detail',
    });
  }, [])



  useEffect(()=> {
    if (personResponse && tenantResponse) {setIsLoading(false)}
  },[personResponse, tenant]);
  const {selectedPrimary} =props
  return (
    (!isLoading && primaryApplicant !== undefined && tenant !== null) ?
      <main className="main-content pt-4 tenants-container" role="main">
        {/* Resident Detail */}
        <div className="row">
          <div className="col-md-12">
            <div className="theme-card card">
              <div class="card-header">
                <div className="card-profile-header d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="resident-profile-icon"><img src={userIcon} /></div>
                    <div>
                      <div className="profile-title">{tenant.person.firstName} {tenant.person.lastName}</div>
                      <div className="profile-subtitle">Email: <a href={`mailto:${tenant.person.email}`}>{tenant.person.email}</a>. Phone Number: <a href={`tel:${tenant.person.phoneMobile}`}>{tenant.person.phoneMobile}.</a></div>
                    </div>
                  </div> 
                  <div className="d-flex align-items-center">
                    <GiftPointsModal location={selectedPrimary.node.id} user={props.user.ndbId} recipient={tenant.person.email}/>
                    <div className="resident-account-status">Primary Applicant</div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div className="card-profile-body">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Goverment ID Type</div>
                        <div className="profile-label-value">{primaryApplicant.drivingLicence&&primaryApplicant.drivingLicence.idType}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Drivers License/ID Number</div>
                        <div className="profile-label-value">{primaryApplicant.drivingLicence&&primaryApplicant.drivingLicence.number}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Issuing State</div>
                        <div className="profile-label-value">{primaryApplicant.drivingLicence&&primaryApplicant.drivingLicence.issuingState}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Date of Birth</div>
                        <div className="profile-label-value">{primaryApplicant.dateOfBirth}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Marital Status</div>
                        <div className="profile-label-value">{primaryApplicant.maritalStatus === "" ? "N/A" : primaryApplicant.maritalStatus}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="profile-label-row">
                        <div>
                          <span className="profile-label-title">Lease Info</span>
                          <span className="profile-label-subtitle">Lease signed on {createdAtDate}</span>
                        </div>
                        <div className="profile-label-line"></div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Monthly Rent</div>
                        <div className="profile-label-value">${tenant.amount}/month</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Lease Frequency</div>
                        <div className="profile-label-value">{tenant.leaseFrequency}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Deposit</div>
                        <div className="profile-label-value">${tenant.deposit}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Non-Refundable</div>
                        <div className="profile-label-value">${tenant.nonRefundable}</div>
                      </div>
                    </div>
                    {tenant.extLeaseInfo !== null &&
                    <>
                      <div className="col-md-4">
                        <div className="profile-basic">
                          <div className="profile-label">Sign Method</div>
                          <div className="profile-label-value">{tenant.extLeaseInfo.type.charAt(0).toUpperCase()+tenant.extLeaseInfo.type.slice(1)}</div>
                        </div>
                      </div>
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">Sign Status</div>
                            <div className="profile-label-value">{tenant.extLeaseInfo.status.charAt(0).toUpperCase()+tenant.extLeaseInfo.status.slice(1)}</div>
                          </div>
                        </div>
                    </>
                    }
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Lease Guarantee</div>
                        <div className="profile-label-value">{tenant.guarantee ? "Yes" : "No"}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Pro Rate</div>
                        <div className="profile-label-value">{tenant.proRata ? "Yes" : "No"}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Pets</div>
                        <div className="profile-label-value">{tenant.petQuantity === 0 ? "N/A" : tenant.petQuantity}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="profile-label-row">
                        <div>
                          <span className="profile-label-title">Emergency Contact</span>
                          <span className="profile-label-subtitle">{primaryApplicant.emergencyContact&&primaryApplicant.emergencyContact.relationship}</span>
                        </div>
                        <div className="profile-label-line"></div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Name</div>
                        <div className="profile-label-value">{primaryApplicant.emergencyContact&&primaryApplicant.emergencyContact.name}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Cell Phone</div>
                        <div className="profile-label-value">{primaryApplicant.emergencyContact&&primaryApplicant.emergencyContact.homePhone !== "" ? <a href={`tel:${primaryApplicant.emergencyContact.mobilePhone}`}>{primaryApplicant.emergencyContact.mobilePhone}</a> : "N/A"}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Home Phone</div>
                        <div className="profile-label-value">{primaryApplicant.emergencyContact&&primaryApplicant.emergencyContact.homePhone !== "" ? <a href={`tel:${primaryApplicant.emergencyContact.homePhone}`}>{primaryApplicant.emergencyContact.homePhone}</a> : "N/A"}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Work Phone</div>
                        <div className="profile-label-value">{primaryApplicant.emergencyContact&&primaryApplicant.emergencyContact.workPhone !== "" ? <a href={`tel:${primaryApplicant.emergencyContact.workPhone}`}>{primaryApplicant.emergencyContact.workPhone}</a> : "N/A"}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Email</div>
                        <div className="profile-label-value">{primaryApplicant.emergencyContact&&primaryApplicant.emergencyContact.email !== "" ? <a href={`mailto:${primaryApplicant.emergencyContact.email}`}>{primaryApplicant.emergencyContact.email}</a> : "N/A"}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Address</div>
                        <div className="profile-label-value">{primaryApplicant&&primaryApplicant.emergencyContact&&primaryApplicant.emergencyContact.address.streetLine1}, {primaryApplicant.emergencyContact&&primaryApplicant.emergencyContact.address.city}, {primaryApplicant.emergencyContact&&primaryApplicant.emergencyContact.address.state}, {primaryApplicant.emergencyContact&&primaryApplicant.emergencyContact.address.postcode}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="profile-label-row">
                        <div>
                          <span className="profile-label-title">Employment</span>
                          {/* <span className="profile-label-subtitle">(3 months of employment history)</span> */}
                        </div>
                        <div className="profile-label-line"></div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Occupation</div>
                        <div className="profile-label-value">{primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].title}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Employer/Company</div>
                        <div className="profile-label-value">{primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].employerName}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Monthly Income</div>
                        <div className="profile-label-value">${primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].monthlyIncome}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">From Date</div>
                        <div className="profile-label-value">{primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].startDate}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">To Date</div>
                        <div className="profile-label-value">{primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].endDate}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Supervisor Name</div>
                        <div className="profile-label-value">{primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].supervisorName}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Address</div>
                        <div className="profile-label-value">{primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].address.streetLine1}, {primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].address.city}, {primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].address.state}, {primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].postcode}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Supervisor Email</div>
                        <div className="profile-label-value">{primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].supervisorEmail !== "" ? <a href={`mailto:${primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].supervisorEmail}`}>{primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].supervisorEmail}</a> : "N/A"}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Supervisor Phone</div>
                        <div className="profile-label-value">{primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].supervisorPhone !== "" ? <a href={`tel:${primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].supervisorPhone}`}>{primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].supervisorPhone}</a> : "N/A"}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      : (!isLoading && tenant !== null) ?
      <main className="main-content pt-4 tenants-container" role="main">
        <div className="row">
          <div className="col-md-12">
            <div className="theme-card card">
              <div class="card-header">
                <div className="card-profile-header d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="resident-profile-icon"><img src={userIcon} /></div>
                    <div> 
                      <div className="profile-title">{tenant.person.firstName.charAt(0) + tenant.person.firstName.slice(1).toLowerCase()} {tenant.person.lastName.charAt(0) + tenant.person.lastName.slice(1).toLowerCase()}</div>
                      <div className="profile-subtitle">Email: <a href={`mailto:${tenant.person.email}`}>{tenant.person.email}</a>. Phone Number: <a href={`tel:${tenant.person.phoneMobile}`}>{tenant.person.phoneMobile}.</a></div>
                    </div>
                  </div>
                  <GiftPointsModal location={selectedPrimary.node.id} user={props.user.ndbId} recipient={tenant.person.email}/>
                  <div className="resident-account-status">Primary Applicant</div>
                </div>
              </div>
              <div class="card-body">
                <div className="card-profile-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="profile-label-row">
                        <div>
                          <span className="profile-label-title">Lease Info</span>
                          <span className="profile-label-subtitle">Lease signed on {createdAtDate}</span>
                        </div>
                        <div className="profile-label-line"></div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Monthly Rent</div>
                        <div className="profile-label-value">${tenant.amount}/month</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Lease Frequency</div>
                        <div className="profile-label-value">{tenant.leaseFrequency}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Deposit</div>
                        <div className="profile-label-value">${tenant.deposit}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Non-Refundable</div>
                        <div className="profile-label-value">${tenant.nonRefundable}</div>
                      </div>
                    </div>
                    {tenant.extLeaseInfo !== null &&
                    <>
                      <div className="col-md-4">
                        <div className="profile-basic">
                          <div className="profile-label">Sign Method</div>
                          <div className="profile-label-value">{tenant.extLeaseInfo.type.charAt(0).toUpperCase()+tenant.extLeaseInfo.type.slice(1)}</div>
                        </div>
                      </div>
                        <div className="col-md-4">
                          <div className="profile-basic">
                            <div className="profile-label">Sign Status</div>
                            <div className="profile-label-value">{tenant.extLeaseInfo.status.charAt(0).toUpperCase()+tenant.extLeaseInfo.status.slice(1)}</div>
                          </div>
                        </div>
                    </>
                    }
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Lease Guarantee</div>
                        <div className="profile-label-value">{tenant.guarantee ? "Yes" : "No"}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Pro Rate</div>
                        <div className="profile-label-value">{tenant.proRata ? "Yes" : "No"}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="profile-basic">
                        <div className="profile-label">Pets</div>
                        <div className="profile-label-value">{tenant.petQuantity === 0 ? "N/A" : tenant.petQuantity}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      : (!isLoading && primaryApplicant !== undefined) ?
      <main className="main-content pt-4 tenants-container" role="main">
        <div className="row">
          <div className="col-md-12">
            <div className="theme-card card">
              <div class="card-header">
                <div className="card-profile-header d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="resident-profile-icon"><img src={userIcon} /></div>
                    <div> 
                      <div>
                        <div className="profile-title">{primaryApplicant.firstName} {primaryApplicant.lastName}</div>
                        <div className="profile-subtitle">Email: <a href={`mailto:${primaryApplicant.email}`}>{primaryApplicant.email}</a>. Phone Number: <a href={`tel:${primaryApplicant.phoneMobile}`}>{primaryApplicant.phoneMobile}.</a></div>
                      </div>
                    </div>
                    <GiftPointsModal location={selectedPrimary.node.id} user={props.user.ndbId} recipient={tenant.person.email}/>
                    <div className="resident-account-status">Primary Applicant</div>
                  </div>
                </div>
                <div class="card-body">
                  <div className="card-profile-body">
                    <div class="card-body">
                      <div className="card-profile-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="profile-basic">
                              <div className="profile-label">Goverment ID Type</div>
                              <div className="profile-label-value">{primaryApplicant.drivingLicence.idType}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="profile-basic">
                              <div className="profile-label">Drivers License/ID Number</div>
                              <div className="profile-label-value">{primaryApplicant.drivingLicence.number}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="profile-basic">
                              <div className="profile-label">Issuing State</div>
                              <div className="profile-label-value">{primaryApplicant.drivingLicence.issuingState}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="profile-basic">
                              <div className="profile-label">Date of Birth</div>
                              <div className="profile-label-value">{primaryApplicant.dateOfBirth}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="profile-basic">
                              <div className="profile-label">Marital Status</div>
                              <div className="profile-label-value">{primaryApplicant.maritalStatus === "" ? "N/A" : primaryApplicant.maritalStatus}</div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="profile-label-row">
                              <div>
                                <span className="profile-label-title">Emergency Contact</span>
                                <span className="profile-label-subtitle">{primaryApplicant.emergencyContact.relationship}</span>
                              </div>
                              <div className="profile-label-line"></div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="profile-basic">
                              <div className="profile-label">Name</div>
                              <div className="profile-label-value">{primaryApplicant.emergencyContact.name}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="profile-basic">
                              <div className="profile-label">Cell Phone</div>
                              <div className="profile-label-value">{primaryApplicant.emergencyContact.homePhone !== "" ? <a href={`tel:${primaryApplicant.emergencyContact.mobilePhone}`}>{primaryApplicant.emergencyContact.mobilePhone}</a> : "N/A"}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="profile-basic">
                              <div className="profile-label">Home Phone</div>
                              <div className="profile-label-value">{primaryApplicant.emergencyContact.homePhone !== "" ? <a href={`tel:${primaryApplicant.emergencyContact.homePhone}`}>{primaryApplicant.emergencyContact.homePhone}</a> : "N/A"}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="profile-basic">
                              <div className="profile-label">Work Phone</div>
                              <div className="profile-label-value">{primaryApplicant.emergencyContact.workPhone !== "" ? <a href={`tel:${primaryApplicant.emergencyContact.workPhone}`}>{primaryApplicant.emergencyContact.workPhone}</a> : "N/A"}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="profile-basic">
                              <div className="profile-label">Email</div>
                              <div className="profile-label-value">{primaryApplicant.emergencyContact.email !== "" ? <a href={`mailto:${primaryApplicant.emergencyContact.email}`}>{primaryApplicant.emergencyContact.email}</a> : "N/A"}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="profile-basic">
                              <div className="profile-label">Address</div>
                              <div className="profile-label-value">{primaryApplicant&&primaryApplicant.emergencyContact&&primaryApplicant.emergencyContact.address.streetLine1}, {primaryApplicant.emergencyContact.address.city}, {primaryApplicant.emergencyContact.address.state}, {primaryApplicant.emergencyContact.address.postcode}</div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="profile-label-row">
                              <div>
                                <span className="profile-label-title">Employment</span>
                                {/* <span className="profile-label-subtitle">(3 months of employment history)</span> */}
                              </div>
                              <div className="profile-label-line"></div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="profile-basic">
                              <div className="profile-label">Occupation</div>
                              <div className="profile-label-value">{primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].title}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="profile-basic">
                              <div className="profile-label">Employer/Company</div>
                              <div className="profile-label-value">{primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].employerName}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="profile-basic">
                              <div className="profile-label">Monthly Income</div>
                              <div className="profile-label-value">${primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].monthlyIncome}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="profile-basic">
                              <div className="profile-label">From Date</div>
                              <div className="profile-label-value">{primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].startDate}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="profile-basic">
                              <div className="profile-label">To Date</div>
                              <div className="profile-label-value">{primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].endDate}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="profile-basic">
                              <div className="profile-label">Currently Employed</div>
                              <div className="profile-label-value">{primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.inCurrentlyEmployed ? "Yes" : (primaryApplicant&&primaryApplicant.employmentHistory&& !primaryApplicant.inCurrentlyEmployed) ? "No" : "N/A"}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="profile-basic">
                              <div className="profile-label">Supervisor Name</div>
                              <div className="profile-label-value">{primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].supervisorName}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="profile-basic">
                              <div className="profile-label">Address</div>
                              <div className="profile-label-value">{primaryApplicant.employmentHistory[0].address.streetLine1}, {primaryApplicant.employmentHistory[0].address.city}, {primaryApplicant.employmentHistory[0].address.state}, {primaryApplicant.employmentHistory[0].postcode}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="profile-basic">
                              <div className="profile-label">Supervisor Email</div>
                              <div className="profile-label-value">{primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].supervisorEmail !== "" ? <a href={`mailto:${primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].supervisorEmail}`}>{primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].supervisorEmail}</a> : "N/A"}</div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="profile-basic">
                              <div className="profile-label">Supervisor Phone</div>
                              <div className="profile-label-value">{primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].supervisorPhone !== "" ? <a href={`tel:${primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].supervisorPhone}`}>{primaryApplicant&&primaryApplicant.employmentHistory&&primaryApplicant.employmentHistory[0]&&primaryApplicant.employmentHistory[0].supervisorPhone}</a> : "N/A"}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    :
      <PageLoader text/>
  );
}

export default TenantDetail
