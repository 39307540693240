import React, { useMemo, useState, useEffect } from "react";
import { ToastContainer, } from "react-toastify";
import AccountingTable from "./AccountingTable";
import { ACCOUNTING } from "../../utils/constants";
import { multipleColumnsSearch } from "../../utils/common";
import { getClient } from "../../init-apollo-googleFn";
import ViewInvoiceDetails from "../Accounting/ViewInvoiceDetails";
import { InvoiceInfoAccounting } from "../../store/person/accounting";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const invoiceClient = getClient(ACCOUNTING);


const Status = ({ values }) => {
  if(values){
    return <p style={{textTransform: 'capitalize'}}>{values}</p>
  }
};

export function Table(props) {
  const [filterInput, setFilterInput] = useState("");
  const [toggled, viewChange] = useState(false);
  const [viewInvoiceData, viewDataInvoice] = useState("");
  const [viewInvoicesData, viewDataInvoices] = useState("");
  const locationId = props.propertyId && props.propertyId;
  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    const filteredData = multipleColumnsSearch(
      value,
      props.cashCache && props.cashCache
    );
    setData(filteredData);
    setFilterInput(value);
  };

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    return "";
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const DateFormat = ({ values }) => {
    return moment(values).format('l')
     };
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "_id",
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: ({ cell: { value } }) => (value ? <Status values={value} /> : ''),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (value ? <Status values={value} /> : ''),
      },
      {
        Header: "Location",
        accessor: "Location.name",
      },
      {
        Header: "Invoice",
        accessor: "Invoice.number",
      },
      {
        Header: "Payment Date",
        accessor: "paymentDate",
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : ''),
      },
      {
        Header: "Posted Date",
        accessor: "postedDate",
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : ''),
      },
      {
        Header: "Memo",
        accessor: "memo",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      
    ],
    []
  );
  const viewInvoice = (e, props) => {
    viewDataInvoices(props);
    if (toggled) {
      viewChange(false);
    }
    if (!toggled) {
      getInvoice(props);
    }
  };

  const getInvoice = (props) => {
    try {
      invoiceClient
        .query({
          query: InvoiceInfoAccounting,
          variables: {
            location: locationId,
            id: props.props._id,
          },
        })
        .then((res) => {
          if (res.data) {
            const data = res.data.slLocationInvoice
            /* eslint-disable */
            const Check = data.payments.map((payment) => {
              const check = data.payments.filter(
                (pay) => pay.externalCheckBankId === payment._id
              );
              if (check.length > 0) {
                return payment.status = "refund";
              }
            });
            /* eslint-enable */
            viewDataInvoice(
              res.data.slLocationInvoice && res.data.slLocationInvoice
            );
            viewChange(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
    }
  };

  const [data, setData] = useState([]);
  const [lastPage, setLastPage] = useState();

  /* eslint-disable */
  useEffect(() => {
    const result = props.totalItems / 30;
    setLastPage(Math.ceil(result));
    setData(props.cashCache && props.cashCache);
  }, [props.cashCache && props.cashCache]);
  /* eslint-enable */


  return (
    <>
    <div className="table-container">
      {!toggled ? (
        <>
          <AccountingTable
            value={filterInput}
            defaultColumn={defaultColumn}
            onChange={handleFilterChange}
            columns={columns}
            data={data}
            getLedger={props.getLedger}
            totalItems={props.totalItems}
            pageCount={lastPage}
            lastPage={lastPage}
          />
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
          />
        </>
      ) : (
        <ViewInvoiceDetails
          property={props.property}
          invoice={viewInvoiceData}
          invoices={viewInvoicesData}
          ledgerView={viewInvoice}
        />
      )}
    </div>
    </>
  );
}
