const prodConfig = {
  apiKey: 'AIzaSyCPImmNznKLDej_mlD3tsBm1EqvXPtWVb8',
  authDomain: `leasera-${process.env.REACT_APP_DEMO}.firebaseapp.com`,
  databaseURL: `https://leasera-${process.env.REACT_APP_DEMO}.firebaseio.com`,
  projectId: `leasera-${process.env.REACT_APP_DEMO}`,
  storageBucket: `${process.env.REACT_APP_DEMO}.appspot.com`,
  messagingSenderId: '787660496150',
  appId: "1:787660496150:web:63efd01be202870886d991"
}
  
export const config = process.env.NODE_ENV === 'production' ? prodConfig : prodConfig // : devConfig;
  