import React, { useState, useEffect } from "react";
import { Icon} from "semantic-ui-react";
import { ToastContainer, } from "react-toastify";
import {FinancialsTable} from "./FinancialsTable";
import {RentRollTable} from "./FinancialsTable";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const Status = ({ values }) => {
  const numberize = Number(values)
  if (Math.sign(numberize) === 1 || numberize === 0) {
    return (
      <p style={{textAlign:"center"}}>$ {numberize}</p>
    );
  }
  else {
    return (
      <p style={{textAlign:"center"}}>$ ({Math.abs(numberize)})</p>
    );
  }
};
const Coma = ({ values }) => {
if(values.length >1){
  return values.join(', ');
}
else return values
};
const Upper = ({ values }) => {
 return values.replace('_',' ').toUpperCase()
  };
  const DateFormat = ({ values }) => {
    return moment(values).format('l')
     };


export function Table(props) {

  const columns = React.useMemo(
    () => [
      {
        // Build our expander column
        id: 'expander', // Make sure it has an ID
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps(true)} id="allExpand">
            {isAllRowsExpanded ? <Icon disabled name='arrow alternate circle down outline' id="allExpand" /> : <Icon disabled name='arrow alternate circle right outline' id="allExpand" />} Name
          </span>
        ),
        accessor: 'name',
        Cell: ({ row , value, }) =>
          row.canExpand ? (
            <span
            style={{textAlign:"left"}}
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 15}px`,
                },
              })}
            >
              {row.isExpanded ? <Icon disabled name='arrow alternate circle down outline' />  : <Icon disabled name='arrow alternate circle right outline' />} {value}
            </span>
          ) : (
            <span
            style={{textAlign:"left"}}
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 15}px`,
                },
              })}
            >
              {value}
            </span>
          ),
      },
      {
        Header: ' ',
        columns: [
        
          {
            Header: 'Balance',
            accessor: 'value',
            Cell: ({ cell: { value } }) => (value ? <Status values={value} /> : ''),
          },
        ],
      },
    ],
    []
  )


  const [data, setData] = useState([]);
  const [lastPage, setLastPage] = useState();

  /* eslint-disable */
  useEffect(() => {
    const result = props.financialsCache.length / 30;
    setLastPage(Math.ceil(result));
    setData(props.financialsCache && props.financialsCache);
  }, [props.financialsCache && props.financialsCache]);
  /* eslint-enable */

  return (
    <>
     {/* <main className="main-content" role="main"> */}
        <div className="finance-container">
        {/* <div className="table-container"> */}
          <FinancialsTable
            columns={columns} data={data} lastPage={lastPage}
          />
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
          />
          {/* </div> */}
          </div>
          {/* </main> */}
    </>
  );
}

export function RollTable(props) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Apt",
        accessor: "Apt",
      },
      {
        Header: "Type",
        accessor: 'Type',
      },
      {
        Header: "Status",
        accessor:'AptStatus',
        Cell: ({ value }) => (value ? <Upper values={value} /> : ''),
      },
      {
        Header: "Names",
        accessor: 'Names',
        Cell: ({ value }) => (value ? <Coma values={value} /> : ''),
      },
      {
        Header: "Sq.Ft",
        accessor: 'SqFt',
      },
      {
        Header: "Lease Frequency",
        accessor: 'LeaseChargeFreq',
      },
      {
        Header: "Lease Charge",
        accessor: 'LeaseCharges',
        Cell: ({ value }) =>value && "$" + value.toLocaleString(),
      },
      {
        Header: "Gross Possible",
        accessor: 'GrossPossible',
        Cell: ({ value }) =>value && "$" + value.toLocaleString(),
      },
       {
        Header: "Potential",
        accessor: 'PotentialCharges',
        Cell: ({ value }) => value && "$" + value.toLocaleString(),
      },
     
      {
        Header: "Expires",
        accessor: 'LeaseExpires',
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : ''),
      },
      {
        Header: "End Balance",
        accessor: 'EndingBalance',
        Cell: ({ value }) =>value && "$" + value.toLocaleString(),
      },  
    ],
    []
  )


  const [data, setData] = useState([]);
  const [lastPage, setLastPage] = useState();

  /* eslint-disable */
  useEffect(() => {
    const result = props.financialsCache.length / 30;
    setLastPage(Math.ceil(result));
    setData(props.financialsCache && props.financialsCache);
  }, [props.financialsCache && props.financialsCache]);

  /* eslint-enable */

  return (
    <>
     <main className="main-content" role="main">
      <div className="roll-container">
        {/* <div className="table-container"> */}
          <RentRollTable
            columns={columns}
            data={data}
            lastPage={lastPage}
          />
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
          />
          {/* </div> */}
          </div>
        </main>
    </>
  );
}