import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getAuthToken } from '../store/auth';
import { getCookie } from '../store/auth/authUtility';

const PrivateRoute = ({ component: Component, isServiceProf, ...rest }) => {
    const cookie = getCookie()

    return (
        <Route {...rest} render={props =>
        (Boolean(getAuthToken()) &&
            (cookie.manager === "true") ?
            <Component {...props} role={cookie} {...rest} />
            :  <Redirect to="/" />) 
        } />
    )
}


export default PrivateRoute;