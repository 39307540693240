import React from 'react';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { getClient } from '../../../init-apollo-googleFn';
import {
  ACCOUNTING,
  LOCATIONAPI,
  LOCATION_TYPE_API,
  LOCATION_UTILS,
  ORGANIZATION_API,
  UNITAPI,
} from '../../../utils';
import {
  getAmenities,
  getLocationType,
  getPetsList,
  getPropertyInfoAndPolicies,
  getUnitsInfo,
} from '../../../store/person/properties';
import { GetOrganisationDetails } from '../../../store/organization/organization';
import {
  parseGraphQLErrors,
  parsePhotosUrl,
  toastFailMsg,
  parseDepositRules,
  parseArrayString,
} from '../../../utils/common';
import { ChargeAccounting } from '../../../store/person/accounting';

const locationClient = getClient(LOCATIONAPI);
const unitClient = getClient(UNITAPI);
const organizationClient = getClient(ORGANIZATION_API);
const locationTypeClient = getClient(LOCATION_TYPE_API);
const locationUtilsClient = getClient(LOCATION_UTILS);
const chargeClient = getClient(ACCOUNTING);

const reducer = (state, action) => ({ ...state, [action]: true });

export default (WrappedComponent, displayName) => {
  const ExtendedComponent = withRouter((props) => {
    const [details, setDetails] = React.useState({});
    const [units, setUnits] = React.useState([]);
    const [orgDetails, setOrgDetails] = React.useState({});
    const [propertyTypes, setPropertyTypes] = React.useState([]);
    const [petTypes, setPetTypes] = React.useState([]);
    const [amenityTypes, setAmenityTypes] = React.useState([]);
    const [chargeCodes, setChargeCodes] = React.useState([]);
    const [refetched, setRefetched] = React.useState(false);
    const [dataLoaded, dispatch] = React.useReducer(reducer, {
      details: false,
      units: false,
      orgDetails: false,
      propertyTypes: false,
      amenityTypes: false,
      petTypes: false,
    });

    const { location, match } = props;
    const params = new URLSearchParams(location.search);
    const currentLocationId = match.params.locationId || params.get('locationId');

    const fetchPropertyTypes = () => {
      locationTypeClient.query({ query: getLocationType })
        .then((response) => {
          if (response.data.locationType) {
            setPropertyTypes(response.data.locationType.edges || []);
          }
        })
        .catch((error) => toastFailMsg(parseGraphQLErrors(error)))
        .finally(() => dispatch('propertyTypes'));
    };

    const fetchPetTypes = () => {
      locationUtilsClient.query({ query: getPetsList, variables: { distinct: true } })
        .then((response) => {
          if (response.data.pets) setPetTypes(response.data.pets.edges || []);
        })
        .catch((error) => toastFailMsg(parseGraphQLErrors(error)))
        .finally(() => dispatch('petTypes'));
    };

    const fetchAmenitiesTypes = () => {
      locationUtilsClient.query({ query: getAmenities })
        .then((response) => {
          if (response.data.amenities) setAmenityTypes(response.data.amenities.edges || []);
        })
        .catch((error) => toastFailMsg(parseGraphQLErrors(error)))
        .finally(() => dispatch('amenityTypes'));
    };

    const fetchChargeCodes = (locationId) => {
      chargeClient
        .query({
          query: ChargeAccounting,
          variables: {
            location: locationId,
          },
        })
        .then((res) => {
          if (res.data) {
            setChargeCodes(get(res, 'data.chargeCodes.edges', null));
          }
        })
        .catch((error) => console.log(error));
    };

    const fetchOrganisationDetails = (orgId) => {
      if (orgId) {
        organizationClient.query({ query: GetOrganisationDetails, variables: { id: orgId } })
          .then((response) => {
            const orgData = get(response, 'data.organization.edges[0]');
            if (orgData) setOrgDetails(orgData.node);
          })
          .catch((error) => {
            toastFailMsg(parseGraphQLErrors(error).toString() || 'Unable to get unit details.');
          })
          .finally(() => dispatch('orgDetails'));
      } else {
        dispatch('orgDetails');
      }
    };

    const fetchLocationDetails = async () => {
      locationClient.query({
        query: getPropertyInfoAndPolicies,
        variables: { locationId: currentLocationId },
      })
        .then((response) => {
          const locationData = get(response, 'data.location.edges[0]');
          if (locationData) {
            const parseLocation = {
              ...locationData.node,
              addresses: parseArrayString(locationData.node.addresses),
              photos: parsePhotosUrl(locationData.node.photos),
              amenities: parseArrayString(locationData.node.amenities),
              depositRules: parseDepositRules(locationData.node.depositRules),
            };
            fetchChargeCodes(locationData.node.id);
            fetchOrganisationDetails(locationData.node.organizationId);
            setDetails(parseLocation);
          } else {
            toastFailMsg('Unable to get location details.');
          }
        })
        .catch((error) => {
          toastFailMsg(parseGraphQLErrors(error).toString() || 'Unable to get location details.');
        })
        .finally(() => dispatch('details'));
    };

    const fetchUnitDetails = () => {
      unitClient.query({
        query: getUnitsInfo,
        variables: { locationId: currentLocationId },
      })
        .then((response) => {
          const unitData = get(response, 'data.units.edges');
          if (unitData) {
            setUnits(unitData.map((item) => ({
              ...item.node,
              photos: parsePhotosUrl(item.node.photos),
            })));
          } else {
            toastFailMsg('Unable to get unit details.');
          }
        })
        .catch((error) => {
          toastFailMsg(parseGraphQLErrors(error).toString() || 'Unable to get unit details.');
        })
        .finally(() => dispatch('units'));
    };

    /* eslint-disable */
    React.useEffect(() => {
      if (currentLocationId) {
        fetchLocationDetails();
        fetchUnitDetails();
      }
    }, [currentLocationId]);
    /* eslint-enable */

    React.useEffect(() => {
      fetchPropertyTypes();
      fetchPetTypes();
      fetchAmenitiesTypes();
    }, []);

    const refetchDetails = (type = 'all', ...args) => {
      setRefetched(true);
      switch (type) {
        case 'details':
        case 'policies':
          currentLocationId && fetchLocationDetails();
          break;
        case 'units':
          currentLocationId && fetchUnitDetails();
          break;
        case 'orgDetails':
          fetchOrganisationDetails(...args);
          break;
        case 'amenities':
          fetchAmenitiesTypes(...args);
          break;
        default:
          currentLocationId && fetchLocationDetails();
          currentLocationId && fetchUnitDetails();
          break;
      }
    };

    return (
      <WrappedComponent
        {...props}
        {...{
          units,
          details,
          orgDetails,
          depositRules: {
            ...details.depositRules,
            hoaPolicyDocPath: details.hoaPolicyDocPath,
            distributionNoticeDocPath: details.distributionNoticeDocPath,
            cancellationPolicyDocPath: details.cancellationPolicyDocPath,
            paymentPolicyDocPath: details.paymentPolicyDocPath,
            petPolicyDocPath: details.petPolicyDocPath,
            applicationPolicyDocPath: details.esignDocPath && details.esignDocPath.application_doc_path,
            leasePolicyDocPath: details.esignDocPath && details.esignDocPath.lease_doc_path,
          },
          chargeCodes,
          amenityTypes,
          propertyTypes,
          petTypes,
          dataLoaded,
          refetched,
          refetchDetails,
        }}
      />
    );
  });

  ExtendedComponent.displayName = `WithPropertyDetails${displayName || WrappedComponent.displayName || WrappedComponent.name}`;

  return ExtendedComponent;
};
