import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { RentRollGQL } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from '../../components/Loader/Loader';
import { Table } from "../../components/Tables/RentRollData";
import { ACCOUNTING_REPORTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import "./Accounting.scss"
import { Button } from "semantic-ui-react";
import mixpanel from 'mixpanel-browser';


const invoiceClient = getClient(ACCOUNTING_REPORTING);

class RentRoll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
      ? this.props.selectedPrimary.node
      : "",
      loading: false,
      rollCache: [],
    };
  }
  
  componentDidMount() {
    this.getLedger();
    mixpanel.track('Manager Page Load', {
      'sub': 'Rent Roll'
    });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }

  getLedger = (data) => {
    try {
        this.setState({ loading: true });
        invoiceClient
          .query({
            query: RentRollGQL,
            variables: {
              locationId: this.props.selectedPrimary.node.id,
            },
          })
          .then((res) => {
            if (res.data && res.data.rentRoll ) {
              this.setState({
                rollCache: get(res, "data.rentRoll", []),
              });
            }
            this.setState({ loading: false });
          })
          .catch((error) => {
            console.log(error);
            this.setState({ loading: false });
          });
      
    } catch (e) {
      this.setState({ loading: false });
    }
  };



  dataPush = (response) => {
    this.setState((prevState) => ({
      journalCache: [
        response.data.createSlInvoice.slInvoice,
        ...prevState.journalCache,
      ],
    }));
  };
  updateInvoice = (status, invoiceId) => {
    const { journalCache } = this.state;
    const array = [];
    if (status === "Delete") {
      this.setState({ journalCache: journalCache.filter(invoice => invoice._id !== invoiceId) })
    }
    else {
      journalCache.forEach((invoice) => {
        if (invoice._id === invoiceId) {
          invoice.status = status;
          array.push(invoice);
        } else {
          array.push(invoice);
        }
      });
      this.setState({ journalCache: array });
    }
  };

  render() {
    const { loading } = this.state;
    return (
      <>
      {/* <main className="main-content" role="main"> */}
        {/* <div className="tenants-container resident-container"> */}
        {loading ? (
          <Loader text inTable/>
          ) : (
          <>
            <Button style={{paddingTop:'0px', paddingBottom:"0px", height:"46.17px", marginTop:'20px'}} className="noPrint" onClick={() => window.print()}>Print</Button>
            <Table
              rollCache={this.state.rollCache && this.state.rollCache}
            />
          </>
        )}
        {/* </div> */}
        {/* </main> */}
      </>
    );
  }
}

export default withApollo(RentRoll);
