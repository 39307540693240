/* eslint-disable no-param-reassign */
import React from "react";
import { Button, Modal } from "semantic-ui-react";
import "./TAModal.scss";


// const iframeScript = `
//   const report_lib = function () {
//     function ready(fn) {
//       setTimeout(() => {
//         if (document.readyState != 'loading') {
//           fn();
//         } else if (document.addEventListener) {
//           document.addEventListener('DOMContentLoaded', fn);
//         } else {
//           document.attachEvent('onreadysftatechange', () => {
//             if (document.readyState != 'loading') { fn(); }
//           });
//         }
//       }, 1000);
//     }

//     function addEventListener(el, eventName, handler) {
//       if (el.addEventListener) {
//         el.addEventListener(eventName, handler);
//       }
//     }

//     function toggleClickListener(event) {
//       event.stopPropagation();
//       const target = event.currentTarget;
//       const targetParent = target.parentElement;
//       let isOpen;
//       var className = target.nextElementSibling.className;
//       if (className.indexOf('hide') >= 0) {
//         isOpen = false;
//       } else {
//         isOpen = true;
//       }

//       const extraHeight = target.nextElementSibling.clientHeight;
//       const extendedHeight = targetParent.clientHeight + extraHeight + 'px';
//       const frameId = targetParent.parentElement.id.replace('_body', '');
//       var myCustomData = { event, targetParent, extendedHeight, extraHeight, frameId, isOpen };
//       var event = new CustomEvent('myEvent', { detail: myCustomData });
//       window.parent.document.dispatchEvent(event);
//     }

//     ready(() => {
//       const toggleButtons = document.querySelectorAll('.button-toggle');
//       for (let i = 0; i < toggleButtons.length; i++) {
//         addEventListener(toggleButtons[i], 'click', toggleClickListener);
//       }
//     });
//   };

//   if (typeof ari_report === 'undefined') {
//     ari_report = true;
//     report_lib();
//   }

// `;

export default function TAModal(props) {
  const [open, setOpen] = React.useState(false);
  // const [loader, setLoader] = React.useState(true);
  // const [html, htmlDecGet] = React.useState(false);
  // const [reports, reportDecGet] = React.useState(false);
  // const [object, objectDecGet] = React.useState(false);

  // const BGC = () => {
  //   setLoader(true);
  //   try {
  //     BGCCLIENT.query({
  //       query: BGCCheck,
  //       variables: {
  //         email: props.ele,
  //       },
  //     }).then((res) => {

  //       setLoader(false);
  //       const htmlRep = res.data.taApplication.response.result.applicant_html;
  //       const repRep = res.data.taApplication.response.result.report;
  //       const objectRep = res.data.taApplication.response.result;

  //       const node = document.createRange().createContextualFragment(htmlRep);
  //       objectDecGet(objectRep);
  //       reportDecGet(repRep);
  //       htmlDecGet(htmlRep);
  //     });
  //   } catch (e) {
  //     setLoader(false);
  //   }
  // };

  // const injectScript = (ifr, index) => {
  //   const scriptId = `reportFrame_script__${index}`;
  //   ifr.id = `reportFrame_frame__${index}`;
  //   if (ifr.contentWindow.document.body) {
  //     ifr.contentWindow.document.body.style.overflowX = "hidden";
  //     if (!ifr.contentWindow.document.getElementById(scriptId)) {
  //       const scriptObj = ifr.contentWindow.document.createElement("script");
  //       scriptObj.type = "text/javascript";
  //       scriptObj.id = scriptId;
  //       scriptObj.innerHTML = iframeScript;
  //       ifr.contentWindow.document.body.appendChild(scriptObj);
  //     }
  //     ifr.contentWindow.document.body.id = `reportFrame_frame_body__${index}`;
  //     ifr.style.height = `${
  //       ifr.contentWindow.document.body.clientHeight + 40
  //     }px`;
  //   }
  // };

  // useEffect(() => {
  //   if (html)
  //     setTimeout(
  //       () => document.querySelectorAll("iframe").forEach(injectScript),
  //       2000
  //     );
  // }, [html]);

  // useEffect(() => {
  //   function handleEvent(e) {
  //     const targetedFrame = document.getElementById(e.detail.frameId);
  //     if (targetedFrame) {
  //       let newHeight = targetedFrame.clientHeight;
  //       const bodyEle = targetedFrame.contentDocument.children[0].querySelector(
  //         "body"
  //       );
  //       if (e.detail.isOpen) {
  //         newHeight = targetedFrame.contentDocument.children[0].scrollHeight;
  //         if (bodyEle) bodyEle.style.overflowX = "hidden";
  //       } else if (bodyEle) {
  //         newHeight = bodyEle.clientHeight;
  //       }
  //       targetedFrame.style.height = `${newHeight}px`;
  //     }
  //   }
  //   window.document.addEventListener("myEvent", handleEvent, false);
  //   return () => {
  //     window.document.removeEventListener("myEvent", handleEvent, false);
  //   };
  // }, []);

  return (
    <Modal
      className="semanticModal "
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="large"
      trigger={
        <Button size="tiny">
          Full Decision
        </Button>
      }
    >
      <Modal.Header>
        Decision for:
        {props.ele}
        
      </Modal.Header>
    <Modal.Content>
          <embed src="assets/Tenant.pdf" width="100%" height="500px" />
    </Modal.Content> 
      <Modal.Actions>
        <Button content="Close" style={{backgroundImage: "linear-gradient(276deg, #7127c4, #4c158a)"}} onClick={() => setOpen(false)} positive />
      </Modal.Actions>
    </Modal>
  );
}
