import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from 'mixpanel-browser';
import {
    TransAccounting
} from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from 'lodash/get'
import { Loader } from '../../components/Loader/Loader';
import { Table } from "../../components/Tables/TransactionsData";
import { ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";

const transClient = getClient(ACCOUNTING)

class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary ? this.props.selectedPrimary.node : '',
      loading: false,
      transCache:[],
    };
  }

  componentDidMount() {
    this.getLedger()
    mixpanel.track('Manager Page Load', {
          'sub': 'Transactions'
     });

  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary ? prevProps.selectedPrimary.node : '';
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({ selectedMonthEvents: { ...this.state.selectedMonthEvents, montEvents: [] } })
        this.getLedger()
      })
    }
  }

  getLedger = (data) => {
    try {
      if(data=== undefined){
        this.setState({ loading: true })
        transClient.query({
          query: TransAccounting,
          variables: {
            location:  this.props.selectedPrimary.node.id,
            offset: 0,
              limit: 30
          }
        }).then(res => {
          if(res.data) {
  
            this.setState({
              transCache: get(res, 'data.slLocationTransactions.data', null),
              totalItems:get(res, "data.slLocationTransactions.totalItems"),
            })
          }
          this.setState({ loading: false })
        })
          .catch(error => {
            console.log(error)
            this.setState({ loading: false })
          });
      }
      else {
        transClient.query({
          query: TransAccounting,
          variables: {
            location:  this.props.selectedPrimary.node.id,
            offset: data +data,
            limit: 30
          }
        })
          .then((res) => {
            if (res.data) {
              const value = get(res, "data.slLocationTransactions.data", [])
              value.forEach(node => {
                this.state.transCache.push(node)
              })
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      }
    catch (e) {
      this.setState({ loading: false })
    }
  }


  render() {
    const {loading,} =this.state
    return (
      <>
       {loading ? <Loader text inTable/> : 
       <Table transCache={this.state.transCache} 
       getLedger={this.getLedger}
       totalItems={this.state.totalItems&&this.state.totalItems}
       propertyData={this.props.selectedPrimary&& this.props.selectedPrimary}
       propertyId={this.props.selectedPrimary.node &&this.props.selectedPrimary.node.id} />}
      </>
    );
  }
}

export default withApollo(Transactions);
