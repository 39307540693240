import React, { Component} from "react";
import moment from 'moment';
import { getClient } from "../../init-apollo-googleFn";
import { maintenanceGql } from "../../store/person/maintenance"
import './Schedule.scss'
import { LEADS_TOUR, APPLICATION_MANAGER,PERSON_URL} from "../../utils/constants";
import { TourLocation, updateTour } from "../../store/person/leads";
import { pmApplicationsGql } from "../../store/person/applications";
import { eventsGql } from "../../store/person/person";
import { PageLoader } from "../../components/Loader/PageLoader";
import { ToastContainer, toast } from 'react-toastify';
import { getCookie } from '../../store/auth/authUtility';
import mixpanel from 'mixpanel-browser';
import { Icon, Grid, Card, Segment, Container, Menu, Confirm } from 'semantic-ui-react';
const toursClient = getClient(LEADS_TOUR)
const appClient = getClient(APPLICATION_MANAGER);
const personClient = getClient(PERSON_URL)
class Schedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
			chosen:moment().startOf('day'),
			selectedMonth: moment(),
			selectedDay: moment().startOf('day'),
			selectedMonthEvents: [],
			showEvents: false,
			primary: this.props.selectedPrimary
				? this.props.selectedPrimary.node
				: '',
			tourData: '',
			modalOpen: false,
			events: '',
			loading: false,
		};

    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
    this.addEvent = this.addEvent.bind(this);
    this.showCalendar = this.showCalendar.bind(this);
    this.goToCurrentMonthView = this.goToCurrentMonthView.bind(this);

    this.initialiseEvents();
  }

  componentDidMount() {
    const cookie = getCookie()
    if(cookie.manager==="true"){
      // this.getAgenda()
      this.getCal()

    }

    mixpanel.track('Manager Page Load', {
      'sub': 'Schedule'
     });
  }

  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary ? prevProps.selectedPrimary.node : '';
    const cookie = getCookie()
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({ selectedMonthEvents: { ...this.state.selectedMonthEvents, montEvents: [] } })
        if (cookie.manager==="true"){
          // this.getAgenda()
          this.getCal()
          this.getApplicationAgenda()
          
        }
      })
    }
  }

  getCal = () => {
    try {
      personClient.query ({
        query:eventsGql,
        variables: {
          locationId:this.props.dashboard?this.props.primaryLocation:this.props.selectedPrimary.node.id
        }
      }).then(res => {
        const setAgenda = [];
        const events = res.data.personCalendar && res.data.personCalendar.edges
        console.log(res)
        events && events.forEach(event => {
          const split = event.node.startDate.split('T')
          const newT = moment(split[0] + ' ' +split[1], 'DD/MM/YYYY HH:mm A')
          const secT = moment.utc(newT._i)
          const newEvent = {
            title: event.node.eventName ,
            time: secT.local().format('DD/MM/YYYY hh:mm A'),
            lodat:moment(secT),
            date: moment(secT) ,
            description: event.node.description,
            id: event.node.eventId,
            dynamic: true,
          };
          setAgenda.push(newEvent);
        })
        
        this.setState({
          selectedMonthEvents: setAgenda,
          loading: false,
        });
      })
    }
    catch (e) {
      console.log(e)
  
    }
  }


  getAgenda = () => {

    try {
      toursClient.query({
        query: TourLocation,
        variables: {
          location: this.props.dashboard?this.props.primaryLocation:this.props.selectedPrimary.node.id
        }
      }).then(res => {
        const eventFilter = res.data.tours.edges;
        const setAgenda = [];
        const filterArr = eventFilter.filter(
          task => task.node.status !== 'cancel'
        );
        filterArr.forEach(event => {
          const address = event.node.primaryLocation.addresses[0].streetOne +" "+	event.node.primaryLocation.addresses[0].city +" "+event.node.primaryLocation.addresses[0].state +" "+event.node.primaryLocation.addresses[0].zip
          const localTime = moment(event.node.date + ' ' +event.node.time, 'DD/MM/YYYY HH:mm A')
          const local = moment.utc(localTime._i)
          const DDate = local
          const adjustedTime = DDate.local().format('hh:mm A')
          const adjustedDate = DDate.local().format('MM/DD/YYYY')
          const newEvent = {
            title: 'Tour -' ,
            time: adjustedTime+ " - " + address,
            lodat:moment(adjustedTime,'hh:mm A'),
            date: moment(adjustedDate) ,
            description:
              'Tour with ' +
              event.node.name +
              ' at ' +
              event.node.primaryLocation.name,
            id: event.node.id,
            dynamic: true,
          };
          setAgenda.push(newEvent);
        });
        this.setState({
          selectedMonthEvents: setAgenda,
          loading: false,
        });
        this.getMaintenanceRequests()
        this.clickToday()
      })
        .catch(error => {
          console.log(error)
          this.clickToday()
          this.setState({ loading: false })
        });
    }
    catch (e) {
      console.log(e)
      this.clickToday()
      this.setState({ loading: false })
    }
  }

  getApplicationAgenda = () => {
    this.setState({ loading: true })
    try {
      appClient.query({
        query: pmApplicationsGql,
        variables: {
          locationId: this.props.selectedPrimary.node.id
        }
      }).then(res => {
        const eventFilter = res.data.applications.edges
        const setAgenda = [...this.state.selectedMonthEvents]
        const filterArr = eventFilter.filter(task => task.node.status !== "Cancelled" || task.node.status !== 'Denied')
        filterArr.forEach(event => {
          const newEvent = {
            title: "Application",
            date: moment(event.node.targetMoveInDate),
            description: "Application of " + event.node.primaryApplicant.firstName,
            id: event.node.id,
            dynamic: true
          };
          setAgenda.push(newEvent);
        })
        this.setState({
          selectedMonthEvents: setAgenda, loading: false
        });
        this.clickToday()
      })
        .catch(error => {
          this.clickToday()
          this.setState({ loading: false })
        });
    }
    catch (e) {
      this.clickToday()
      this.setState({ loading: false })
    }
  }

  getMaintenanceRequests() {
    const monthEvents = this.state.selectedMonthEvents;
    const roleType = this.props.role.serviceProfessional === "true" ? "serviceProfessional" : "manager"
    const statusType = ["Submitted", "Processing", "Scheduled", "Completed", "Canceled", "Approval Needed", "Approved"]
    this.setState({ loading: true })
    try {
      this.props.maintenanceClient.query({
        query: maintenanceGql,
        variables: {
          role: roleType,
          status: statusType,
          location: this.props.selectedPrimary.node.id
        }
      }).then(res => {
        const array = res.data.maintenanceRequest.edges

        let newEvents = [];
        array.forEach(event => {
          const newEvent = {
            title: event.node.unit,
            date: moment(event.node.scheduledStart),
            dynamic: true,
            item:event.node.category,
            id:event.node.id,
            location:event.node.location,
            maintenance:true
          };
          newEvents.push(newEvent);
        })


        for (var i = 0; i < newEvents.length; i++) {
          monthEvents.push(newEvents[i]);
        }
        this.setState({ loading: false })
        this.setState({
          selectedMonthEvents: monthEvents
        });
        this.clickToday()
      })
        .catch(error => {
          this.clickToday()
          console.log(error)
          this.setState({ loading: false })
        });

    } catch (e) {
      this.clickToday()
      this.setState({ loading: false })
      console.log(e);
    }
  }

  previous() {
    const currentMonthView = this.state.selectedMonth;
    this.setState({
      selectedMonth: currentMonthView.subtract(1, "month")
    });
  }

  next() {
    const currentMonthView = this.state.selectedMonth;
    this.setState({
      selectedMonth: currentMonthView.add(1, "month")
    });
  }
  clickToday = () =>{
		if(this.props.dashboard){
			const data = {date:''}
			data.date = moment().format('MM/DD/YYYY')
			this.select(data)
		}
	}

  select(day) {
		this.setState({chosen:day.date})
		if(!this.props.dashboard){
			this.setState({
				selectedMonth: day.date,
				selectedDay: day.date.clone(),
				showEvents: true,
			});
		}
		else {
			const dayEventsRendered = [];
			for (var i = 0; i < this.state.selectedMonthEvents.length; i++) {
				if (this.state.selectedMonthEvents[i].date.isSame(day.date, 'day')) {
					dayEventsRendered.push(this.state.selectedMonthEvents[i]);
				}
			}
			this.props.dashboardList(dayEventsRendered, day.date)
		}
	}

  goToCurrentMonthView() {
    this.setState({
      selectedMonth: moment()
    });
  }

  showCalendar() {
    this.setState({
      selectedMonth: this.state.selectedMonth,
      selectedDay: this.state.selectedDay,
      showEvents: false
    });
  }

  renderMonthLabel() {
    const currentMonthView = this.state.selectedMonth;
    return (
      <Menu.Header className="month-label">{currentMonthView.format("MMMM YYYY")}</Menu.Header>
    );
  }

  renderDayLabel() {
    const currentSelectedDay = this.state.selectedDay;
    return (
      <Menu.Header className="month-label">{currentSelectedDay.format("DD MMMM YYYY")}</Menu.Header>
    );
  }

  renderWeeks() {
    const currentMonthView = this.state.selectedMonth;
    const currentSelectedDay = this.state.selectedDay;
    const monthEvents = this.state.selectedMonthEvents;
    const chosen = this.state.chosen;
    let weeks = [];
    let done = false;
    let previousCurrentNextView = currentMonthView
      .clone()
      .startOf("month")
      .subtract(1, "d")
      .day("Sunday");
    let count = 0;
    let monthIndex = previousCurrentNextView.month();

    while (!done) {
      weeks.push(
        <Week
          previousCurrentNextView={previousCurrentNextView.clone()}
          currentMonthView={currentMonthView}
          monthEvents={monthEvents}
          selected={currentSelectedDay}
          select={day => this.select(day)}
          chosen={chosen}
        />
      );
      previousCurrentNextView.add(1, "w");
      done = count++ > 2 && monthIndex !== previousCurrentNextView.month();
      monthIndex = previousCurrentNextView.month();
    }
    return weeks;
  }

  handleAdd() {
    const monthEvents = this.state.selectedMonthEvents;
    const currentSelectedDate = this.state.selectedDay;

    let newEvents = [];
    var eventTitle = prompt("Please enter a name for your event: ");

    switch (eventTitle) {
      case "":
        alert("Event name cannot be empty.");
        break;
      case null:
        alert("Changed your mind? You can add one later!");
        break;
      default:
        var newEvent = {
          title: eventTitle,
          date: currentSelectedDate,
          dynamic: true
        };

        newEvents.push(newEvent);

        for (var i = 0; i < newEvents.length; i++) {
          monthEvents.push(newEvents[i]);
        }

        this.setState({
          selectedMonthEvents: monthEvents
        });
        break;
    }
  }

  addEvent() {
    const currentSelectedDate = this.state.selectedDay;
    let isAfterDay = moment().startOf("day").subtract(1, "d");

    if (currentSelectedDate.isAfter(isAfterDay)) {
      this.handleAdd();
    }
  }
  success = () => toast.success('Event Canceled!', {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  removeEvent(i) {
    const monthEvents = this.state.selectedMonthEvents.slice();
    const index = i;
    mixpanel.track('Manager Lead Action', {
      'sub': 'Remove Tour'
    });
    try {
      toursClient.mutate({
        mutation: updateTour,
        variables: {
          input: {
            tour: {
              id: index.id,
              status: "cancel",
              comment: "Cancel"
            }
          }
        }
      }).then(response => {
        /* eslint-disable */
        const newEvent = monthEvents.filter(event => event !== index)
        console.log(newEvent)
        /* eslint-enable */
        this.success()
        this.setState({
          selectedMonthEvents: newEvent
        });
      })
    } catch (e) {
      console.log(e)
          alert(e)
    }
  }

  

  initialiseEvents() {
    const monthEvents = this.state.selectedMonthEvents;
    let allEvents = [

    ];

    for (var i = 0; i < allEvents.length; i++) {
      monthEvents.push(allEvents[i]);
    }

    this.setState({
      selectedMonthEvents: monthEvents
    });
  }

  render() {
    const { loading } = this.state
    const showEvents = this.state.showEvents;
    if (showEvents) {
      return (
        <>
          {loading ? <PageLoader text /> :
            <Container fluid textAlign="center" className="main-calendar">
              <Menu attached="top" fluid className="calendar-header">
                <Menu.Item onClick={this.showCalendar} icon="calendar alternate outline" position="left" className="calendar-action" />
                {this.renderDayLabel()}
                <Menu.Item onClick={this.addEvent} icon="add square" position="right" className="calendar-action" />
              </Menu>
              <Segment attached="bottom" textAlign="center" className="calendar-body">
                <Events
                  selectedMonth={this.state.selectedMonth}
                  selectedDay={this.state.selectedDay}
                  history={this.props}
                  selectedMonthEvents={this.state.selectedMonthEvents}
                  removeEvent={i => this.removeEvent(i)}
                />
              </Segment>
              <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}

              />
            </Container>

          }
        </>
      );
    } else {
      return (
        <>
          {loading ? <PageLoader /> :
            <Container textAlign="center" className="main-calendar">
              <Menu attached="top" fluid className="calendar-header">
                <Menu.Item onClick={this.previous} icon="arrow left" position="left" className="calendar-action" />
                {this.renderMonthLabel()}
                <Menu.Item onClick={this.next} icon="arrow right" position="right" className="calendar-action" />
              </Menu>
              <Segment attached="bottom" textAlign="center" className="calendar-body">
                <Grid columns={7}>
                  <DayNames />
                  {this.renderWeeks()}
                </Grid>
              </Segment>
            </Container>
          }
        </>
      );

    }
  }
}


class Events extends React.Component {
  state = { open: false, result: 'show the modal to capture a result' }
  show = () => this.setState({ open: true })
  handleCancel = () => this.setState({ result: 'cancelled', open: false })
  handleConfirm = (event) => {
    this.props.removeEvent(event)
    this.setState({ result: 'confirmed', open: false })
  }

  reRoute =(item)=> {
    if(item.maintenance === true){
      const { id, location } = item
      this.props.history.history.push({
        pathname: '/maintenanceDetail/location=' + location + "/id=" + id,
      })
    }
    else return
  }
  render() {
    const currentSelectedDay = this.props.selectedDay;
    const monthEvents = this.props.selectedMonthEvents;
    const { open } = this.state;
    const monthEventsRendered = monthEvents.map((event, i) => {
      const formattedTime = event.time.split('/');
      return (
        <>
          <Card fluid className="event-card" onClick={()=>this.reRoute(event)} >
            <Card.Content >
              {event.title !== 'Application' && <Icon floated="right" name="trash alternate" onClick={this.show} className="event-delete" />}
              {event.maintenance ? <Card.Header>Maintenance Request in Unit: #{event.title}</Card.Header>:<Card.Header>{event.title}</Card.Header>}
              <Card.Meta>{formattedTime[1]}/{formattedTime[0]}/{formattedTime[2]}</Card.Meta>
            </Card.Content>
            <Card.Content description={event.maintenance? event.item.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}) : event.description} />
          </Card>
          <Confirm
            className="semanticModal"
            open={open}
            onCancel={() => this.handleCancel()}
            onConfirm={() => this.handleConfirm(event)}
          />
        </>
      );
    });

    const dayEventsRendered = [];

    for (var i = 0; i < monthEventsRendered.length; i++) {
      if (monthEvents[i].date.isSame(currentSelectedDay, "day")) {
        dayEventsRendered.push(monthEventsRendered[i]);
      }
    }

    return (
      <Card.Group className="events-daily">
        {dayEventsRendered}
      </Card.Group>
    );
  }
}

class DayNames extends React.Component {
  render() {
    return (
      <>
        <Grid.Column className="calendar-dow">Sun</Grid.Column>
        <Grid.Column className="calendar-dow">Mon</Grid.Column>
        <Grid.Column className="calendar-dow">Tue</Grid.Column>
        <Grid.Column className="calendar-dow">Wed</Grid.Column>
        <Grid.Column className="calendar-dow">Thu</Grid.Column>
        <Grid.Column className="calendar-dow">Fri</Grid.Column>
        <Grid.Column className="calendar-dow">Sat</Grid.Column>
      </>
    );
  }
}

class Week extends React.Component {
  render() {
    let days = [];
    let date = this.props.previousCurrentNextView;
    let currentMonthView = this.props.currentMonthView;
    let selected = this.props.selected;
    let select = this.props.select;
    let monthEvents = this.props.monthEvents;
    let chosen = this.props.chosen;
    for (var i = 0; i < 7; i++) {
      var dayHasEvents = false;
      let dayEventCount = 0;

      for (var j = 0; j < monthEvents.length; j++) {
        if (monthEvents[j].date.isSame(date, 'day')) {
					dayHasEvents = true;
					dayEventCount++;
				}
      }

      let day = {
        name: date.format("dd").substring(0, 1),
        number: date.date(),
        isCurrentMonth: date.month() === currentMonthView.month(),
        isBefore: date.isBefore(new Date(), "day"),
        isToday: date.isSame(new Date(), "day"),
        date: date,
        hasEvents: dayHasEvents,
        eventCount: dayEventCount,
      };

      days.push(<Day day={day} chosen={chosen} selected={selected} select={select} />);
      date = date.clone();
      date.add(1, "d");
    }
    return (
      <>
        {days}
      </>
    );
  }
}

class Day extends React.Component {
render() {
    let day = this.props.day;
    let selected = this.props.selected;
    let select = this.props.select;
    let chosen = this.props.chosen
    const choose = (date) => {
			chosen = date
		}
    return (
      <Grid.Column
        centered
        textAlign="center"
        className={
          "day" +
          (day.isToday ? " today" : "") +
          (day.date.isSame(chosen)  ? ' chosen' : '') +
          (day.isBefore ? " before" : "") +
          (day.isCurrentMonth ? "" : " different-month") +
          (day.date.isSame(selected) ? " selected" : "")
        }
        onClick={() => {select(day); choose(day.date)}}
      >
        <div className="day-number">{day.number}</div>
        {day.eventCount >= 1 ? (
					<div className="event-label">{day.eventCount} Event</div>
				) : <div>&nbsp;</div>}

      </Grid.Column>
    );
  }
}

export default Schedule
