import React, { useMemo, useState, useEffect} from "react";
import { ToastContainer, } from "react-toastify";
import AccountingTable from "./AccountingTable";
import {  ACCOUNTING } from "../../utils/constants";
import { multipleColumnsSearch } from "../../utils/common";
import { getClient } from "../../init-apollo-googleFn";
import ViewInvoiceDetails from "../Accounting/ViewInvoiceDetails";
import { InvoiceInfoAccounting } from "../../store/person/accounting";
import "react-toastify/dist/ReactToastify.css";

const invoiceClient = getClient(ACCOUNTING);

export function Table(props) {
  const [filterInput, setFilterInput] = useState("");
  const [toggled, viewChange] = useState(false);
  const [viewInvoiceData, viewDataInvoice] = useState("");
  const [viewInvoicesData, viewDataInvoices] = useState("");
  const locationId = props.propertyId && props.propertyId;
  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    const filteredData = multipleColumnsSearch(
      value,
      props.invoiceCache && props.invoiceCache
    );
    setData(filteredData);
    setFilterInput(value);
  };

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    return "";
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "Customer",
        accessor: "number",
      },
      {
        Header: "Total",
        accessor: "status",
      },
      {
        Header: "0-30",
        accessor: "0-30",
      },
      {
        Header: "31-60",
        accessor: "31-60",
      },
      {
        Header: "61-90",
        accessor: "61-90",
      },
      {
        Header: "91-120",
        accessor: "91-120",
      },
      {
        Header: "121-150",
        accessor: "121-150",
      },
      {
        Header: "150+",
        accessor: "150+",
      },
    ],
    []
  );
  const viewInvoice = (e, props) => {
    viewDataInvoices(props);
    if (toggled) {
      viewChange(false);
    }
    if (!toggled) {
      getInvoice(props);
    }
  };

  const getInvoice = (props) => {
    try {
      invoiceClient
        .query({
          query: InvoiceInfoAccounting,
          variables: {
            location: locationId,
            id: props.props._id,
          },
        })
        .then((res) => {
          if (res.data) {
            const data = res.data.slLocationInvoice
            /* eslint-disable */
            const Check = data.payments.map((payment) => {
              const check = data.payments.filter(
                (pay) => pay.externalCheckBankId === payment._id
              );
              if (check.length > 0) {
                return payment.status = "refund";
              }
            });
            /* eslint-enable */
            viewDataInvoice(
              res.data.slLocationInvoice && res.data.slLocationInvoice
            );
            viewChange(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
    }
  };

  const [data, setData] = useState([]);
  /* eslint-disable */
  const [loading, setLoading] = React.useState(false);
  /* eslint-enable */
  const [lastPage, setLastPage] = useState();

  /* eslint-disable */
  useEffect(() => {
    const result = props.totalItems / 30;
    setLastPage(Math.ceil(result));
    setData(props.invoiceCache && props.invoiceCache);
  }, [props.invoiceCache && props.invoiceCache]);
  /* eslint-enable */

  return (
    <>
      {!toggled ? (
        <>
          <AccountingTable
            value={filterInput}
            defaultColumn={defaultColumn}
            onChange={handleFilterChange}
            columns={columns}
            data={data}
            loading={loading}
            totalItems={props.totalItems}
            pageCount={lastPage}
            lastPage={lastPage}
          />
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
          />
        </>
      ) : (
        <ViewInvoiceDetails
          property={props.property}
          invoice={viewInvoiceData}
          invoices={viewInvoicesData}
          ledgerView={viewInvoice}
        />
      )}
    </>
  );
}
