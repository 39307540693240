import React from 'react'

export const CriminalHistory = ({

  verifiedIcon = <span className="text-success"><i className="fa fa-check-circle"></i></span>

}) => (
    <div className="card card-md applicant-history-card">
      <div className="applicant-history-card-title">
        Criminal History {verifiedIcon}
        <a
          className="validations-link"
          data-toggle="collapse"
          href="#criminal-validations"
          aria-expanded="false"
          aria-controls="validations"
        >
          See Validations
        </a>
        <div className="collapse" id="criminal-validations">
          <div className="validations-container">
            <div className="validations">
              <span className="validations-item">
                Questionnaire: {verifiedIcon}
              </span>
            </div>
            <div className="validations">
              <span className="validations-item">
                Felonies (3 yrs): {verifiedIcon}
              </span>
            </div>
            <div className="validations">
              <span className="validations-item">
                Felonies (7 yrs): {verifiedIcon}
              </span>
            </div>
            <div className="validations">
              <span className="validations-item">
                Misdemeanors: {verifiedIcon}
              </span>
            </div>
            <div className="validations">
              <span className="validations-item">
                Sex Offender: {verifiedIcon}
              </span>
            </div>
          </div>
        </div>
      </div>
      <ul className="nav nav-tabs" id="criminalTabs" role="tablist">
        <li className="nav-item">
          <a
            className="nav-link active"
            id="felonies-tab"
            data-toggle="tab"
            href="#felonies"
            role="tab"
            aria-controls="felonies"
            aria-selected="true"
          >
            Felonies
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="misdemeanors-tab"
            data-toggle="tab"
            href="#misdemeanors"
            role="tab"
            aria-controls="misdemeanors"
            aria-selected="false"
          >
            Misdemeanors
          </a>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="felonies"
          role="tabpanel"
          aria-labelledby="felonies-tab"
        >
          <div className="card-media-list criminal-table-container">
            <div className="responsive-table">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Case #</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-target="Name">FirstName Last</td>
                    <td data-target="Case #">123sdaf</td>
                    <td data-target="Description">Armed Robbery</td>
                  </tr>
                  <tr>
                    <td data-target="Name">FirstName Last</td>
                    <td data-target="Case #">123sdaf</td>
                    <td data-target="Description">Armed Robbery</td>
                  </tr>
                  <tr>
                    <td data-target="Name">FirstName Last</td>
                    <td data-target="Case #">123sdaf</td>
                    <td data-target="Description">Armed Robbery</td>
                  </tr>
                  <tr>
                    <td data-target="Name">FirstName Last</td>
                    <td data-target="Case #">123sdaf</td>
                    <td data-target="Description">Armed Robbery</td>
                  </tr>
                  <tr>
                    <td data-target="Name">FirstName Last</td>
                    <td data-target="Case #">123sdaf</td>
                    <td data-target="Description">Armed Robbery</td>
                  </tr>
                  <tr>
                    <td data-target="Name">FirstName Last</td>
                    <td data-target="Case #">123sdaf</td>
                    <td data-target="Description">Armed Robbery</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="misdemeanors"
          role="tabpanel"
          aria-labelledby="misdemeanors-tab"
        >
          <div className="card-media-list criminal-table-container">
            <div className="responsive-table">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Case #</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-target="Name">FirstName Last</td>
                    <td data-target="Case #">123sdaf</td>
                    <td data-target="Description">Jaywalking</td>
                  </tr>
                  <tr>
                    <td data-target="Name">FirstName Last</td>
                    <td data-target="Case #">123sdaf</td>
                    <td data-target="Description">Jaywalking</td>
                  </tr>
                  <tr>
                    <td data-target="Name">FirstName Last</td>
                    <td data-target="Case #">123sdaf</td>
                    <td data-target="Description">Jaywalking</td>
                  </tr>
                  <tr>
                    <td data-target="Name">FirstName Last</td>
                    <td data-target="Case #">123sdaf</td>
                    <td data-target="Description">Jaywalking</td>
                  </tr>
                  <tr>
                    <td data-target="Name">FirstName Last</td>
                    <td data-target="Case #">123sdaf</td>
                    <td data-target="Description">Jaywalking</td>
                  </tr>
                  <tr>
                    <td data-target="Name">FirstName Last</td>
                    <td data-target="Case #">123sdaf</td>
                    <td data-target="Description">Jaywalking</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )