import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import Datetime from "react-datetime";
import mixpanel from 'mixpanel-browser';
import {
  BoxScoreGql
} from "../../store/person/accounting";
import moment from "moment";
import { toastFailMsg } from "../../utils/common";
import "./Accounting.scss";

import { Button, Header } from "semantic-ui-react";
import { Table } from "../../components/Tables/RollData";
import { ACCOUNTING_REPORTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "../../components/Loader/Loader";
// images
import backIcon from '../../assets/images/back-arrow.svg';

const reportingClient = getClient(ACCOUNTING_REPORTING);

const DEFAULT_LIMIT = 30;

class BoxScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      initialFetch: false,
      startDate: moment().startOf('month').format("YYYY-MM-DD"),
      endDate: moment().endOf('month').format("YYYY-MM-DD"), 
      boxId:'occupied_units_preleased',
      transactions: [],
      totalItems: 0,
      paginate: {
        start: 0,
        end: DEFAULT_LIMIT
      }
    };
  }

  componentDidMount() {
    mixpanel.track('Manager Page Load', {
      'sub': 'Box Score'
     });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        if (this.state.transactions.length) {
          this.setState({
            transactions: [],
            paginate: {
              start: 0,
              end: DEFAULT_LIMIT
            }
          });
        }
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.ReconcileAccount();
      });
    }
  }


  changeLedger = (val) => {
    this.setState({ boxId: val.value });
  };

  ReconcileAccount = async () => {
    this.setState({loading:true})
    const {id} = this.props.selectedPrimary.node;
    reportingClient
        .query({
          query: BoxScoreGql,
          variables: {
            startDate: this.state.startDate || "",
            endDate: this.state.endDate || "",
            locationId:id,
            reportType:this.state.boxId,
          },
        })
        .then((res) => {
          if (res.data) {
            const value = get(res, "data.boxScoreResults.edges", []);

            this.setState({
              transactions: value,
            });
            this.setState({loading:false})
          }
          this.setState({loading:false})
        })
        .catch((error) => {
          this.setState({loading:false})
          console.log(error);
          toastFailMsg(error);
        });
  };

  

  handleStartClick = (date) => {
    this.setState({
      startDate: moment(date._d).format("YYYY-MM-DD"),
    });
  };
  handleEndClick = (date) => {
    this.setState({
      endDate: moment(date._d).format("YYYY-MM-DD"),
    });
  };


  render() {
    const { loading } = this.state;

      const { transactions, totalItems, sumData } = this.state;
    return (
      <>
       <main className="main-content" role="main">

       <div className="row">
          <div className="col-md-12">
            <div className="page-header">
              <div className="d-flex align-items-center">
                <span className="page-back"><img src={backIcon} alt="go back icon" /></span>
                <div>
                  <div className="page-header-title">Box Score</div>
                  <div className="page-header-subtitle"></div>
                </div>
              </div>
              <div className="d-flex align-items-end">
                <div className="ml-3">
                <Header textAlign="left">Start Date</Header>
                <Datetime inputProps={{ placeholder: 'Select Start Date' }} defaultValue={moment().startOf('month')} timeFormat={false}  onChange={this.handleStartClick} />
                </div>
                <div className="ml-3">
                <Header textAlign="left">End Date</Header>
                <Datetime inputProps={{ placeholder: 'Select End Date' }} defaultValue={moment().endOf('month')} timeFormat={false}   onChange={this.handleEndClick} />
                </div>
                <Button className="ml-3" id="getLedger" style={{ background: "#5e6267", color: "white" }} onClick={() => this.ReconcileAccount()}>Get Report</Button>
                <Button className="ml-4 noPrint" style={{ background: "#5e6267", color: "white" }} onClick={() => window.print()}>Print</Button>
                {/* <Button className="ml-2" style={{ backgroundImage: "linear-gradient(110deg, #3b1c5a, #374db1 162%)", color: "white" }}>Share</Button> */}
              </div>
            </div>
          </div>
        </div>

        <div className="tenants-container resident-container">

        {/* <div className="dates">
          <div>
            <Header textAlign="center">Start Date</Header>
            <Datetime inputProps={{ placeholder: 'Select Start Date' }} defaultValue={moment().startOf('month')} timeFormat={false}  onChange={this.handleStartClick} />
          </div>
          <div>
            <Header textAlign="center">End Date</Header>
            <Datetime inputProps={{ placeholder: 'Select End Date' }} defaultValue={moment().endOf('month')} timeFormat={false}   onChange={this.handleEndClick} />
          </div>
          <div>
              <Header textAlign="center">Ledger</Header>
                <Dropdown
                  onChange={(e, { value }) => this.changeLedger({ value })}
                  selection
                  defaultValue={financials[0].value}
                  options={financials}
                />
          </div>
          <div>
            <Button
            id="getLedger"
              style={{ background: "#624d8e", color: "white" }}
              className="noPrint" 
              onClick={() => this.ReconcileAccount()}
            >
              Gather
            </Button>
          </div>
          <div>

          <Button style={{paddingTop:'0px', paddingBottom:"0px", height:"35px", marginTop:'5px'}} className="noPrint" onClick={() => window.print()}>Print</Button>
          </div>

        </div> */}
      
        {loading ? <Loader text inTable/>
        : 
        <>
          {typeof sumData == "number" && (
            <Header>
              Net Balance:{" "}
              {sumData && sumData >= 0
                ? `$${sumData
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                : `$(${Math.abs(sumData)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`}
            </Header>
          )}
          <div className="box-score-table-title">Lease - New Residents</div>
          <Table
            rollCache={transactions}
            getLedger={this.getLedger}
            ReconcileAccount={() => this.ReconcileAccount(false)}
            totalItems={totalItems && totalItems}
            propertyData={
              this.props.selectedPrimary && this.props.selectedPrimary
            }
            propertyId={
              this.props.selectedPrimary.node &&
              this.props.selectedPrimary.node.id
            }
          />
        </>
        }
        </div>
        </main>
      </>
    );
  }
}

export default withApollo(BoxScore);
