import React from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';
import { getClient } from '../../../init-apollo-googleFn';
import { getLocationType } from '../../../store/person/properties';
import { LOCATION_TYPE_API } from '../../../utils';
import { parseGraphQLErrors, toastFailMsg } from '../../../utils/common';

const locationTypeClient = getClient(LOCATION_TYPE_API);

const PropertyTypeSelection = ({
  isOpen,
  onClose,
  onSelect,
}) => {
  const [propertyTypes, setPropertyTypes] = React.useState([]);
  const [selected, setSelected] = React.useState();

  const fetchPropertyTypes = () => {
    locationTypeClient.query({ query: getLocationType }).then((response) => {
      if (response.data.locationType) {
        setPropertyTypes(response.data.locationType.edges || []);
      }
    }).catch((error) => toastFailMsg(parseGraphQLErrors(error)));
  };

  React.useEffect(() => {
    fetchPropertyTypes();
  }, []);

  return (
    <Modal
      className="semanticModal custom-modal amenitie-modal add-property-modal"
      onClose={onClose}
      closeIcon
      open={isOpen}
      size="mini"
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        <Header>Let&apos;s get started</Header>
        <p>We&apos;re as excited as you to get your property listed with Leasera!</p>
        <p>
          We&apos;ll walk you through what you need to.
          To get started, we need to know what kind of property you are adding.
        </p>
      </Modal.Header>
      <Modal.Content>
        {
          propertyTypes.map(({ node }) => {
            const iconClasses = {
              'Apartment': 'apartment-rw',
              'Condo': 'condo-rw',
              'House': 'house-rw',
              'Office': 'office-rw',
              'Multi-Family' : 'multi-rw'
            };
            return (
              <h4
                key={node.id}
                onClick={() => setSelected(node.id)}
                className={`p-3 white-block ${iconClasses[node.type]}`}
                style={{
                  color: selected === node.id ? '#3b1c5a' : '#000',
                  border: selected === node.id ? '1px solid rgba(59, 28, 90, 0.15)' : 'none',
                  backgroundColor: selected === node.id ? '#f0eef2' : '#fff'
                }}
              >
                {node.type}
              </h4>
            )
          })
        }
        <Button type="button" className="btn btn-primary w-100" disabled={!selected} onClick={() =>  onSelect(selected)}>Proceed</Button>
      </Modal.Content>
    </Modal>
  );
};

export default PropertyTypeSelection;
