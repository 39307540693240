import React, { useEffect, useState } from 'react';
import {
  useTable, useFilters, useSortBy, usePagination,
} from 'react-table';


export default function MaintenanceTableListing({
  columns,
  data,
  defaultColumn,
  filterTypes,
  lastPage,
  pageCount: controlledPageCount,
}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    canNextPage,
    canPreviousPage,
    getTableProps,
    getTableBodyProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    prepareRow,
    previousPage,
    state: { pageIndex },
  } = useTable({
    autoResetPage: false, // If this works for you this was the fix
    columns,
    filterTypes,
    data,
    defaultColumn,
    initialState: {
      pageSize: 30,
      sortBy: [
        {
          id: 'node.created',
          desc: true,
        },
      ],
    },
    pageCount: controlledPageCount,
  },
  useFilters,
  useSortBy,
  usePagination
  );

  const [navNums, setNavNums] = useState([0, 1, 2, 3, 4]);
  const [rightEllipsis, setRightEllipsis] = useState(0);
  
  const incrementNavNums = () => {
    const incremented = navNums.map((k) => { return k + 5});
    setNavNums(incremented);
  }
  
  const decrementNavNums = () => {
    const decremented = navNums.map((k) => { return k - 5});
    setNavNums(decremented);
  }
  
  // creates iterable array for mapping ui buttons.
  /* eslint-disable */
  const pageLength = Array.apply(null, Array(!isNaN(lastPage) ? lastPage : 0)).map(() => {});
  /* eslint-enable */

  // Handles querying additional ledger via gotoPage if data > 1000
  const handleGoToPageIndex = (i) => {
    gotoPage(i);
  }
  
  const handleGoToPreviousPage = () => {
    previousPage();
    if ((pageIndex % 5) === 0) decrementNavNums();
  }
  
  // Handles querying additional ledger data via next page arrow if data > 1000
  const handleGoToNextPage = () => {
    nextPage();
    if ((pageIndex + 1) % 5 === 0) incrementNavNums();
  }
  
  // Handles Ellipsis Animation, pagination UI, and calls getLedger
  const handleRightEllipsis = () => {
    incrementNavNums();
    setRightEllipsis(rightEllipsis + 1);
  }
  
  const handleLeftEllipsis = () => {
    decrementNavNums();
    gotoPage(navNums[4] - 5);
  }

  /* eslint-disable */
  useEffect(() => {
    gotoPage(navNums[0]);
  }, [rightEllipsis]);
  /* eslint-enable */
  //
  
  return (
    <>
      <table  {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {page&&page.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
        </tbody>
      </table>
      {pageLength.length > 1 && (
        <div className="pagination">
          <span className='pagination-index'>
            Page{' '}
            <strong>
              {pageIndex + 1} of {lastPage}.
            </strong>{' '}
          </span>
          {pageIndex > 0 &&
            <button
              className='pagination-nav arrow-left'
              onClick={() => handleGoToPreviousPage()}
              disabled={!canPreviousPage}
            >
              {"<"}
            </button>
          }
          {lastPage > 6 && navNums[0] > 4 &&
            <button className='pagination-nav' onClick={()=>handleLeftEllipsis()}>
              <div className='ellipsis'></div>
            </button>
          }
          {(navNums[0] < lastPage && navNums[0] < Math.ceil(data.length / 30)) &&
            <button className='pagination-nav' onClick={() => handleGoToPageIndex(navNums[0])}>
              {navNums[0] + 1}
            </button>
          }
          {(navNums[1] < lastPage && navNums[1] < Math.ceil(data.length / 30)) &&
            <button className='pagination-nav' onClick={() => handleGoToPageIndex(navNums[1])}>
              {navNums[1] + 1}
            </button>
          }
          {(navNums[2] < lastPage && navNums[2] < Math.ceil(data.length / 30)) &&
            <button className='pagination-nav' onClick={() => handleGoToPageIndex(navNums[2])}>
              {navNums[2] + 1}
            </button>
          }
          {(navNums[3] < lastPage && navNums[3] < Math.ceil(data.length / 30)) &&
            <button className='pagination-nav' onClick={() => handleGoToPageIndex(navNums[3])}>
              {navNums[3] + 1}
            </button>
          }
          {(navNums[4] < lastPage && navNums[4] < Math.ceil(data.length / 30)) &&
            <button className='pagination-nav' onClick={() => handleGoToPageIndex(navNums[4])}>
              {navNums[4] + 1}
            </button>
          }
          {(navNums[4] + 1) < lastPage &&
            <button className='pagination-nav' onClick={()=>handleRightEllipsis()}>
              <div className='ellipsis'></div>
            </button>
          }
          {(pageIndex + 1) < lastPage &&
            <button
              className='pagination-nav arrow-right'
              onClick={() => handleGoToNextPage()}
              disabled={!canNextPage}
            >
              {">"}
            </button>
          }
        </div>
      )}
    </>
  );
}
