import React, { Component } from "react";
import get from 'lodash/get';
import { withApollo } from "react-apollo";
import Table from "../../components/Lease/LeaseTable";
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import { leasesGql, updateBulkEdit} from '../../store/person/leases';
import { qpDatatables, qpTooltipPopover } from "../../utils/misc";
import { Header } from 'semantic-ui-react';
import { LEASE_MANAGER, ADD_LEASE_STATUS, CREATED_AT_DESC, ROUTES } from "../../utils/constants";
import "../Applications/Applications.scss"
import { getClient } from "../../init-apollo-googleFn";
import { PageLoader } from "../../components/Loader/PageLoader";
import { parseGraphQLErrors, toastFailMsg } from "../../utils/common";
import BulkEditModal from "../../components/Modals/BulkEditModal";
import mixpanel from 'mixpanel-browser';

const newClient = getClient(LEASE_MANAGER)

class Lease extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isError: false,
      message: "",
      lease: [],
      loading: false,
      selectedLeaseForBulkEdit: [],
      disableBulkEdit: true,
      sending: false,
      editLease: false,
      selectedPrimary: this.props.selectedPrimary ? this.props.selectedPrimary.node : '',
    }
  }

  componentDidMount() {
    qpDatatables();
    qpTooltipPopover();
    this.props.selectedPrimary.node && this.props.selectedPrimary.node.customId && this.getLease()
    mixpanel.track('Manager Page Load', {
      'sub': 'Lease'
     });
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {};
    if (props.primaryLocations) {
      const nodeData = props.selectedPrimary.node;
      if (nodeData !== state.selectedPrimary) {
        newState['loading'] = true;
        newState['selectedPrimary'] = nodeData;
      }
    }
    return newState;
  }

  componentDidUpdate(props) {
    if (props.selectedPrimary.node !== this.state.selectedPrimary) {
      this.setState({ selectedLeaseForBulkEdit: [] });
      this.getLease();
    }
  }

  success = (msg) => toast.success(msg, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  setMessage = (message, isError = false) => {
    this.setState({ message, isError })
  }

  getLease = () => {
    this.setState({ loading: true })
    try {
      newClient.query({
        query: leasesGql,
        variables: {
          locationId: this.props.selectedPrimary && this.props.selectedPrimary.node.id,
          sort: CREATED_AT_DESC
        }
      }).then(response => {
        this.setState({ loading: false, disableBulkEdit: true });
        const lease = get(response, 'data.leases.edges', [])
        this.setState({ lease });
      }).catch(error => {
        this.setState({ loading: false })
        this.setMessage(parseGraphQLErrors(error), true)
      });
    } catch (e) {
      console.log(e);
    }
  }

  /* function to delete the unchecked leads from the selectedLeaseForBulkEdit array */
  removeUncheckedLeads = (data, id) => {
    const tempArr = [...data];
    const index = tempArr.indexOf(id);
    if (index !== -1) {
      tempArr.splice(index, 1);
      this.setState({ selectedLeaseForBulkEdit: tempArr }, () => {
        if (this.state.selectedLeaseForBulkEdit.length === 0) {
          this.setState({ disableBulkEdit: true });
        }
      });
    }
  }

  /* function to add checked leads in the selectedLeaseForBulkEdit Array */
  addCheckedLeads = (data, id) => {
    const joined = data.concat(id);
    this.setState({
      selectedLeaseForBulkEdit: joined,
      disableBulkEdit: false
    });
  }

  /* function called on the click of the select checkbox for leads
    checks if the lead is already checked, if yes, calls removeUncheckedLeads,
    if not, calls addCheckedLeads and add the lead in the array */
  handleMultipleCheckboxSelection = (item, e) => {
    const { selectedLeaseForBulkEdit } = this.state;
    const isPresent = selectedLeaseForBulkEdit.includes(item.node.id);
    if (isPresent) {
      this.removeUncheckedLeads(selectedLeaseForBulkEdit, item.node.id)
    } else {
      this.addCheckedLeads(selectedLeaseForBulkEdit, item.node.id)
    }
  }

  /* function called on the selection of the changed status from the bulk edit dropdown
   calls updateBulkEdit mutation */
  updateBulkEdit = async (value) => {
    this.setState({ sending: true, loading: true })
    try {
      mixpanel.track('Manager Lease Action', {
        'sub': 'Bulk Update'
      });
      await newClient.mutate({
        mutation: updateBulkEdit,
        variables: {
          input: {
            lease: {
              leaseIds: this.state.selectedLeaseForBulkEdit, status: value,
            }
          }
        }
      }).then(res => {
        this.setState({ loading: true, selectedLeaseForBulkEdit: [] });
        this.setState({ sending: false, loading: false })
        setTimeout(() => {
          this.getLease();
        }, 200)
      })
    }
    catch (e) {
      this.setState({ sending: false, loading: false, selectedLeaseForBulkEdit: [] })
      toastFailMsg(e.toString().replace('GraphQL error:', ''));
    }
  }

  onViewDetails = (item) => {
    this.props.history.push(ROUTES.leaseDetails.replace(':leaseId', item.node.id));
  }

  // Button Data -------------------------------------------------------
  ButtonGroup = () => (
    <div className="button-group-row">
      <Header as="h2">Leases</Header>
      <div className="lead-action-wrapper">
        <BulkEditModal values={ADD_LEASE_STATUS} sending={this.state.sending} showButton={this.state.disableBulkEdit} updateBulkEdit={this.updateBulkEdit} />
      </div>
    </div>
  )

  render() {
    const { lease, loading } = this.state;

    return (
      loading ? (
        <PageLoader text/>
      ) : (
        <>
          <main className="main-content" role="main">
            <div className="roll-container">
              <ToastContainer />
              {lease.length > 0 ?
                <>
                  <this.ButtonGroup />
                  <Table
                    lease={lease}
                    user={this.props.user}
                    multipleHandling={this.handleMultipleCheckboxSelection}
                    selectedAppsForBulkEdit={this.state.selectedLeaseForBulkEdit}
                    onViewDetails={this.onViewDetails}
                    history={this.props.history}
                    getLease={this.getLease}
                  />
                </>
              :
                <div className="no-results-text-application lease-no-result text-center py-5">
                  <span className="not-found-icon"></span>
                  <p className="no-results-text">
                    You don't have any lease for the selected property. Click 'Create Lease' from application detail page.
                  </p>
                </div>
              }
            </div>
          </main>
        </>
      )
    );
  }
}

export default withApollo(Lease)
