import React, { Component } from "react";
import { withApollo } from "react-apollo";
import {
  LOCATIONAPI,
} from "../../utils/constants";
import "./AddProperty.scss";
import { getClient } from "../../init-apollo-googleFn";
import PropertyDetails from "./Subforms/PropertyDetails";
import PropertyData from "./Subforms/PropertyData";
import Confirmation from "./Subforms/Confirmation";
import Success from "./Subforms/Success";
import { CreateLocation, AlterLocation } from "../../store/person/properties";
import mixpanel from 'mixpanel-browser';
const addInfo = ["state", "city", "postcode", "streetOne", "name"];
const fields = [...addInfo];
const newlocationClient = getClient(LOCATIONAPI);

class EditLocation extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = fields.reduce(
      (output, field) => ({ ...output, [field]: "" }),
      {
        step: 1,
        name: "",
        city: "",
        zip: "",
        streetOne: "",
        state: "",
        customId: "",
        totalUnits: "",
        photos: [],
        preview: [],

        primary: this.props.selectedPrimary
          ? this.props.selectedPrimary.node
          : "",

        message: "",
        isError: false,
        errors: {},
        formSubmit: false,
        btnText: "Next",
        previous: false,
        edit: false,
        addressMissing: false,
        submitting: false,
      }
    );
    this.photoUpload = React.createRef();
  }
  // Next Step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };
  // Previous Step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };
  // Handle the change to Input
  handleChange = (input) => (event) => {
    this.setState({ [input]: event.target.value });
  };

  // Create new Location
  createLocation = () => {
    const {
      name,
      city,
      zip,
      streetOne,
      state,
      customId,
      totalUnits,
    } = this.state;
    const location = {
      streetOne,
      city,
      state,
      zip,
    };

    if (window.location.toString().includes("location=")) {
      try {
        mixpanel.track('Manager Property Action', {
          'sub': 'Edit Location'
        });
        newlocationClient
          .mutate({
            mutation: AlterLocation,
            variables: {
              input: {
                customId: customId,
                addresses: [JSON.stringify(location)],
                id:this.props.selectedPrimary.node.id
                // 'photos': formData
              },
            },
          })
          .then((res) => {
          });
      } catch (e) {
        console.log(e)
          alert(e)
      }
    }
    else {
      try {
        mixpanel.track('Manager Property Action', {
          'sub': 'Create Location'
        });
        newlocationClient
          .mutate({
            mutation: CreateLocation,
            variables: {
              input: {
                name: name,
                organizationId: "1",
                customId: customId,
                totalUnits: totalUnits,
                addresses: [JSON.stringify(location)],
              },
            },
          })
          .then((res) => {

          });
      } catch (e) {
        console.log(e)
          alert(e)
      }
    }
  };

  // Upload Pictures
  fileUpload = (e) => {
    const { count } = this.state;
    this.setState({ count: count + 1 });
    return Array.from(e.target.files).forEach((file) => {
      const { photos } = this.state;
      photos.push(file);
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        this.setState({
          preview: [...this.state.preview, reader.result],
        });
      };
      e.target.value = ""; // prevents error where if you delete photo you can't reupload
    });
  };

  // Remove Photo
  removePhoto = (index) => {
    const preview = this.state.preview;
    const photos = this.state.photos;
    preview.splice(index, 1);
    photos.splice(index, 1);
    this.setState({ preview, photos });
  };

  componentDidMount() {
    if ( this.state.primary) {
        if (window.location.toString().includes("location=")) {
          const { primary } = this.state;
          const parseAddress = JSON.parse(primary.addresses)
          this.setState({
            name: primary.name,
            city: parseAddress[0].city,
            state:parseAddress[0].state,
            zip: parseAddress[0].zip,
            streetOne: parseAddress[0].streetOne,
            customId: primary.customId,
            totalUnits: primary.totalUnits,
          });
        }
    }
    mixpanel.track('Manager Page Load', {
      'sub': 'Edit Location'
     });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        if (window.location.toString().includes("location=")) {
          const { primary } = this.state;
          const parseAddress = JSON.parse(primary.addresses)
          this.setState({
            name: primary.name,
            city: parseAddress[0].city,
            state:parseAddress[0].state,
            zip: parseAddress[0].zip,
            streetOne: parseAddress[0].streetOne,
            customId: primary.customId,
            totalUnits: primary.totalUnits,
          });
        }
      });
    }
  }

  render() {
    const { step } = this.state;
    const {
      name,
      city,
      zip,
      streetOne,
      preview,
      state,
      customId,
      totalUnits,
      photos,
    } = this.state;
    const values = {
      name,
      city,
      zip,
      streetOne,
      preview,
      state,
      customId,
      totalUnits,
      photos,
    };

    /* eslint-disable */
    switch (step) {
      case 1:
        return (
          <PropertyDetails
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 2:
        return (
          <PropertyData
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            fileUpload={this.fileUpload}
            removePhoto={this.removePhoto}
            photoUpload={this.photoUpload}
            values={values}
          />
        );
      case 3:
        return (
          <Confirmation
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            createLocation={this.createLocation}
            values={values}
          />
        );
      case 4:
        return <Success />;
    }
    /* eslint-enable */
  }
}

export default withApollo(EditLocation);
