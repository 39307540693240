import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Container, Dropdown, Grid } from 'semantic-ui-react';
import moment from 'moment';
import get from 'lodash/get';
import Carousel from 'react-bootstrap/Carousel';
import { getClient } from '../../../init-apollo-googleFn';
import {
  LOCATIONAPI, ROUTES, UNIT_AVAILABLITY_STATUS, BLUEMOON_API,
} from '../../../utils';
import { AlterLocation } from '../../../store/person/properties';
import {
  parseGraphQLErrors,
  parsePhotosUrl,
  pluralize,
  toastFailMsg,
  parseAddresses,
  toastSuccessMsg,
} from '../../../utils/common';
import { bluemoonProperties } from '../../../store/bluemoon/bluemoon';
import UnitInfoModal from '../PropertyListing/UnitInfoModal';
import withPropertyDetails from './withPropertyDetails';
import UnitCard from '../PropertyListing/UnitCard';
import UnitListItem from '../PropertyListing/UnitListItem';
import mixpanel from 'mixpanel-browser';


const locationClient = getClient(LOCATIONAPI);
const bluemoonApi = getClient(BLUEMOON_API);

const ReviewPropertyDetails = ({
  details,
  units,
  locationId,
  match,
  history,
  orgDetails,
  petTypes,
  amenityTypes,
  onCancelClick,
  propertyTypes = [],
  isPropertyDetails = false,
  user,
  onPublish,
  ...props
}) => {
  const [filteredUnits, setFilteredUnits] = React.useState([]);
  const [unitInfo, setUnitInfo] = React.useState(null);
  const [unitStatusFilter, setUnitStatusFilter] = React.useState('all');
  const [isListView, setIsListView] = React.useState(false);
  const [bluemoonName, setBluemoonName] = React.useState([]);
  const { params } = match;
  const contact = details && details.contact && JSON.parse(details.contact);

  const socials = contact &&contact.socials && Object.keys(contact.socials).filter(
    (key) => !key.includes('email')).reduce(
    (cur, key) => {return Object.assign(cur, {[key]: contact.socials[key] })}, {});

  const socialsArray = socials && Object.entries(socials);
  const socialArr1 = socialsArray && socialsArray.slice(0,3);
  const socialArr2 = socialsArray && socialsArray.slice(3,socialsArray.length);

  const currentLocationId = locationId || params.locationId;

  React.useEffect(() => {
    setFilteredUnits(units);
  }, [units]);

  /* eslint-disable */
  React.useEffect(() => {
    mixpanel.track('Manager Page Load', {
      ndbId: user && user.ndbId,
      sub: 'Review Property Detail',
    });
  }, []);
  /* eslint-enable */


  const savePropertyInfo = (status = 'publish') => {
    
    locationClient.mutate({
      mutation: AlterLocation,
      variables: {
        input: {
          id: currentLocationId,
          name: details.name,
          status,
        },
      },
    })
      .then((response) => {
        if (response.data) {
          toastSuccessMsg(`Property ${status === 'publish' ? 'added' : 'updated'} successfully.`);
          onPublish();
          history.push(ROUTES.propertiesListing);
        }
      })
      .catch((error) => {
        toastFailMsg(parseGraphQLErrors(error).toString() || 'Unable to get unit details.');
      });
  };

  const handleStatusFilter = (e, { value }) => {
    setUnitStatusFilter(value);
    setFilteredUnits(units.filter((u) => (value !== 'all' ? u.status.toLowerCase() === value.toLowerCase() : true)));
  };

  const getAmenities = React.useCallback((amenities = []) => (Array.isArray(amenities) ? amenities.map((item) => {
    const amenity = amenityTypes.find((x) => x.node.id === item);
    return get(amenity, 'node.name', '');
  }).filter((x) => x).join(', ') : ''), [amenityTypes]);

  /* eslint-disable */
  const photos = React.useMemo(() => {
    if (details.photos) return parsePhotosUrl(details.photos);
    return [];
  }, [details.photos]);

  const { depositRules = {} } = details;
  const propertyType = React.useMemo(() => (propertyTypes.find((p) => p.node.id === details.locationTypeId) || {}), [propertyTypes, details.locationTypeId]);

  React.useEffect(() => {
    if (details.esginType === 'bluemoon') {
      fetchBluemoonProperties();
    }
  }, [details]);
  /* eslint-enable */

  const fetchBluemoonProperties = async () => {
    await bluemoonApi.query({
      query: bluemoonProperties,
    }).then((res) => {
      const data = res.data.bluemoonProperties.edges;
      setBluemoonName(data[0].node.name)
      data.map(((ele) => {
        if (ele.node.id === details.bmLocationId) {
          setBluemoonName(ele.node.name);
        }
        return null;
      }));
    }).catch((error) => {
      toastFailMsg(parseGraphQLErrors(error));
    });
  };
  return (
    <Container className="add-property-container">
      {isPropertyDetails && (
        <div className="header">
          <div>
            {/* eslint-disable */}
            <a className="back-btn" onClick={() => history.goBack()}>
              <img src="assets/img/left-arrow-small.svg" alt="arrow-down-small" />
            </a>
            {/* eslint-enable */}
            Property Detail
          </div>
          <div className="btn-section">
            <Link to={ROUTES.editPropertyDetails.replace(':locationId', currentLocationId)}>
              <button type="button" className="btn next-btn">Edit Property</button>
            </Link>
            <div className="custom-dropdown">
              {/* eslint-disable */}
              <a className="more-btn">
                <img src="assets/img/icons-more.svg" alt="icons-more" />
              </a>
              <div className="dropdown-list">
                <div className="list-item">
                  <a>
                    <img src="assets/img/icons-edit.svg" alt="icons-edit" />
                    View Public URL
                  </a>
                </div>
                <div className="list-item red-text">
                  <a>
                    <img src="assets/img/icons-red-delete.svg" alt="icons-red-delete" />
                    Delete Property
                  </a>
                  {/* eslint-enable */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="review-container">
        <Carousel fade>
          {photos.map((img, index) => (
            <Carousel.Item key={img}>
              <div className="img-box" key={index.toString()}>
                <img src={img} alt="renter-bg" className="d-block w-80" />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="propery-head">
          <div className="text-left">
            <div className="propery-name">{details.name}</div>
            <div className="address">{parseAddresses(details.addresses)}</div>
            <div className="property-info">
              {`${get(details, 'totalBeds[0]', 0)}+ ${pluralize(get(details, 'totalBeds[0]', 0), 'Bed')}`}
              {' '}
              <span className="dot" />
              {' '}
              <span>{`${get(details, 'totalBaths[0]', 0)}+ ${pluralize(get(details, 'totalBaths[0]', 0), 'Bath')}`}</span>
              {' '}
              <span className="dot" />
              {' '}
              <span>{`${get(details, 'totalSizes[0]', 0)} sq.ft - ${get(details, 'totalSizes[1]', 0)} sq.ft`}</span>
            </div>
          </div>
          <div className="big-text">
            <span>{`$${get(details, 'totalPrices[0]', 0).toLocaleString()}-$${get(details, 'totalPrices[1]', 0).toLocaleString()}`}</span>
            <span className="bar">|</span>
            <span>{`${units.length} units`}</span>
          </div>
        </div>
        <div className="grey-row">
          <div className="block">
            <span className="head">Property type</span>
            <div className="text">{get(propertyType, 'node.type', '')}</div>
          </div>
          <div className="block">
            <span className="head">Year built</span>
            <div className="text">{details.yearBuilt}</div>
          </div>
          <div className="block">
            <span className="head">Date listed</span>
            <div className="text">{moment(details.createdAt).format('MMMM DD, YYYY')}</div>
          </div>
          <div className="block">
            <span className="head">Property Phone</span>
            <div className="text">{contact && contact.phone}</div>
          </div>
          <div className="block">
            <span className="head">Property Email</span>
            <div className="text">{contact && contact.email}</div>
          </div>
        </div>
        <h3>Description</h3>
        <pre style={{textAlign:"left", fontFamily:"Be Vietnam", whiteSpace:'pre-wrap'}}>{details.description}</pre>
        <h3>Amenities</h3>
        <p>{getAmenities(details.amenities)}</p>
        <div className="d-flex mb-5">
          <div className="w-100">
            <h3>Pets Permitted</h3>
            <p>{details.petsPermitted ? 'Yes' : 'No'}</p>
          </div>
        </div>
        {Array.isArray(details.petInformation) && details.petInformation.length > 0 && (
          <div className="pets-section">
            <div className="head">Pet Restrictions</div>
            <div className="head-row">
              <div className="w-25">Weight</div>
              <div className="w-25">Total Number</div>
            </div>
            <div className="data-row">
              <div className="w-25">{`${get(details, 'petInformation[0].weight', '')} lbs`}</div>
              <div className="w-25">{get(details, 'petInformation[0].number', 'NA')}</div>
            </div>
            <div className="head-row">
              <div className="w-25">S. No.</div>
              <div className="w-25">Pet type</div>
              <div className="w-50">Breed</div>
            </div>
            {get(details, 'petInformation.description', []).map((item, index) => {
              const pet = petTypes.find((p) => p.node.name === item.name);
              if (!pet) return null;
              return (
                <div className="data-row" key={index.toString()}>
                  <div className="w-25">{index + 1}</div>
                  <div className="w-25">{pet.node.type}</div>
                  <div className="w-50">{pet.node.name}</div>
                </div>
              );
            })}
          </div>
        )}
        <div className="d-flex mb-5">
          <div className="w-30">
            <h3>Deposit Required</h3>
            <p>{depositRules.deposit_amount ? `$${depositRules.deposit_amount.toLocaleString()}` : 'NA'}</p>
          </div>
          <div className="w-30">
            <h3>Deposit refundable</h3>
            <p>{depositRules.deposit_nonrefundable_amount ? `$${depositRules.deposit_nonrefundable_amount.toLocaleString()}` : 'NA'}</p>
          </div>
          <div className="w-30">
            <h3>Lease Guarantee</h3>
            <p>{depositRules.lease_guarantee ? 'Yes' : 'No'}</p>
          </div>
        </div>
        <div className="d-flex mb-5">
          <div className="w-30">
            <h3>Application Credit Score</h3>
            <p>{depositRules.application_credit_score || 'NA'}</p>
          </div>
          <div className="w-30">
            <h3>Income x times of rent</h3>
            <p>{depositRules.income_x_time_rent || 'NA'}</p>
          </div>
          <div className="w-30">
            <h3>First and Last Month Rent Required</h3>
            <p>{depositRules.fl_month_required ? 'Yes' : 'No'}</p>
          </div>
        </div>
        {details.distributionNoticeDocPath ? (
          <>
            <div className="d-flex mb-5">
              <div className="w-30">
                <h3 className="m-t-4">Distribution Notice</h3>
                <p>
                  <a
                    href={details.distributionNoticeDocPath}
                    className="preview-link"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    View Distribution Notice
                  </a>
                </p>
              </div>
              {details.hoaPolicyDocPath && (
                <div className="w-30">
                  <h3 className="m-t-4">HOA Policy</h3>
                  <p>
                    <a
                      href={details.hoaPolicyDocPath}
                      className="preview-link"
                      target="_blank"
                      rel="noreferrer noopener"
                      >
                      View HOA Policy
                    </a>
                  </p>
                </div>
              )}
              {details.esignDocPath && details.esignDocPath.application_doc_path && (
                <div className="w-30">
                  <h3 className="m-t-4">Application Policy</h3>
                  <p>
                    <a
                      href={details.esignDocPath.application_doc_path}
                      className="preview-link"
                      target="_blank"
                      rel="noreferrer noopener"
                      >
                      View Application Policy
                    </a>
                  </p>
                </div>
              )}
            </div>
            <div className="d-flex mb-5">
              {details.esignDocPath && details.esignDocPath.lease_doc_path && (
                <div className="w-30">
                  <h3 className="m-t-4">Lease Policy</h3>
                  <p>
                    <a
                      href={details.esignDocPath.lease_doc_path}
                      className="preview-link"
                      target="_blank"
                      rel="noreferrer noopener"
                      >
                      View Lease Policy
                    </a>
                  </p>
                </div>
              )}
              {details.esignDocPath && details.esignDocPath.lease_doc_path && (
                <div className="w-30">
                  <h3 className="m-t-4">Pet Policy</h3>
                  <p>
                    <a
                      href={details.petPolicyDocPath}
                      className="preview-link"
                      target="_blank"
                      rel="noreferrer noopener"
                      >
                      View Pet Policy
                    </a>
                  </p>
                </div>
              )}
              {details.esignDocPath && details.esignDocPath.lease_doc_path && (
                <div className="w-30">
                  <h3 className="m-t-4">Payment Policy</h3>
                  <p>
                    <a
                      href={details.paymentPolicyDocPath}
                      className="preview-link"
                      target="_blank"
                      rel="noreferrer noopener"
                      >
                      View Payment Policy
                    </a>
                  </p>
                </div>
              )}
            </div>
            <div className="d-flex mb-5">
              {details.esignDocPath && details.esignDocPath.lease_doc_path && (
                <div className="w-30">
                  <h3 className="m-t-4">Cancellation Policy</h3>
                  <p>
                    <a
                      href={details.cancellationPolicyDocPath}
                      className="preview-link"
                      target="_blank"
                      rel="noreferrer noopener"
                      >
                      View Cancellation Policy
                    </a>
                  </p>
                </div>
              )}
            </div>
          </>
        ):(
          <>
            <div className="d-flex mb-5">
              {details.esignDocPath && details.esignDocPath.application_doc_path && (
                <div className="w-30">
                  <h3 className="m-t-4">Application Policy</h3>
                  <p>
                    <a
                      href={details.esignDocPath.application_doc_path}
                      className="preview-link"
                      target="_blank"
                      rel="noreferrer noopener"
                      >
                      View Application Policy
                    </a>
                  </p>
                </div>
              )}
              {details.esignDocPath && details.esignDocPath.lease_doc_path && (
                <div className="w-30">
                  <h3 className="m-t-4">Lease Policy</h3>
                  <p>
                    <a
                      href={details.esignDocPath.lease_doc_path}
                      className="preview-link"
                      target="_blank"
                      rel="noreferrer noopener"
                      >
                      View Lease Policy
                    </a>
                  </p>
                </div>
              )}
              {details.esignDocPath && details.esignDocPath.lease_doc_path && (
                <div className="w-30">
                  <h3 className="m-t-4">Pet Policy</h3>
                  <p>
                    <a
                      href={details.petPolicyDocPath}
                      className="preview-link"
                      target="_blank"
                      rel="noreferrer noopener"
                      >
                      View Pet Policy
                    </a>
                  </p>
                </div>
              )}
            </div>
            <div className="d-flex mb-5">
              {details.esignDocPath && details.esignDocPath.lease_doc_path && (
                <div className="w-30">
                  <h3 className="m-t-4">Payment Policy</h3>
                  <p>
                    <a
                      href={details.paymentPolicyDocPath}
                      className="preview-link"
                      target="_blank"
                      rel="noreferrer noopener"
                      >
                      View Payment Policy
                    </a>
                  </p>
                </div>
              )}
              {details.esignDocPath && details.esignDocPath.lease_doc_path && (
                <div className="w-30">
                  <h3 className="m-t-4">Cancellation Policy</h3>
                  <p>
                    <a
                      href={details.cancellationPolicyDocPath}
                      className="preview-link"
                      target="_blank"
                      rel="noreferrer noopener"
                      >
                      View Cancellation Policy
                    </a>
                  </p>
                </div>
              )}
            </div>
          </>
        )}
        {details.esginType === 'bluemoon' && (
          <div className="enable-bluemoon">
          <div className="top-header">
            <div className="left-data">
              <h6>Bluemoon Enabled</h6>
            </div>
          </div>
          <div className="has-float-label select-float-label">
            <label htmlFor="locationTypeId">Mapped Bluemoon Properties</label>
            <p>{bluemoonName}</p>
          </div>
        </div>
        )}
        {socialsArray && 
        <>
          <div style={{margin: "60px 0"}}>
            <div className="d-flex mb-5">
                {socialArr1.map((k, i) => {
                  return (
                    <div className="w-30">
                      <h3>{k[0]}</h3>
                      <p>
                        {k[1]}
                      </p>
                    </div>
                  )
                })}
            </div>
          </div>
          {socialArr2.length > 0 &&
            <div style={{margin: "60px 0"}}>
              <div className="d-flex mb-5">
                  {socialArr2.map((k, i) => {
                    return (
                      <div className="w-30">
                        <h3>{k[0]}</h3>
                        <p>
                          {k[1]}
                        </p>
                      </div>
                    )
                  })}
              </div>
            </div>
          }
          </>
        }
        {contact && contact.schedule &&
          <>
            <h3 className="review-mb10">Business Hours</h3>
            <div className="review-hours">
              <div className="d-flex review-mb10">
                <div className="w-30">
                  <h3>
                    Monday
                  </h3>
                  <p>
                    {contact.schedule.mondayFrom ?
                      `${contact.schedule.mondayFrom} - ${contact.schedule.mondayTo}`
                    :
                      'Closed'
                    }
                  </p>
                </div>
                  <div className="w-30">
                    <h3>
                      Tuesday
                    </h3>
                    <p>
                      {contact.schedule.tuesdayFrom ?
                        `${contact.schedule.tuesdayFrom} - ${contact.schedule.tuesdayTo}`
                      :
                        'Closed'
                      }
                    </p>
                  </div>
                  <div className="w-30">
                    <h3>
                      Wednesday
                    </h3>
                    <p>
                      {contact.schedule.wednesdayFrom ?
                        `${contact.schedule.wednesdayFrom} - ${contact.schedule.wednesdayTo}`
                      :
                        'Closed'
                      }
                    </p>
                  </div>
                  <div className="w-30">
                    <h3>
                      Thursday
                    </h3>
                    <p>
                      {contact.schedule.thursdayFrom ?
                        `${contact.schedule.thursdayFrom} - ${contact.schedule.thursdayTo}`
                      :
                        'Closed'
                      }
                    </p>
                  </div>
              </div>
              <div className="d-flex review-mb10">
                  <div style={{width: '25%'}}>
                    <h3>
                      Friday
                    </h3>
                    <p>
                      {contact.schedule.fridayFrom ?
                        `${contact.schedule.fridayFrom} - ${contact.schedule.fridayTo}`
                      :
                        'Closed'
                      }
                    </p>
                  </div>
                  <div style={{width: '25%'}}>
                    <h3>
                      Saturday
                    </h3>
                    <p>
                      {contact.schedule.saturdayFrom ?
                        `${contact.schedule.saturdayFrom} - ${contact.schedule.saturdayTo}`
                      :
                        'Closed'
                      }
                    </p>
                  </div>
                  <div style={{width: '25%'}}>
                    <h3>
                      Sunday
                    </h3>
                    <p>
                      {contact.schedule.sundayFrom ?
                        `${contact.schedule.sundayFrom} - ${contact.schedule.sundayTo}`
                      :
                        'Closed'
                      }
                  </p>
                </div>
              </div>
            </div>
          </>
        }
        {Array.isArray(orgDetails.contacts) && orgDetails.contacts.length > 0 && (
          <div className="pets-section">
            <div className="head">Vendors</div>
            <div className="head-row">
              <div className="w-25">Name</div>
              <div className="w-25">Email</div>
              <div className="w-25">Phone</div>
              <div className="w-25">Services</div>
            </div>
            {orgDetails.contacts.map((item, index) => (
              <div className="data-row" key={index.toString()}>
                <div className="w-25">{item.name}</div>
                <div className="w-25">{item.email || 'NA'}</div>
                <div className="w-25">{item.phone || 'NA'}</div>
                <div className="w-25">{item.services.join(', ')}</div>
              </div>
            ))}
          </div>
        )}
        {!!unitInfo && (
          <UnitInfoModal
            isOpen={!!unitInfo}
            onClose={() => setUnitInfo(null)}
            unit={unitInfo}
            propertyImages={photos}
            getAmenities={getAmenities}
          />
        )}
        <div className="m-t-4 unit-dropdown-container">
          <h3 className="">{`${filteredUnits.length} Units`}</h3>
          {isPropertyDetails && (
            <div className="filter-section">
              <div className="right-container">
                <ul>
                  {/* eslint-disable */}
                  <li>
                    <a className={isListView ? '' : 'active'} onClick={() => setIsListView(false)}>
                      <img src="assets/img/card-menu-icon.svg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a className={isListView ? 'active' : ''} onClick={() => setIsListView(true)}>
                      <img src="assets/img/list-menu-icon.svg" alt="" />
                    </a>
                  </li>
                  {/* eslint-enable */}
                </ul>
              </div>
              <span className="normal-text">Filter</span>
              <Dropdown
                fluid
                selection
                options={[{ key: 'all', value: 'all', text: 'All' }, ...UNIT_AVAILABLITY_STATUS]}
                selectOnBlur={false}
                value={unitStatusFilter}
                onChange={handleStatusFilter}
              />
            </div>
          )}
        </div>
        {filteredUnits.length > 0 ? (
          <div className="small-card-container mt-3">
            {filteredUnits.map((unit) => {
              const unitImg = unit.photos && unit.photos.length ? unit.photos[0] : photos[0];
              const UnitView = isListView ? UnitListItem : UnitCard;
              return (
                <UnitView
                  key={unit.id}
                  unit={unit}
                  unitImg={unitImg}
                  setUnitInfo={setUnitInfo}
                  onClick={() => setUnitInfo({ ...unit, unitImg })}
                />
              );
            })}
          </div>
        ) : (
          <p className="text-center pb-4">No Units available.</p>
        )}
        {!isPropertyDetails && (
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <div className="btn-block">
              <div>
                <button type="button" className="btn cancel-btn" onClick={onCancelClick}>Cancel</button>
              </div>
              <div>
                <button type="button" className="btn save-btn" onClick={() => savePropertyInfo('draft')}>Save as a draft</button>
                <button type="button" className="btn next-btn" onClick={() => savePropertyInfo('publish')}>Finish, Publish Listing</button>
              </div>
            </div>
          </Grid.Column>
        )}
      </div>
    </Container>
  );
};

export default withPropertyDetails(withRouter(ReviewPropertyDetails), 'PropertyDetails');
