import React, { useState } from "react";
import {
  Button,
  Segment,
  Menu,
  Grid,
  Header,
} from "semantic-ui-react";
import Datetime from "react-datetime";
import { SingleLedgerAccounting } from "../../store/person/accounting";
import "react-datetime/css/react-datetime.css";
import get from "lodash/get";
import { getClient } from "../../init-apollo-googleFn";
import { Table } from "../../components/Tables/TransactionsData";
import moment from "moment";

import { ACCOUNTING } from "../../utils/constants";

export default function ViewLedgerDetails(props) {
  const transClient = getClient(ACCOUNTING);
  const [sDate, startDate] = useState(false);
  const [eDate, endDate] = useState(false);
  const [transCache, getTrans] = useState(false);

  const { data } = props;
  //   Grab Transaction based on the Ledger account
  const findTransaction = () => {
    try {
      transClient
        .query({
          query: SingleLedgerAccounting,
          variables: {
            location: this.props.selectedPrimary.node.id,
            LedgerAccountId: data._id,
            startDate: moment(sDate).format("YYYY-MM-DD"),
            endDate: moment(eDate).format("YYYY-MM-DD"),
          },
        })
        .then((res) => {
          if (res.data) {
            getTrans(get(res, "data.slLocationAccountTransactions.data", []));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {}
  };
  return (
    <>
      <Segment>
        <Button floated="left" onClick={props.ledgerView}>
          Go Back
        </Button>
        <Header as="h3" textAlign="center">
          Ledger Number: {data.number}
        </Header>
        <Grid textAlign="center" columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Menu fluid vertical>
                <Menu.Item className="header">Info</Menu.Item>
                <Menu.Item>ID: {data._id}</Menu.Item>
                <Menu.Item>Name: {data.name}</Menu.Item>
                <Menu.Item>Description: {data.description}</Menu.Item>
              </Menu>
            </Grid.Column>
            <Grid.Column>
              <Menu fluid vertical>
                <Menu.Item className="header">Settings</Menu.Item>
                <Menu.Item>Type: {data.type}</Menu.Item>
                <Menu.Item>Sub Type: {data.subtype}</Menu.Item>
                <Menu.Item>Natural Balance: {data.naturalBalance}</Menu.Item>
              </Menu>
            </Grid.Column>
          </Grid.Row>
          View Transactions{" "}
          <Grid.Row columns={3}>
              <Grid.Column width={7}>
                  Start Date:{" "}
                  <Datetime
                    timeFormat={false}
                    onChange={(e) => startDate(e._d)}
                  />{" "}
              </Grid.Column>
              <Grid.Column width={7}>
                  End Date:{" "}
                  <Datetime
                    timeFormat={false}
                    onChange={(e) => endDate(e._d)}
                  />
              </Grid.Column>
              <Grid.Column width={2}><Button onClick={findTransaction}>Go</Button></Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid textAlign="center">
          <Grid.Row textAlign="center">
            <Grid.Column>
              <Menu fluid vertical>
                <Menu.Item> {data.description}</Menu.Item>
              </Menu>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {transCache && <Table transCache={transCache} />}
      </Segment>
    </>
  );
}
