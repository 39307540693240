import React, {
  useMemo, useState, useEffect,
} from 'react';
import AccountingTable from './AccountingTable';
import { multipleColumnsSearch } from '../../utils/common';
import ChargeCodeModal from '../Modals/ChargeCodeModal';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export function Table(props) {

  const [filterInput, setFilterInput] = useState('');

  const handleFilterChange = (e) => {
    const value = e.target.value || '';
    const filteredData = multipleColumnsSearch(value, props.chargeCache && props.chargeCache);
    setData(filteredData);
    setFilterInput(value);
  };

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    return (
      ''
    );
  }
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  /* eslint-disable */
  const columns = useMemo(

    () => [

      {
        Header: 'Name',
        accessor: 'node.name',
      },
      {
        Header: 'Description',
        accessor: 'node.desc',
      },
      {
        Header: 'Type',
        accessor: 'node.type',
      },
      {
        Header: 'Rate',
        accessor: 'node.amountDefault',
      },

      {
        Header: 'Edit',
        accessor: (item) => <ChargeCodeModal success={success} propertyId={props.propertyId} updateInvoice={props.updateInvoice} props={item} />,
      },
    ],
    [],
  );
  /* eslint-enable */

  const [data, setData] = useState([]);
  const [lastPage, setLastPage] = useState();

  /* eslint-disable */
  useEffect(() => {
    const result = props.totalItems / 30;
    setLastPage(Math.ceil(result));
    setData(props.chargeCache && props.chargeCache);
  }, [props.chargeCache && props.chargeCache]);
  /* eslint-enable */

  const success = () => toast.success('Success!', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  return (
 <div className="table-container">
      <AccountingTable
        value={filterInput}
        defaultColumn={defaultColumn}
        onChange={handleFilterChange}
        columns={columns}
        data={data}
        pageCount={lastPage}
        lastPage={lastPage}
      />
      </div>
  );
}
