//TopNav.js
import React, { Component, useState, useContext, useEffect } from 'react';
import { Menu, Icon, Input, Dropdown, Label, Feed, Divider } from 'semantic-ui-react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withApollo } from "react-apollo";
import {
  getEmitter, LOCATIONAPI
} from '../../utils'
import CountUp from "react-countup";

import { Loader } from '../../components/Loader/Loader';
import './TopNav.scss';
import PropertyChangeWarning from '../../components/Modals/propertyChangeWarningModal';
import { getClient,logoutAction, } from "../../store/auth";
import { NotificationContext } from '../NotificationContext';
import moment from 'moment';
import { getPointsBalance } from '../../store/person/properties';


const eventEmit = getEmitter();

function TopNav (props) {
  const { unreadAlerts, useUpdateEffect} = useContext(NotificationContext);
  const [ points, setPoints] = useState(0);
  const NewPrimaryLocations = getClient(LOCATIONAPI);
  // useEffect(() => {
    
  //   eventEmit.on(NOTIFICATION, (data) => updateNotif(data));
  //  messaging &&  
  //  messaging.onMessage(function(payload) {
  //     const eventEmit = getEmitter()
  //     eventEmit.emit(NOTIFICATION, payload)
  //   });
  // },[]);

  const getMyPoints = () => {
			try {
			NewPrimaryLocations
					.query({
						query: getPointsBalance,
						variables: {
							locationId: props.selectedPrimary.node.id
						  }
					})
					.then(res => {
						const data = res.data&&res.data.location
						if(data && data.edges && data.edges[0]){
              if(data.edges[0].node){
                setPoints(data.edges[0].node.pointsBalance)

              }
						}
					})
			} catch (e) {
			}
	}

/* eslint-disable */
  useEffect(() => {
    eventEmit.on('PurchasePoints', () => getMyPoints())
    props.getUserRole();
    getMyPoints()
  },[props])
/* eslint-enable */

  const handleLogout = () => {
    logoutAction(props.client).then(() => {
      window.location.reload();
    })
  };

  return (
    <>
      {useUpdateEffect()}
      <Menu fixed='top' borderless className={props.leftNavCollapsed ? 'topnav expanded' : 'topnav'}>
        <br />
        <img src="/assets/img/leasera-logonew-nav-icon.png" className='logo-small' alt="Leasera" />
        <TopNavSearch />
        <Menu.Menu position='right'>
        <div className="top-nav-item-point">
            <div className="top-nav-item">
              <span className="points-icon"></span>
              <Link to="/markets">
                <CountUp
                  className="points"
                  delay={0}
                  duration={10}
                  // start={currentPoints}
                  end={points}
                />
                <span className="top-nav-text">Points</span>
              </Link>
            </div>
          </div>
          <PropertySelector {...props} />
          <TopNavNotif icon='alarm' getNotif={props.rightSidebarToggle} notifCount={unreadAlerts} {...props} />
          <TopNavUserMenu {...props} handleLogout={handleLogout} />
        </Menu.Menu>
      </Menu>
    </>
  );
}

export default compose(withRouter, withApollo)(TopNav);

function TopNavSearch () {
  return (
    <span className='leasera-search'>
      <Menu.Item className="search-inline">
        {/* <Input placeholder='Search...' /> */}
      </Menu.Item>
      <TopDrawer trigger={<Icon icon='search' className='search phone-only nav-icon' />} className='phone-only'>
        <span className='search-dropdown'>
          <Input placeholder='Search...' />
        </span>
      </TopDrawer>
    </span>
  )
}

// function TopNavBurger (props) {
//   const { unreadAlerts, allAlerts, getAlerts, notifMutation } = useContext(NotificationContext);
//   const [showAllAlerts, setShowAllAlerts] = useState(false);
  
//   const idArray = Object.values(unreadAlerts).map((k) => {
//     return k.Id;
//   })


//   const handleDropdown = (e) => {
//     e.stopPropagation();
//     setShowAllAlerts(!showAllAlerts)
//   }

//   return (
//     <Dropdown
//       item
//       floating
//       direction="left"
//       closeOnChange={false}
//       onClose={()=>setShowAllAlerts(false)}
//       trigger={
//         <div >
//           <div className="top-nav-item" >
//             <Icon name="bars" className="large-icon" />
//           </div>
//         </div>
//       }
//       className="user-menu"
//     >
//       <Dropdown.Menu className="notification-wrap">
//         <div className="dropdown-top-menu-user-info">
//           <p className="mb-0">Notifications</p>
//           <button
//             className='toggle-alerts'
//             onClick={
//               (e)=>handleDropdown(e)
//             }
//           >
//             {!showAllAlerts ?
//               "VIEW ALL" 
//             :
//               "VIEW UNREAD"
//             }
//           </button>
//         </div>
//         <Feed className="" style={{ overflowY: 'auto', maxHeight: "350px", topMargin: "0px" }}>
//         {!showAllAlerts ?
//           unreadAlerts.length > 0 ?
//             unreadAlerts.map((data) =>
//               <>
//                 <div className="detail-txt">
//                   <span className="icon-block">
//                     <i class="bell icon"></i>
//                   </span>
//                   <Feed.Event
//                     summary={data.message}
//                     meta={moment(data.created ? data.created : data.created_at).local().format("lll")}
//                   />
//                 </div>
//                 <Divider />
//               </>
//             )
//           :
//           <>
//             <div className="detail-txt">
//               <span className="icon-block">
//                 <i class="bell icon"></i>
//               </span>
//               <Feed.Event>No new Notifications</Feed.Event>
//             </div>
//             <Divider />
//           </>
//         :
//           allAlerts.map((data) => 
//             <>
//               <div className="detail-txt">
//                 <span className="icon-block">
//                   <i class="bell icon"></i>
//                 </span>
//                 <Feed.Event
//                   summary={data.message}
//                   meta={moment(data.created ? data.created : data.created_at).local().format("lll")}
//                 />
//               </div>
//               <Divider />
//             </>
//           )
//         }
//         </Feed>
//       </Dropdown.Menu>
//     </Dropdown>
//   );
// }

function TopNavNotif (props) {
  const { unreadAlerts, allAlerts, notifMutation } = useContext(NotificationContext);
  const [showAllAlerts, setShowAllAlerts] = useState(false);

  const openNotificationPage = () => {
    props.history.push({
      pathname: '/notifications',
      state: {
        data: allAlerts,
      }
    });
  }

  return (
    <div className="notification-btn alert" onClick={openNotificationPage}>
      <img src="../../assets/img/icons-notification.svg" alt="icon" />
    </div>
  );
}

function TopNavUserMenu (props) {
  return (
    <Dropdown item floating direction='left' trigger={<span><Icon name='user circle' className="large-icon" /><span className='name'>{props.user.firstName} {props.user.lastName}</span></span>} className='user-menu'>
      <Dropdown.Menu>
        {/* <Dropdown.Item text='Manage Profile' icon='user' /> */}
        <Dropdown.Item text='Logout' icon='log out' onClick={props.handleLogout} />
      </Dropdown.Menu>
    </Dropdown>
  );
}

function PropertySelector (props) {
  return (
    <Menu.Item className="property-selector">
      <PropertyDropdown {...props} className='phone-hide' />
      <TopDrawer trigger={<i class="fa fa-angle-down" aria-hidden="true"></i>} className='phone-only'>
        <PropertyDropdown {...props} className='property-selector-mobile ' />
      </TopDrawer>
    </Menu.Item>
  )
}

class PropertyDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primaryList: {},
      loading: true,
      open: false,
      selectedProperty: '',
      type: ''
    }
  }

  componentDidMount() {
    this.setPropertyList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.primaryLocations !== prevProps.primaryLocations) {
      this.setPropertyList();
    }
  }

  setPropertyList = () => {
    if (this.props.primaryLocations) {
      this.setState({
        primaryList: this.props.primaryLocations.map((location, index) => ({
          key: "location.node" + index + 1,
          text: location.node.customId,
          value: index,
        })),
        loading: false
      });
    }
  }

  selectProperty = (e) => {
    eventEmit.emit('topDrawerClose'); // Now that we selected a property let's close the top drawer
    this.props.setLocation(e); // call back to the parent to set the location used for all pages (todo change to Event Emitter)
  }

  changeProperty = async (e) => {
    if (window.location.href.includes('applicationForm') || window.location.href.includes('viewApplication')) {
      this.setState({ open: true, selectedProperty: e.target.textContent, type: 'application' });
    } else if (window.location.href.includes('viewLead')) {
      this.setState({ open: true, selectedProperty: e.target.textContent, type: 'lead' });
    } else {
      this.setState({ open: false });
      this.selectProperty(e.target.textContent);
    }
  }

  closeApplication = () => {
    this.setState({ open: false });
    this.selectProperty(this.state.selectedProperty)
  }

  closeModal = () => {
    this.setState({ open: false });
  }

  render() {
    const className = "property-dropdown " + this.props.className; // merge the className coming from the parent

    return (
      <>
        {this.state.loading && !this.props.selectedPrimary && <Loader active size='tiny' />}
        {!this.state.loading && <Dropdown
          className={className}
          floating 
          options={this.state.primaryList}
          text={this.props.selectedPrimary ? this.props.selectedPrimary.node.customId : ""}
          search
          onChange={(e) => this.changeProperty(e)} />}

        {this.state.open && <PropertyChangeWarning changeProperty={this.closeApplication}
          value={this.state.selectedProperty} closeModal={this.closeModal} type={this.state.type} />}
      </>
    )
  }

}

class TopDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    eventEmit.on('topDrawerClose', this.closeDrawer); // single event to manage closing all top drawers for the top nav.
  }

  componentWillUnmount() {
    eventEmit.removeEventListener('topDrawerClose', this.closeDrawer); // we no longer need the listener, remove it
  }

  toggleOpen = (e, val) => {
    eventEmit.emit('topDrawerClose'); //if any other top drawers are open close them
    if (e) e.preventDefault();

    if (!val) {
      this.setState({ open: !this.state.open });
    } else {
      if (val !== this.state.open) this.setState({ open: val });
    }
  }

  // method used by event emitter for closing this top drawer instance
  closeDrawer = () => {
    this.setState({ open: false });
  }

  render() {
    const wrapperClassName = this.props.className ? 'top-drawer ' + this.props.className : 'top-drawer'; // merge the className coming from the parent

    return (
      <span className={wrapperClassName}>
        <Menu.Item className='td-trigger' onClick={(e) => this.toggleOpen(e)} >
          {this.props.trigger}
        </Menu.Item>
        <div className={this.state.open ? 'td-drawer td-visible' : 'td-drawer'}>
          {this.props.children}
          <br /><div className='td-close'><a href='/' onClick={(e) => this.toggleOpen(e, false)}>Close</a></div>
        </div>
        <div className={this.state.open ? 'td-backdrop td-visible' : 'td-backdrop'} />
      </span>
    )
  }
}