import React, { useEffect, useRef } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import MicrositeModal from '../../Modals/MicrositeModal';
import { getClient } from '../../../init-apollo-googleFn';
import { LOCATIONAPI } from '../../../utils';
import { toastSuccessMsg, toastFailMsg } from '../../../utils/common';
import { AlterLocation, } from '../../../store/person/properties';

const locationClient = getClient(LOCATIONAPI);

// ref function to call setTemplate on click outside of modal.
/* eslint-disable */
function useOutsideAlerter(ref, mutation) {
  useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
              mutation(null, true);
          }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [ref]);
}
/* eslint-enable */

const PropertyCardModal = (props) => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const { locationTypeId, locationId, modalTrigger, onRerender } = props;

  // sets the template id.
  const setTemplateMutation = (templateId, closeModal = false) => {
    try {
      locationClient.mutate({
        mutation:AlterLocation,
        variables:{
          input: {
            id: locationId,
            templateId
          }
        }
      }).then(response => {
        if (closeModal) {
          return;
        } else {
          toastSuccessMsg('Templated Successfully Selected');
          onRerender("template-set")
        }
      }).catch(error => {
        if (closeModal) {
          return;
        } else {
          toastFailMsg('An error has occurred');
          console.log(error)
        }
      })
    }
    catch(e) {
      console.log(e);
    }
  }

  // ref to call setTemplate on click outside of modal.
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setTemplateMutation);


  const handleModalClose = (e) => {
    setTemplateMutation(null, true);
    setModalOpen(false);
  }

  return (
    <div ref={wrapperRef}>
      <Modal
        centered
        className="semanticModal"
        size="tiny"
        onClose={() => setModalOpen(false)}
        onOpen={() => setModalOpen(true)}
        open={modalOpen}
        trigger={modalTrigger}
      >
        <Modal.Content>
        {/* <Modal.Content> */}
          <MicrositeModal
            locationTypeId={locationTypeId}
            locationId={locationId}
            setTemplateMutation={setTemplateMutation}
          />
        </Modal.Content>
        <Modal.Actions className="microsites-modal-actions">
          <Button className="microsites-modal-close" onClick={(e) => handleModalClose(e)}>
            CLOSE
          </Button>
        </Modal.Actions>
        {/* <Modal.Actions className="modal-container-actions"> */}
      </Modal>
    </div>
  )
}

export default PropertyCardModal;