import gql from 'graphql-tag';

export const bluemoonAuth = gql`
  mutation bluemoonAuth($input: BluemoonAuthInput!){
    bluemoonAuth(bluemoon: $input){
      response,
      bluemoon{
        id,
        username,
        password,
    }    
    }
  }
`;

export const updateBmAuth = gql`
  mutation updateBmAuth($input: BluemoonAuthUpdateInput!){
    updateBmAuth(bluemoon: $input){
      response,
      bluemoon{
        id,
        username,
        password,
    }    
    }
  }
`;

export const bluemoonInfo = gql`
  query {
    bluemoonInfo{
    edges {
      node {
        id
        accessToken
        password
        grantType
        organizationId
        username
        refreshToken
        tokenType
      }
    }
  }
}
`;

export const bluemoonProperties = gql`
query{
  bluemoonProperties{
    edges{
      node{
        id
     name
        legalName
        units
        unitType
        accountId
        telephone
      }
    }
  }
}
`;

export const bluemoonPolicies = gql`
query bluemoonForms($locationId: String!){
  bluemoonForms(locationId: $locationId) {
    edges {
      node {
        custom{
          type,
          name,
          label,
          language,
          status,
          rank,
          section,
          restricted,
          esignature,
          contract,
          onlineOnly
        },
        standard{
          name,
          label,
          type,
          language,
          status,
          rank,
          section,
          restricted,
          esignature,
          onlineOnly,
          contract
      }
      }
    }
  }
}
`;

export const leaseSign = gql`
  mutation esignManager($input: EsignManagerInput!){
    esignManager(lease: $input){
      response
    }
  }
`;
