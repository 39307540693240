import React, { Component } from 'react';
import { Form, Button } from 'semantic-ui-react';

class PropertyDetails extends Component{

    saveAndContinue = (e) => {
        e.preventDefault()
        this.props.nextStep()
    }

    render(){
        const { values } = this.props;

        return(
            <Form >
                <h1 className="ui centered">Enter Property Details</h1>
                <Form.Field>
                    <label>Name</label>
                    <input
                    disabled={window.location.toString().includes("location=")}
                    placeholder='Insert Property Name'
                    onChange={this.props.handleChange('name')}
                    defaultValue={values.name}
                    />
                </Form.Field>
                <Form.Field>
                    <label>City</label>
                    <input
                    placeholder='Insert City'
                    onChange={this.props.handleChange('city')}
                    defaultValue={values.city}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Zip</label>
                    <input
                    placeholder='Insert Zip'
                    onChange={this.props.handleChange('zip')}
                    defaultValue={values.zip}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Street</label>
                    <input
                    placeholder='Insert Street Address'
                    onChange={this.props.handleChange('streetOne')}
                    defaultValue={values.streetOne}
                    />
                </Form.Field>
                <Form.Field>
                    <label>State</label>
                    <input
                    placeholder='Insert State'
                    onChange={this.props.handleChange('state')}
                    defaultValue={values.state}
                    />
                </Form.Field>
                <Button onClick={this.saveAndContinue}>Save And Continue </Button>
            </Form>
        )
    }
}

export default PropertyDetails;