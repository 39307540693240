import React, { useMemo, useState, useEffect } from "react";
import {Button } from "semantic-ui-react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import AccountingTable from "./AccountingTable";
import { ACCOUNTING } from "../../utils/constants";
import { multipleColumnsSearch } from "../../utils/common";
import InvoicesModal from "../Modals/InvoicesModal";
import VoidInvoiceModal from "../Modals/VoidInvoiceModal";
import { getClient } from "../../init-apollo-googleFn";
import getAuthToken from "../../store/auth/authUtility";
import ViewInvoiceDetails from "../Accounting/ViewInvoiceDetails";
import { InvoiceInfoAccounting } from "../../store/person/accounting";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const invoiceClient = getClient(ACCOUNTING);

export function Table(props) {
  const [filterInput, setFilterInput] = useState("");
  const [toggled, viewChange] = useState(false);
  const [viewInvoiceData, viewDataInvoice] = useState("");
  const [viewInvoicesData, viewDataInvoices] = useState("");
  const locationId = props.propertyId && props.propertyId;
  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    const filteredData = multipleColumnsSearch(
      value,
      props.invoiceCache && props.invoiceCache
    );
    setData(filteredData);
    setFilterInput(value);
  };

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    return "";
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const DateFormat = ({ values }) => {
    return moment(values).format('l')
     };
  /* eslint-disable */
  const columns = useMemo(
    () => [
      {
        Header: "Number",
        accessor: "number",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "Invoice",
        accessor: "invoiceDate",
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : ''),
      },
      {
        Header: "Posted",
        accessor: "postedDate",
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : ''),
      },
      {
        Header: "Actions",
        accessor: (item) => (
          <>
            {item.status === "created" && (
              <InvoicesModal
                propertyId={props.propertyId}
                success={success}
                user={props.user}
                props={item}
              />
            )}
            {item.status === "approved" && (
              <InvoicesModal
                propertyId={props.propertyId}
                success={success}
                user={props.user}
                props={item}
              />
            )}
            {item.status !== "voided" && (
              <VoidInvoiceModal
                propertyId={props.propertyId}
                updateInvoice={props.updateInvoice}
                success={success}
                user={props.user}
                props={item}
              />
            )}
            <Button icon="file pdf outline" onClick={download} props={item} />
            <Button
              icon="eye"
              onClick={viewInvoice}
              property={props.property}
              props={item}
            />
          </>
        ),
      },
    ],
    []
  );
  /* eslint-enable */

  const viewInvoice = (e, props) => {
    viewDataInvoices(props);
    if (toggled) {
      viewChange(false);
    }
    if (!toggled) {
      getInvoice(props);
    }
  };

  const getInvoice = (props) => {
    try {
      invoiceClient
        .query({
          query: InvoiceInfoAccounting,
          variables: {
            location: locationId,
            id: props.props._id,
          },
        })
        .then((res) => {
          if (res.data) {
            const data = res.data.slLocationInvoice
            /* eslint-disable */
            const Check = data.payments.map((payment) => {
            /* eslint-enable */
              const check = data.payments.filter(
                (pay) => pay.externalCheckBankId === payment._id
              );
              if (check.length > 0) {
                return payment.status = "refund";
              }
            });

            viewDataInvoice(
              res.data.slLocationInvoice && res.data.slLocationInvoice
            );
            viewChange(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
    }
  };

  const download = (e, props) => {
    const url = new URL(ACCOUNTING);
    url.searchParams.append("invoice_id", props.props._id);
    url.searchParams.append("action", "invoice");
    axios({
      method: "get",
      url: url.href,
      headers: {
        authorization: getAuthToken(),
      },
      responseType: "blob", // important
    })
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" });
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [data, setData] = useState([]);
  const [lastPage, setLastPage] = useState();

  const fail = () =>
    toast.error("No agent with this ID exists in our system", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const success = () =>
    toast.success("Success!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  /* eslint-disable */
  useEffect(() => {
    const result = props.totalItems / 30;
    setLastPage(Math.ceil(result));
    setData(props.invoiceCache && props.invoiceCache);
  }, [props.invoiceCache && props.invoiceCache]);
  /* eslint-enable */

  return (
    <>
      {!toggled ? (
        <>
          <InvoicesModal
            dataPush={props.dataPush}
            fail={fail}
            success={success}
            propertyId={props.propertyId}
          />
                    <Button style={{paddingTop:'0px', paddingBottom:"0px", height:"35px", marginTop:'5px'}} className="noPrint" onClick={() => window.print()}>Print</Button>

          {/* <div className="table-container"> */}
          <AccountingTable
            value={filterInput}
            defaultColumn={defaultColumn}
            onChange={handleFilterChange}
            columns={columns}
            data={data}
            getLedger={props.getLedger}
            totalItems={props.totalItems}
            pageCount={lastPage}
            lastPage={lastPage}
          />
          {/* </div> */}
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
          />
        </>
      ) : (
        <ViewInvoiceDetails
          property={props.property}
          invoice={viewInvoiceData}
          invoices={viewInvoicesData}
          ledgerView={viewInvoice}
        />
      )}
    </>
  );
}
