import React, { useMemo, useState, useEffect } from "react";
import { ToastContainer, } from "react-toastify";
import SimpleTable from "./SimpleReportsTable";
import { multipleColumnsSearch } from "../../utils/common";

import "react-toastify/dist/ReactToastify.css";

export function Table(props) {
  const [filterInput, setFilterInput] = useState("");
  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    const filteredData = multipleColumnsSearch(
      value,
      props.rollCache && props.rollCache
    );
    setData(filteredData);
    setFilterInput(value);
  };




  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      // Filter: DefaultColumnFilter,
    }),
    []
  );

  const columns = useMemo(
    () => [

      {
        Header: 'Unit',
        accessor: 'node.unit',
      },
      {
        Header: 'Floor Plan',
        accessor: 'node.floorPlan',
      },
      {
        Header: 'Name',
        accessor: 'node.name',
      },
      {
        Header: 'Apply',
        accessor: 'node.applyDate',
      },
      {
        Header: 'Move-In',
        accessor: 'node.moveIn',
      },
      {
        Header: 'Term',
        accessor: 'node.leaseTerm',
      },
      {
        Header: 'Market Rent',
        accessor: 'node.marketRent',
        Cell: ({ value }) => (value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''),
      },
      {
        Header: 'Lease Rent',
        accessor: 'node.leaseRent',
        Cell: ({ value }) => (value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''),
      },
      {
        Header: 'Credits',
        accessor: 'node.credits',
        Cell: ({ value }) => (value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''),
      },
      {
        Header: 'Other',
        accessor: 'node.otherCharges',
        Cell: ({ value }) => (value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","): ''),
      },
      {
        Header: 'Deposit On Hand',
        accessor: 'node.depositOnhand',
        Cell: ({ value }) => (value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''),
      },
      {
        Header: 'Ad Source',
        accessor: 'node.adSource',
      },
      {
        Header: 'Leased By',
        accessor: 'node.leasedBy',
      },
    ],
    [],
  );
  


  const [data, setData] = useState([]);
  const [lastPage, setLastPage] = useState();

  /* eslint-disable */
  useEffect(() => {
    const result = props.rollCache.length / 30;
    setLastPage(Math.ceil(result));
    setData(props.rollCache && props.rollCache);
  }, [props.rollCache && props.rollCache]);
  /* eslint-enable */

  return (
    <>
       <main className="main-content" role="main">
        <div className="reconcile-container">
      <div className="table-container">

          <SimpleTable
            value={filterInput}
            defaultColumn={defaultColumn}
            onChange={handleFilterChange}
            columns={columns}
            data={data}
            pageCount={lastPage}
            lastPage={lastPage}
          />
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
          />
        </div>
        </div>
        </main>
    </>
  );
}
