import React from 'react'

export const RentalHistory = ({

  verifiedIcon = <span className="text-success"><i className="fa fa-check-circle"></i></span>

}) => (
    <div className="card applicant-history-card">
      <div className="applicant-history-card-title">
        Rental History {verifiedIcon}
        <a
          className="validations-link"
          data-toggle="collapse"
          href="#rental-validations"
          aria-expanded="false"
          aria-controls="validations"
        >
          See Validations
        </a>
        <div className="collapse" id="rental-validations">
          <div className="validations-container">
            <div className="validations">
              <span className="validations-item">
                Questionnaire: {verifiedIcon}
              </span>
              <span className="validations-item">
                Landlord Court Records: {verifiedIcon}
              </span>
            </div>
            <div className="validations">
              <span className="validations-item">
                Evictions: {verifiedIcon}
              </span>
              <span className="validations-item">
                Address Match: {verifiedIcon}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body" id="rental">
        <div className="rental-table-container">
          <div className="responsive-table">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Current</th>
                  <th>Previous</th>
                  <th>Validations</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-target="Current">1234 S. Main St. <br /> Seattle, WA 98133</td>
                  <td data-target="Previous">323 N. Ravena <br /> Seattle, WA 98133</td>
                  <td data-target="Validations"> {verifiedIcon} </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
