import gql from 'graphql-tag'



export const personGql = gql`
query Person {
  person {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        ndbId
        firstName
        lastName
        dateOfBirth
        id
        firebaseId
        email
        phoneHome
        phoneMobile
        verificationType
        verificationNumber
        pointsBalance
        photoUrl
        addresses {
          street
          postcode
          city
          state
        }
        identification {
          number
          country
          stateIssued
          # identType
          # identCountry
        }
        residence {
          price
          relocationReason
          startDate
          endDate
          manager {
            name
            phone
          }
          address {
            street
            city
            state
            postcode
          }
        }
        employment {
          title
          supervisorName
          supervisorEmail
          supervisorPhone
          salary
          startDate
          endDate
          employerName
          address {
            street
            city
            state
            postcode
          }
        }
        criminalEviction {
          isEvicted
          isConvictedOfMisdemeanor
          isConvictedOfFelony
          # files {
          #   id
          #   url
          # }
        }
        reference {
          name
          title
          phone
          email
        }
      }
    }
  }
}
`
export const personPetsGql = gql`
  query PersonPet($id: String) {
    personPet (id: $id) {
      type
      name
    }
  }`

  export const verifyPerson = gql`
  mutation verifyPersonEmail($input: VerifyPersonEmailInput!) {
    verifyPersonEmail(input: $input) {
      response
    }
  }
`

export const personDependentsGql = gql`
  query PersonDependent($id: String) {
    personDependent (id: $id) {
      firstName
    }
  }
`

export const personRoleGql = gql`
  query PersonRoles {
    personRoles {
      role
    }
  }
`

export const eventsGql = gql`
  query personCalendar($locationId: String!) {
    personCalendar(locationId: $locationId) {
      edges {
        node {
          id
          personId
          locationId
          eventName
          eventType
          eventId
          description
          startDate
          endDate
          createdAt
          updatedAt
        }
      }
    }
  }
`

export const personRequestGql = gql`
  query renterRequests {
    renterRequests 
  }
`

export const getMaintenanceMetrics = gql`
  query dashboardMaintenanceMetrics($location: String!){
    dashboardMaintenanceMetrics(location: $location)
  }
`;
export const getApplicationMetrics = gql`
  query dashboardApplicationMetrics($location: String!){
    dashboardApplicationMetrics(location: $location)
  }
`;
export const getResidentsMetrics = gql`
  query dashboardResidentsMetrics($locationId: String!){
    dashboardResidentsMetrics(locationId: $locationId)
  }
`;
export const getUnitsMetrics = gql`
  query dashboardUnitsMetrics($locationId: String!){
    dashboardUnitsMetrics(locationId: $locationId)
  }
`;