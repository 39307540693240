import React, {
  useMemo, useState, useEffect, useCallback,
} from 'react';
import AccountingTable from './AccountingTable';
import { multipleColumnsSearch } from '../../utils/common';

export function Table(props) {
  const [filterInput, setFilterInput] = useState('');

  const handleFilterChange = (e) => {
    const value = e.target.value || '';
    const filteredData = multipleColumnsSearch(value, props.transCache);
    setData(filteredData);
    setFilterInput(value);
  };

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    return (
      ''
    );
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  /* eslint-disable */
  const columns = useMemo(
    () => [

      {
        Header: 'Ledger',
        accessor: 'LedgerAccount_name',
      },
      {
        Header: 'Location',
        accessor: 'Location_name',
      },
      {
        Header: 'Id',
        accessor: 'InvoiceId',
      },
      {
        Header: 'Posted',
        accessor: 'postedDate',
      },

      {
        Header: 'Transaction',
        accessor: 'transactionDate',
      },
      {
        Header: 'Debit',
        accessor: 'debit',
      },
      {
        Header: 'Credit',
        accessor: 'credit',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
    ],
    [],
  );
  /* eslint-enable */

  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const [loading, setLoading] = React.useState(false);
  const [startRowIndex, setStartRowIndex] = useState(0);
  const [endRowIndex, setEndRowIndex] = useState(0);
  const [lastPage, setLastPage] = useState();

  const calculateLastPage = ({ pageSize, controlledPageCount }) => {
    const result = controlledPageCount / pageSize;
    setLastPage(Math.ceil(result));
  };

  /* eslint-disable */
  const fetchData = useCallback(({ pageSize, pageIndex }) => {
    const fetchId = ++fetchIdRef.current;
    setLoading(true);
    setTimeout(() => {
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const totalRecords = props.transCache && props.totalItems;
        const startRow = pageSize * pageIndex;
        const endRow = totalRecords < 30 ? totalRecords : 30;
        setData(props.transCache &&props.transCache.concat());
        setPageCount(totalRecords);
        setStartRowIndex(startRow);
        setEndRowIndex(endRow);
        setLoading(false);
      }
    }, 2000);
  }, [props.transCache.transCache]);
  /* eslint-enable */

  const fetchTransData = () => {
    // Run requery here
    return setTimeout(() => {
      const startRow = endRowIndex;
      let endRow = startRow + 30;
      props.getLedger(startRow)
      if (pageCount <= endRow) endRow = pageCount;
      setEndRowIndex(endRow);
      setStartRowIndex(startRow);
      if(endRow !== pageCount){
        setData([...data, ...props.transCache.concat()]);
      }
    }, 1500);
  };

  useEffect(() => {
    setData(props.transCache);
  }, [props.transCache]);

  return (

    <div className="table-container">
        <AccountingTable
          value={filterInput}
          startRow={startRowIndex}
          defaultColumn={defaultColumn}
          endRow={endRowIndex}
          onChange={handleFilterChange}
          columns={columns}
          data={data}
          loading={loading}
          fetchData={fetchData}
          pageCount={pageCount}
          calculateLastPage={calculateLastPage}
          lastPage={lastPage}
          update={fetchTransData}
        />
    </div>
  );
}
