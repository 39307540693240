import gql from 'graphql-tag';

export const leasesGql = gql`
query Leases($locationId: String!, $sort: [LeaseTypeSortEnum]){
  leases(locationId: $locationId, sort: $sort) {
    edges {
      node {
        id
        applicationId
        status
        amount
        status
        startDate
        lgState
        lgCompleted
        skipGuarantee
        backgroudCheckId
        insuranceCompleted
        extLeaseInfo
        insurancePolicy
        endDate
        leaseFrequency
        nonRefundable
        isProRata
        billOnDate
        unitProRata
        petProRata
        billOnDate
        vehicleProRata
        unit{
          id
          number
          deposit
          unitType {
            id
            type
          }
        }
        unitChargeCode{
          id
          name
          code
          amountDefault
          quantity
        }
        petChargeCode{
          id
          name
          code
          amountDefault
          quantity
        }
        vehicleChargeCode{
          id
          name
          code
          amountDefault
          quantity
        }
        depositChargeCode{
          id
          name
        }
        deposit
        guarantee
        firstLastInvoice
        extraCharges
        vehicleAmount
        petAmount
        unitAmount
        vehicleQuantity
        petQuantity
        unitQuantity
        extLeaseInfo
        person {
          firstName
          lastName
        }
        createdAt
      }
    }
  }
}
`;

export const leaseDetails = gql`
  query Lease($leaseId: String!){
    lease(leaseId: $leaseId) {
      edges {
        node {
          id
          applicationId
          status
          amount
          status
          startDate
          unitProRata
          petProRata
          isProRata
          vehicleProRata
          billOnDate
          endDate
          moveInDate
          leaseFrequency
          nonRefundable
          firstLastInvoice
          guarantee
          lgState
          application {
            renterPrimaryApplicant{
              id
            }
          }
          leaseType
          unit{
            id
            number
            deposit
            unitType {
              id
              type
            }
          }
          unitChargeCode{
            id
            name
            code
            amountDefault
            quantity
          }
          petChargeCode{
            id
            name
            code
            amountDefault
            quantity
          }
          vehicleChargeCode{
            id
            name
            code
            amountDefault
            quantity
          }
          depositChargeCode{
            id
            name
          }
          deposit
          extraCharges
          vehicleAmount
          petAmount
          unitAmount
          vehicleQuantity
          petQuantity
          unitQuantity
          person {
            firstName
            lastName
            email
            phoneMobile
          }
          extLeaseInfo
          createdAt
        }
      }
    }
  }
`;

export const createLease = gql`
mutation createLease($input: CreateLeaseInput!) {
  createLease(input: $input) {
    response
  }
}
`;

export const updateBulkEdit = gql`
  mutation bulkUpdateLease($input: BulkUpdateLeaseInput!) {
    bulkUpdateLease(input: $input) {
      response
    }
  }
`;

export const unitAvailabilityCheck = gql`
  query isUnitAvailable($unitId: String!, $startDate: DateTime!, $endDate: DateTime!) {
    isUnitAvailable(unitId: $unitId, startDate: $startDate, endDate: $endDate)
  }
`;

export const updateLease = gql`
mutation updateLease($input: UpdateLeaseInput!) {
  updateLease(input: $input) {
    response
  }
}
`;

export const getLeaseComments = gql`
  query comments($leaseId: String!){
    comments(leaseId: $leaseId){
      edges{
        node{
          id
          subject
          message
          commentType
          person{
            id
            firstName
            lastName
          }
          created
          updated
        }
      }
    }
  }
`;

export const createLeaseComment = gql`
  mutation ct($input: CreateLeaseCommentInput!){
    createComment(input: $input){
      response
    }
  }
`;

export const updateLeaseComment = gql`
  mutation updateCommet($input: UpdateLeaseCommentInput!){
    updateComment(input: $input){
      response
    }
  }
`;

export const deleteLeaseComment = gql`
  mutation DL($input: DeleteLeaseCommentInput!){
    deleteComment(input: $input){
      response
    }
  }
`;

export const getLeaseClaim = gql`
  query leaseClaim($leaseId: String!){
    leaseClaim(leaseId: $leaseId)
  }
`;

export const proRate = gql`
  mutation PP($input: ProRataMutationInput!) {
    proRata(input: $input) {
      results {
        inputDate
        billOnDate
        finalAmount
      }
    }
  }
`;

export const locationPolicies = gql`
  query locationPolicies($locationId: String!) {
    locationPolicies(id: $locationId) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const leaseDocuments = gql `
  mutation sendLeaseDocuments($input:SendLeaseDocumentsInput!){
    sendLeaseDocuments(input:$input){
      response
    }
  }
`
export const lgavailable = gql`
  query isLgAvailable($applicationId: String!){
    isLgAvailable(applicationId: $applicationId)
  }
`;
