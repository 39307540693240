import React, { useState} from 'react';
import {
  Button,
  Modal,
  Header,
  Form,
  Icon,
} from 'semantic-ui-react';
import get from 'lodash/get';
import { getClient } from '../../init-apollo-googleFn';

import './moveLeadModal.scss';
import {
  ChargeAccountingMutation,
  LedgerAccountingRoll,
  TaxAccountingRoll,
  UpdateAccountingMutation,
} from '../../store/person/accounting';
import { ACCOUNTING, CHARGE_TYPES } from '../../utils/constants';


const chargeClient = getClient(ACCOUNTING);

export default function ChargeCodeModal(props) {
  const [open, setOpen] = useState(false);
  const [charge, chargeCode] = useState(false);
  const [name, nameCode] = useState(false);
  const [description, descCode] = useState(false);
  const [loading, setLoader] = useState(false);

  const [internal, internalCode] = useState(false);
  const [amount, amountCode] = useState(false);
  const [chargeType, chargeTypeCode] = useState(false);
  const [selectedLedger, chosenLedgerCode] = useState(false);
  const [ledger, ledgerData] = useState(false);
  const [taxType, taxTypeCode] = useState(false);
  const [selectedTax, chosenTaxCode] = useState(false);

  const createCharge = (e) => {
    setLoader(true);
    e.preventDefault();
    if (props.props) {
      try {
        chargeClient
          .mutate({
            mutation: UpdateAccountingMutation,
            variables: {
              input: {
                location: props.propertyId,
                extLedgerId: selectedLedger,
                code: charge,
                name,
                desc: description,
                descInternal: internal,
                id: props.props.node.id,
                amountDefault: parseFloat(amount).toFixed(2),
              },
            },
          })
          .then((response) => {
            props.success();
            setLoader(false);
            props.updateInvoice(response);
            setOpen(false);
          });
      } catch (e) { alert(e) }
    } else {
      try {
        chargeClient
          .mutate({
            mutation: ChargeAccountingMutation,
            variables: {
              input: {
                location: props.propertyId,
                extLedgerId: selectedLedger,
                type: chargeType,
                code: charge,
                name,
                extTaxcodeId: selectedTax,
                desc: description,
                descInternal: internal,
                amountDefault: parseFloat(amount).toFixed(2),
              },
            },
          })
          .then((response) => {
            props.success();
            setLoader(false);
            props.dataPush(response);
            setOpen(false);
          });
      } catch (e) { alert(e) }
    }
  };

  const getLedger = (event) => {
    event.preventDefault();
    ledgerData('');
    chargeCode('');
    nameCode('');
    descCode('');
    internalCode('');
    amountCode('');
    chargeTypeCode('');
    chosenLedgerCode('');
    taxTypeCode('');
    chosenTaxCode('');
    if (props.props) {
      chargeCode(props.props.node.code);
      nameCode(props.props.node.name);
      descCode(props.props.node.desc);
      internalCode(props.props.node.descInternal);
      amountCode(props.props.node.amountDefault);
      chargeTypeCode(props.props.node.type);
      chosenLedgerCode(props.props.node.extLedgerId);
    }
    try {
      chargeClient
        .query({
          query: TaxAccountingRoll,
          variables: {
            location: props.propertyId,
            offset: 0,
            limit: 1000,
          },
        })
        .then((res) => {
          taxTypeCode(get(res, 'data.slLocationTaxCodes.data', null));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) { }
    try {
      chargeClient
        .query({
          query: LedgerAccountingRoll,
          variables: {
            location: props.propertyId,
            offset: 0,
            limit: 1000,
          },
        })
        .then((res) => {
          if (res.data) {
            ledgerData(get(res, 'data.slLocationLedgerAccounts.data', null));
            if (props.props) {
              chargeCode(props.props.node.code);
              nameCode(props.props.node.name);
              descCode(props.props.node.desc);
              ledgerData(props.props.node.extLedgerId);
              internalCode(props.props.node.descInternal);
              amountCode(props.props.node.amountDefault);
              chargeTypeCode(props.props.node.type);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) { }
  };

  return (
    <Modal
      className="semanticModal modal-ui modal-radius overflow-y-visible"
      onOpen={() => setOpen(true)}
      open={open}
      trigger={(
        <Button type="button" onClick={getLedger} icon>
          <Icon name="add circle" />
          {props.isCustomCharge ? 'Create Charge Code' : ''}
        </Button>
      )}
    >
      <Modal.Header
        textAlign="left"
        className="modal-header-bg position-sticky"
      >
        <Header
          className="modal-heading-custom position-sticky"
          textAlign="left"
        >
          {props.props ? 'Update Charge Code' : 'Create Charge Code'}
        </Header>
      </Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          <Form color="blue">
            <Form.Field required>
              <label title="Charge codes are entities that are added to a resident’s account/invoice.  Examples include Rent, Parking Fee, Pet Fee, Cleaning Fee, etc. Charge codes are linked to a ledger account for financial reporting purposes.">Code</label>
              <input
                value={charge || ''}
                onChange={(e) => chargeCode(e.target.value)}
              />
            </Form.Field>
            <Form.Field required>
              <label>Name</label>
              <input
                value={name || ''}
                onChange={(e) => nameCode(e.target.value)}
              />
            </Form.Field>
            <Form.Field required>
              <label>Description</label>
              <input
                value={description || ''}
                onChange={(e) => descCode(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>Internal Description</label>
              <input
                value={internal || ''}
                onChange={(e) => internalCode(e.target.value)}
              />
            </Form.Field>
            <Form.Field required>
              <label>Amount</label>
              <input
                value={amount || ''}
                type="number"
                style={{ width: '93%' }}
                onChange={(e) => amountCode(e.target.value)}
              />
            </Form.Field>
            {!props.props &&
              <>
                <Form.Field required>
                  <label>Type</label>
                  <select
                    className="form-control"
                    defaultValue={chargeType}
                    id="leaseLength"
                    onChange={(e) => chargeTypeCode(e.target.value)}
                  >
                    <option value=""></option>
                    <option value={CHARGE_TYPES.Revenue}>Revenue</option>
                    <option value={CHARGE_TYPES.Credit}>Credit</option>
                    <option value={CHARGE_TYPES.Payment}>Payment</option>
                    <option value={CHARGE_TYPES.Refund}>Refund</option>
                  </select>
                </Form.Field>
                <Form.Field required>
                  <label>Ledger Account</label>
                  <select
                    className="form-control"
                    id="leaseLength"
                    onChange={(e) => chosenLedgerCode(e.target.value)}
                  >
                    <option value=''></option>
                    {ledger &&
                      ledger.map((item) => {
                        return <option value={item._id}>{item.name}</option>;
                      })}
                  </select>
                </Form.Field>
                {/* <Form.Field >
                  <label>Tax Code {!taxType && <Link to='/accounting/tax'>Create Tax Code</Link>}</label>
                  <select
                    className="form-control"
                    id="leaseLength"
                    onChange={(e) => chosenTaxCode(e.target.value)}
                  >
                    {!taxType && <option value=''>No Available Tax Codes!</option>}
                    <option value=''></option>
                    {taxType &&
                      taxType.map((item) => {
                        return <option value={item._id}>{item.code}</option>;
                      })}
                  </select>
                </Form.Field> */}
              </>
            }
            {props.props && props.props.node &&
              <>
                <Form.Field>
                  <label>
                    Type:
                    {props.props.node.type && (props.props.node.type.charAt(0) +
                      props.props.node.type.substring(1).toLowerCase())}
                  </label>
                </Form.Field>
                <Form.Field>
                  <label>
                    Code:
                    {props.props.node.code && props.props.node.code}
                  </label>
                </Form.Field>
                <Form.Field>
                  <label>
                    Ledger Id:
                    {props.props.node.extLedgerId && props.props.node.extLedgerId}
                  </label>
                </Form.Field>
              </>
            }
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="modal-close-button"
          content="Close"
          onClick={() => setOpen(false)}
          positive
        />
        {loading ? (
          <Button loading>Loading</Button>
        ) : (
          <>
            {/* eslint-disable */}
            <Button
              className="modal-save-button"
              content={props.props ? 'Update' : 'Create'}
              disabled={props.props
                ? chargeType && !chargeType.trim()
                || name && !name.trim()
                || description && !description.trim()
                || !chargeType
                || !name
                || !description
                || !amount
                : !selectedLedger
                || !taxType
                || chargeType && !chargeType.trim()
                || name && !name.trim()
                || description && !description.trim()
                || !chargeType
                || !name
                || !description
                || !amount}
              onClick={createCharge}
              positive
            />
            {/* eslint-enable */}
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
}
