import React, { Component } from 'react';
import Slider from 'react-slick';
import { Grid } from 'semantic-ui-react';
import './Explore.scss';
import { ROUTES } from '../../utils';
import PropertyTypeSelection from '../../components/Properties/PropertyListing/PropertyTypeSelection';
import mixpanel from 'mixpanel-browser';
class Explore extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isPropertySelection: false,
    };
  }

  componentDidMount(){
    mixpanel.track('Manager Page Load', {
      'sub': 'Explore'
     });

  };

  onSelectPropertyType = (propertyType) => {
    this.setState({ isPropertySelection: false });
    this.props.history.push({ pathname: ROUTES.addProperty, search: `propertyType=${propertyType}` });
  }

  render() {
    const settings = {
      // dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    const { isPropertySelection } = this.state;
    return (
      <>
        <div className="homepage">
          <div className="property-manager-wrapper">
            <Slider {...settings} className="home-banner">
              <div>
                <img src="assets/img/marketing/property-manager-banner.jpg" className="d-block w-100" alt="..." />
                <div className="carousel-caption">
                  <h5>
                    Enter a new era of
                    rental management.
                    {' '}
                  </h5>
                  <p>
                    Your software can no longer just offer maintenance and accounting. Thanks to Leasera, you
                    can now handle flexible leasing opportunities and engage a supportive network. You’re
                    now part of an experience.
                    {' '}
                  </p>
                  {/* <a className="homebtn">Get Started Now</a> */}
                </div>
              </div>
             
            </Slider>
            <Grid divided="vertically half-image-section property-section">
              <Grid.Row columns={2}>
                <Grid.Column>
                  <div className="image-container">
                    <img src="assets/img/marketing/pm_image_10.jpg" alt="Illustration" className="renter-image" />
                    {/* <img src="assets/img/marketing/homepage-house.svg" alt="Illustration image" className="fixed-left-image" /> */}
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <h3>Property Management Software that Wow's</h3>
                  <p className="text">
                    If you’re seeking powerful, margin boosting, service rich, consumer wow’ing
                    property management software, welcome! Fill listings with a single, affordable click.
                  </p>
                  <p className="text">
                    Our sophisticated digital approach frees you to focus precisely and exclusively
                    on information that will improve time, energy and financial efficiencies. We’ve worked hard
                    to cut unnecessary costs and stress for you.
                  </p>
                  <div className="mt-5">
                    {/* eslint-disable */}
                    <a className="homebtn" onClick={() => this.setState({ isPropertySelection: true })}>Add your property</a>
                    {/* eslint-enable */}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid divided="vertically half-image-section activity-section">
              <Grid.Row columns={2}>
                <Grid.Column>
                  <h3>Digitally manage your leasing activity and beyond</h3>
                  <p className="text">You've probably noticed, property management isn't just about the property or accounting anymore. Get more with Leasera.</p>
                  <p className="text">We'll handle all of your administrative and reporting needs from one single location, to an entire portfolio of rentals..</p>

                </Grid.Column>
                <Grid.Column>
                  <div className="image-container">
                    <img src="assets/img/marketing/pm_image_8.png" alt="Illustration" className="renter-image" />
                    <img src="assets/img/marketing/pm_image_5.png" alt="Illustration" className="fixed-left-image" />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid divided="vertically manage-properties-section half-image-section">
              <Grid.Row columns={2}>
                <Grid.Column>
                  <p className="badge-btn">Property Management</p>
                  <h3>
                    Powerful system to
                    manage all properties,
                    units & renter needs.
                  </h3>
                  <p>
                    Today's era of leasing activities go far beyond maintenance, lead generation or accounting.
                    You need software that goes beyond as well, supporting you, your team and offering you an
                    affordable way of scaling operations.
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <div className="image-container">
                    <img src="assets/img/marketing/pm_image_7.png" alt="Illustration" className="renter-image" />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>
        <PropertyTypeSelection
          isOpen={isPropertySelection}
          onClose={() => this.setState({isPropertySelection: false})}
          onSelect={this.onSelectPropertyType}
        />
      </>
    );
  }
}

export default Explore;
