import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from 'mixpanel-browser';
import { transactionsGql } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from '../../components/Loader/Loader';
import { Table } from "../../components/Tables/JournalData";
import { ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import "./Accounting.scss"
import { Button } from "semantic-ui-react";

const invoiceClient = getClient(ACCOUNTING);

class JournalReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      journalCache: [],
      offset: 0,
    };
  }

  componentDidMount() {
    this.getLedger();
    mixpanel.track('Manager Page Load', {
      'sub': 'Journal Report'
     });

  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }

  getLedger = (data) => {
    try {
      if (data === undefined) {
        this.setState({ loading: true });
        invoiceClient
          .query({
            query: transactionsGql,
            variables: {
              location: this.props.selectedPrimary.node.id,
              offset: this.state.offset,
              limit: 1000,
            },
          })
          .then((res) => {
            if (res.data) {
              this.setState({
                journalCache: get(res, "data.slLocationTransactions.data", []),
                totalItems: get(res, "data.slLocationTransactions.totalItems"),
              });
              if (res.data.slLocationTransactions.data.length === 1000) {
                this.setState({
                  offset: this.state.offset + 1000
                });
              }
            }
            this.setState({ loading: false });
          })
          .catch((error) => {
            console.log(error);
            this.setState({ loading: false });
          });
      }
      else {
        invoiceClient
          .query({
            query: transactionsGql,
            variables: {
              location: this.props.selectedPrimary.node.id,
              offset: this.state.offset,
              limit: 1000,
            },
          })
          .then((res) => {
            if (res.data) {
              const value = [...data, ...get(res, "data.slLocationTransactions.data", [])];
              this.setState({ journalCache: value });
              if (res.data.slLocationTransactions.data.length === 1000) {
                this.setState({
                  offset: this.state.offset + 1000
                });
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  };



  dataPush = (response) => {
    this.setState((prevState) => ({
      journalCache: [
        response.data.createSlInvoice.slInvoice,
        ...prevState.journalCache,
      ],
    }));
  };
  updateInvoice = (status, invoiceId) => {
    const { journalCache } = this.state;
    const array = [];
    if (status === "Delete") {
      this.setState({ journalCache: journalCache.filter(invoice => invoice._id !== invoiceId) })
    }
    else {
      journalCache.forEach((invoice) => {
        if (invoice._id === invoiceId) {
          invoice.status = status;
          array.push(invoice);
        } else {
          array.push(invoice);
        }
      });
      this.setState({ journalCache: array });
    }
  };

  render() {
    const { loading } = this.state;
    return (
      <>
      <main className="main-content" role="main">
        <div className="tenants-container resident-container">
        {loading ? (
          <Loader text inTable/>
        ) : (
          <>
          <Button style={{paddingTop:'0px', paddingBottom:"0px", height:"46.17px", marginTop:'20px'}} className="noPrint" onClick={() => window.print()}>Print</Button>
          <Table
            updateInvoice={this.updateInvoice}
            getNewData={this.getNewData}
            property={this.props.selectedPrimary && this.props.selectedPrimary.node && this.props.selectedPrimary.node.customId}
            dataPush={this.dataPush}
            user={this.props.user}
            getLedger={this.getLedger}
            journalCache={this.state.journalCache && this.state.journalCache}
            totalItems={this.state.totalItems && this.state.totalItems}
            propertyId={this.props.selectedPrimary.node && this.props.selectedPrimary.node.id}
          />
          </>
        )}
        </div>
        </main>
      </>
    );
  }
}

export default withApollo(JournalReport);
