import React, { useMemo} from 'react';
import ReconcileTable from './ReconcileTable';
import moment from 'moment';




export function Table(props) {

const Status = ({ values }) => {
  const numberize = Number(values)
  if (Math.sign(numberize) === 1) {
    return (
      <p style={{color:'black'}}>${numberize}</p>
    );
  } 
  if (numberize === 0) {
    return (
      '-'
    )
  }
  else {
    return (
      <p style={{color:'black'}}>$({Math.abs(numberize)})</p>
    );
  }
};


  const defaultColumn = React.useMemo(() => ({ Filter: '' }), []);

  /* eslint-disable */
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'data[Account Name]',
      },
      
      {
        Header: 'Number',
        accessor: 'name',
      },
      {
        Header: props.monthYear === 'Monthly' ?moment().subtract(11,'months').format('MM/YYYY'):moment().subtract(11,'years').format('YYYY'),
        accessor: 'data.12',
        Cell: ({ cell: { value } }) => (value ? <Status values={value} /> : '-'),
      }, 
      {
        Header: props.monthYear === 'Monthly' ?moment().subtract(10,'months').format('MM/YYYY'):moment().subtract(10,'years').format('YYYY'),
        accessor: 'data.11',
        Cell: ({ cell: { value } }) => (value ? <Status values={value} /> : '-'),
      },
      {
        Header: props.monthYear === 'Monthly' ?moment().subtract(9,'months').format('MM/YYYY'):moment().subtract(9,'years').format('YYYY'),
        accessor: 'data.10',
        Cell: ({ cell: { value } }) => (value ? <Status values={value} /> : '-'),
      },
      {
        Header: props.monthYear === 'Monthly' ?moment().subtract(8,'months').format('MM/YYYY'):moment().subtract(8,'years').format('YYYY'),
        accessor: 'data.9',
        Cell: ({ cell: { value } }) => (value ? <Status values={value} /> : '-'),
      },
      {
        Header: props.monthYear === 'Monthly' ?moment().subtract(7,'months').format('MM/YYYY'):moment().subtract(7,'years').format('YYYY'),
        accessor: 'data.8',
        Cell: ({ cell: { value } }) => (value ? <Status values={value} /> : '-'),
      },
      {
        Header: props.monthYear === 'Monthly' ?moment().subtract(6,'months').format('MM/YYYY'):moment().subtract(6,'years').format('YYYY'),
        accessor: 'data.7',
        Cell: ({ cell: { value } }) => (value ? <Status values={value} /> : '-'),
      },
      {
        Header: props.monthYear === 'Monthly' ?moment().subtract(5,'months').format('MM/YYYY'):moment().subtract(5,'years').format('YYYY'),
        accessor: 'data.6',
        Cell: ({ cell: { value } }) => (value ? <Status values={value} /> : '-'),
      },
      {
        Header: props.monthYear === 'Monthly' ?moment().subtract(4,'months').format('MM/YYYY'):moment().subtract(4,'years').format('YYYY'),
        accessor: 'data.5',
        Cell: ({ cell: { value } }) => (value ? <Status values={value} /> : '-'),
      },
      {
        Header: props.monthYear === 'Monthly' ?moment().subtract(3,'months').format('MM/YYYY'):moment().subtract(3,'years').format('YYYY'),
        accessor: 'data.4',
        Cell: ({ cell: { value } }) => (value ? <Status values={value} /> : '-'),
      },
      {
        Header: props.monthYear === 'Monthly' ?moment().subtract(2,'months').format('MM/YYYY'):moment().subtract(2,'years').format('YYYY'),
        accessor: 'data.3',
        Cell: ({ cell: { value } }) => (value ? <Status values={value} /> : '-'),
      },
      {
        Header: props.monthYear === 'Monthly' ?moment().subtract(1,'months').format('MM/YYYY'):moment().subtract(1,'years').format('YYYY'),
        accessor: 'data.2',
        Cell: ({ cell: { value } }) => (value ? <Status values={value} /> : '-'),
      },
      {
        Header: props.monthYear === 'Monthly' ?moment().format('MM/YYYY'):moment().format('YYYY'),
        accessor: 'data.1',
        Cell: ({ cell: { value } }) => (value ? <Status values={value} /> : '-'),
      },
    ],
    [],
  );
  /* eslint-enable */

  const { trialsCache, totalItems, ReconcileAccount, columnLimit, } = props;
  return (
    <>
    {
        trialsCache&&
      <ReconcileTable
      columnLimit={columnLimit}
        defaultColumn={defaultColumn}
        columns={columns}
        data={trialsCache}
        totalItems={totalItems}
        update={ReconcileAccount}
      />
    }
</>
  );
}
