/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { pluralize } from '../../../utils/common';
import { ROUTES } from '../../../utils/constants';

const PropertyListItem = ({
  id,
  name,
  addresses,
  photos,
  totalBaths,
  totalBeds,
  totalSizes,
  totalPrices,
  totalUnits,
  status,
  disabled,
  navigateToDetails,
  onDeactivate,
}) => {
  const [showActions, setShowActions] = React.useState(false);

  const toggleActionDropDown = (event) => {
    event.stopPropagation();
    setShowActions((prev) => !prev);
  };

  const onCardClick = () => {
    if (status !== 'draft' && !disabled) navigateToDetails(id);
  };
  return (
    <div className="card-row" style={{ cursor: disabled ? 'no-drop' : 'pointer' }} onClick={onCardClick}>
      <div className="block img-box d-inline-flex">
        <img src={Array.isArray(photos) &&photos.length !==0 ? photos[0] : 'assets/img/404-boxes-background.jpg'} alt="404-boxes-background" />
      </div>
      <div className="block">
        <div className="card-titile">{name}</div>
        <div className="address">{addresses}</div>
      </div>
      <div className="block card-aminites">
        <span>
          {`${get(totalBeds, '[0]', 0)}+ ${pluralize(get(totalBeds, '[0]', 0), 'Bed')}`}
        </span>
        {' '}
        <span className="dot" />
        <span>
          {`${get(totalBaths, '[0]', 0)}+ ${pluralize(get(totalBaths, '[0]', 0), 'Bath')}`}
        </span>
        <span className="dot" />
        <span>{`${get(totalSizes, '[1]', 0)} sq.ft`}</span>
      </div>

      <div className="block">
        {totalPrices && (
          <div className="black-strip">
            <span>{`$${get(totalPrices, '[0]', 0)}-$${get(totalPrices, '[1]', 0)}`}</span>
            {!!totalUnits && (
              <>
                <span className="bar" />
                <span>{`${totalUnits} units`}</span>
              </>
            )}
          </div>
        )}
        <div className="custom-dropdown">
          <a className="more-btn" onClick={toggleActionDropDown}>
            <img src="assets/img/black-icons-more.svg" alt="white-icons-more" />
          </a>
          {showActions && (
            <div className="dropdown-list">
              {!disabled && (
                <div className="list-item" onClick={(e) => e.stopPropagation()}>
                  <Link to={ROUTES.editPropertyDetails.replace(':locationId', id)}>
                    <img src="assets/img/icons-edit.svg" alt="icons-edit" />
                    {' '}
                    Edit Property
                  </Link>
                </div>
              )}
              <div
                className="list-item red-text"
                onClick={(e) => {
                  toggleActionDropDown(e);
                  onDeactivate({ id, disabled, name, photos });
                }}
              >
                <a>
                  <img
                    src="assets/img/icons-red-delete.svg"
                    alt="icons-red-delete"
                  />
                  {` ${disabled ? 'Activate' : 'Deactivate'} Property`}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertyListItem;
