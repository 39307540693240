import React, { Component } from "react";
import { Query } from "react-apollo";
import get from 'lodash/get'
import { leasesGql } from "../../store/person/leases"
import { qpDatatables } from "../../utils/misc";
import Moment from 'react-moment';
import { DATE_FORMAT } from "../../utils/constants";
import "./Leases.scss"
import mixpanel from 'mixpanel-browser';


class Leases extends Component {
 
  componentDidMount() {
    qpDatatables();
    mixpanel.track('Manager Page Load', {
      'sub': 'Leases'
     });
  }

  redirectToDetail = (id, tenantId) => {
    this.props.history.push(`/tenantDetail/${id}/${tenantId}`)
  }

  tenantRows = (tenant, property, node) => {
    return (
      <>
        <td>{tenant.firstName} {tenant.lastName}</td>
        <td>${property.place.price}</td>
        <td>{}</td>
        <td>{node.status}</td>
        <td><Moment format={DATE_FORMAT}>{node.startDate}</Moment></td>
        <td><Moment format={DATE_FORMAT}>{node.endDate}</Moment></td>
      </>
    )
  }

  render() {
    return (
      <main className="main-content p-4" role="main">
        <div className="applications-container">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="heading heading-md">Lease</h2>
                </div>
              </div>
            </div>
          </div>
          <br/>

          <Query query={leasesGql} variables={{primaryLocationId: this.props.match.params.leaseId}}>
            {({ loading, error, data }) => {
              if (loading) return <div>Loading...</div>;
              if (error) return <div>Error</div>;
              const applications = get(data, 'leases.edges', [])
              return (
                applications.length === 0 ?
                  <tr><th>No results found</th></tr> :
                  applications.map(
                    (application, index) => {
                        const node = application.node, { property, primaryLessee, tenants } = node

                        const { streetOne, streetTwo, city, state, unitNum } = property.location;

                      return (
                          <div key={Math.random()}>
                            <div className="row mb-2">
                              <div className="col-md-12">
                                <div className="card">
                                  <div className="card-body p-0 application-card-grid">
                                    <div className="img">
                                      <img
                                        className="property-img float-left mr-2"
                                        src="assets/img/renter-bg.jpg"
                                        alt="Gallery 1"
                                      />
                                    </div>
                                    <div className="but">
                                      <button data-toggle="collapse"
                                        data-target={`#group-of-rows-${index}`}
                                        aria-expanded="false"
                                        aria-controls={`group-of-rows-${index}`}
                                        aria-hidden="true" className="clickable btn btn-primary pull-right">
                                        View
                                      </button>
                                    </div>
                                    <div className="adr">
                                      <span>{streetOne} {streetTwo}</span>
                                      <span>{city}, {state} {unitNum && <span>Unit: {unitNum}</span>}</span>
                                    </div>
                                    <div className="ale">
                                      <span className="alert badge badge-info badge-pill ml-4">
                                        5 Alerts
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row collapse" id={`group-of-rows-${index}`}>
                              <div className="col-lg-12">
                                <div className="responsive-table">
                                  <table
                                    className="table table-hover table-striped table-applications">
                                    <thead className="thead-dark">
                                      <tr >
                                        <th>Name</th>
                                        <th>Monthly Rent</th>
                                        <th>Additionals</th>
                                        <th>Status</th>
                                        <th>Start</th>
                                        <th>End</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr className="primary-lessee cursor-pointer" key={Math.random()} onClick={() => this.redirectToDetail(node.ndbId, primaryLessee.ndbId)}>
                                        {this.tenantRows(primaryLessee, property, node)}
                                      </tr>
                                      {tenants.map(
                                        (tenant) => (<tr className="cursor-pointer" key={Math.random()} onClick={() => this.redirectToDetail(node.ndbId, tenant.ndbId)}>
                                          {this.tenantRows(tenant, property, node)}
                                        </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                    }
                  )
                )
            }}
          </Query>
        </div>
      </main>
    )
  }
}

export default Leases
