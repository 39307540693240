import React, { useState} from "react";
import {
  Button,
  Modal,
  Header,
  Icon,
} from "semantic-ui-react";
import { getClient } from "../../init-apollo-googleFn";
import "./moveLeadModal.scss";
import {
  DeleteInvoiceMutation,
  VoidInvoiceMutation,
  IssueInvoiceMutation,
} from "../../store/person/accounting";
import { ACCOUNTING } from "../../utils/constants";

const chargeClient = getClient(ACCOUNTING);

export default function VoidInvoiceModal(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoader] = useState(false);
  const [choice, chosenAction] = useState(false);

  /* eslint-disable */
  const ActionCharge = (e) => {
    setLoader(true)
    e.preventDefault();
    {
      if (choice === "Delete") {
        try {
          chargeClient
            .mutate({
              mutation: DeleteInvoiceMutation,
              variables: {
                input: {
                  location: props.propertyId,
                  id: props.props._id,
                },
              },
            })
            .then((response) => {
              props.success()
              setLoader(false)
              props.updateInvoice("Delete", props.props._id)
              setOpen(false);
            });
        } catch (e) {
          alert(e)
          setOpen(false);
        }
      }
      if (choice === "Void") {
        try {

          chargeClient
            .mutate({
              mutation: VoidInvoiceMutation,
              variables: {
                input: {
                  location: props.propertyId,
                  id: props.props._id,
                },
              },
            })
            .then((response) => {
              props.success()
              props.updateInvoice("voided", props.props._id)
              setLoader(false)
              setOpen(false);
            });
        } catch (e) {
          alert(e)
          setOpen(false);
        }
      } else {
        try {
          chargeClient
            .mutate({
              mutation: IssueInvoiceMutation,
              variables: {
                input: {
                  location: props.propertyId,
                  id: props.props._id,
                },
              },
            })
            .then((response) => {
              props.success()
              props.updateInvoice("issued" , props.props._id)
setLoader(false)
              setOpen(false);
            });
        } catch (e) {
          alert(e)
          setOpen(false);
        }
      }
    }
  };
  /* eslint-enable */

  return (
    <Modal
      className="semanticModal"
      onOpen={() => setOpen(true)}
      size="small"
      open={open}
      trigger={
        <Button icon>
          <Icon name="wrench" />
        </Button>
      }
    >
      <Modal.Header
        textAlign="left"
        className="modal-header-bg position-sticky"
      >
        <Header
          className="modal-heading-custom position-sticky"
          textAlign="left"
        >
          Invoice Action
        </Header>
      </Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          Please select which action you would like to activate for this
          invoice.
          <select
            className="form-control"
            id="leaseLength"
            onChange={(e) => chosenAction(e.target.value)}
          >
            <option value=""></option>
            {props.props.status === "issued" && (
              <option value="Void">Void</option>
            )}
            {props.props.status === "created" && (
              <>
                <option value="Issue">Issue</option>
                <option value="Delete">Delete</option>
              </>
            )}
          </select>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="modal-close-button"
          content="Close"
          onClick={() => setOpen(false)}
          positive
        />
        {loading ? (
          <Button loading>Loading</Button>
        ) : (
          <>
            <Button
              className="modal-save-button"
              content="Submit"
              disabled={!choice}
              onClick={ActionCharge}
              positive
            />
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
}
