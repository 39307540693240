import React from 'react';
import {
  Button,
  Header,
  Modal,
} from 'semantic-ui-react';
import Amenities from './Amenities';

const UnitAmenities = ({
  isOpen,
  onClose,
  onSubmit,
  unitNumber,
  ...amenitiesProps
}) => (
  <Modal
    className="semanticModal custom-modal amenitie-modal"
    onClose={onClose}
    closeIcon
    open={isOpen}
    size="mini"
    closeOnEscape={false}
    closeOnDimmerClick={false}
  >
    <Modal.Header>
      <Header>
        Select amenities for unit
        {unitNumber}
      </Header>
      <p>Please upload all the media for this unit.</p>
    </Modal.Header>
    <Modal.Content>
      <Amenities {...amenitiesProps} />
      <Button
        type="button"
        positive
        className="modal-save-button"
        content="Done"
        onClick={onClose}
      />
    </Modal.Content>
  </Modal>
);

export default UnitAmenities;
