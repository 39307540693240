import React, { Component } from "react";
import { withApollo } from "react-apollo";
import get from "lodash/get";
import { tenantsByLocationGql, billsGql, personPaymentActionGql } from "../../store/tenants/tenants";
import { leasesGql } from '../../store/person/leases';
import { getClient } from "../../init-apollo-googleFn";
import { LEASE_MANAGER } from "../../utils/constants";
import { qpDatatables, qpTooltipPopover } from "../../utils/misc";
import { primaryLocations } from "../../store/person/properties";
import { telephoneFormatter } from "../../utils/common";
import { PageLoader } from "../../components/Loader/PageLoader";
import { Table } from "../../components/Tenants/TenantsTableData";
import { FAKE_LEASE, ALL } from "../../utils/constants";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TenantKeyModal } from "../../components/Modals/TenantKeyModal";
import "./Tenants.scss";
import mixpanel from 'mixpanel-browser';

const newClient = getClient(LEASE_MANAGER)

class Tenants extends Component {

  constructor(props) {
    super(props)
    this.state = {
      lease: FAKE_LEASE,
      filter: { onlyCurrentTenants: true },
      tenantsCache: [
      
      ],
      primaryLocations: [],
      loading: false,
      message: "",
      selectedPrimary: this.props.selectedPrimary ? this.props.selectedPrimary.node : '',
      tenant: {},
      leaseHistory: {},
      paid: "",
      billId: "",
      transBillId: "",
      refundAmount: "",
      transaction: []
    }
  }

  componentDidMount() {
    qpDatatables();
    qpTooltipPopover();
    this.getTenants();

    mixpanel.track('Manager Page Load', {
      'sub': 'Tenants'
     });

  }


  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary ? prevProps.selectedPrimary.node : '';
    if (nodeData !== this.state.selectedPrimary) {
      this.setState({ selectedPrimary: nodeData }, () => {
        this.getTenants();
      })
    }
  }
  componentDidUpdate(props) {
    if (props.selectedPrimary.node !== this.state.selectedPrimary){
      this.setState({selectedPrimary:this.props.selectedPrimary.node})
      this.getTenants();
    }
  }

  setModalTenant = (lease) => this.setState({ lease })
  // Get Tenants
  getTenants = () => {
    this.setState({ loading: true })
    try {
      newClient.query({
        query: leasesGql,
        variables: {
          locationId: this.props.selectedPrimary && this.props.selectedPrimary.node.id,
        }
      }).then(response => {
        const Tenants = response.data.leases.edges
        const tenantFilter = Tenants.filter(node =>node.node.status === "move_in")
        this.setState({ tenantsCache: tenantFilter,loading: false })
      }).catch(error => {
        this.setState({ loading: false })
        // this.setMessage(parseGraphQLErrors(error), true)
      });
    } catch (e) {
      console.log(e);
    }
  }



  filteredTenants = () => {
    const { filter, currentLocation, tenantsCache } = this.state

    const { ndbId } = currentLocation


    const filteredTenantData = filter.onlyCurrentTenants ? tenantsCache[ndbId].current : filter.onlyPastTenants ? tenantsCache[ndbId].past : [...tenantsCache[ndbId].past, ...tenantsCache[ndbId].current]
    return filteredTenantData
  }

  getPrimaryLocations = () => {
    this.props.primaryLocationClient.query({
      query: primaryLocations
    })
      .then(res => {
        const prime = get(res, "data.primaryLocations.edges", null);
        this.setState({
          primaryLocations: prime,
          currentLocation: prime[0]
        })
        this.setLocation(1)
      })
      .catch(err => {
        console.log(err)
      })
  }

  setLocation = (locationIndex = ALL) => {
    const location = this.state.primaryLocations[locationIndex - 1]
    this.setState({ currentLocation: locationIndex !== ALL ? location : { name: 'All' } })
    // if (!(location.node.ndbId in tenantsCache)) 
    this.getTenants(location.node.ndbId);
  }

  getPaymentHistory = () => {
    const { billId } = this.state
    this.setState({ loading: true })
    this.props.invoicesClient.query({
      query: billsGql,
      variables: {
        role: 'manager',
        leaseId: billId
      }
    })
      .then(res => {
        if (res.data.bills) {
          this.setState({ leaseHistory: res.data.bills, loading: false })
        }
        else {
          this.setState({ leaseHistory: ["No Bills"], loading: false })
        }
      })
      .catch(error => {
        this.setState({ loading: false })
        console.log(error)
      })
  }




  getPaymentHistory = () => {
    const { billId } = this.state
    this.setState({ loading: true })
    this.props.invoicesClient.query({
      query: billsGql,
      variables: {
        role: 'manager',
        leaseId: billId
      }
    })
      .then(res => {
        if (res.data.bills) {
          this.setState({ leaseHistory: res.data.bills, loading: false })
        }
        else {
          this.setState({ leaseHistory: ["No Bills"], loading: false })
        }
      })
      .catch(error => {
        this.setState({ loading: false })
        console.log(error)
      })
  }

  getId = (locationNdbId, filter = this.state.filter) => {
    // const { currentLocation } = this.state
    // if (!currentLocation || !currentLocation.ndbId) return
    this.setState({ loading: true })
    this.props.leaseClient.query({
      query: tenantsByLocationGql,
      variables: {
        primaryLocationId: locationNdbId,
        filter: filter
      }
    }).then(res => {
      const billId = res.data.tenants.edges[0] ? res.data.tenants.edges[0].node.ndbId : []
      this.getPaymentHistory(billId)
    })
  }
  fail = () => toast.error('Charge has already been fully refunded!', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  unable = () => toast.error('Alert: Amount exceeds possible transaction amount!', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  selectedTrans = (transaction) => this.setState({ transaction })
  selectedBill = (transBillId) => this.setState({ transBillId })
  handleChange = (e) => {
    const custom = parseFloat(e.target.value).toFixed(2)
    this.setState({ refundAmount: custom })
  }
  submitRefund = () => {
    const { transaction, transBillId, refundAmount } = this.state
    if (refundAmount === "" || transBillId.paid === 0 || refundAmount > parseFloat(transaction.amount)) {
      return this.unable()
    }
    if (transaction.trans_type === "CCCHAR") {
      this.setState({ loading: true })
      this.props.personClient.mutate({
        mutation: personPaymentActionGql,
        variables: {
          input: {
            transactionId: transaction.transaction_reference.transaction_id,
            amount: parseFloat(refundAmount).toFixed(2),
            transType: "cc_refund",
            actionType: "lease",
            billNdbId: transBillId.ndbId
          }
        }
      })
        .then(results => {
          if (results.data.personPaymentAction.response.GatewayException) {
            this.fail()
          }
          this.setState({ loading: false })
          setTimeout(() => {
            this.getPaymentHistory()
          }, 1000);
        })
        .catch(e => {
          console.log(e)
          alert(e)
        })
    }
    else {
      this.props.personClient.mutate({
        mutation: personPaymentActionGql,
        variables: {
          input: {
            transactionId: transaction.transaction_reference.transaction_id,
            amount: parseFloat(refundAmount).toFixed(2),
            transType: "ach_refund",
            actionType: "lease",
            billNdbId: transBillId.ndbId
          }
        }
      })
        .then(results => {
          if (results.data.personPaymentAction.response.GatewayException) {
            this.fail()
          }
          this.setState({ loading: false })
          setTimeout(() => {
            this.getPaymentHistory()
          }, 1000);
        })
        .catch(e => {
          console.log(e)
          alert(e)
        })
    }
  }

  phoneFormatter = (data) => {
    return telephoneFormatter(data._cell.value)
  }

  rentFilter = (headerValue, rowValue, rowData, filterParams) => {
    const headerInt = parseInt(headerValue)
    const valueInt = parseInt(rowValue)

    switch (headerValue) {
      //All
      case '0':
        return true

      //Selections with 500 increments
      case '500':
      case '1000':
      case '1500':
      case '2000':
      case '2500':
      case '3000':
        if (valueInt < headerInt && valueInt >= headerInt - 500) return true
        break;

      //Selections with 1000 increments
      case '4000':
      case '5000':
      case '6000':
      case '7000':
      case '8000':
        if (valueInt < headerInt && valueInt >= headerInt - 1000) return true
        break;


      // Selections with 2000 increments
      case '10000': {
        if (valueInt < headerInt && valueInt >= headerInt - 2000) return true
        break;
      }

      // Selections with +
      case '10001': {
        if (valueInt > headerInt) return true
        break;
      }

      default:
        return false;
    }
  }

  viewIconFormatter = () => {
    return `<i class="fa fa-eye action-icon" title="View" />`
  }

  setTenant = (tenant) => this.setState({ tenant })

  walkIconFormatter = () => {
    return `<i class="fa fa-binoculars action-icon" title="Walkthrough" />`
  }

  keyIconFormatter = () => {
    return `<i class="fa fa-key action-icon" title="Key" />`
  }

  openKeyModal = (e, cell) => {
    document.getElementById("tenantKey-modal-btn").click()
  }

  redirectToView = (item) => {
    const customId = item.node.id
    const applicationId = item.node.applicationId
    this.props.history.push({
      pathname: "/tenantDetail/tenantId=" + customId + "/applicationId=" + applicationId,
      state: { item },
      data: item
    })
  }


  render() {
    const { tenant, loading } = this.state
    return (
      loading ? (
        <PageLoader text active style={{ marginTop: "200px" }} inline='centered' />
      ) : (
        <main className="main-content" role="main">
          <div className="roll-container">
            <div className="row mb-4">
              <div className="col-md-12">
                <span className="heading heading-md">Residents</span>
                <h6>Here’s the list of your added residents</h6>
              </div>
            </div>
            <Table
              tenantsCache={this.state}
              view={this.redirectToView}
              refreshTable={this.refreshTable}
              getRequest={this.getMaintenanceRequests}
              cancel={this.openCancelModal}
            />
          </div>
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
          />
          {(tenant ) &&
            <TenantKeyModal/>
          }
          <button id="tenant-modal-btn" className="invisible" data-target="#TenantModal" data-toggle="modal"  ></button>
          <button id="tenantKey-modal-btn" className="invisible" data-target="#tenantKeyModal" data-toggle="modal"></button>
        </main>
      )
    );
  }
}

export default withApollo(Tenants)
