import React, { useState } from "react";
import {
  Button,
  Modal,
  Icon,
  Header,
  Form,
} from "semantic-ui-react";
import { getClient } from "../../init-apollo-googleFn";
import "./moveLeadModal.scss";
import {
  CreateLedgerMutation,
  UpdateLedgerMutation,
} from "../../store/person/accounting";
import mixpanel from 'mixpanel-browser';
import { ACCOUNTING, LEDGER_TYPES, DEFAULT_LEDGER } from "../../utils/constants";

const chargeClient = getClient(ACCOUNTING);

export default function LedgerCreationAccounting(props) {
  const [open, setOpen] = useState(false);
  const [name, nameCode] = useState(false);
  const [amount, amountCode] = useState(false);
  const [description, descCode] = useState(false);
  const [loading, setLoader] = useState(false);
  const [subType, inputSub] = useState(false);
  const [ledgerType, ledgerTypeCode] = useState(false);
  const [defaultType, ledgerDefaultCode] = useState(false);
  // const [balanceType, balanceTypeCode] = useState(false);



  const createCharge = (e) => {
    setLoader(true);
   
    e.preventDefault();
    if (props.props) {
      try {
        mixpanel.track('Manager Accounting Action', {
          'sub': 'Update Ledger'
        });
        chargeClient
          .mutate({
            mutation: UpdateLedgerMutation,
            variables: {
              input: {
                location: props.propertyId,
                name: name,
                description: description,
                id: props.props && props.props._id,
              },
            },
          })
          .then((response) => {
            setLoader(false);
            props.success()
            props.updateLedger(response);
            setOpen(false);
          }) 
          .catch((error) => {
            setLoader(false);
            props.failure()
            // setOpen(false);
          });
      } catch (e) {alert(e)}
    } else {
      try {
        mixpanel.track('Manager Accounting Action', {
          'sub': 'Create Ledger'
        });
        chargeClient.mutate({
          mutation: CreateLedgerMutation,
          variables: {
            input: {
              location: props.propertyId,
              name: name,
              description: description,
              subtype: subType,
              type: ledgerType,
              default:defaultType,
              number: amount,
            },
          },
        }).then((response) => {
          props.success()
          setLoader(false);
          if (
            response.data.createSlLedgerAccount.response ===
            "number must be unique"
          ) {
            setOpen(false);
          } else {
            props.dataPush(response);
            setOpen(false);
          }
        }).catch((error) => {
          setLoader(false);
          setOpen(false);
        });
      } catch (e) {alert(e)}
    }
  };

  const editCheck = () => {
    nameCode("");
    amountCode("")
    descCode("");
    inputSub("");
    ledgerTypeCode("");
    ledgerDefaultCode("")
    if (props.props) {
      amountCode(props.props.number)
      nameCode(props.props.name);
      descCode(props.props.description);
      inputSub(props.props.subtype);
      ledgerTypeCode(props.props.type);
      // balanceTypeCode(props.props.naturalBalance);
      // amountCode(props.props.node.amountDefault);
      // chargeTypeCode(props.props.node.type);
    }
  };

  return (
    <Modal
      className="semanticModal modal-ui modal-radius overflow-y-visible"
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button onClick={editCheck} icon>
          <Icon name="add circle" />
        </Button>
      }
    >
      <Modal.Header
        textAlign="left"
        className="modal-header-bg position-sticky"
      >
        <Header
          className="modal-heading-custom position-sticky"
          textAlign="left"
        >
          {props.props ? "Update Ledger Account" : "Create Ledger Account"}
        </Header>
      </Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          <Form color="blue">
            <Form.Field required>
              <label>Name</label>
              <input
                value={name ? name : ""}
                onChange={(e) => nameCode(e.target.value)}
              />
            </Form.Field>
            <Form.Field required>
              <label>Desciption</label>
              <input
                value={description ? description : ""}
                onChange={(e) => descCode(e.target.value)}
              />
            </Form.Field>
            {!props.props && (
              <>
                <Form.Field required>
              <label>Id</label>
              <input
              type="number"
              style={{width:"94%"}}
                value={amount ? amount : ""}
                onChange={(e) => amountCode(e.target.value)}
              />
            </Form.Field>
                <Form.Field required>
                  <label>Sub Type</label>
                  <input
                    value={subType ? subType : ""}
                    onChange={(e) => inputSub(e.target.value)}
                  />
                </Form.Field>
                <Form.Field required>
                  <label>Type</label>
                  <select
                    className="form-control"
                    // disabled={props.props}
                    id="leaseLength"
                    onChange={(e) => ledgerTypeCode(e.target.value)}
                  >
                    <option value=""></option>
                    <option value={LEDGER_TYPES.Asset}>Asset</option>
                    <option value={LEDGER_TYPES.Liability}>Liability</option>
                    <option value={LEDGER_TYPES.Equity}>Equity</option>
                    <option value={LEDGER_TYPES.Revenue}>Revenue</option>
                    <option value={LEDGER_TYPES.Expense}>Expense</option>
                  </select>
                </Form.Field>
                <Form.Field required>
                  <label>Default Ledger</label>
                  <select
                    className="form-control"
                    id="leaseLength"
                    onChange={(e) => ledgerDefaultCode(e.target.value)}
                  >
                    <option value=""></option>
                    <option value={DEFAULT_LEDGER.Receivables}>Receivables</option>
                    <option value={DEFAULT_LEDGER.Income}>Income</option>
                    <option value={DEFAULT_LEDGER.Other}>Other</option>
                    <option value={DEFAULT_LEDGER.Undeposited}>Undeposited</option>
                    <option value={DEFAULT_LEDGER.Deposit}>Deposit</option>
                    <option value={DEFAULT_LEDGER.Bank}>Bank</option>
                    <option value={DEFAULT_LEDGER.Payable}>Payable</option>
                    <option value={DEFAULT_LEDGER.Expenses}>Expenses</option>
                    <option value={DEFAULT_LEDGER.Tax}>Tax</option>
                  </select>
                </Form.Field>
              </>
            )}
            {props.props && (
              <>
                <Form.Field>
                  <div style={{ fontWeight: "bold", display: "inline-block" }}>
                    Type:
                  </div>
                  <div style={{ display: "inline-block" }}>
                    {" "}
                    {props.props.type.charAt(0) +
                      props.props.type.substring(1).toLowerCase()}
                  </div>
                </Form.Field>
                <Form.Field>
                  <div style={{ fontWeight: "bold", display: "inline-block" }}>
                    Natural Balance:
                  </div>
                  <div style={{ display: "inline-block" }}>
                    {" "}
                    {props.props.naturalBalance.charAt(0).toUpperCase() +
                      props.props.naturalBalance.slice(1)}
                  </div>
                </Form.Field>
              </>
            )}
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="modal-close-button"
          content="Close"
          onClick={() => setOpen(false)}
          positive
        />
        {loading ? (
          <Button loading>Loading</Button>
        ) : (
          <>
            {/* eslint-disable */}
            <Button
              className="modal-save-button"
              content={props.props ? "Update" : "Create"}
              disabled={
                name && !name.trim() || description && !description.trim()||subType && !subType.trim() || ledgerType && !ledgerType.trim() || !props.props && !defaultType||
                !name || !description || !subType || !ledgerType 
              }
              onClick={createCharge}
              positive
            />
            {/* eslint-enable */}
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
}
