import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from 'mixpanel-browser';
import { ChargeAccounting } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from '../../components/Loader/Loader';
import { Table } from "../../components/Tables/ChargeData";
import ChargeCodeModal from "../../components/Modals/ChargeCodeModal";
import { ACCOUNTING } from "../../utils/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button} from "semantic-ui-react";
const chargeClient = getClient(ACCOUNTING);

class Charge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      chargeCache: [],
    };
  }

  componentDidMount() {
    this.getLedger();
    mixpanel.track('Manager Page Load', {
      'sub': 'Charge'
     });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }

  getLedger = () => {
    this.setState({ loading: true });
    try {
      chargeClient
        .query({
          query: ChargeAccounting,
          variables: {
            location: this.props.selectedPrimary.node.id
          },
        })
        .then((res) => {

          if (res.data) {
            this.setState({
              chargeCache: get(res, "data.chargeCodes.edges", []),
            });
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  dataPush = (response) => {
    const node = { node: response.data.createChargeCode.chargeCode };
    this.setState((prevState) => ({
      chargeCache: [node, ...prevState.chargeCache],
    }));
  };

  updateInvoice = (update, chargeId) => {
    const { chargeCache } = this.state;
    const array = [];
    if (update === "Delete") {
      this.setState({
        chargeCache: chargeCache.filter(
          charge => charge.node.id !== chargeId
        ),
      });
    } else {
      chargeCache.forEach((charge) => {
        if (charge.node.id === update.data.updateChargeCode.chargeCode.id) {
          charge = {node:update.data.updateChargeCode.chargeCode};
          array.push(charge);
        } else {
          array.push(charge);
        }
      });
      this.setState({ chargeCache: array });
    }
  };


  render() {
    const { loading } = this.state;
    const success = () => toast.success('Success!', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return (
        loading ? (
          <Loader text inTable/>
        ) : (
          <>
            <main className="main-content" role="main">
              <div className="roll-container">
              <ChargeCodeModal
                updateInvoice={this.updateInvoice}
                success={success}
                dataPush={this.dataPush}
                propertyId={this.props.selectedPrimary.node&&this.props.selectedPrimary.node.id}
              />
              <Button style={{paddingTop:'0px', paddingBottom:"0px", height:"35px", marginTop:'5px'}} className="noPrint" onClick={() => window.print()}>Print</Button>
              <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
              />
              <Table
                updateInvoice={this.updateInvoice}
                chargeCache={this.state.chargeCache}
                propertyId={this.props.selectedPrimary.node&&this.props.selectedPrimary.node.id}
              />
            </div>
          </main>
        </>
      )
    );
  }
}

export default withApollo(Charge);
