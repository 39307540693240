import React from 'react';
import { Query } from 'react-apollo';
import { adopt } from 'react-adopt';
import get from 'lodash/get';
import mixpanel from 'mixpanel-browser';
import { applicationGql } from '../../store/person/applications';
import { personPetsGql, personDependentsGql } from '../../store/person/person';
import TenantsPersonalInfo1 from '../../components/Tenants/TenantsPersonalInfo1';
import ApplicationHist from '../../components/Tenants/ApplicationHist';
import ApplicationPropertyCard from '../../components/Tenants/ApplicationPropertyCard';
import { ROUTES } from '../../utils/constants';


export const ApplicantDetail = (props) => {
  const Composed = adopt({
    petsQuery: ({ render }) => (
      <Query query={personPetsGql} variables={{ id: props.match.params.tenantId }}>
        {render}
      </Query>
    ),
    dependentQuery: ({ render }) => (
      <Query query={personDependentsGql} variables={{ id: props.match.params.tenantId }}>
        {render}
      </Query>
    ),
    applicationQuery: ({ render }) => (
      <Query query={applicationGql} variables={{ id: props.match.params.applicationId }}>
        {render}
      </Query>
    ),
  });

  /* eslint-disable */
  React.useEffect(() => {
    mixpanel.track('Manager Page Load', {
      ndbId: props.user.ndbId,
      sub: 'Application Detail',
    });
  }, []);
  /* eslint-enable */

  return (
    <Composed>
      {({ applicationQuery }) => {
        const applicationDetail = get(applicationQuery, 'data.application.edges', [])[0];
        let property = {}; let
          application = {};
        if (applicationDetail) {
          property = applicationDetail.node.property;
          application = applicationDetail.node.application;
        }

        return (
          <main className="main-content p-4" role="main">
            <button type="button" className="btn btn-with-icons btn-primary" title="Back" onClick={() => props.history.push(ROUTES.applications)}>
              <i className="batch-icon batch-icon-arrow-left" />
            </button>
            <div className="applicant-detail-container">
              {applicationDetail && (
              <>
                <div className="row mb-4">
                  <div className="col-md-12 mb-4">
                    <ApplicationPropertyCard property={property} />
                  </div>
                  <div className="col-md-12">
                    <TenantsPersonalInfo1 application={application} />
                  </div>
                </div>
                <ApplicationHist application={application} />
              </>
              )}
            </div>
          </main>
        );
      }}
    </Composed>
  );
};
