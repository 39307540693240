import React from 'react';
import { pluralize } from '../../../utils/common';

const UnitCard = ({ unit, unitImg, onClick }) => (
  <div className="small-card-block cursor-pointer" onClick={onClick}>
    <div className="top-chip">{unit.status}</div>
    <div className="img-container">
      <img src={unitImg || 'assets/img/renter-bg.jpg'} alt="unit-img" />
    </div>
    <div>
      <p className="normal-text">{`Unit No: ${unit.number}`}</p>
      <p className="bold-text">{`$${unit.price.toLocaleString()} /month`}</p>
      <div className="small-text">
        <span>{`${Number(unit.bedrooms)} ${pluralize(unit.bedrooms, 'Bed')}`}</span>
        {' '}
        <div className="dot" />
        <span>{`${Number(unit.bathrooms)} ${pluralize(unit.bathrooms, 'Bath')}`}</span>
        {' '}
        <div className="dot" />
        {' '}
        <span>{`${Number(unit.sqft)} sq. ft.`}</span>
      </div>
    </div>
  </div>
);

export default UnitCard;
