import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from 'mixpanel-browser';
import get from "lodash/get";
import { qpDatatables, qpTooltipPopover } from "../../utils/misc";
import "./Settings.scss";
import { PageLoader } from "../../components/Loader/PageLoader";
import TourCreation from "../../components/Modals/TourCreationModal";
import { Header, Card, Segment } from "semantic-ui-react";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      primaryLocations: [],
      loading: false,
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      allApps: [],
      isFirstRender: true,
    };
  }

  componentDidMount() {
    // this.getLocations();
    qpDatatables();
    qpTooltipPopover();
    mixpanel.track('Manager Page Load', {
      'sub': 'Settings'
     });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
      });
    }
  }
  setLocation() {
    this.setState({ primary: this.props.selectedPrimary }, () => {});
  }

  componentWillUnmount() {
    this.setState({
      isFirstRender: true,
    });
  }


  setTitle = (data) => {
    if (data) {
      const prime = get(data, "data.primaryLocations.edges", null);
      this.setState({
        primaryLocations: prime,
        allApps: prime,
        isFirstRender: false,
      });
    }
  };

  handleChange(e) {
    let currentList = [];
    let newList = [];
    // If the search bar isn't empty
    if (e.target.value !== "") {
      // Assign the original list to currentList
      currentList = this.state.allApps;
      newList = currentList.filter((item) => {
        const lc = item.node.property.location.name.toLowerCase();
        const filter = e.target.value.toLowerCase();
        return lc.includes(filter);
      });
    } else {
      newList = this.state.allApps;
    }
    this.setState({
      primaryLocations: newList,
    });
  }

  redirectToLeases(id) {
    this.props.history.push(`/leases/${id}`);
  }

  redirectToProperty() {
    this.props.history.push({
      pathname:
        "/manageLocation/location=" +
        this.props.selectedPrimary.node.customId,
    })
  }

  render() {
    const { primary } = this.state;
    return (
      <>
        {!this.props.selectedPrimary.node ? (
          <PageLoader text/>
        ) : (
          <>
            <Header>Property Settings for {primary.name}</Header>
            <Segment>
              <Card.Group itemsPerRow="4">
                <TourCreation primary={primary} />

                {/* <Card
                  disabled
                  onClick={() => this.props.history.push(`/manageLocation/location=` + primary.customId)}
                  style={{ border: "2px solid" }}
                >
                  <Card.Content>
                    <Card.Header textAlign="center">Edit Property</Card.Header>
                    <Card.Meta textAlign="center">
                      Edit current property
                    </Card.Meta>
                  </Card.Content>
                </Card> */}
                
                {/* <Card
                  disabled
                  onClick={() => this.props.history.push(`/userManagement/location=` + primary.customId)}
                  style={{ border: "2px solid" }}
                >
                  <Card.Content>
                    <Card.Header textAlign="center">User Management</Card.Header>
                    <Card.Meta textAlign="center">
                      Manage your users
                    </Card.Meta>
                  </Card.Content>
                </Card> */}

                {/* <Card
                  disabled
                  onClick={() => this.props.history.push(`/addProperty`)}
                  style={{ border: "2px solid" }}
                >
                  <Card.Content>
                    <Card.Header textAlign="center">Add Unit</Card.Header>
                    <Card.Meta textAlign="center">
                      Create new units on current property
                    </Card.Meta>
                  </Card.Content>
                </Card> */}
              </Card.Group>
            </Segment>
            {/* <Header>General Settings</Header> */}
            {/* <Segment> */}
              {/* <Card.Group> */}
                {/* <NewAppConnection /> */}
                {/* <Card
                  disabled
                  onClick={() => this.props.history.push(`/manageLocation`)}
                  style={{ border: "2px solid" }}
                >
                  <Card.Content>
                    <Card.Header textAlign="center">Add Property</Card.Header>
                    <Card.Meta textAlign="center">Add a new property</Card.Meta>
                  </Card.Content>
                </Card> */}

                {/* <Card
                  disabled
                  onClick={() => this.props.history.push(`/addProperty`)}
                  style={{ border: "2px solid" }}
                >
                  <Card.Content>
                    <Card.Header textAlign="center">Help</Card.Header>
                    <Card.Meta textAlign="center">Help articles</Card.Meta>
                  </Card.Content>
                </Card> */}
              {/* </Card.Group> */}
            {/* </Segment> */}
           
          </>
        )}
      </>
    );
  }
}

export default withApollo(Settings);
