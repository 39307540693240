import moment from 'moment';
import React, { Component } from 'react';
import Datetime from "react-datetime";
import NumberFormat from 'react-number-format';
import "react-datetime/css/react-datetime.css";
import PlacesAutocomplete from 'react-places-autocomplete';
import { Accordion, Button, Checkbox, Form, Icon, Segment, Message } from 'semantic-ui-react';
import {  handleArrayPlacesSelect, ageValidation } from "../../utils/common";
import { DATE_FORMAT, MARITAL_STATUS, US_STATES, EMAIL_REGEX, PHONE_LEAD, NUMBERS } from '../../utils/constants';
import './Applications.scss';
import toaster from '../../utils/toaster';
import placesAutoCompleteData from '../../utils/placesAutoComplete';

const ID_TYPES = [
  { key: 'dl', text: 'Driving Licence', value: 'DrivingLicence' },
  { key: 'govtId', text: 'Government Id', value: 'GovernmentId' }
];

class CoApplicants extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      enableCheckbox: false,
      applicantIndex: 0,
      statesList: [],
      address: this.props.application,
      setToSelectedAddress: false,
      errors: []
    }

    window.scrollTo(0, 0);
  }

  componentDidMount() {
    this.statesListing();
  }

  /* function to set the states values taking US_STATES from the constants file */
  statesListing() {
    let list = [];
    Object.keys(US_STATES).map((key) => {
      list.push({ key: US_STATES[key], text: key, value: key.toLowerCase() })
      return null
    })
    this.setState({ statesList: list });
  }

  handleAddress = (address, parentIndex, parentKey, index, setAddress) => {
    this.setState({ setToSelectedAddress: false });
    // if (index === undefined) {
    //   const parentArr = [...this.state.address];
    //   parentArr[parentIndex] = {
    //     ...parentArr[parentIndex], [parentKey]: {
    //       ...parentArr[parentIndex][parentKey],
    //       address: { ...parentArr[parentIndex][parentKey].address, streetLine1: address }
    //     }
    //   };
    //   this.setState({ address: parentArr });
    // } else {
    //   const newArr = [...this.state.address[parentIndex][parentKey]];
    //   const parentArr = [...this.state.address];
    //   newArr[index] = { ...newArr[index], address: { ...newArr[index].address, streetLine1: address } }
    //   parentArr[parentIndex] = { ...parentArr[parentIndex], [parentKey]: newArr }
    //   this.setState({ address: parentArr });
    // }
    setAddress('streetLine1', address, parentIndex, index, parentKey, 'address')
  }

  /* function calls the setAddressFields of the parent component*/
  setAddressFields = (data, index, parentKey, functionToCall, type) => {
    this.setState({ setToSelectedAddress: true })
    this.props.setAddressFields(data, index, parentKey, functionToCall, type);
  }

  /* function to validate the to date to be greater than from date */
  isValid = (current, index, type, nestedIndex) => {
    const { application } = this.props;
    return current.isAfter(moment(application[index][type][nestedIndex].startDate))
  }

  /* function to validate the next address start date to be less than the previous address start date */
  isNextAddressDateValid = (current, index, type, nestedIndex) => {
    const { application } = this.props;
    let lastIndexStartDate;
    if (nestedIndex > 0) {
      lastIndexStartDate = moment(application[index][type][nestedIndex - 1].startDate);
      return current.isBefore(lastIndexStartDate)
    } else {
      return true;
    }
  }

  /* function to validate the address fields */
  addressValidation = (type) => {
    const { application } = this.props;
    let valueToReturn = true;
    if (type === 'employmentHistory') {
      application.forEach((ele) => {
        ele.isFinanciallyResponsible && ele[type].forEach((item) => {
          if (item.address.city === '' || item.address.state === '' || item.address.postcode === '') {
            valueToReturn = false;
          }
        })
      })
    } else if (type === 'rentalHistory') {
      application.forEach((ele) => {
        ele[type].forEach((item) => {
          if (item.address.city === '' || item.address.state === '' ||
            item.address.postcode === '' || item.address.streetLine1 === '') {
            valueToReturn = false;
          }
        })
      })
    }
    return valueToReturn;
  }

  coApplicantValidation = () => {
    const { application } = this.props;
    let valueToReturn = true;
    application.forEach((ele) => {
      /* eslint-disable */
      if (ele.firstName === '' || ele.lastName === '' || ele.email === '' || ele.dateOfBirth === ''
        || ele.drivingLicence && ele.drivingLicence.issuingState === '' || ele.phoneMobile === ''
        || ele.drivingLicence && ele.drivingLicence.number === ''
        || ele.drivingLicence && ele.drivingLicence.idType === '') {
        valueToReturn = false;
      }
      /* eslint-enable */
    })
    return valueToReturn;
  }

  emailApplicantValidation = () => {
    const { application } = this.props;
    let valueToReturn = true;
    application.forEach((ele) => {
      if (!EMAIL_REGEX.test(ele.email)) {
        valueToReturn = false;
      }
    })
    return valueToReturn;
  }

  /* function to check for invalid email */
  checkForInvalidEmail = (type, label) => {
    const { application } = this.props;
    let valueToReturn = true;
    application.forEach((ele) => {
      ele[type].forEach((item) => {
        if (item[label] !== '' && !EMAIL_REGEX.test(item[label])) {
          valueToReturn = false;
        }
      })
    })
    return valueToReturn;
  }

  /* function to check for invalid phone */
  checkForInvalidPhone = (type, label) => {
    const { application } = this.props;
    let valueToReturn = true;
    application.forEach((ele) => {
      ele[type].forEach((item) => {
        if (item[label] !== '' && !PHONE_LEAD.test(item[label])) {
          valueToReturn = false;
        }
      })
    })
    return valueToReturn;
  }

  verificationNoValidation = () => {
    const { application } = this.props;
    let valueToReturn = true;
    application.forEach((ele) => {
      if (ele.verificationNumber && (!ele.verificationNumber.charAt(ele.verificationNumber.length - 1).match(NUMBERS) || ele.verificationNumber.length < 11)) {
        valueToReturn = false;
      }
    })
    return valueToReturn;
  }

  /* function to validate the employment history validation */
  employmentValidation = () => {
    const { application } = this.props;
    let valueToReturn = true;
    application.forEach((ele) => {
      ele.isFinanciallyResponsible && ele.employmentHistory.forEach((item) => {
        if (item.title === '' || item.employerName === '' || item.monthlyIncome === '' || item.supervisorName === ''
          || item.startDate === '' || (!item.isCurrentlyWorking && item.endDate === '')) {
          valueToReturn = false;
        }
      })
    })
    return valueToReturn;
  }

  /* function to validate the employment history validation */
  rentalValidation = () => {
    const { application } = this.props;
    let valueToReturn = true;
    application.forEach((ele) => {
      ele.rentalHistory.forEach((item) => {
        if (item.landlordName === '' || item.startDate === '' || (!item.isCurrentlyResiding && item.endDate === '')) {
          valueToReturn = false;
        }
      })
    })
    return valueToReturn;
  }

  checkForNumericIncome = (type, field) => {
    const { application } = this.props;
    let valueToReturn = 1;
    application.forEach((ele) => {
      ele[type] && ele[type].forEach((item) => {
        /* eslint-disable */
        if (!/^(-?\d+\,){0,4}(-?\d+)$/.test(item[field]) && item[field] !== '' && item[field] !== null && item[field]) {
          valueToReturn = 0;
        }
        /* eslint-enable */
      })
    })
    return valueToReturn;
  }


  /* function to validate the employment history validation */
  numericIncome = () => {
    if (this.checkForNumericIncome('employmentHistory', 'monthlyIncome') === 0) {
      return false;
    }
    return true;
  }

  /* function to validate the employment history validation */
  rentalNumericIncome = () => {
    if (this.checkForNumericIncome('rentalHistory', 'monthlyRent') === 0) {
      return false;
    }
    return true;
  }

  otherIncomeNumericIncome = () => {
    if (this.checkForNumericIncome('otherIncomes', 'monthlyIncome') === 0) {
      return false;
    }
    return true;
  }

  /* function that validates the data captured, if data is valid,
  it calls the handlesubmit function to integrate the mutation */
  validateAndSubmit = async (e) => {
    e.preventDefault();
    const { application, updateFormSteps } = this.props;
    const isValidRentalHistory = this.dateValidation('rentalHistory');
    let isValidEmploymentHistory = undefined;
    if (application[this.state.applicantIndex].isFinanciallyResponsible) {
      isValidEmploymentHistory = this.dateValidation('employmentHistory');
    }
    let updatedErrors = [];
    if (!this.coApplicantValidation()) {
      updatedErrors.push({field: 'Co-Applicant Details', message: 'Please fill all required co-applicant details'});
    }
    if (!this.emailApplicantValidation()) {
      updatedErrors.push({field: 'Email', message: 'Please enter a valid email'});
    }
    if (!this.checkForInvalidEmail('rentalHistory', 'landlordEmail')) {
      updatedErrors.push({field: 'Landlord Email', message: 'Please enter a valid email'});
    }
    if (!this.checkForInvalidEmail('employmentHistory', 'supervisorEmail')) {
      updatedErrors.push({field: 'Supervisor Email', message: 'Please enter a valid email'});
    }
    if (!this.checkForInvalidPhone('rentalHistory', 'landlordPhone')) {
      updatedErrors.push({field: 'Landlord Phone', message: 'Please enter a valid phone number'});
    }
    if (!this.checkForInvalidPhone('employmentHistory', 'supervisorPhone')) {
      updatedErrors.push({field: 'Supervisor Phone', message: 'Please enter a valid phone number'});
    }
    if (!this.addressValidation('rentalHistory')) {
      updatedErrors.push({field: 'Rental History', message: 'Please fill all the required address fields'});
    }
    if (!this.addressValidation('employmentHistory')) {
      updatedErrors.push({field: 'Employment History', message: 'Please fill all the required address fields'});
    }
    if (!this.rentalValidation()) {
      updatedErrors.push({field: 'Rental History', message: 'Please fill all the required rental details'});
    }
    if (!this.employmentValidation()) {
      updatedErrors.push({field: 'Employment History', message: 'Please fill all the required employment details'});
    }
    if (!this.numericIncome() && application[this.state.applicantIndex].isFinanciallyResponsible) {
      updatedErrors.push({ field: 'Employment History', message: 'Monthly Income should only contain numeric characters' });
    } if (!this.otherIncomeNumericIncome() && application[this.state.applicantIndex].isFinanciallyResponsible) {
      updatedErrors.push({ field: 'Other Income', message: 'Monthly Income should only contain numeric characters' });
    } if (!this.rentalNumericIncome() && application[this.state.applicantIndex].isFinanciallyResponsible) {
      updatedErrors.push({ field: 'Rental History', message: 'Monthly Rent should only contain numeric characters' });
    } 
    if (typeof(isValidRentalHistory) === 'string') {
      updatedErrors.push({ field: 'Rental History', message: isValidRentalHistory })
    } 
    if (typeof(isValidEmploymentHistory) === 'string') {
      updatedErrors.push({ field: 'Employment History', message: isValidEmploymentHistory })
    }
    this.setState({ errors: updatedErrors });
    if (!updatedErrors.length) {
        updateFormSteps(3);
    }
  }

  /* function to set local address to new updated array after adding multi segments */
  setData = (arr) => {
    this.setState({ address: arr });
  }

  /* eslint-disable */
  addMultiSegments = (type, application, applicantIndex) => {
    switch (type) {
      case 'address': {
        application[applicantIndex].rentalHistory.push({
          monthlyRent: '',
          landlordName: '',
          landlordEmail: '',
          landlordPhone: '',
          startDate: '',
          endDate: '',
          address: {
            streetLine1: '',
            streetLine2: '',
            city: '',
            state: '',
            postcode: ''
          }
        })
        this.setData(application);
      }
        break;
      case 'employment': {
        application[applicantIndex].employmentHistory.push({
          title: '',
          employerName: '',
          monthlyIncome: '',
          startDate: '',
          endDate: '',
          supervisorName: '',
          supervisorEmail: '',
          supervisorPhone: '',
          address: {
            streetLine1: '',
            streetLine2: '',
            city: '',
            state: '',
            postcode: ''
          }
        })
        this.setData(application);
      }
        break;
      case 'otherIncome': {
        application[applicantIndex].otherIncomes.push({
          source: '',
          monthlyIncome: ''
        })
      }
        this.setState({ state: this.state });
    }
  }
  /* eslint-enable */

  removeSegment = (application, applicantIndex, index, type) => {
    application[applicantIndex][type].splice(index, 1);
    this.setState({ state: this.state });
  }

  removeCoApp = (application, index) => {
    const { adults, setCoAppNumber } = this.props;
    application.splice(index, 1);
    setCoAppNumber(adults - 1, 'adults')
    this.setState({ applicantIndex: 0 });
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex, applicantIndex: index })
    if (this.props.application[index].isFinanciallyResponsible) {
      this.setState({ enableCheckbox: true });
    } else {
      this.setState({ enableCheckbox: false });
    }
  }

  updateEmploymentStatus = (checked, index) => {
    this.setState({ enableCheckbox: checked, applicantIndex: index }, () => {
      this.props.setCoApplicantDirectFields('isFinanciallyResponsible', checked, index, 'coApplicants');
      this.setState({ state: this.state });
    });
  }

  dateValidation = (type) => {
    const { application } = this.props;
    const comparisonType = type === 'employmentHistory' ? 'months' : 'years';
    let msg = undefined;
    application.forEach((ele, index) => {
      if(moment(application[index][type][application[index][type].length-1].endDate)
      .diff(moment(application[index][type][0].startDate), comparisonType) < 3) {
        msg = `Please add minimum 3 ${comparisonType} of ${type}`;
      }
     })
     return msg;
  }

  renderAddressField = (index, parentKey, functionToCall, type) => (
    <PlacesAutocomplete
      style={{ opacity: "0" }}
      value={this.state.address[index][type].length > 0
        ? this.props.application[index][type][parentKey].address.streetLine1
        : ''
      }
      onChange={(e) => this.handleAddress(e, index, type, parentKey, functionToCall)}
      onSelect={(e) => handleArrayPlacesSelect(e, type, index, parentKey, functionToCall,
        (e) => this.setAddressFields(e, index, parentKey, functionToCall, type))}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) =>
        placesAutoCompleteData(getInputProps, suggestions, getSuggestionItemProps)}
    </PlacesAutocomplete>
  )

  addressGroup = (application, applicantIndex, setCoApplicantArrayFields, index) => {
    return (
      <>
        <Form className="co-applicant-form">
          <Segment className="primary-applicant-segment merge-section pt-0">
          <span><b>3 years of residence history required</b></span>
            <div className="segment-header-box d-flex align-items-center justify-content-between">
              <header className="primary-applicant-subheader">{index === 0 ? 'Current Address' : `Prior Address #${index}`}</header>
              {index > 0 && <Button negative
                onClick={() => this.removeSegment(application, applicantIndex, index, 'rentalHistory')}>
                Remove
          </Button>}
            </div>
            <Form.Group widths='equal' className="co-applicant-primary-address">
              {this.renderAddressField(applicantIndex, index, setCoApplicantArrayFields, 'rentalHistory')}
              <Form.Input
                fluid
                className="height-38"
                label='Street Line 2'
                placeholder='Street Line 2'
                value={application[applicantIndex].rentalHistory && application[applicantIndex].rentalHistory[index].address && application[applicantIndex].rentalHistory[index].address.streetLine2}
                onChange={(e) => setCoApplicantArrayFields('streetLine2', e.target.value, applicantIndex, index, 'rentalHistory', 'address')} />
              <Form.Input
                fluid
                className="height-38"
                required
                label='City'
                placeholder='City'
                value={application[applicantIndex].rentalHistory && application[applicantIndex].rentalHistory[index].address && application[applicantIndex].rentalHistory[index].address.city}
                onChange={(e) => setCoApplicantArrayFields('city', e.target.value, applicantIndex, index, 'rentalHistory', 'address')} />
            </Form.Group>
            <Form.Group widths='equal' className="unit-info-form-first-row mt-4">
              <Form.Input
                fluid
                className="height-38"
                required
                label='State'
                placeholder='State'
                value={application[applicantIndex].rentalHistory && application[applicantIndex].rentalHistory[index].address && application[applicantIndex].rentalHistory[index].address.state}
                onChange={(e) => setCoApplicantArrayFields('state', e.target.value, applicantIndex, index, 'rentalHistory', 'address')} />
              <Form.Input
                fluid
                className="height-38"
                required
                label='Postal Code'
                placeholder='Postal Code'
                value={application[applicantIndex].rentalHistory && application[applicantIndex].rentalHistory[index].address && application[applicantIndex].rentalHistory[index].address.postcode}
                onChange={(e) => setCoApplicantArrayFields('postcode', e.target.value, applicantIndex, index, 'rentalHistory', 'address')} />
              <Form.Input
                fluid
                className="height-38"
                label='Monthly Rent'
                placeholder='Monthly Rent'
                value={application[applicantIndex].rentalHistory && application[applicantIndex].rentalHistory[index].monthlyRent}
                onChange={(e) => setCoApplicantArrayFields('monthlyRent', !isNaN(Number(e.target.value)) ? Number(e.target.value) : e.target.value, applicantIndex, index, 'rentalHistory')}>
              </Form.Input>
            </Form.Group>
            <Form.Group widths='equal' className="mt-4">
              <div className="move-date required field">
                <label className="move-date-label">From Date</label>
                <Datetime
                  timeFormat={false}
                  inputProps={{ readOnly: true, placeholder: 'MM/DD/YYYY' }}
                  closeOnSelect={true}
                  value={moment(application[applicantIndex].rentalHistory && application[applicantIndex].rentalHistory[0].startDate)}
                  onChange={(e) => setCoApplicantArrayFields('startDate', moment(e).format(DATE_FORMAT), applicantIndex, index, 'rentalHistory')} />
              </div>
              {/* <Checkbox className={"height-38 "} checked={application[applicantIndex].rentalHistory && application[applicantIndex].rentalHistory[0].isCurrentlyResiding}
                  onChange={(e) => setCoApplicantArrayFields('isCurrentlyResiding', !application[applicantIndex].rentalHistory[0].isCurrentlyResiding, applicantIndex, index, 'rentalHistory')} label="Currently Residing?"></Checkbox> */}
              <div className="move-date required field">
              {application[applicantIndex].rentalHistory && !application[applicantIndex].rentalHistory[0].isCurrentlyResiding && 
                <div>
                  <label className="move-date-label">To Date</label>
                  <Datetime
                    timeFormat={false}
                    inputProps={{ readOnly: true, placeholder: 'MM/DD/YYYY' }}
                    isValidDate={(e) => this.isValid(e, applicantIndex, 'rentalHistory', index)}
                    closeOnSelect={true}
                    value={moment(application[applicantIndex].rentalHistory && application[applicantIndex].rentalHistory[index].endDate)}
                    onChange={(e) => setCoApplicantArrayFields('endDate', moment(e).format(DATE_FORMAT), applicantIndex, index, 'rentalHistory')} />
                  </div>
                }
              </div>
              <div className="field application-checkbox">
                <Checkbox className={"height-38 "} checked={application[applicantIndex].rentalHistory && application[applicantIndex].rentalHistory[0].isCurrentlyResiding}
                  onChange={(e) => setCoApplicantArrayFields('isCurrentlyResiding', !application[applicantIndex].rentalHistory[0].isCurrentlyResiding, applicantIndex, index, 'rentalHistory')} label="Currently Residing?"></Checkbox>
              </div>
              <Form.Input
                fluid
                className="height-38"
                required
                label='Landlord Name'
                placeholder='Landlord Name'
                value={application[applicantIndex].rentalHistory && application[applicantIndex].rentalHistory[index].landlordName}
                onChange={(e) => setCoApplicantArrayFields('landlordName', e.target.value, applicantIndex, index, 'rentalHistory')} />
            </Form.Group>
            <Form.Group widths='equal' className="mt-4">
              <Form.Input
                fluid
                className="height-38"
                label='Landlord Email'
                placeholder='Landlord Email'
                value={application[applicantIndex].rentalHistory && application[applicantIndex].rentalHistory[index].landlordEmail}
                onChange={(e) => setCoApplicantArrayFields('landlordEmail', e.target.value, applicantIndex, index, 'rentalHistory')}
              />
              <Form.Input
                 fluid
                 className="height-38"
                 label='Landlord Phone'
                 placeholder='Landlord Phone'>
                <NumberFormat value={application[applicantIndex].rentalHistory && application[applicantIndex].rentalHistory[index].landlordPhone} 
                onChange={(e) => setCoApplicantArrayFields('landlordPhone', e.target.value, applicantIndex, index, 'rentalHistory')} format="### ### ####"/>
              </Form.Input>
            </Form.Group>
          </Segment>
        </Form>
      </>
    )
  };

  employmentGroup = (application, applicantIndex, setCoApplicantArrayFields, index) => {
    return (<>
      <Form className="co-applicant-form">
        <Segment className="primary-applicant-segment merge-section pt-0">
        <span><b>3 months of employment history required</b></span>
          <div className="segment-header-box d-flex align-items-center justify-content-between">
            <header className="primary-applicant-subheader">{index === 0 ? 'Current Employment' : `Prior Employment #${index}`}</header>
            {index > 0 && <Button negative
              onClick={() => this.removeSegment(application, applicantIndex, index, 'employmentHistory')}>
              Remove
          </Button>}
          </div>
          <Form.Group widths='equal'>
            <Form.Input
              fluid
              className="height-38"
              required
              label='Occupation'
              value={application[applicantIndex].employmentHistory[index].title}
              placeholder='Occupation'
              onChange={(e) => setCoApplicantArrayFields('title', e.target.value, applicantIndex, index, 'employmentHistory')}
            />
            <Form.Input
              fluid
              className="height-38"
              required
              label='Employer/Company'
              placeholder='Employer/Company'
              value={application[applicantIndex].employmentHistory[index].employerName}
              onChange={(e) => setCoApplicantArrayFields('employerName', e.target.value, applicantIndex, index, 'employmentHistory')}
            />
            <Form.Input
              fluid
              className="height-38"
              label='Monthly Income'
              required
              placeholder='Monthly Income'
              value={application[applicantIndex].employmentHistory[index].monthlyIncome}
              onChange={(e) => setCoApplicantArrayFields('monthlyIncome', !isNaN(Number(e.target.value)) ? Number(e.target.value) : e.target.value, applicantIndex, index, 'employmentHistory')}>
            </Form.Input>
          </Form.Group>
          <Form.Group widths='equal' className="unit-info-form-first-row mt-4">
            <div className="move-date required field">
              <label className="move-date-label">From Date</label>
              <Datetime
                timeFormat={false}
                inputProps={{ readOnly: true, placeholder: 'MM/DD/YYYY' }}
                closeOnSelect={true}
                value={application[applicantIndex].employmentHistory[index].startDate}
                onChange={(e) => setCoApplicantArrayFields('startDate', moment(e).format(DATE_FORMAT), applicantIndex, index, 'employmentHistory')}
              />
            </div>
            {/* <Checkbox className={"height-38 "} checked={application[applicantIndex].employmentHistory && application[applicantIndex].employmentHistory[0].isCurrentlyWorking}
                  onChange={(e) => setCoApplicantArrayFields('isCurrentlyWorking', !application[applicantIndex].employmentHistory[0].isCurrentlyWorking, applicantIndex, index, 'employmentHistory')} label="Currently Working?"></Checkbox> */}
            <div className="move-date required field">
              {application[applicantIndex].employmentHistory && !application[applicantIndex].employmentHistory[0].isCurrentlyWorking && 
              <div>
                <label className="move-date-label">To Date</label>
                <Datetime
                  timeFormat={false}
                  inputProps={{ readOnly: true, placeholder: 'MM/DD/YYYY' }}
                  isValidDate={(e) => this.isValid(e, applicantIndex, 'employmentHistory', index)}
                  closeOnSelect={true}
                  value={application[applicantIndex].employmentHistory[index].endDate}
                  onChange={(e) => setCoApplicantArrayFields('endDate', moment(e).format(DATE_FORMAT), applicantIndex, index, 'employmentHistory')}
                />
              </div>
              }
            </div>
            <div className="field application-checkbox">
              <Checkbox className={"height-38 "} checked={application[applicantIndex].employmentHistory && application[applicantIndex].employmentHistory[0].isCurrentlyWorking}
                  onChange={(e) => setCoApplicantArrayFields('isCurrentlyWorking', !application[applicantIndex].employmentHistory[0].isCurrentlyWorking, applicantIndex, index, 'employmentHistory')} label="Currently Working?"></Checkbox>
            </div>
            {this.renderAddressField(applicantIndex, index, setCoApplicantArrayFields, 'employmentHistory')}
          </Form.Group>
          <Form.Group widths='equal' className="mt-4">
            <Form.Input
              fluid
              className="height-38"
              required
              label='City'
              placeholder='City'
              value={application[applicantIndex].employmentHistory[index].address.city}
              onChange={(e) => setCoApplicantArrayFields('city', e.target.value, applicantIndex, index, 'employmentHistory', 'address')}
            />
            <Form.Input
              fluid
              className="height-38"
              required
              label='State'
              placeholder='State'
              value={application[applicantIndex].employmentHistory[index].address.state}
              onChange={(e) => setCoApplicantArrayFields('state', e.target.value, applicantIndex, index, 'employmentHistory', 'address')}
            />
            <Form.Input
              fluid
              className="height-38"
              required
              label='Postal Code'
              placeholder='Postal Code'
              value={application[applicantIndex].employmentHistory[index].address.postcode}
              onChange={(e) => setCoApplicantArrayFields('postcode', e.target.value, applicantIndex, index, 'employmentHistory', 'address')}
            />
          </Form.Group>
          <Form.Group widths='equal' className="mt-4">
            <Form.Input
              fluid
              className="height-38"
              required
              label='Supervisor Name'
              placeholder='Supervisor Name'
              value={application[applicantIndex].employmentHistory[index].supervisorName}
              onChange={(e) => setCoApplicantArrayFields('supervisorName', e.target.value, applicantIndex, index, 'employmentHistory')}
            />
            <Form.Input
              fluid
              className="height-38"
              label='Supervisor Email'
              placeholder='Supervisor Email'
              value={application[applicantIndex].employmentHistory[index].supervisorEmail}
              onChange={(e) => setCoApplicantArrayFields('supervisorEmail', e.target.value, applicantIndex, index, 'employmentHistory')}
            />
            <Form.Input
              fluid
              className="height-38"
              label='Supervisor Phone'
              placeholder='Supervisor Phone'>
              <NumberFormat value={application[applicantIndex].employmentHistory[index].supervisorPhone} 
              onChange={(e) => setCoApplicantArrayFields('supervisorPhone', e.target.value, applicantIndex, index, 'employmentHistory')} format="### ### ####"/>
            </Form.Input>
          </Form.Group>
        </Segment>
      </Form>
    </>)
  }

  otherIncomes = (application, applicantIndex, setCoApplicantArrayFields, index) => (
    <Form.Group widths='equal'>
      <Form.Input
        fluid
        className="height-38"
        label={index === 0 ? 'Source - Please Describe' : ''}
        placeholder="Source of Other Income"
        value={application[applicantIndex].otherIncomes[index].source}
        onChange={(e) => setCoApplicantArrayFields('source', e.target.value, applicantIndex, index, 'otherIncomes')}
      />
      <Form.Input
        fluid
        className="height-38"
        label={index === 0 ? 'Monthly Income' : ''}
        placeholder='Monthly Income'
        value={application[applicantIndex].otherIncomes[index].monthlyIncome}
        onChange={(e) => setCoApplicantArrayFields('monthlyIncome', !isNaN(Number(e.target.value)) ? Number(e.target.value) : e.target.value, applicantIndex, index, 'otherIncomes')}>
      </Form.Input>
    </Form.Group>
  )

  dropdownGroup = (application, activeIndex, applicantIndex, setCoApplicantDirectFields,
    enableCheckbox, setCoApplicantArrayFields, noOfCoApplicants) => {
    return (<>
      <Accordion fluid styled className="custom-accordian mt-25">
        {application.map((ele, index) => (
          <>
            <Accordion.Title
              active={activeIndex === index}
              index={index}
              onClick={this.handleClick}
            >
              {`Co-Applicant ${index + 1}`}
              <Icon name='chevron right' className="mr-0" />
            </Accordion.Title>
            {/* <Button negative
              onClick={() => this.removeCoApp(application, index)}>
              Remove
            </Button> */}
            <Accordion.Content active={activeIndex === index}>
              <Form className="co-applicant-form mt-0">
                <Form.Group widths='equal'>
                  <Form.Input
                    fluid
                    className="height-38"
                    required
                    autoFocus
                    label='First Name'
                    value={application[index].firstName}
                    placeholder='First Name'
                    onChange={(e) => setCoApplicantDirectFields('firstName', e.target.value, index, 'coApplicants')} />
                  <Form.Input
                    fluid
                    className="height-38"
                    required
                    label='Last name'
                    placeholder='Last name'
                    value={application[index].lastName}
                    onChange={(e) => setCoApplicantDirectFields('lastName', e.target.value, index, 'coApplicants')} />
                  <Form.Input
                    fluid
                    className="height-38"
                    required
                    label='Email'
                    placeholder='Email'
                    disabled={index <= noOfCoApplicants - 1}
                    value={application[index].email}
                    onChange={(e) => setCoApplicantDirectFields('email', e.target.value, index, 'coApplicants')} />
                </Form.Group>
                <Form.Group widths='equal' className="mt-4">
                  <Form.Input
                    fluid
                    className="height-38"
                    required
                    label='Mobile Phone'
                    placeholder='Mobile Phone'>
                    <NumberFormat value={application[index].phoneMobile} 
                    onChange={(e) => setCoApplicantDirectFields('phoneMobile', e.target.value, index, 'coApplicants')} format="### ### ####"/>
                  </Form.Input>
                  <Form.Select
                    fluid
                    className="height-38 select-dropdown"
                    label='Government ID Type'
                    required
                    selection
                    search
                    placeholder='Government ID Type'
                    options={ID_TYPES}
                    text={application[index].drivingLicence && application[index].drivingLicence.idType
                      ? ID_TYPES.find(x => x.value === application[index].drivingLicence.idType).text : ''}
                    value={application[index].drivingLicence && application[index].drivingLicence.idType}
                    onChange={(e, data) => setCoApplicantDirectFields('idType', data.value, index, 'coApplicants', 'drivingLicence')}
                  />
                  <Form.Input
                    fluid
                    className="height-38"
                    label='Drivers License/ ID Number'
                    required
                    value={application[index].drivingLicence && application[index].drivingLicence.number}
                    placeholder='Drivers License/ ID Number'
                    onChange={(e) => setCoApplicantDirectFields('number', e.target.value, index, 'coApplicants', 'drivingLicence')} />
                </Form.Group>
                <Form.Group widths='equal' className="unit-info-form-first-row mt-4">
                  <Form.Select
                    fluid
                    className="select-dropdown"
                    label='Issuing State'
                    required
                    placeholder='Issuing State'
                    selection
                    search
                    selectOnBlur={false}
                    options={this.state.statesList}
                    text={application[index].drivingLicence && application[index].drivingLicence.issuingState}
                    value={application[index].drivingLicence && application[index].drivingLicence.issuingState}
                    onChange={(e) => setCoApplicantDirectFields('issuingState', e.target.textContent, index, 'coApplicants', 'drivingLicence')}
                  />
                  <div className="move-date required field">
                    <label className="move-date-label">Date of birth</label>
                    <Datetime
                      timeFormat={false}
                      inputProps={{ readOnly: true, placeholder: 'MM/DD/YYYY' }}
                      isValidDate={ageValidation}
                      closeOnSelect={true}
                      value={application[index].dateOfBirth}
                      onChange={(e) => setCoApplicantDirectFields('dateOfBirth', moment(e).format(DATE_FORMAT), index, 'coApplicants')} />
                  </div>
                  <Form.Select
                    fluid
                    label='Marital Status'
                    placeholder='Marital Status'
                    className="select-dropdown"
                    selection
                    search
                    selectOnBlur={false}
                    options={MARITAL_STATUS}
                    value={application[index].maritalStatus}
                    onChange={(e) => setCoApplicantDirectFields('maritalStatus', e.target.textContent, index, 'coApplicants')}
                  />
                </Form.Group>
                <Form.Group widths='equal' className="unit-info-form-first-row mt-4 pl-2">
                  {/* <Checkbox
                    label="Tenant Alert"
                    checked={application[index].tenantAlert}
                    onChange={(e) => setCoApplicantDirectFields('tenantAlert', !application.tenantAlert, index, 'coApplicants')} /> */}
                </Form.Group>
                {application[applicantIndex].rentalHistory.map((ele, index) => (
                  this.addressGroup(application, applicantIndex, setCoApplicantArrayFields, index)
                ))}
                <div className="text-right mt-18">
                  <Button
                    positive
                    className="modal-save-button wid-110 m-0"
                    onClick={() => this.addMultiSegments('address', application, applicantIndex)}>
                    Add
        </Button>
                </div>
                <Checkbox className="mt-3" checked={(enableCheckbox || application[applicantIndex].isFinanciallyResponsible)}
                  onChange={(e) => this.updateEmploymentStatus(!application[applicantIndex].isFinanciallyResponsible, index)} label="Co-Applicant is Financially Responsible?"></Checkbox>
                {(enableCheckbox || application[applicantIndex].isFinanciallyResponsible) && application[applicantIndex].employmentHistory.map((ele, index) => {
                  return (this.employmentGroup(application, applicantIndex, setCoApplicantArrayFields, index))
                })}

                <div className="text-right mt-18">
                  {(enableCheckbox || application[applicantIndex].isFinanciallyResponsible) && <Button
                    positive
                    className="modal-save-button wid-110 m-0"
                    onClick={() => this.addMultiSegments('employment', application, applicantIndex)}>
                    Add
        </Button>}
                </div>


                <Segment className="primary-applicant-segment pt-0">
                  {(enableCheckbox || application[applicantIndex].isFinanciallyResponsible) && <div className="segment-header-box d-flex align-items-center justify-content-between">
                    <header className="primary-applicant-subheader">Other Income</header>
                    {(enableCheckbox || application[applicantIndex].isFinanciallyResponsible) && <Button
                      positive
                      className="add-income-button m-0"
                      onClick={() => this.addMultiSegments('otherIncome', application, applicantIndex)}>
                      <i aria-hidden="true" class="add circle big icon m-0"></i>
                    </Button>}
                  </div>}

                  {(enableCheckbox || application[applicantIndex].isFinanciallyResponsible) && application[applicantIndex].otherIncomes && application[applicantIndex].otherIncomes.map((data, index) => {
                    return (
                      <>
                        <div className="income-input-row">
                          {this.otherIncomes(application, applicantIndex, setCoApplicantArrayFields, index)}
                          {index > 0 && <Button
                            positive
                            className="remove-income-button m-0"
                            onClick={() => this.removeSegment(application, applicantIndex, index, 'otherIncomes')}>
                            <i aria-hidden="true" class="remove circle big icon m-0"></i>
                          </Button>}
                        </div>
                      </>
                    );
                  })}
                </Segment>
              </Form>
              <Button negative
                onClick={() => this.removeCoApp(application, index)}>
                Remove
              </Button>
            </Accordion.Content>
          </>
        ))}
      </Accordion>
    </>)
  }

  render() {
    const { application,
      setCoApplicantDirectFields,
      setCoApplicantArrayFields,
      noOfCoApplicants,
    } = this.props;
    const { activeIndex, applicantIndex, enableCheckbox } = this.state;
    return (
      <>
        {this.dropdownGroup(application, activeIndex, applicantIndex, setCoApplicantDirectFields,
          enableCheckbox, setCoApplicantArrayFields, noOfCoApplicants)}
          {!!this.state.errors.length && (
          <Message visible error>
            <Message.List>
              {this.state.errors.map((item, index) => (
                <Message.Item key={index}>{`${item.field} - ${item.message}`}</Message.Item>
              ))}
            </Message.List>
          </Message>
        )}
        <div className="text-right mt-4">
          <Button positive className="modal-save-button wid-110" onClick={() => this.props.updateFormSteps(1)}>
            Previous
              </Button>
          <Button
            positive
            className="modal-save-button mr-0 ml-2 wid-110"
            content="Next"
            onClick={(e) => this.validateAndSubmit(e)}
          />
          {toaster()}
        </div>
      </>
    )
  }
}
export default (CoApplicants)
