import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from 'mixpanel-browser';
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import Datetime from "react-datetime";
import {
  LedgerAccounting,
  ReconcileData,
  ReconcileSumData,
} from "../../store/person/accounting";
import moment from "moment";
import { toastFailMsg } from "../../utils/common";
import "./Accounting.scss";

import { Dropdown, Button, Header } from "semantic-ui-react";
import { Table } from "../../components/Tables/ReconcileData";
import { ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "../../components/Loader/Loader";

const ledgerClient = getClient(ACCOUNTING);

const DEFAULT_LIMIT = 1000;

class Reconcile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      initialFetch: false,
      startDate: moment().startOf('month').format("YYYY-MM-DD"),
      endDate: moment().endOf('month').format("YYYY-MM-DD"), 
      ledgerId:'',
      transactions: [],
      totalItems: 0,
      paginate: {
        start: 0,
        end: DEFAULT_LIMIT
      }
    };
  }

  componentDidMount() {
    this.getLedger();
    mixpanel.track('Manager Page Load', {
      'sub': 'Reconcile'
     });

  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        if (this.state.transactions.length) {
          this.setState({
            transactions: [],
            paginate: {
              start: 0,
              end: DEFAULT_LIMIT
            }
          });
        }
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }

  getLedger = (data) => {
    try {
      this.setState({ loading: true });
      ledgerClient
        .query({
          query: LedgerAccounting,
          variables: {
            location: this.props.selectedPrimary.node.id,
            offset: 0,
            limit: 1000,
          },
        })
        .then((res) => {
          if (res.data) {
            this.setState({
              ledgerCache: get(res, "data.slLocationLedgerAccounts.data", null),
              ledgerId: get(res, "data.slLocationLedgerAccounts.data[0]._id", null),
            });
            if(window.document.getElementById('getLedger')) {
              window.document.getElementById('getLedger').click()
            }
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  changeLedger = (val) => {
    this.setState({ ledgerId: val.value });
  };

  ReconcileAccount = async (refetch) => {
    this.setState({loading:true})
    if (refetch) {
      await this.setState({
        totalItems: 1,
        transactions: [],
        paginate: {
          start: 0,
          end: DEFAULT_LIMIT,
        },
        initialFetch: true
      })
      if (
        this.state.startDate ||
        this.state.endDate ||
        this.state.ledgerId
      ) {
        this.SumData();
      }
      this.setState({loading:false})
    }
    const { id: locationId } = this.props.selectedPrimary.node;
    const { startDate, endDate, ledgerId, paginate, transactions, totalItems } = this.state;
    if (transactions.length <= totalItems)
      ledgerClient
        .query({
          query: ReconcileData,
          variables: {
            start: startDate || "",
            end: endDate || "",
            ledger: String(ledgerId) || "",
            location: locationId,
            offset: paginate.start,
            limit: paginate.end,
          },
        })
        .then((res) => {
          if (res.data) {
            const value = get(res, "data.slLocationTransactions.data", []);
            const total = get(res, "data.slLocationTransactions.totalItems", 0);
            let updatedPagination = paginate;
            if (value.length === DEFAULT_LIMIT && total > DEFAULT_LIMIT)
              updatedPagination = {
                start: paginate.end,
                end: paginate.end + DEFAULT_LIMIT
              };

            this.setState({
              transactions: paginate.start === 0 ? value : [...transactions, ...value],
              totalItems: total,
              paginate: updatedPagination
            });
            this.setState({loading:false})
          }
          this.setState({loading:false})
        })
        .catch((error) => {
          this.setState({loading:false})
          console.log(error);
          toastFailMsg(error);
        });
  };

  SumData = () => {
    ledgerClient
      .query({
        query: ReconcileSumData,
        variables: {
          start: this.state.startDate ? this.state.startDate : "",
          end: this.state.endDate ? this.state.endDate : "",
          ledger: this.state.ledgerId ? this.state.ledgerId.toString() : "",
          location: this.props.selectedPrimary.node.id,
        },
      })
      .then((res) => {
        if (res.data) {
          this.setState({ sumData: res.data.slSumLocationAccount });
        }
      })
      .catch((error) => {
        console.log(error);
        toastFailMsg(error);
      });
  };

  handleStartClick = (date) => {
    this.setState({
      startDate: moment(date._d).format("YYYY-MM-DD"),
    });
  };
  handleEndClick = (date) => {
    this.setState({
      endDate: moment(date._d).format("YYYY-MM-DD"),
    });
  };

  // renderTable() {
  //   const { initialFetch, transactions, totalItems, sumData } = this.state;
  //   if (initialFetch)
  //     return (
       
  //     )
  // }

  render() {
    const { loading, ledgerCache } = this.state;
    const financials = 
      ledgerCache&&ledgerCache.map(val => (
        {
          key: val._id,
          value: val._id,
          text: val.name
        }
      ))
    
      const { transactions, totalItems, sumData } = this.state;
    return (
      <>
       <main className="main-content" role="main">
        <div className="tenants-container ">
      {
        ledgerCache &&
     
        <div className="dates">
          <div>
            <Header textAlign="center">Start Date</Header>
            <Datetime inputProps={{ placeholder: 'Select Start Date' }} defaultValue={moment().startOf('month')} timeFormat={false}  onChange={this.handleStartClick} />
          </div>
          <div>
            <Header textAlign="center">End Date</Header>
            <Datetime inputProps={{ placeholder: 'Select End Date' }} defaultValue={moment().endOf('month')} timeFormat={false}   onChange={this.handleEndClick} />
          </div>
          <div>
            {/* <Form>
              <Form.Field> */}
              <Header textAlign="center">Ledger</Header>
                <Dropdown
                  style={{zIndex: "11"}}
                  onChange={(e, { value }) => this.changeLedger({ value })}
                  selection
                  defaultValue={financials[0].value}
                  options={financials}
                />
              {/* </Form.Field>
            </Form> */}
          </div>
          <div>
            <Button
            id="getLedger"
              style={{ background: "#624d8e", color: "white" }}
              onClick={() => this.ReconcileAccount()}
            >
              Reconcile
            </Button>
          </div>
          <div>

          <Button style={{paddingTop:'0px', paddingBottom:"0px", height:"46.17px", marginTop:'10px'}} className="noPrint" onClick={() => window.print()}>Print</Button>
          </div>
        </div>
      }
        {loading ? <Loader text inTable/>
        : 
        <>
          {typeof sumData == "number" && (
            <Header>
              Net Balance:{" "}
              {sumData && sumData >= 0
                ? `$${sumData
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                : `$(${Math.abs(sumData)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`}
            </Header>
          )}
          <Table
            transCache={transactions}
            getLedger={this.getLedger}
            ReconcileAccount={() => this.ReconcileAccount(false)}
            totalItems={totalItems && totalItems}
            propertyData={
              this.props.selectedPrimary && this.props.selectedPrimary
            }
            propertyId={
              this.props.selectedPrimary.node &&
              this.props.selectedPrimary.node.id
            }
          />
        </>
        }
        </div>
        </main>
      </>
    );
  }
}

export default withApollo(Reconcile);
