export const TOKEN = 'token';
export const EXPIRE_TIME = 'expireTime';
export const RELOAD = 'reload';
export const LOGIN = 'login';
export const SESSION_TIMEOUT_THRESHOLD = 0;
export const USER_EVENT = 'userLoggedStatus';
export const USER_LOC = 'locationData';
export const RENTER = 'renter';
export const NOTIFICATION = 'notification';
export const MANAGER = 'manager';
export const SERVICEPROFESSIONAL = 'serviceProfessional';
export const USER_ROLE_EVENT = 'userRoleStatus';
export const ROUTES = {
  signup: '/signup',
  leads: '/leads',
  fileManager: '/fileManager',
  properties: '/properties',
  applications: '/applications',
  existingapplications: '/applications/existingapplications',
  tenants: '/residents',
  schedule: '/schedule',
  loyalty: '/loyalty',
  home: '/',
  maintenance: '/maintenance',
  reports: '/reports',
  waitlist: '/leads/waitlist',
  ledger: '/accounting/ledger',
  boxscore: '/accounting/boxscore',
  transactions: '/accounting/transactions',
  reconcile: '/accounting/reconcile',
  aging: '/accounting/aging',
  journalreport: '/accounting/journalreport',
  trialreport: '/accounting/trialreport',
  charge: '/accounting/charge',
  invoices: '/accounting/invoices',
  rentroll: '/accounting/rentroll',
  paybills: '/accounting/paybills',
  bills: '/accounting/bills',
  vendors: '/accounting/vendors',
  financials: '/accounting/financials',
  credits: '/accounting/credits',
  arcash: '/accounting/arcash',
  tax: '/accounting/tax',
  dashboard: '/dashboard',
  lease: '/lease',
  settings: '/settings',
  leaseDetails: '/lease/details/:leaseId',
  addProperties: '/addProperties',
  addProperty: '/addProperty',
  bluemoonSettings: '/bluemoonSettings',
  propertiesListing: '/properties/list',
  propertyDetails: '/properties/details/:locationId',
  editPropertyDetails: '/properties/edit/:locationId',
  editLease: '/editLease/:leaseId',
  reportsData: '/reportsData',
  balanceSheet: '/balanceSheet',
};

export const PORTALS = {
  landing: 'https://www.leasera.com',
  search: 'https://www.leasera.com/properties',
};

export const DOC_EXT = {
  pdf: 'Form',
  ppt: 'Template',
  xls: 'Sheet',
};
export const EXTENSIONS = {
  FORM: 'pdf',
  TEMPLATE: 'ppt',
  SHEET: 'xls',
  IMAGE: 'png',
};
export const CATEGORIES = ['Exterior', 'Electrical', 'Doors', 'Flooring', 'Heating/Cooling', 'Lighting', 'Locks', 'Parking Lot', 'Plumbing', 'Walls', 'General Misc'];

export const LEAD_STATUS = ['Prospect', 'Applied', 'Tour', 'Resident', 'Lost', 'Waitlist'];

export const CATEGORIES_VALUES = ['exterior', 'electrical', 'doors', 'flooring', 'heating_cooling', 'lighting', 'locks', 'parking_lot', 'plumbing', 'walls', 'general_misc'];

export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const URGENCIES = ['critical', 'urgent', 'important'];
export const TYPES = ['repair', 'replace'];

export const VALID_FORM_INPUT = 'form-control is-valid';
export const INVALID_FORM_INPUT = 'form-control is-invalid';

export const APP_STATUS = {
  action: 'Requires Action',
  accept: 'Accepted',
  declined: 'Declined',
  canceled: 'Canceled',
};


export const APPLICATION_ACTIONS = {
  accept: 'accept',
  decline: 'decline',
  action: 'requires_action',
};

export const UNIT_TYPE = [{
  key: '1BR/1BA',
  text: '1BR/1BA',
  value: '1BR/1BA',
},
{
  key: '1BR/2BA',
  text: '1BR/2BA',
  value: '1BR/2BA',
},
{
  key: '1BR/3BA',
  text: '1BR/3BA',
  value: '1BR/3BA',
},
{
  key: '2BR/1BA',
  text: '2BR/1BA',
  value: '2BR/1BA',
},
{
  key: '2BR/2BA',
  text: '2BR/2BA',
  value: '2BR/2BA',
},
{
  key: '2BR/3BA',
  text: '2BR/3BA',
  value: '2BR/3BA',
},
{
  key: '3BR/1BA',
  text: '3BR/1BA',
  value: '3BR/1BA',
},
{
  key: '3BR/2BA',
  text: '3BR/2BA',
  value: '3BR/2BA',
},
{
  key: '3BR/3BA',
  text: '3BR/3BA',
  value: '3BR/3BA',
},
];

export const DATE_FORMAT = 'MM/DD/YYYY';

export const DATE_ALPHA = 'DD MMM YYYY';
// export const DATE_ALPHA_US = "MMM DD, YYYY"

export const DATE_ALPHA_US = 'hh:mm A MMM DD, YYYY';
export const DATE_ALPHA_US_NO_TIME = 'MMM DD, YYYY';
export const SET_UPDATE = 'YYYY-MM-DD hh:mm A';
export const LEAD_CREATED_FORMAT = 'MMM DD, YYYY hh:mm A';
export const TIME_FORMAT = 'hh:mm';
export const LEASE_TIME_FORMAT = 'YYYY-MM-DDThh:mm:ss';

export const APPLIANCES = [{
  washerMachine: 'Washing Machine',
},
{
  dailyReception: 'Daily Reception',
},
{
  restaurant: 'Restaurant',
},
{
  freeWifi: 'Free Wifi',
},
{
  parking: 'Parking',
},
{
  nonSmokingRooms: 'Non Smoking Rooms',
},
{
  airportShuttle: 'Airport Shuttle',
},
{
  ac: 'AC',
},
{
  balcony: 'Balcony',
},
{
  heating: 'Heating',
},
{
  pool: 'Pool',
},
{
  hotTub: 'Hot Tub',
},
{
  gym: 'Gym',
},
{
  petsAllowed: 'Pets Allowed',
},
{
  dishWasher: 'Dish Washer',
},
{
  microwave: 'Microwave',
},
{
  terrace: 'Terrace',
},
];

export const propertyTypeOptions = [{
  title: 'House',
  value: 'house',
},
{
  title: 'Apartment',
  value: 'apartment',
},
{
  title: 'Hotel',
  value: 'hotel',
},
{
  title: 'Hostel',
  value: 'hostel',
},
{
  title: 'Motel',
  value: 'motel',
},
];

export const US_STATES = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District of Columbia': 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

export const NUMBERS = /^[0-9]*$/;

export const YEAR = 1900;

export const NUM_ARR = ['1', '2', '3', '4', '5', '5+'];

export const UNIT_STATUS = {
  lease: 'leased',
  ready: 'Ready',
  notReady: 'Not Ready',
};

export const CHARGE_TYPES = {
  Revenue: 'Revenue',
  Credit: 'Credit',
  Payment: 'Payment',
  Refund: 'Refund',
};

export const LEDGER_TYPES = {
  Asset: 'Asset',
  Liability: 'Liability',
  Equity: 'Equity',
  Revenue: 'Revenue',
  Expense: 'Expense',
};
export const DEFAULT_LEDGER = {
  Receivables: 'rent_receivables',
  Income: 'rent_income',
  Other: 'other_income',
  Undeposited: 'undeposited_funds',
  Deposit: 'deposit',
  Bank: 'bank_account',
  Payable: 'accounts_payable',
  Expenses: 'expenses',
  Tax: 'tax',
};
export const BALANCE_TYPES = {
  Debit: 'Debit',
  Credit: 'Credit',
};

export const MAP_DEFAULT_CENTER = {
  lat: 47.6062,
  lng: 122.3321,
}; // Seattle's lat/lng

export const URGENCY = [{
  browsing: 'Browsing',
},
{
  nearFuture: 'Near future',
},
{
  immediate: 'Immediate',
},
{
  inAMonth: 'In a month',
},
];

export const MESSAGE = 'message';
export const CALL = 'call';
export const DEACTIVATE = 'deactivate';
export const ACTIVATE = 'activate';

export const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
export const PRICE_REGEX = /^\d{1,}(\.\d{1,2})?\s*$/;
export const NUMERIC_REGEX = /^[1-9][0-9]*$/;
export const NUMERIC_REGEX_DECIMAL = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
export const WEBSITE_REGEX = /(\w|\d)(\w|\d)*\.\w+/i
export const PHONE_REGEX = /^\d{10}$/; 

export const PHONE_LEAD = /^(?=.*\d)[\d ]+$/;

export const SQ_FEETS = [
  '500 - 1000',
  '1000 - 1500',
  '1500 - 2000',
  '2000 - 2500',
  '2500 - 3000',
  '3000 - 3500',
  '3500 - 4000',
  '4000 - 4500',
  '4500 - 5000',
  '5000+',
];

export const FAKE_LEASE = {
  startDate: null,
  endDate: null,
  primaryLessee: {
    firstName: null,
    lastName: null,
    ndbId: null,
    email: null,
    phoneHome: null,
    phoneMobile: null,
    bio: null,
    dateOfBirth: null,
    references: [{
      name: null,
      phone: null,
      email: null,
      __typename: null,
    }],
    __typename: 'PersonType',
  },
  tenants: [{
    firstName: null,
    lastName: null,
    ndbId: null,
    __typename: null,
  }],
  paid: null,
  __typename: 'PropertyLeaseType',
};

export const FAKE_APPLICANT = {
  firstName: null,
  lastName: null,
  email: null,
  phoneHome: null,
  phoneMobile: null,
  dateOfBirth: null,
  references: [{
    phone: null,
    title: null,
    name: null,
    email: null,
  }],
};

export const ALL = 'all';
export const CREATED_AT_DESC = 'CREATED_AT_DESC';

export const TASKS = [
  'Clean garbage disposal.',
  'Clean the range hood and filter.',
  'Wash outside windows and siding.',
  'Unclog drains',
  'Test smoke detectors.',
];

export const MATERIALS = [
  'Hammer',
  'Measuring tape',
  'Screwdriver',
  'Power drill',
  'Hand saw',
  'Utility knife',
  'Five gallon bucket',
  'Step ladder',
  'Safety glasses',
];

export const ADD_LEAD_STATUS = [{
  key: 'prospect',
  text: 'Prospect',
  value: 'Prospect',
},
{
  key: 'tour',
  text: 'Tour',
  value: 'Tour',
},
{
  key: 'applied',
  text: 'Applied',
  value: 'Applied',
},
{
  key: 'resident',
  text: 'Resident',
  value: 'Resident',
},
{
  key: 'lost',
  text: 'Lost',
  value: 'Lost',
},
{
  key: 'waitlist',
  text: 'Waitlist',
  value: 'Waitlist',
},
];

export const SERVICE_REQUIRED = [{
  key: 'true',
  text: 'Yes',
  value: 'Yes',
}, {
  key: 'false',
  text: 'No',
  value: 'No',
}];

export const ADD_APPLICATION_STATUS = [{
  key: 'new',
  text: 'New',
  value: 'new',
},
{
  key: 'approved',
  text: 'Approved',
  value: 'approved',
},
{
  key: 'under_review',
  text: 'Under Review',
  value: 'under_review',
},
{
  key: 'denied',
  text: 'Denied',
  value: 'denied',
},
{
  key: 'approved_with_conditions',
  text: 'Approve w/Conditions',
  value: 'approved_with_conditions',
},
{
  key: 'cancelled',
  text: 'Cancelled',
  value: 'cancelled',
},
];

export const ADD_LEASE_STATUS = [{
  key: 'created',
  text: 'Created',
  value: 'created',
},
{
  key: 'signed',
  text: 'Signed',
  value: 'signed',
},
{
  key: 'move_in',
  text: 'Move In',
  value: 'move_in',
},
{
  key: 'move_out',
  text: 'Move Out',
  value: 'move_out',
},
];

export const LEAD_SOURCE = [{
  key: 'leas',
  text: 'Leasera.com',
  value: 'Leasera.com',
},
{
  key: 'apart',
  text: 'Apartments.com',
  value: 'Apartments.com',
},
{
  key: 'walk',
  text: 'Walk-In',
  value: 'Walk-In',
},
];

export const MARITAL_STATUS = [{
  key: 'single',
  text: 'Single',
  value: 'Single',
},
{
  key: 'married',
  text: 'Married',
  value: 'Married',
},
{
  key: 'widowed',
  text: 'Widowed',
  value: 'Widowed',
},
{
  key: 'seperated',
  text: 'Seperated',
  value: 'Seperated',
},
];

export const LEASE_FREQUENCY = [
  {
    key: 'Weekly',
    text: 'Weekly',
    value: 'Weekly',
  },
  {
    key: 'Monthly',
    text: 'Monthly',
    value: 'Monthly',
  },
  {
    key: 'Yearly',
    text: 'Yearly',
    value: 'Yearly',
  },
];

export const UNIT_AVAILABLITY_STATUS = [
  {
    key: 'Vacant',
    text: 'Vacant',
    value: 'Vacant',
  },
  {
    key: 'Pending',
    text: 'Pending',
    value: 'Pending',
  },
  {
    key: 'Signed',
    text: 'Signed',
    value: 'Signed',
  },
  {
    key: 'Unavailable',
    text: 'Unavailable',
    value: 'Unavailable',
  },
  {
    key: 'Unlisted',
    text: 'Unlisted',
    value: 'Unlisted',
  },
  {
    key: 'Off the Market',
    text: 'Off the Market',
    value: 'OffTheMarket',
  },
];

export const AMENITIES = [
  'WiFi',
  'Heater',
  'Free parking',
  'Kitchen',
  'Cable TV',
  'Air conditioning',
  'Hangers',
  'Iron',
  'Dryer',
  'Washing machine',
  'Games console',
  'High chair',
  'Smoke alarm',
  'Fire extinguisher',
  'Kettle',
];

export const VENDOR_SERVICES = [
  'Plumbing',
  'Electrician',
  'Fire',
  'Water damage',
  'Mold',
  'House cleaning',
  'Landscaping',
];

export const ON_BOARDING_STEPS = ['prop_info', 'units', 'payment_policies', 'org_info'];

export const MAX_PIN_LENGTH = 6;
export const MIN_PIN_LENGTH = 4;
export const NUMBER_ONE = 1;
export const NUMBER_TWO = 2;
export const NUMBER_THREE = 3;
export const NUMBER_ZERO = 0;
export const NUMBER_TEN = 10;
export const BACKGROUND_CHECK_TEXT = `Please indicate the year, location and type of each felony, misdemeanor
involving a controlled substance, violence to another person or destruction of property, or a sex crime other than those
resolved by dismissal or acquittal. We may need to discuss more facts before making a decision. You represent the answer is
"no" to any item not checked above.`;
export const PRIORITY = ['Urgent', 'Medium', 'Low'];
export const COMING_SOON = 'COMING SOON';
export const GOOGLE_API_KEY = 'AIzaSyAnOMBzboGt-zL69J7ae_aC_yjWchdAn8M';
export const LEASE_PATH = '/lease';
export const BLUEMOON_PATH = 'https://www.bluemoonforms.com/?p=home';
export const CLEAR_NOTIFICATIONS = 'Mark As Read';
export const CLEAR_NOTIFICATIONS_TEXT = 'Are you sure you want to mark all the notifications as read?';

export const DOCUMENTS_URL = `https://service-organization-2tisrzg7pa-uc.a.run.app/organization/graphql`;
export const DOCUMENTS_GET_URL = `https://us-central1-leasera-${process.env.REACT_APP_DEMO}.cloudfunctions.net/org_documents`;
export const APPLICATIONS_URL = `https://us-central1-leasera-${process.env.REACT_APP_DEMO}.cloudfunctions.net/person_application`;
export const HELLOSIGN_URL = `https://us-central1-leasera-${process.env.REACT_APP_DEMO}.cloudfunctions.net/person_hellosign`;
export const PERSON_URL = `https://service-person-2tisrzg7pa-uc.a.run.app/person/graphql`;
export const PRIMARY_LOCATION_URL = `https://us-central1-leasera-${process.env.REACT_APP_DEMO}.cloudfunctions.net/primary_location`;
export const LEASES_URL = `https://us-central1-leasera-${process.env.REACT_APP_DEMO}.cloudfunctions.net/person_lease`;
export const INVOICES_URL = `https://us-central1-leasera-${process.env.REACT_APP_DEMO}.cloudfunctions.net/invoice`;
export const PERSON_PAYMENT = `https://us-central1-leasera-${process.env.REACT_APP_DEMO}.cloudfunctions.net/person_payment`;
export const MAINTENANCE_URL = `https://service-mr-manager-2tisrzg7pa-uc.a.run.app/mr_manager/graphql`;
export const MAINTENANCE_URL_PHOTO = `https://service-mr-manager-2tisrzg7pa-uc.a.run.app/mr_manager`;
export const MANAGEMENT_URL = `https://us-central1-leasera-${process.env.REACT_APP_DEMO}.cloudfunctions.net/management`;
export const LEADS_URL = `https://service-lead-manager-2tisrzg7pa-uc.a.run.app/lead_manager/graphql`;
export const LEADS_TOUR = `https://us-central1-leasera-${process.env.REACT_APP_DEMO}.cloudfunctions.net/lead_tour_mr`;
export const LEADS_TOUR_TYPE = `https://us-central1-leasera-${process.env.REACT_APP_DEMO}.cloudfunctions.net/lead_tour_type`;
export const LEADS_COMMENT_URL = `https://us-central1-leasera-${process.env.REACT_APP_DEMO}.cloudfunctions.net/lead_comments`;
export const APPLICATION_COMMENT_URL = `https://us-central1-leasera-${process.env.REACT_APP_DEMO}.cloudfunctions.net/application_comment`;
export const LEADS_COMMUNICATION_URL = `https://us-central1-leasera-${process.env.REACT_APP_DEMO}.cloudfunctions.net/lead_communication`;
export const AFFORDABLE_HOUSING = `https://service-affordable-housing-2tisrzg7pa-uc.a.run.app/affordable_housing/graphql`;
export const FUSEBIT = `https://us-central1-leasera-${process.env.REACT_APP_DEMO}.cloudfunctions.net/fusebit_configuration`;
export const APPLICATION_MANAGER = `https://service-application-manager-2tisrzg7pa-uc.a.run.app/application_manager/graphql`;
export const CHARGE_CODE = `https://service-charge-code-2tisrzg7pa-uc.a.run.app/charge_code/graphql`;
export const ACCOUNTING = `https://service-accounting-2tisrzg7pa-uc.a.run.app/accounting/graphql`;
export const ACCOUNTING_REPORTING = `https://service-reporting-2tisrzg7pa-uc.a.run.app/reporting/graphql`;
export const INVOICE_ACCOUNTING = `https://service-accounting-2tisrzg7pa-uc.a.run.app/accounting`;
export const INVOICE_ACCOUNTING_PAYMENT = `https://service-accounting-2tisrzg7pa-uc.a.run.app/accounting/graphql`;
export const LOCATIONAPI = `https://service-location-manager-2tisrzg7pa-uc.a.run.app/location/graphql`;
export const LOCATION_TYPE_API = `https://service-location-type-2tisrzg7pa-uc.a.run.app/location_type/graphql`;
export const UNITAPI = `https://service-unit-2tisrzg7pa-uc.a.run.app/unit/graphql`;
export const UNIT_TYPE_API = `https://service-unit-type-2tisrzg7pa-uc.a.run.app/unit_type/graphql`;
export const LOCATION_UTILS = `https://us-central1-leasera-${process.env.REACT_APP_DEMO}.cloudfunctions.net/location_utils`;
export const LEASE_MANAGER = `https://service-lease-manager-2tisrzg7pa-uc.a.run.app/lease_manager/graphql`;
export const PAYMENTS_URL = `https://payment-dev-dot-leasera-${process.env.REACT_APP_DEMO}.uc.r.appspot.com/graphql`
export const BGC = `https://service-background-manager-2tisrzg7pa-uc.a.run.app/background_manager/graphql`;
export const ACCMETRICS = `https://us-central1-leasera-${process.env.REACT_APP_DEMO}.cloudfunctions.net/accounting_metrics_v2`;
export const LEASE_COMMENT = `https://us-central1-leasera-${process.env.REACT_APP_DEMO}.cloudfunctions.net/lease_comment`;
export const ORGANIZATION_API = `https://service-organization-2tisrzg7pa-uc.a.run.app/organization/graphql`;
export const TASK_URL = `https://service-task-manager-2tisrzg7pa-uc.a.run.app/task_manager/graphql`;
export const DOMAIN_MANAGER = `https://service-domain-manager-2tisrzg7pa-uc.a.run.app/domain_manager/graphql`;
export const MICROSITE = `https://service-domain-manager-2tisrzg7pa-uc.a.run.app/domain_manager/graphql`
export const BLUEMOON_API = `https://us-central1-leasera-${process.env.REACT_APP_DEMO}.cloudfunctions.net/bluemoon`;
export const APPLICATION_CREATION_EMAIL = `https://service-application-manager-2tisrzg7pa-uc.a.run.app/application_manager/graphql`;
export const LOYALTY = `https://service-package-loyalty-2tisrzg7pa-uc.a.run.app/package_loyalty/graphql`;
