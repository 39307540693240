import firebase from 'firebase';
// import firebase from 'firebase/app';
import 'firebase/messaging';
import { config } from '../../../config/firebase'
import * as is from 'is_js';

const app = firebase.initializeApp(config)

export const refresh = firebase.auth()

export const messaging = is.chrome() ? firebase.messaging() : null;

export const auth = app.auth()


export default app