import React, { Component } from 'react';
import moment from 'moment';
import Datetime from "react-datetime";
import { Button, Table, Input, Message, Dropdown } from 'semantic-ui-react';
import ReeValidate from 'ree-validate';
import { DATE_FORMAT, NUMBER_TWO, NUMBER_ONE, SERVICE_REQUIRED } from '../../utils/constants';
import {  disableFutureDt } from '../../utils/common';
import './Applications.scss';
import toaster from '../../utils/toaster';

class Occupants extends Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate.Validator({
      firstName: 'required',
      lastName: 'required',
      dateOfBirth: 'required'
    });

    this.state = {
      errors: []
    }

    window.scrollTo(0,0);
  }
  
   /* function to validate the occupants validation */
   occupantsValidation = () => {
    const { application } = this.props;
    let valueToReturn = true;
    application.occupants.forEach((ele) => {
      if (ele.firstName === '' || ele.lastName === '' || ele.dateOfBirth === '') {
        valueToReturn = false;
      }
    })
    return valueToReturn;
  }

   /* function to validate the pets validation */
   petsValidation = () => {
    const { application } = this.props;
    let valueToReturn = true;
    application.pets.forEach((ele) => {
      if (ele.name === '' || ele.petType === '' || ele.breed === '' || ele.weight === '' || ele.age === '') {
        valueToReturn = false;
      }
    })
    return valueToReturn;
  }

   /* function to check for numeric character validation */
   checkForNumericCharacter = () => {
    const { application } = this.props;
    let valueToReturn = true;
    application.pets.forEach((ele) => {
      if ((ele.weight !== '' && !/^\d+$/.test(ele.weight)) || (ele.age !== '' && !/^\d+$/.test(ele.age))) {
        valueToReturn = false;
      }
    })
    return valueToReturn;
  }

  /* function that validates the data captured, if data is valid, 
  it calls the handlesubmit function to integrate the mutation */
  validateAndSubmit = async (e) => {
    e.preventDefault();
    const { application, updateFormSteps } = this.props;
    /* eslint-disable */
    let valid = true;
    let updatedErrors = [];
    valid = await this.validator.validateAll(application);
    /* eslint-enable */
    application.vehicles.forEach((ele) => {
      if (ele.make === '' && ele.model === '' && ele.year === '' && ele.color === '' && ele.licensePlate === '' && ele.state === '') {
        application.vehicles = [];
      }
    })
    if (!this.occupantsValidation()) {
      updatedErrors.push({field: 'Occupant', message: 'Please fill mandatory occupants details'});
    } if (!this.petsValidation()) {
      updatedErrors.push({field: 'Pets', message: 'Please fill mandatory pets details'});
    } if (!this.checkForNumericCharacter()) {
      updatedErrors.push({field: 'Weight and Age', message: 'Weight and Age should contain numeric characters'});
    }
    this.setState({ errors: updatedErrors }); 
    if (!updatedErrors.length) {
      updateFormSteps(4);
    }
  }

  /* function to add multiple vehicles by clicking on + button */
  addMultiVehicles = (application) => {
    application.vehicles.push({
      make: '',
      model: '',
      color: '',
      year: '',
      licensePlate: '',
      state: ''
    })
    this.setState({ state: this.state });
  }

  /* function to add multiple pets by clicking on + button */
  addMultiPets = (application, setPetNumber) => {
    application.pets.push({
      name: '',
      petType: '',
      weight: '',
      color: '',
      breed: '',
      gender: '',
      age: '',
      service: false
    })
    setPetNumber(application.petsNumber + 1, 'petsNumber')
    this.setState({ state: this.state });
  }

  /* function to remove the selected vehicle by clicking on - button */
  removeSegment = (application, index) => {
    application.vehicles.splice(index, 1);
    this.setState({ state: this.state });
  }

  /* function to navigate to previous page */
  /* eslint-disable */
  setPreviousData = () => {
    const { updateFormSteps, numberOfAdults } = this.props;
    {Number(numberOfAdults) === NUMBER_ONE ? updateFormSteps(NUMBER_ONE) : updateFormSteps(NUMBER_TWO)};
  }
  /* eslint-enable */

  /* function to render occupants (children) table data\
    number of children depends on the data filled by user in user info */
  renderOccupantsTable = (application, setCoApplicantDirectFields) => (
    <table className="occupant-table w-100">
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell className="required-field">First Name</Table.HeaderCell>
        <Table.HeaderCell className="required-field">Last Name</Table.HeaderCell>
        <Table.HeaderCell className="required-field">DOB</Table.HeaderCell>
        <Table.HeaderCell className="text-center">Type</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      <Table.Row>
        <Table.Cell>{application.primaryApplicant.firstName}</Table.Cell>
        <Table.Cell>{application.primaryApplicant.lastName}</Table.Cell>
        <Table.Cell>{application.primaryApplicant.dateOfBirth}</Table.Cell>
        <Table.Cell>Primary Applicant</Table.Cell>
      </Table.Row>
      {application.coApplicants.map((ele, index) => {
        return (
            <>
            <Table.Row>  
                <Table.Cell>{ele.firstName}</Table.Cell>
                <Table.Cell>{ele.lastName}</Table.Cell>
                <Table.Cell>{ele.dateOfBirth}</Table.Cell>
                <Table.Cell>Co-Applicant</Table.Cell>
            </Table.Row>
            </>
        )
      })}
      {application.occupants.map((ele, index) => {
        return (
            <>
            <Table.Row>   
               <Table.Cell><Input type="text" className="height-38 w-100" autoFocus={index === 0} value={ele.firstName} required placeholder="First Name"
                    onChange={(e) => setCoApplicantDirectFields('firstName', e.target.value, index, 'occupants')}/>
                </Table.Cell>
               <Table.Cell><Input type="text" className="height-38 w-100" value={ele.lastName} placeholder="Last Name"
                    onChange={(e) => setCoApplicantDirectFields('lastName', e.target.value, index, 'occupants')}/>
                </Table.Cell>
               <Table.Cell> <Datetime
                    className="occupants-date" 
                    timeFormat={false}
                    inputProps={{readOnly: true, placeholder: 'MM/DD/YYYY'}}
                    closeOnSelect={true}
                    value={ele.dateOfBirth}
                    isValidDate={disableFutureDt}
                    onChange={(e) => setCoApplicantDirectFields('dateOfBirth', moment(e).format(DATE_FORMAT), index, 'occupants')} /> 
               </Table.Cell>
               <Table.Cell>Children</Table.Cell>
            </Table.Row>
            </>
        )
      })}
    </Table.Body>
  </table>
  )

  /* funciton to render pets table data
    number of pets depends on the data filled by user in user info */
  renderPetsTable = (application, setCoApplicantDirectFields, setPetNumber) => (
    <Table celled className="occupant-table">
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell className="required-field">Name</Table.HeaderCell>
        <Table.HeaderCell className="required-field">Type</Table.HeaderCell>
        <Table.HeaderCell className="required-field">Breed</Table.HeaderCell>
        <Table.HeaderCell>Gender</Table.HeaderCell>
        <Table.HeaderCell className="required-field">Weight</Table.HeaderCell>
        <Table.HeaderCell>Color</Table.HeaderCell>
        <Table.HeaderCell className="required-field">Age</Table.HeaderCell>
        <Table.HeaderCell className="text-center text-nowrap">Service?</Table.HeaderCell>
        <Table.HeaderCell className="text-center min-wid-85">
        <Button 
            positive 
            className="add-income-button m-0" 
            onClick={() => this.addMultiPets(application, setPetNumber)}>
                <i aria-hidden="true" class="add circle big icon m-0"></i>
            </Button>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {application.pets.map((ele, index) => {
        return (
            <>
            <Table.Row>   
               <Table.Cell><Input type="text" className="height-38" value={ele.name} autoFocus={index === 0 && !application.occupants.length} placeholder="Name"
                    onChange={(e) => setCoApplicantDirectFields('name', e.target.value, index, 'pets')}/>
                </Table.Cell>
               <Table.Cell><Input type="text" className="height-38" value={ele.petType} placeholder="Type"
                    onChange={(e) => setCoApplicantDirectFields('petType', e.target.value, index, 'pets')}/>
                </Table.Cell>
               <Table.Cell><Input type="text" className="height-38" value={ele.breed} placeholder="Breed"
                    onChange={(e) => setCoApplicantDirectFields('breed', e.target.value, index, 'pets')}/>
               </Table.Cell>
               <Table.Cell><Input type="text" className="height-38" value={ele.gender} placeholder="Gender"
                    onChange={(e) => setCoApplicantDirectFields('gender', e.target.value, index, 'pets')}/>
               </Table.Cell>
               <Table.Cell><Input type="text" className="height-38" value={ele.weight} placeholder="Weight"
                    onChange={(e) => setCoApplicantDirectFields('weight', !isNaN(Number(e.target.value)) ? Number(e.target.value) : e.target.value, index, 'pets')}/>
               </Table.Cell>
               <Table.Cell><Input type="text" className="height-38" value={ele.color} placeholder="Color"
                    onChange={(e) => setCoApplicantDirectFields('color', e.target.value, index, 'pets')}/>
               </Table.Cell>
               <Table.Cell><Input type="text" className="height-38" value={ele.age} placeholder="Age"
                    onChange={(e) => setCoApplicantDirectFields('age', !isNaN(Number(e.target.value)) ? Number(e.target.value) : e.target.value, index, 'pets')}/>
               </Table.Cell>
               <Table.Cell className="text-center"><Dropdown fluid text={ele.service ? "Yes" : "No"} options={SERVICE_REQUIRED}
                    onChange={(e) => setCoApplicantDirectFields('service', e.target.textContent === 'Yes' ? true : false , index, 'pets')}/>
               </Table.Cell>
            </Table.Row>
            </>
        )
      })}
    </Table.Body>
  </Table>
  )

  /* function to render vehicles table data */
  renderVehicleTable = (application, setCoApplicantDirectFields) => (
  <Table celled className="occupant-table">
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Make</Table.HeaderCell>
        <Table.HeaderCell>Model</Table.HeaderCell>
        <Table.HeaderCell>Color</Table.HeaderCell>
        <Table.HeaderCell>Year</Table.HeaderCell>
        <Table.HeaderCell>License Plate</Table.HeaderCell>
        <Table.HeaderCell>State</Table.HeaderCell>
        <Table.HeaderCell className="text-center min-wid-85">
        <Button 
            positive 
            className="add-income-button m-0" 
            onClick={() => this.addMultiVehicles(application)}>
                <i aria-hidden="true" class="add circle big icon m-0"></i>
            </Button>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {application.vehicles.map((ele, index) => {
        return (
            <>
            <Table.Row>   
               <Table.Cell><Input type="text" className="height-38" value={ele.make} autoFocus={index === 0 && !application.occupants.length && !application.pets.length} placeholder="Make"
                    onChange={(e) => setCoApplicantDirectFields('make', e.target.value, index, 'vehicles')}/>
                </Table.Cell>
               <Table.Cell><Input type="text" className="height-38" value={ele.model} placeholder="Model"
                    onChange={(e) => setCoApplicantDirectFields('model', e.target.value, index, 'vehicles')}/>
                </Table.Cell>
               <Table.Cell><Input type="text" className="height-38" value={ele.color} placeholder="Color"
                    onChange={(e) => setCoApplicantDirectFields('color', e.target.value, index, 'vehicles')}/>
               </Table.Cell>
               <Table.Cell><Input type="text" className="height-38" value={ele.year} placeholder="Year"
                    onChange={(e) => setCoApplicantDirectFields('year', e.target.value, index, 'vehicles')}/>
               </Table.Cell>
               <Table.Cell><Input type="text" className="height-38" value={ele.licensePlate} placeholder="License Plate"
                    onChange={(e) => setCoApplicantDirectFields('licensePlate', e.target.value, index, 'vehicles')}/>
               </Table.Cell>
               <Table.Cell><Input type="text" className="height-38" value={ele.state} placeholder="State"
                    onChange={(e) => setCoApplicantDirectFields('state', e.target.value, index, 'vehicles')}/>
               </Table.Cell>
               <Table.Cell className="text-center">
                {index > 0 && <Button 
                    positive 
                    className="remove-income-button position-static m-0" 
                    onClick={() => this.removeSegment(application, index)}>
                      <i aria-hidden="true" class="remove circle big icon m-0"></i>
                </Button>} 
               </Table.Cell>
            </Table.Row>
            </>
        )
      })}
    </Table.Body>
  </Table>
  )

  render() {
    const { application,
        setCoApplicantDirectFields,
        setPetNumber
         } = this.props;
    return (
        <>
          <header className="primary-applicant-header">Occupants</header>
          {this.renderOccupantsTable(application, setCoApplicantDirectFields)}
          
          <header className="primary-applicant-header">Pets</header>
          {this.renderPetsTable(application, setCoApplicantDirectFields, setPetNumber)}

          <header className="primary-applicant-header">Vehicles</header>
          {this.renderVehicleTable(application, setCoApplicantDirectFields)}
          {!!this.state.errors.length && (
          <Message visible error>
            <Message.List>
              {this.state.errors.map((item, index) => (
                <Message.Item key={index}>{`${item.field} - ${item.message}`}</Message.Item>
              ))}
            </Message.List>
          </Message>
          )}
            <div className="text-right mt-4">  
              <Button positive className="modal-save-button wid-110" onClick={this.setPreviousData}>
                Previous
              </Button>
              <Button
                positive
                className="modal-save-button mr-0 ml-2 wid-110"
                content="Next"
                onClick={(e) => this.validateAndSubmit(e)}
              />
            {toaster()}
            </div>
        </>
    )
    }
 }
export default (Occupants)
