import React, { useMemo, useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import AccountingTable from "./AccountingTable";
import {  ACCOUNTING, DOCUMENTS_GET_URL} from "../../utils/constants";
import { multipleColumnsSearch } from "../../utils/common";
import InvoicesModal from "../Modals/InvoicesModal";
import VendorsModal from "../Modals/VendorsModal";
import NewBillsModal from "../Modals/NewBillsModal";
import BillDetailsModal from "../Modals/BillDetailsModal"
import PaymentModal from "../Modals/PaymentModal";
import VoidInvoiceModal from "../Modals/VoidInvoiceModal";
import { getClient } from "../../init-apollo-googleFn";
import getAuthToken from "../../store/auth/authUtility";
import ViewInvoiceDetails from "../Accounting/ViewInvoiceDetails";
import { InvoiceInfoAccounting, } from "../../store/person/accounting";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
const invoiceClient = getClient(ACCOUNTING);

export function Table(props) {
  const [filterInput, setFilterInput] = useState("");

  const [toggled, viewChange] = useState(false);
  const [viewInvoiceData, viewDataInvoice] = useState("");
  const [viewInvoicesData, viewDataInvoices] = useState("");
  const locationId = props.propertyId && props.propertyId;
  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    const filteredData = multipleColumnsSearch(
      value,
      props.invoiceCache && props.invoiceCache
    );
    setData(filteredData);
    setFilterInput(value);
  };

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    return "";
  }
  const DateFormat = ({ values }) => {
    return moment(values).format('l')
  };

  const StatusFormat = ({ values }) => {
    if(values === "partiallyPaid") return "Partially Paid"
    if(values === "paid") return "Paid"
    if(values === "created") return "Created"
    if(values === "approved") return "Approved"
    if(values === "voided") return "Voided"
    else return ""
  };

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  /* eslint-disable */
  const columns = useMemo(
    () => [
      {
        Header: "Number",
        // accessor: "number",
        accessor: (item) => <BillDetailsModal bill={item}  user={props.user} location={props} invoice updateInvoice={props.updateInvoice} />,
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ cell: { value } }) => value ? "$" + value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"):null,
      },
      {
        Header: "Amount Remaining",
        accessor: "amountPayable",
        Cell: ({ cell: { value } }) => value ? "$" + value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"):null,
      },
      {
        Header: "Invoice",
        accessor: "invoiceDate",
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : null),
      },
      {
        Header: "Posted",
        accessor: "postedDate",
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : null),
      },
      {
        Header: "Due",
        accessor: "dueDate",
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : null),
      },
      {
        Header: "Actions",
        accessor: (item) => (
          <>
            {/* {item.status === "created" && (
              <InvoicesModal
                propertyId={props.propertyId}
                success={success}
                props={item}
                user={props.user}
              />
            )}
            {item.status === "approved" && (
              <InvoicesModal
                propertyId={props.propertyId}
                success={success}
                user={props.user}
                props={item}
              />
            )} */}
            {item.status !== "voided" && item.status !== "paid" && (
              <PaymentModal
                location={props}
                updateInvoice={props.updateInvoice}
                success={success}
                bills={false}
                bill={item}
                user={props.user}
              />
            )}
            {/* <Button icon="file pdf outline" onClick={download} props={item} /> */}
            {/* <Button
              icon="eye"
              onClick={viewInvoice}
              property={props.property}
              props={item}
            /> */}
          </>
        ),
      },
    ],
    []
  );

  const columnsBills = useMemo(
    () => [
      {
        Header: "ID",
        accessor: (item) => <BillDetailsModal bill={item}  user={props.user} location={props} bills/>,
      },
      {
        Header: "AP Account ID",
        accessor: "APAccountId",
      },
      {
        Header: "Due",
        accessor: "dueDate",
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : ''),
      },
      {
        Header: "Invoice",
        accessor: "invoiceDate",
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : ''),
      },
      {
        Header: "Posting",
        accessor: "postingDate",
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : ''),
      },
      {
        Header: "Number",
        accessor: "invoiceNumber",
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ cell: { value } }) => value && "$" + value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
      },
      {
        Header: "Amount Due",
        accessor: "dueAmount",
        Cell: ({ cell: { value } }) => value && "$" + value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (value ? <StatusFormat values={value} /> : ''),
      },
      {
        Header: "Vendor",
        accessor: "Vendor.name",
      },
      {
        Header: "Action",
        accessor: (item) => item.status !== "paid"  && item.status !== "voided" && <PaymentModal bill={item} user={props.user} location={props} bills={true} />,
      },
      {
        Header: "Edit",
        accessor: (item) => item.status == "created" && <NewBillsModal bill={item} user={props.user}  location={props}/>,
      },
    ],
    []
  );

  /* eslint-disable */
  const columnsVendors = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Account Number",
        accessor: "accNumber",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Terms",
        accessor: "terms",
      },
      {
        Header: "Attachment",
        accessor: "invoiceNumber",
      },
      {
        Header: "Actions",
        accessor: "status",
      },
    ],
    []
  );
  /* eslint-enable */

  const viewInvoice = (e, props) => {
    viewDataInvoices(props);
    if (toggled) {
      viewChange(false);
    }
    if (!toggled) {
      getInvoice(props);
    }
  };

  const getInvoice = (props) => {
    try {
      invoiceClient
        .query({
          query: InvoiceInfoAccounting,
          variables: {
            location: locationId,
            id: props.props._id,
          },
        })
        .then((res) => {
          if (res.data) {
            const data = res.data.slLocationInvoice
            /* eslint-disable */
            const Check = data.payments.forEach((payment) => {
              const check = data.payments.filter(
                (pay) => pay.externalCheckBankId === payment._id
              );
              if (check.length > 0) {
                return payment.status = "refund";
              }
            });
            /* eslint-enable */
            viewDataInvoice(
              res.data.slLocationInvoice && res.data.slLocationInvoice
            );
            viewChange(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
    }
  };

  const download = (e, props) => {
    const url = new URL(ACCOUNTING);
    url.searchParams.append("invoice_id", props.props._id);
    url.searchParams.append("action", "invoice");
    axios({
      method: "get",
      url: url.href,
      headers: {
        authorization: getAuthToken(),
      },
      responseType: "blob", // important
    })
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" });
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [data, setData] = useState([]);
  const [lastPage, setLastPage] = useState();

  const fail = () =>
    toast.error("No agent with this ID exists in our system", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const success = () =>
    toast.success("Success!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  /* eslint-disable */
  useEffect(() => {
    const result = props.totalItems / 30;
    setLastPage(Math.ceil(result));
    setData(props.invoiceCache && props.invoiceCache);
  }, [props.invoiceCache && props.invoiceCache]);
  /* eslint-enable */

  return (
    <>
      {!toggled ? (
        <> 
        {
          !props.bills && !props.vendors &&
          <InvoicesModal
            dataPush={props.dataPush}
            fail={fail}
            user={props.user}
            success={success}
            propertyId={props.propertyId}
          />

        }
        {
          props.vendors &&
          <>
            <VendorsModal
              dataPush={props.dataPush}
              fail={fail}
              user={props.user}
              success={success}
              propertyId={props.propertyId}
            />
                    {/* <Button style={{paddingTop:'0px', paddingBottom:"0px", height:"36px", marginTop:'5px'}} className="noPrint" onClick={() => window.print()}>Print</Button> */}
          </>
        }
        {
          props.bills &&
          <>
            <NewBillsModal
              user={props.user}
              getLedger={props.getLedger}
              dataPush={props.dataPush}
              fail={fail}
              success={success}
              propertyId={props.propertyId}
              id={props.staticId}
            />
            {/* <Button style={{paddingTop:'0px', paddingBottom:"0px", height:"36px", marginTop:'5px'}} className="noPrint" onClick={() => window.print()}>Print</Button> */}
          </>
        }
        <Button style={{paddingTop:'0px', paddingBottom:"0px", height:"36px", marginTop:'5px'}} className="noPrint" onClick={() => window.print()}>Print</Button>

          <br></br>
           <main className="main-content" role="main">
        <div className="roll-container">
    <div className="table-container">
          <AccountingTable
            value={filterInput}
            defaultColumn={defaultColumn}
            onChange={handleFilterChange}
            columns={(!props.bills &&!props.vendors &&columns)|| (props.bills && columnsBills)||(props.vendors && columnsVendors)}
            data={data}
            getLedger={props.getLedger}
            totalItems={props.totalItems}
            pageCount={lastPage}
            lastPage={lastPage}
          />
          </div>
          </div>
          </main>
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
          />
        </>
      ) : (
        <ViewInvoiceDetails
          property={props.property}
          invoice={viewInvoiceData}
          invoices={viewInvoicesData}
          ledgerView={viewInvoice}
        />
      )}
      {/* </div> */}
    </>
  );
}
