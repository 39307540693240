import React, { Component } from "react";
import { withApollo } from "react-apollo"
import { leadsListGql, updateBulkEdit } from "../../store/person/leads";
import moment from 'moment';
import { PageLoader } from "../../components/Loader/PageLoader";
import { getClient } from "../../init-apollo-googleFn";
import { LEADS_URL, ADD_LEAD_STATUS } from '../../utils/constants';
import Table from '../../components/Leads/Table';
import "./Leads.scss"
import { Card, Button, Popup} from 'semantic-ui-react';
import { Pie, Line } from 'react-chartjs-2';
import CreateLeads from './CreateLead';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BulkEditModal from "../../components/Modals/BulkEditModal";
import mixpanel from 'mixpanel-browser';
const LeadsQuery = getClient(LEADS_URL);

class Leads extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: '',
      sending:false,
      startDate: new Date(),
      selectedPrimary: this.props.selectedPrimary ? this.props.selectedPrimary.node : '',
      leadsList: [],
      waitlistLeads: [],
      loading: true,
      selectedLeadsForBulkEdit: [],
      disableBulkEdit: true,
      isWaitlist: false,
      isShowing: false,
      toastOptions: {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        newestOnTop: false,
        rtl: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    }

  }

  componentDidMount() {
    this.fetchLeadsListing();
    mixpanel.track('Manager Page Load', {
      'sub': 'Leads'
     });

  }


  static getDerivedStateFromProps(props, state) {
    const newState = {};
    const pathname = props.location.pathname;
    if (state.isWaitlist !== pathname.includes('waitlist')) {
      newState['isWaitlist'] = pathname.includes('waitlist');
    }
    if (props.primaryLocations) {
      const nodeData = props.selectedPrimary&&props.selectedPrimary.node;
      if (nodeData !== state.selectedPrimary) {
        newState['loading'] = true;
        newState['selectedPrimary'] = nodeData;
      }
    }
    return newState;
  }

  componentDidUpdate(props) {
    if (props.selectedPrimary&&props.selectedPrimary.node !== this.state.selectedPrimary) {
      this.setState({ selectedLeadsForBulkEdit: [] });
      this.fetchLeadsListing();
    }
  }
  redirectToView = (item) => {
    const { node} = this.props.selectedPrimary
    const { id } = item.node
    this.props.history.push({
      pathname: '/viewLead/location=' + node.id + '/id=' + id,
      isWaitlist: this.state.isWaitlist,
      state: { item }
    })
  }

  toggleModal = () => {
    mixpanel.track('Manager Lead Action', {
      'sub': 'Add Lead Modal Button Click'
    });
    this.setState(prevState => ({ isShowing: !prevState.isShowing }))
  }

  /* function to delete the unchecked leads from the selectedLeadsForBulkEdit array */
  removeUncheckedLeads = (data, id) => {
    const tempArr = [...data];
    const index = tempArr.indexOf(id);
    if (index !== -1) {
      tempArr.splice(index, 1);
      this.setState({ selectedLeadsForBulkEdit: tempArr }, () => {
        if (this.state.selectedLeadsForBulkEdit.length === 0) {
          this.setState({ disableBulkEdit: true });
        }
      });
    }
  }

  /* function to add checked leads in the selectedLeadsForBulkEdit Array */
  addCheckedLeads = (data, id) => {
    const joined = data.concat(id);
    this.setState({
      selectedLeadsForBulkEdit: joined,
      disableBulkEdit: false
    });
  }

  /* function called on the click of the select checkbox for leads
    checks if the lead is already checked, if yes, calls removeUncheckedLeads,
    if not, calls addCheckedLeads and add the lead in the array */
  handleMultipleCheckboxSelection = (item, e) => {
    const { selectedLeadsForBulkEdit } = this.state;
    const isPresent = selectedLeadsForBulkEdit.includes(item.node.id);
    if (isPresent) {
      this.removeUncheckedLeads(selectedLeadsForBulkEdit, item.node.id)
    } else {
      this.addCheckedLeads(selectedLeadsForBulkEdit, item.node.id)
    }
  }

  /* function called on the selection of the changed status from the bulk edit dropdown
    calls updateBulkEdit mutation */
  updateBulkEdit = async (value) => {
    this.setState({sending:true, loading: true})
    try {
      mixpanel.track('Manager Lead Action', {
        'sub': 'Bulk Update'
      });
      await LeadsQuery.mutate({
        mutation: updateBulkEdit,
        variables: {
          input: {
            lead: {
              ids: this.state.selectedLeadsForBulkEdit, status: value,
              location: this.props.selectedPrimary && this.props.selectedPrimary.node.id
            }
          }
        }
      }).then(res => {
        this.setState({ loading: true, selectedLeadsForBulkEdit: [] });
        this.setState({sending:false})
        setTimeout(() => {
          this.fetchLeadsListing();
        }, 200)
      })
    }
    catch (e) {
      this.setState({sending:false})
      console.log(e)
      alert(e)
    }
  }

  /* function to fetch the leads with query leadsGql */
  fetchLeadsListing = () => {
    this.setState({ loading: true })
    try {
      LeadsQuery.query({
        query: leadsListGql,
        variables: {
          location: this.state.selectedPrimary.id
        }
      }).then(res => {
        let waitlistLeads = []
        if (res.data.lead) {
          waitlistLeads = res.data.lead.edges.filter((ele) => ele.node.status === 'Waitlist')
          waitlistLeads = waitlistLeads.sort((a, b) => new Date(a.node.waitlistedOn) - new Date(b.node.waitlistedOn));
        }
        this.setState({ loading: false, disableBulkEdit: true });
        /* eslint-disable */
        this.setState({ leadsList: res.data.lead && res.data.lead.edges || [] });
        /* eslint-enable */
        this.setState({ waitlistLeads });
      })
    }
    catch (e) {
      this.setState({ loading: false });
    }
  };

  /* function called after the creation of a lead
    handles the loader and the success msg */
  changeOnCreateLeadCompletion = (value, msgToshow) => {
    this.setState({ loading: value });
    value && msgToshow && toast.success('Lead created successfully', this.state.toastOptions);
  }
  Filter = (e) => {
    this.setState({ filter: e.target.textContent })
  }

  // TABLE FUNCTIONS ----------------------------------------------


  render() {
    // Table Data ---------------------------------------------------------
    const { leadsList, sending } = this.state
    const data = { Leasera: 0, WalkIn: 0, Apartment: 0 }
    const dataDate = {
      First: 0,
      Second: 0,
      Third: 0,
      Fourth: 0,
      Fifth: 0,
      Sixth: 0,
      Seven: 0
    }

    let newArr = [];
    newArr = leadsList && leadsList.filter(function (el) {
      return el.node.status !== "Lost"
    })
    let FinalFilter = [];
    FinalFilter = newArr && newArr.filter(function (el) {
      return el.node.status !== "Resident"
    })
    const params = this.props.location.pathname;
    const isWaitlist = params.includes('waitlist');
    FinalFilter && FinalFilter.map(lead => {
      switch (lead.node.source) {
        case 'Apartments.com':
          return data.Apartment++;
        case 'Leasera.com':
          return data.Leasera++;
        case 'Walk-In':
          return data.WalkIn++;
        default:
          break;
      }
      return null;
    })

    /* eslint-disable */
    FinalFilter && FinalFilter.map(lead => {
      const value = moment(lead.node.created).format("MM/DD/YYYY")
      if (value === moment().format("MM/DD/YYYY")) {
        return dataDate.First++;
      }
      if (value === moment().subtract(1, 'day').format("MM/DD/YYYY")) {
        return dataDate.Second++;
      }
      if (value === moment().subtract(2, 'day').format("MM/DD/YYYY")) {
        return dataDate.Third++;
      }
      if (value === moment().subtract(3, 'day').format("MM/DD/YYYY")) {
        return dataDate.Fourth++;
      }
      if (value === moment().subtract(4, 'day').format("MM/DD/YYYY")) {
        return dataDate.Fifth++;
      }
      if (value === moment().subtract(5, 'day').format("MM/DD/YYYY")) {
        return dataDate.Sixth++;
      }
      if (value === moment().subtract(6, 'day').format("MM/DD/YYYY")) {
        return dataDate.Seven++;
      }
    })
    /* eslint-enable */

    //  Chart Data ---------------------------------------------------
    const leadsData = {
      labels: [`Leasera: ${data.Leasera}`, `Walk-In: ${data.WalkIn}`, `Apartment.com: ${data.Apartment}`],
      datasets: [{
        data: [
          data.Leasera,
          data.WalkIn,
          data.Apartment,
        ],

        backgroundColor: [
          '#4C158A', // $qp-color-primary
          '#fc913166', // $qp-color-orange
          '#97399966', // $qp-color-purple
        ],

      }],
    };

    const leadSevenDays = {
      labels: [
        moment().subtract(6, 'day').format("M/DD"),
        moment().subtract(5, 'day').format("M/DD"),
        moment().subtract(4, 'day').format("M/DD"),
        moment().subtract(3, 'day').format("M/DD"),
        moment().subtract(2, 'day').format("M/DD"),
        moment().subtract(1, 'day').format("M/DD"),
        moment().format("M/DD")
      ],
      type: "line",
      datasets: [
        {
          data: [
            dataDate.Seven,
            dataDate.Sixth,
            dataDate.Fifth,
            dataDate.Fourth,
            dataDate.Third,
            dataDate.Second,
            dataDate.First,
          ],

          backgroundColor: [
            '#4C158A44', // $qp-color-primary
            '#4C158A55', // $qp-color-primary
            '#4C158A66', // $qp-color-primary
            '#4C158A77', // $qp-color-primary
            '#4C158A88', // $qp-color-primary
            '#4C158A99', // $qp-color-primary
            '#4C158A', // $qp-color-primary
          ],
          label: [
            "Leads Created",
          ]
        }
      ]
    }
    const leadsLegend = {
      display: true,
      position: 'right',
      fullWidth: true,
      reverse: false,
      label: 'Lead Source', // for legend
      labels: {
        fontColor: '$qp-color-darkblue',
      }
    }

    // Button Data -------------------------------------------------------
    const ButtonGroup = () => (
      <div className="button-group-row mt-5 mb-4">
        {/* <Header as="h2">{isWaitlist ? 'Waitlist' : 'Leads'}</Header> */}
        <BulkEditModal values={ADD_LEAD_STATUS} sending={sending} showButton={this.state.disableBulkEdit} updateBulkEdit={this.updateBulkEdit} />
        <div className="lead-action-wrapper">
          
          <Popup content='Export with the click of a button coming soon' trigger={<div><Button style={{cursor:'default'}} disabled className="btn-primary-light mr-3 btn-med">Export</Button></div>} />
          <Popup content='Invite with the click of a button coming soon' trigger={<div><Button style={{cursor:'default'}} disabled  className="btn-primary-light mr-3 btn-med">Invite Renter</Button></div>} />
  
          <Button className="btn-gradient btn-med" onClick={this.toggleModal}>Add Lead</Button>
        </div>
      </div>
    )

    const LeadsCard = () => (
      <div className="card-body">
        <div className="card-column">
          <div className="card-label">Active</div>
          <div className="card-value">{FinalFilter && FinalFilter.filter((obj) => obj.node.status !== "Waitlist").length}</div>
        </div>
        <div className="card-column">
          <div className="card-label">Waitlist</div>
          <div className="card-value">{FinalFilter && FinalFilter.filter((obj) => obj.node.status === "Waitlist").length}</div>
        </div>

        {/* <Grid celled="internally" centered verticalAlign='middle' textAlign="center" >
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={8}>
            <Header as="h4" className="mbottom-10">Active</Header>
            <Header as="h1" className="mtop-0">{FinalFilter && FinalFilter.filter((obj) => obj.node.status !== "Waitlist").length}
            </Header>
          </Grid.Column>
          <Grid.Column textAlign="center" mobile={16} tablet={8} computer={8}>
            <Header textAlign="center" as="h4" className="mbottom-10">Waitlist</Header>
            <Header as="h1" className="mtop-0">{FinalFilter && FinalFilter.filter((obj) => obj.node.status === "Waitlist").length}</Header>
          </Grid.Column>
        </Grid> */}
      </div>
    )
    return (
      this.state.loading ? (
        <PageLoader text size="massive" active inline="centered"/>
      ) : (
      <>
      <main className="main-content" role="main">
        <div className="tenants-container resident-container">
          <>
            <ToastContainer />
            <div className="page-header mb-4">
              <div className="d-flex align-items-center">
                <div>
                  <div className="page-header-title">Leads</div>
                  <div className="page-subtitle-light">Here's the overview of your leads</div>
                </div>
              </div>
            </div>
            <Card.Group itemsPerRow={3} stackable className="primary-cards leads-card mtop-0">
              <Card fluid className="card">
                <Card.Content className="card-content">
                  <Card.Header as="a" href="/#/leads" className="card-header">
                    Active Leads
                  </Card.Header>
                  <Card.Content className="card-content">
                    <LeadsCard />
                  </Card.Content>
                </Card.Content>
              </Card>
              <Card fluid className="card">
                <Card.Content className="card-content">
                  <Card.Header as="a" href="/#/leads" className="card-header">
                    Lead Source
                  </Card.Header>
                  <Card.Content className="card-content card-content-height">
                    {/* eslint-disable */}
                    <Pie
                      height={16}
                      width={100}
                      data={leadsData}
                      options={{
                        maintainAspectRatio: true
                      }, {
                        responsive: true
                      }, {
                        tooltips: {
                          callbacks: {
                            title: function (tooltipItems, data) {
                              return '';
                            },
                            label: function (tooltipItem, data) {
                              var label = data.labels[tooltipItem.index];
                              return label;
                            }
                          }
                        }
                      }} // eslint-disable-line
                      legend={leadsLegend}
                    />
                    {/* eslint-disable */}
                  </Card.Content>
                </Card.Content>
              </Card>
              <Card fluid className="card">
                <Card.Content className="card-content">
                  <Card.Header as="a" href="/#/leads" className="card-header">
                    Active Last 7 Days
                  </Card.Header>
                  <Card.Content className="card-content card-content-height">
                    <Line
                      height={16}
                      width={100}
                      legend={leadsLegend}
                      data={leadSevenDays}
                      options={{
                        maintainAspectRatio: true , 
                        responsive: true ,
                        scales: {
                          yAxes :[{
                            ticks: {
                              stepSize:5
                            }
                          }
                          ]
                        }

                      }}
                        
                             />
                  </Card.Content>
                </Card.Content>
              </Card>
            </Card.Group>
            <ButtonGroup />
          </>
          {this.state.leadsList.length > 0 ?
            <Table leadsData={isWaitlist ? this.state.waitlistLeads : this.state.leadsList} view={this.redirectToView}
            isWaitlist={isWaitlist} Filter={this.Filter} multipleHandling={this.handleMultipleCheckboxSelection}
            selectedLeads={this.state.selectedLeadsForBulkEdit} />
          :
            <div className="no-results-text-application text-center py-5">
              <span className="not-found-icon"></span>
              <p className="no-results-text">
                You don't have any leads for the selected property. Click 'Add Lead' to create new lead.
              </p>
            </div>
          }
      {this.state.isShowing && <CreateLeads props={this.props.user} showModal={this.state.isShowing} onClose={this.toggleModal} disabled={this.state.loading}
          loaderChange={this.changeOnCreateLeadCompletion} propertyCustomId={this.state.selectedPrimary && this.state.selectedPrimary.id}
          updateLeadsFetch={this.fetchLeadsListing} />}
      </div>
      </main>
      </>
      )
    )
  }
}

export default withApollo(Leads)
