/* eslint-disable */
// @flow
import Cookie from 'js-cookie';
import config from '../../config';
import { getAuthToken, getClient, logoutAction } from '.';
import { ToastContainer, toast } from 'react-toastify';
import {
  getEmitter, TOKEN, PERSON_URL,
  RELOAD, LOGIN, SESSION_TIMEOUT_THRESHOLD,
  USER_EVENT, USER_ROLE_EVENT, RENTER, MANAGER,
  SERVICEPROFESSIONAL, USER_LOC, LOCATIONAPI, EXPIRE_TIME,NOTIFICATION
} from '../../utils';
import { personGql } from '../person/person';
import { auth } from '../../containers/Auth/Firebase';

let sessionTimeout = null;
export const registerAuthToken = (token) => {
  Cookie.set(TOKEN, token, {
    domain: config.domain,
  });
};

// export const refreshToken = () => {
//   auth.currentUser.getIdToken(true)
// }

export const loginAction = (
  { email, password },
  client,
) => auth.signInWithEmailAndPassword(email, password).then(async () => auth.currentUser).catch((e) => {
  console.log('User e-mail id or password is incorrect.');
}); 

const setSessionTimeout = (duration, client) => {
  clearTimeout(sessionTimeout);
  sessionTimeout = setTimeout(
    refreshToken,
    (duration - SESSION_TIMEOUT_THRESHOLD) * 1000, { client },
  );
};

const getToken = (setTokenFound) => {
  const personMainClient = getClient(PERSON_URL)
  messaging.requestPermission().then(function() {
    return messaging.getToken({vapidKey: process.env.REACT_APP_VAPID_KEY}).then((currentToken) => {
      if (currentToken) {
        try {
          personMainClient.mutate({
            mutation: FCMAttachDevice,
            variables: {
              input: {
                device: {
                  deviceType: "WEB",
                  deviceToken: currentToken
                }
              }
            }
          }).then(res => {
            const eventEmit = getEmitter()
            eventEmit.emit(CURRENT_TOKEN, currentToken)
          })
        } catch(e) {
  
        }
  
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  })
}
const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
});

const refreshToken = ({
  client,
}) => {
  const session = getAuthToken();
  if (!session) {
    return Promise.reject();
  }
  onRequestSuccess(client);
};

export const refreshToken2 = () => {
  auth.currentUser.getIdToken(true)
};

export const handleMangerLogin = (client) => {
 return auth.currentUser.getIdTokenResult()
  .then(async (idTokenResult) => {
    if (idTokenResult.claims.manager && idTokenResult.claims.email_verified == true) {
      
      onRequestSuccess(client);
      return true;
    }
    if(idTokenResult.claims.manager && idTokenResult.claims.email_verified == false){
      toast.warning('Please make sure to verify your email.', {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
    return "Verify"
    }
    else return false
  }).catch(() => {return false});
}
  

export const onRequestSuccess = async (client, from = LOGIN) => {
  const time = '3600';
  if (from === LOGIN || (from === RELOAD && getAuthToken())) {
    let authFlag = true;
    if (auth.currentUser) {
      auth.currentUser.getIdTokenResult()
        .then(async (idTokenResult) => {
          if (idTokenResult.claims.manager || idTokenResult.claims.serviceProfessional) {
            auth.onAuthStateChanged(async (user) => {
              if (authFlag) {
                authFlag = false;
                if (user) {
                  const token = await auth.currentUser.getIdToken(true);
                  registerAuthToken(token);
                  getUserRoles();
                  client.resetStore();
                  const eventEmit = getEmitter();
                  if (from === LOGIN) {
                    const personMainClient = getClient(PERSON_URL);
                    try {
                      personMainClient.query({
                        query: personGql,
                      }).then((res) => {
                        eventEmit.emit(USER_EVENT, { data: res.data.person });
                        getToken();
                        onMessageListener().then(payload => {
                          const eventEmit = getEmitter()
                          eventEmit.emit(NOTIFICATION, payload)
                        }).catch(err => console.log('failed: ', err));
                      }).catch((e) => {
                        console.log(e);
                      });
                    } catch (e) {
                      return;
                    }
                  }
                  // expire time is current time  plus 55 minutes more
                  Cookie.set(EXPIRE_TIME, new Date().getTime() + (55 * 60 * 1000), {
                    domain: config.domain,
                  });
                  setSessionTimeout(time, client);
                }
              }
            });
          }
        }).catch(() => {
          console.log('id token catch')
          return false;
        });
    } else {
      console.log('Currrent user unavailable.');
      // logoutAction(client).then(() => setTimeout(() => {
      //   window.location.href = '/'
      // }, 1000))
    }
  }
  
};

export const getUserRoles = async () => {
  auth.currentUser.getIdTokenResult()
    .then((idTokenResult) => {
      if (idTokenResult.claims) {
        const eventEmit = getEmitter();
        Cookie.set(RENTER, idTokenResult.claims.renter, {
          domain: config.domain,
        });
        Cookie.set(MANAGER, idTokenResult.claims.manager, {
          domain: config.domain,
        });
        Cookie.set(SERVICEPROFESSIONAL, idTokenResult.claims.serviceProfessional, {
          domain: config.domain,
        });
        eventEmit.emit(USER_ROLE_EVENT);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export default { loginAction, onRequestSuccess };
