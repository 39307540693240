import React, { Component } from "react";
import get from 'lodash/get';
import { withApollo } from "react-apollo";
import  Table from "../../components/Application/ApplicationData";
import { existingApplications, updateBulkEdit } from "../../store/person/applications"
import { qpDatatables, qpTooltipPopover } from "../../utils/misc";
import { Button, Header } from 'semantic-ui-react';
import { FAKE_APPLICANT, APPLICATION_MANAGER, ADD_APPLICATION_STATUS } from "../../utils/constants";
import FlashMessage from "../../components/Messages/FlashMessage";
import "./Applications.scss"
import moment from 'moment';
import { getClient } from "../../init-apollo-googleFn";
import { PageLoader } from "../../components/Loader/PageLoader";
import BulkEditModal from "../../components/Modals/BulkEditModal";
import mixpanel from 'mixpanel-browser';

const newClient = getClient(APPLICATION_MANAGER)

class ExistingApplications extends Component {
  constructor(props) {
    super(props)
    this.state = {
      applicant: FAKE_APPLICANT,
      node: {status: ''},
      appId: null,
      isError: false,
      message: "",
      applications: [],
      loading: false,
      signLoader: false,
      index: 0,
      preLease: false,
      selectedAppsForBulkEdit: [],
      disableBulkEdit: true,
      sending:false,
      selectedPrimary: this.props.selectedPrimary ? this.props.selectedPrimary.node : '',
    }
  }

  componentDidMount() {
    qpDatatables();
    qpTooltipPopover();
    this.props.selectedPrimary.node && this.props.selectedPrimary.node.customId && this.getApplications();
    mixpanel.track('Manager Page Load', {
      'sub': 'Existing Applications'
     });
  }



  static getDerivedStateFromProps(props, state) {
    const newState = {};
    if (props.primaryLocations) {
      const nodeData = props.selectedPrimary.node;
      if (nodeData !== state.selectedPrimary) {
        newState['loading'] = true;
        newState['selectedPrimary'] = nodeData;
      }
    }
    return newState;
  }

  componentDidUpdate(props) {
    if (props.selectedPrimary.node !== this.state.selectedPrimary) {
      this.setState({ selectedAppsForBulkEdit: [] });
      this.getApplications();
    }
  }

  setMessage = (message, isError=false) => {
    this.setState({message, isError})
  }

  closeMessage = () => {
    this.setState({message: ''})
  }

  redirectToView = (item) => {
    const { customId } = this.props.selectedPrimary.node
    const { id } = item.node
    this.props.history.push({
      pathname: '/viewApplication/location=' + customId + '/id=' + id,
      state: { item },
      data: this.state.applications
    })
  }

  getApplications = () => {
    this.setState({ loading: true })
    try {
      newClient.query({
        query: existingApplications,
        variables: { 
            locationId: this.state.selectedPrimary.id,
            status: "Current Resident"
        }
      }).then(response => {
        this.setState({ loading: false});

        const applications = get(response, 'data.applications.edges', [])
        const filteredApp = applications.filter(item => item.node.isLeaseCreated !== true)
        this.setState({ applications:filteredApp });
      }).catch(error => {
        this.setState({ loading: false })
        // this.setMessage(parseGraphQLErrors(error), true)
      });
    } catch (e) {
      console.log(e);
    }
  }

  openApplicationForm = () => {
    this.props.history.push({
      pathname: '/applicationForm',
    })  
  };

  /* function to delete the unchecked leads from the selectedAppsForBulkEdit array */
  removeUncheckedLeads = (data, id) => {
    const tempArr = [...data];
    const index = tempArr.indexOf(id);
    if (index !== -1) {
      tempArr.splice(index, 1);
      this.setState({ selectedAppsForBulkEdit: tempArr }, () => {
        if (this.state.selectedAppsForBulkEdit.length === 0) {
          this.setState({ disableBulkEdit: true });
        }
      });
    }
  }

  /* function to add checked leads in the selectedAppsForBulkEdit Array */
  addCheckedLeads = (data, id) => {
    const joined = data.concat(id);
    this.setState({
      selectedAppsForBulkEdit: joined,
      disableBulkEdit: false
    });
  }

  /* function called on the click of the select checkbox for leads
    checks if the lead is already checked, if yes, calls removeUncheckedLeads,
    if not, calls addCheckedLeads and add the lead in the array */
  handleMultipleCheckboxSelection = (item, e) => {
    const { selectedAppsForBulkEdit } = this.state;
    const isPresent = selectedAppsForBulkEdit.includes(item.node.id);
    if (isPresent) {
      this.removeUncheckedLeads(selectedAppsForBulkEdit, item.node.id)
    } else {
      this.addCheckedLeads(selectedAppsForBulkEdit, item.node.id)
    }
  }

   /* function called on the selection of the changed status from the bulk edit dropdown 
    calls updateBulkEdit mutation */
    updateBulkEdit = async (value) => {
      this.setState({sending:true, loading: true})
      try {
        mixpanel.track('Manager Application Action', {
          'sub': 'Bulk Edit Existing Applications'
        });
        await newClient.mutate({
          mutation: updateBulkEdit,
          variables: {
            input: {
              application: {
                ids: this.state.selectedAppsForBulkEdit, status: value,
                locationId: this.props.selectedPrimary && this.props.selectedPrimary.node.id
              }
            }
          }
        }).then(res => {
          this.setState({ loading: true, selectedAppsForBulkEdit: [] });
          this.setState({sending:false})
          setTimeout(() => {
            this.getApplications();
          }, 200)
        })
      }
      catch (e) {
        alert(e)
        this.setState({sending:false, loading: false})
        console.log(e)
      }
    }

    // Button Data -------------------------------------------------------
  ButtonGroup = () => (
    <div className="button-group-row">
      <Header as="h2">Applications</Header>
      <div className={`lead-action-wrapper ${ this.state.applications.length === 0 && 'pr-0' }`}>
        <Button className="dark-button">Export</Button>
        <BulkEditModal values={ADD_APPLICATION_STATUS} sending={this.state.sending} showButton={this.state.disableBulkEdit} updateBulkEdit={this.updateBulkEdit} />
        <Button className="dark-button" onClick={this.openApplicationForm}>Add Applicant</Button>
      </div>
    </div>
    )
    

  render() {
    const { message, isError, applications, loading } = this.state;

    const dataDate = {
      First: 0,
      Second: 0,
      Third: 0,
      Fourth: 0,
      Fifth: 0,
      Sixth: 0,
      Seven: 0
    }
    /* eslint-disable */
    applications && applications.map(applicant => {
      const value = moment(applicant.node.created).format("MM/DD/YYYY")
      if (value === moment().format("MM/DD/YYYY")) {
        return dataDate.First++;
      }
      if (value === moment().subtract(1, 'day').format("MM/DD/YYYY")) {
        return dataDate.Second++;
      }
      if (value === moment().subtract(2, 'day').format("MM/DD/YYYY")) {
        return dataDate.Third++;
      }
      if (value === moment().subtract(3, 'day').format("MM/DD/YYYY")) {
        return dataDate.Fourth++;
      }
      if (value === moment().subtract(4, 'day').format("MM/DD/YYYY")) {
        return dataDate.Fifth++;
      }
      if (value === moment().subtract(5, 'day').format("MM/DD/YYYY")) {
        return dataDate.Sixth++;
      }
      if (value === moment().subtract(6, 'day').format("MM/DD/YYYY")) {
        return dataDate.Seven++;
      }
    })
    /* eslint-enable */
    //  Chart Data ---------------------------------------------------

    return (
      <>
      <main className="main-content" role="main">
        <div className="roll-container">
      {
        loading && <PageLoader />
      }
      
      { !loading && applications.length > 0 &&
        <>
          <Table 
            applications={applications}
            multipleHandling={this.handleMultipleCheckboxSelection}
            view={this.redirectToView}
            selectedAppsForBulkEdit={this.state.selectedAppsForBulkEdit}
            />
        </>
      }
      { !loading && applications.length === 0 &&
        <div className="no-results-text-application text-center py-5">
          <span className="not-found-icon"></span>
          <p className="no-results-text">
            You don't have any application for the selected property. Click 'Add Applicant' to create new application or create application from lead details page.
          </p>
        </div>
      }
      <FlashMessage message={message} isError={isError} closeMessage={this.closeMessage}/>
      </div>
      </main>
      </>
    )
  }
}

export default withApollo(ExistingApplications)