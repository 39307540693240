import React from 'react'
import { Button, Form, Modal, Grid } from 'semantic-ui-react'
import {VendorCreateMutation} from "../../store/person/accounting";
import { ACCOUNTING } from "../../utils/constants";
import mixpanel from 'mixpanel-browser';
import { getClient } from "../../init-apollo-googleFn";
const accountingClient = getClient(ACCOUNTING );
function VendorsModal(props) {
    const [open, setOpen] = React.useState(false)
    const [show, showMore] = React.useState(false)
    const [name, setName] = React.useState(false)
    const [checkName, setCheckName] = React.useState(false)
    const [accountNumber, setAccountNumber] = React.useState(false)
    const [email, setEmail] = React.useState(false)
    const [terms, setTerms] = React.useState(false)
    const [EIN, setEIN] = React.useState(false)
    const [addressLabel, setAddressLabel] = React.useState(false)
    const [address, setAddress] = React.useState(false)
    const [addressCity, setAddressCity] = React.useState(false)
    const [addressState, setAddressState] = React.useState(false)
    const [addressZip, setAddressZip] = React.useState(false)
    const [contactName, setContactName] = React.useState(false)
    const [contactEmail, setContactEmail] = React.useState(false)
    const [contactPhone, setContactPhone] = React.useState(false)

    const createVendor = (e) => {
        const Addresses = [
            {
                label: addressLabel,
                line1:address,
                city: addressCity,
                state: addressState,
                zip: addressZip,
                country:"US",
                isVerified:false
            }
        ]
        const Contacts = [
            {
                name: contactName,
                email: contactEmail,
                phone: contactPhone 
            }
         ]
          try {
            mixpanel.track('Manager Accounting Action', {
                'sub': 'Create New Vendor'
              });
            accountingClient
              .mutate({
                mutation: VendorCreateMutation,
                variables: {
                  input: {
                    name:name,
                    location:props.propertyId,
                    nameOnCheck:checkName,
                    accNumber:accountNumber,
                    email:email,
                    currency:"USD",
                    terms:terms,
                    EIN:EIN,
                    Addresses,
                    Contacts
                  },
                },
              })
              .then((response) => {
                // window.location.reload();
                setOpen(false);
              });
          } catch (e) {alert(e)}
      };




    return (
      <Modal
      className="semanticModal"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button className="noPrint">Add Vendor</Button>}
      >
        <Modal.Header>Add Vendor</Modal.Header>
        <Modal.Content style={{margin:"15px"}}>
        <Form>
        <Form.Group widths='equal'>
            <Form.Field>
                <label>Name</label>
                <input placeholder='Name' onChange={(e)=>setName(e.target.value)} />
            </Form.Field>
            <Form.Field>
                <label>Name on Check</label>
                <input placeholder='Name on Check' onChange={(e)=>setCheckName(e.target.value)} />
            </Form.Field>
        </Form.Group>

        <Form.Group widths='equal'>
            <Form.Field>
                <label>Account Number</label>
                <input placeholder='Account Number' onChange={(e)=>setAccountNumber(e.target.value)} />
            </Form.Field>
            <Form.Field>
                <label>Email</label>
                <input placeholder='Email' onChange={(e)=>setEmail(e.target.value)} />
            </Form.Field>
        </Form.Group>

        <Form.Group widths='equal'>
            <Form.Field>
                <label>Terms</label>
                <input placeholder='Terms' onChange={(e)=>setTerms(e.target.value)}/>
            </Form.Field>
            <Form.Field>
                <label>EIN/SSN</label>
                <input placeholder='EIN/SSN' onChange={(e)=>setEIN(e.target.value)}/>
            </Form.Field>
        </Form.Group>

        <Grid columns="2">
        <Grid.Column>
            {/* eslint-disable */}
            <a onClick={show ?() =>showMore(false):() =>showMore(true)}>Add Address &#x2193;</a>
            {/* eslint-enable */}
         <br></br><br></br>
        </Grid.Column>
        <Grid.Column>
            {/* eslint-disable */}
            <a onClick={show ?() =>showMore(false):() =>showMore(true)}>Add Contact &#x2193;</a>
            {/* eslint-enable */}
         <br></br><br></br>
        </Grid.Column>
        </Grid>
        {
            show && 
            <>
       
        <Form.Group widths='equal'>
            <Form.Field>
                <label>Label</label>
                <input placeholder='Terms' onChange={(e)=>setAddressLabel(e.target.value)} />
            </Form.Field>
            <Form.Field>
                <label>Name</label>
                <input placeholder='Terms' onChange={(e)=>setContactName(e.target.value)} />
            </Form.Field>
        </Form.Group>

        <Form.Group widths='equal'>
            <Form.Field>
                <label>Address</label>
                <input placeholder='Terms'onChange={(e)=>setAddress(e.target.value)} />
            </Form.Field>
            <Form.Field>
                <label>Email</label>
                <input placeholder='Terms' onChange={(e)=>setContactEmail(e.target.value)} />
            </Form.Field>
        </Form.Group>

        <Form.Group widths='equal'>
            <Form.Field>
                <label>City</label>
                <input placeholder='Terms' onChange={(e)=>setAddressCity(e.target.value)} />
            </Form.Field>
            <Form.Field>
                <label>Phone</label>
                <input placeholder='Terms' onChange={(e)=>setContactPhone(e.target.value)} />
            </Form.Field>
        </Form.Group>

        <Form.Group widths='6'>

            <Form.Field>
                <label>State</label>
                <input placeholder='Terms' maxLength='2' onChange={(e)=>setAddressState(e.target.value)} />
            </Form.Field>
            <Form.Field>
                <label>Zip</label>
                <input placeholder='Terms' onChange={(e)=>setAddressZip(e.target.value)} />
            </Form.Field>
        </Form.Group>
        </>
    }
        </Form>
        </Modal.Content>
        <Modal.Actions>
            {/* eslint-disable */}
            <Button
                content="Submit"
                onClick={() => createVendor()}
                positive
                disabled={!name || name && !name.trim()}
            />
            {/* eslint-enable */}
        </Modal.Actions>
      </Modal>
    )
  }

export default VendorsModal