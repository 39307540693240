import React, { useState } from "react";
import {
  Button,
  Modal,
  Dropdown,
  Header,
  Segment,
  List,
  Form,
  Icon,
  Grid,
} from "semantic-ui-react";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "./moveLeadModal.scss";
import moment from "moment";
import { toastFailMsg} from "../../utils/common";
import mixpanel from 'mixpanel-browser';
import {
  UpdateInvoiceMutation,
  ChargeAccounting,
  ManagerInvoice,
  CreateInvoiceMutation,
} from "../../store/person/accounting";
import { ACCOUNTING,LEASE_MANAGER } from "../../utils/constants";

const chargeClient = getClient(ACCOUNTING);
const managerClient = getClient(LEASE_MANAGER);


export default function InvoicesModal(props) {
  const [open, setOpen] = useState(false);
  const [charges, chargesData] = useState(false);
  const [chargeData, chargeCodeData] = useState([]);
  const [managerData, managerCodeData] = useState([]);
  const [refCode, managerCode] = useState(false);
  const [name, nameCode] = useState(false);
  const [loading, setLoader] = useState(false);
  const [date, setDate] = useState(false);
  const [notes, addNotes] = useState(false);
  const createInvoice = (e) => {
    setLoader(true)
    e.preventDefault();
    if (props.props) {
      try {
        mixpanel.track('Manager Accounting Action', {
          'sub': 'Update Invoice'
        });
        chargeClient
          .mutate({
            mutation: UpdateInvoiceMutation,
            variables: {
              input: {
                location: props.propertyId,
                id: props.props._id,
                notes: notes,
              },
            },
          })
          .then((response) => {
            props.success()
            setLoader(false)
            setOpen(false);
          })
          .catch((error) => {
            toastFailMsg(error)
      setLoader(false)
          });
      } catch (e) {
        toastFailMsg(e)
        setOpen(false)
      setLoader(false)
      }
    } else {
      try {
        mixpanel.track('Manager Accounting Action', {
          'sub': 'Create Invoice'
        });
        setLoader(true)
        chargeClient
          .mutate({
            mutation: CreateInvoiceMutation,
            variables: {
              input: {
                location: props.propertyId,
                AgentId: parseInt(name),
                reference:refCode,
                dueDate: moment(date).format("YYYY-MM-DD"),
                InvoiceLineItems: chargeData,
              },
            },
          })
          .then((response) => {
            toastFailMsg()
            if (response.data.createSlInvoice.response.includes("error")){
              setOpen(false);
              setLoader(false)
              props.fail()
            }
            else {
              props.success()
              setLoader(false)
              props.dataPush(response);
              setOpen(false);
            }
          })
          .catch((error) => {
            console.log(error)
            
            error.graphQLErrors.map(({ message }, i) => (
              toastFailMsg(message)
            ))
      setLoader(false)
          });
      } catch (e) {
        
        toastFailMsg(e)
        setOpen(false)
      setLoader(false)
      }
    }
  };
   const handleSourceChange = (event,{ value, text}) => {
    nameCode(value.agent)
    managerCode(value.ref)
}
  const getManager = () => {
    try {
      managerClient
        .query({
          query: ManagerInvoice,
          variables: {
            locationId: props &&props.propertyId, 
          },
        })
        .then((res) => {
          managerCodeData(res.data.invoiceCustomers)
        })
        .catch((error) => {
          toastFailMsg(error)
    setLoader(false)
        });
    } catch (e) {
      toastFailMsg(e)
    setLoader(false)
    }

  }
  const getLedger = () => {
    chargesData('')
    chargeCodeData([])
    nameCode('')
    setDate('')
     addNotes('')
     getManager()
    try {
      chargeClient
        .query({
          query: ChargeAccounting,
          variables: {
            location: props.propertyId, 
          },
        })
        .then((res) => {
          if (res.data) {
            chargesData(get(res, "data.chargeCodes.edges", null));
          }
        })
        .catch((error) => {
          toastFailMsg(error)
      setOpen(false)
    setLoader(false)
        });
    } catch (e) {
      toastFailMsg(e)
      setOpen(false)
    setLoader(false)
    }
  };

  const SetData = (e, { name, value, text }) => {
    const resultArray = value.map((elm) => ({
      TaxCodeId: elm.extTaxcodeId,
      quantity: parseInt(elm.quantity),
      unitAmount: elm.amountDefault ? elm.amountDefault.toString() : 0,
      description: elm.desc,
      LedgerAccountId: parseInt(elm.extLedgerId),
    }));
    chargeCodeData(resultArray);
  };
  const chargeOptions =
    charges &&
    charges.map((charge) => ({
      key: charge.nodename,
      text: charge.node.name,
      value: charge.node,
    }));

  return (
    <Modal
      className="semanticModal "
      onOpen={() => setOpen(true)}
      open={open}
      style={{overflow:"visible"}}
      size="small"
      trigger={
        <Button onClick={getLedger} icon>
          <Icon name="add circle" />
        </Button>
      }
    >
      <Modal.Header
        textAlign="left"
        className="modal-header-bg position-sticky"
      >
        <Header
          className="modal-heading-custom position-sticky"
          textAlign="left"
        >
          {props.props ? "Add Note" : "Create Manual Invoice"}
        </Header>
      </Modal.Header>
      <Modal.Content image>
        <Modal.Description className="w-100">
          <Grid columns="equal" >
            <Grid.Column>
              <Form color="blue">
                {props.props && (
                  <Form.Field>
                    <label>Notes</label>
                    <input
                      placeholder="Insert Notes"
                      value={notes ? notes : ""}
                      onChange={(e) => addNotes(e.target.value)}
                    />
                  </Form.Field>
                )}
                {!props.props && (
                  <>
                  <Form.Field required className="height-38">
                   <label>Renter Code</label>
                   <Dropdown
                      className="w-100"
                      placeholder='Renter'
                      openOnFocus={false}
                      selection
                      onChange={handleSourceChange}
                      options={managerData &&managerData.map(val => (
                          { key: val.referenceId, text: val.name, value: {agent:val.agentId, ref:val.referenceId} }
                      ))}
                  />
                                    </Form.Field>
                    <Form.Field required className="height-38">
                      <label>Date</label>
                      <Datetime
                        timeFormat={false}
                        onChange={(e) => setDate(e._d)}
                      />
                    </Form.Field>
                    <Form.Field required>
                      <label>Charge Code</label>
                      <Dropdown
                        onChange={SetData}
                        placeholder="Charge Codes"
                        search
                        multiple
                        selection
                        options={chargeOptions}
                      />
                    </Form.Field>
                  </>
                )}
              </Form>
            </Grid.Column>
            <Grid.Column  style={{maxHeight:"300px" , overflowY:"auto"}}>
              {chargeData &&
                chargeData.map((charge) => {
                  return (
                  <Segment>
                   <Header> {charge.description}</Header>
                   <List as='ul'>
                  {/* <List.Item as='li'>Tax Code Id:{charge.TaxCodeId}</List.Item> */}
                  <List.Item as='li'>Ledger Account: {charge.LedgerAccountId}</List.Item>
                  <List.Item as='li'>Quantity: {charge.quantity}</List.Item>
                  <List.Item as='li'>Amount: {charge.unitAmount}</List.Item>
                   </List>
                  </Segment>
                  )
                })}
            </Grid.Column>
          </Grid>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="modal-close-button"
          content="Close"
          onClick={() => setOpen(false)}
          positive
        />
        {loading ? (
          <Button loading>Loading</Button>
        ) : 
        <>
        {
          !props.props ?
          <Button
            className="modal-save-button"
            content={props.props ? "Update" : "Create"}
            disabled={props.props ? !name ||!date ||!charges:chargeData.length ===0||!date||!name}
            onClick={createInvoice}
            positive
          />
          :
          <Button
          fluid
          className="modal-save-button"
          content={props.props ? "Update" : "Create"}
          disabled={props.props ? !notes:chargeData.length ===0}
          onClick={createInvoice}
          positive
        />

        }
          </>
        }
      </Modal.Actions>
    </Modal>
  );
}
