import React, { Component } from "react";
import get from 'lodash/get'
import { withRouter } from "react-router";
import { PageLoader } from "../../components/Loader/PageLoader";
import { maintenanceGql } from "../../store/person/maintenance"
import { qpDatatables } from "../../utils/misc";
import { Table } from "../../components/Tables/MaintenanceTableData";
import SettingsModal from "../../components/Modals/SettingsModal";
import MaintenanceModal from "../../components/Modals/MaintenanceModal";
import MInviteModal from "../../components/Modals/MInviteModal";
import FlashMessage from "../../components/Messages/FlashMessage";
import './Maintenance.scss'
import { Icon, Header, Button } from 'semantic-ui-react';
import { getCookie } from '../../store/auth/authUtility';
import mixpanel from 'mixpanel-browser';


class Maintenance extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedPrimary: this.props.selectedPrimary ? this.props.selectedPrimary.node : '',
      requests: [],
      message: '',
      email: '',
      showModal: false,
      loading: false,
      isError: false,
      isServiceProfessional: false
    }
  }

  componentDidMount= async()=> {
    
    await this.getMaintenanceRequests()
    qpDatatables()
    mixpanel.track('Manager Page Load', {
      'sub': 'Maintenance'
     });
  }


  
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary ? prevProps.selectedPrimary.node : '';
    if (nodeData !== this.state.selectedPrimary) {
      this.setState({ selectedPrimary: nodeData }, () => {
        this.getMaintenanceRequests();
      })
    }
  }

  setMessage = (message, isError = false) => {
    this.setState({ message, isError })
  }

  setPriority = (priority) => {
    return priority === 'routine' ? 'low' : priority === 'essential' ? 'medium' : priority
  }

  // Cancel submit


  
  // Gathers the Maintenance Requests
  getMaintenanceRequests() {
    const roleType = this.props.role&&this.props.role.serviceProfessional === "true" ? "serviceProfessional" : "manager"
    const statusType = ["Submitted", "Processing", "Scheduled", "Entered", "Completed", "Canceled", "Assigned", "Approval Needed", "Approved"]
    this.setState({ loading: true })
    try {
      this.props.maintenanceClient.query({
        query: maintenanceGql,
        variables: {
          role: roleType,
          status: statusType,
          location: this.props.selectedPrimary.node.id
        }
      }).then(response => {
        this.setState({ loading: false })
        if (response.data.maintenanceRequest){

          this.setState({
            requests: get(response, 'data.maintenanceRequest.edges', null)
  
          }, () => { })
        }
      }).catch(error => {
        this.setState({ loading: false })
      });
    } catch (e) {
      this.setState({ loading: false })
      console.log(e);
    }
  }

  refreshTable=(e)=> {
    this.setState({loading:true})
    const newArray = this.state.requests
    newArray.forEach(value => {
      if(value.node.id === e){
        value.node.status = 'Canceled'
      }
    })
    this.setState({requests:newArray})
    this.setState({loading:false})
  }

  modalOpen = (node) => this.setState({ node })

  // Redirect to view individual request
  redirectToView = (item) => {
    const { id, location } = item.node
    this.props.history.push({
      pathname: '/maintenanceDetail/location=' + location + "/id=" + id,
    })
  }
  openCancelModal = (cancelItem) => {
    this.modalOpen(cancelItem.node)
    document.getElementById("cancel-modal-btn").click()
  }

  cancelIconFormatter = () => {
    return `<i class="fa fa-trash action-icon" title="View" />`
  }

  closeMessage = () => {
    this.setState({ message: '' })
  }

  render() {
    const { message, isError, loading, node } = this.state
    const { primaryLocationClient } = this.props
    const cookie = getCookie()
    return (
      loading ? (
        <PageLoader text />
      ) : (
        <main className="main-content" role="main">
          <div className="roll-container">
            <>
              <Header textAlign="center">Maintenance</Header>
              <Button.Group floated='left'>
                <Button compact icon labelPosition='left' disabled >
                  <Icon name='cog' style={{ paddingTop: "15px" }} />
                  Settings
                </Button>
                {cookie.manager === "true" &&
                  <MInviteModal user={this.props.user} handleInviteSubmit={this.handleInviteSubmit} primary={this.props.selectedPrimary&&this.props.selectedPrimary.node}/>
                }
              </Button.Group>
              <Button.Group floated='right'>
                <MaintenanceModal primary={this.props.selectedPrimary&&this.props.selectedPrimary.node} primaryLocations={this.props.primaryLocations} primaryLocationClient={primaryLocationClient} getMaintenanceRequests={() => this.getMaintenanceRequests()} />
              </Button.Group>
              <br></br><br></br>
              <Table data={this.state} view={this.redirectToView} user={this.props.user} getRequest={this.refreshTable} cancel={this.openCancelModal} />
              <SettingsModal></SettingsModal>
              <button id="cancel-modal-btn" className="invisible" data-target="#CancelModal" data-toggle="modal" onClick={() => this.modalOpen(node)} ></button>
              {message && <FlashMessage message={message} closeMessage={this.closeMessage} isError={isError} />}
            </>
          </div>
        </main>
      )
    );
  }
}

export default withRouter(Maintenance)
