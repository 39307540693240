import React from 'react'

export const References = ({

  verifiedIcon = <span className="text-success"><i className="fa fa-check-circle"></i></span>

}) => (
    <div className="card card-md applicant-history-card">
      <div className="applicant-history-card-title">
        References {verifiedIcon}
      </div>
      <div className="card-media-list " id="references">
        <div className="responsive-table references-table-container">
          <div className="">

            <table className="table table-hover">
              <thead>
              <tr>
                  <th>Name</th>
                  <th>Relation</th>
                  <th>Contact</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-target="Name">Dylan Crocker</td>
                  <td data-target="Relation">Friend</td>
                  <td data-target="Contact">
                    dylan.t.crocker@gmail.com<br />
                    (206) 708-0663
                  </td>
                </tr>
                <tr>
                  <td data-target="Name">Dylan Crocker</td>
                  <td data-target="Relation">Friend</td>
                  <td data-target="Contact">
                    dylan.t.crocker@gmail.com<br />
                    (206) 708-0663
                  </td>
                </tr>
                <tr>
                  <td data-target="Name">Dylan Crocker</td>
                  <td data-target="Relation">Friend</td>
                  <td data-target="Contact">
                    dylan.t.crocker@gmail.com<br />
                    (206) 708-0663
                  </td>
                </tr>
                <tr>
                  <td data-target="Name">Dylan Crocker</td>
                  <td data-target="Relation">Friend</td>
                  <td data-target="Contact">
                    dylan.t.crocker@gmail.com<br />
                    (206) 708-0663
                  </td>
                </tr>
                <tr>
                  <td data-target="Name">Dylan Crocker</td>
                  <td data-target="Relation">Friend</td>
                  <td data-target="Contact">
                    dylan.t.crocker@gmail.com<br />
                    (206) 708-0663
                  </td>
                </tr>
                <tr>
                  <td data-target="Name">Dylan Crocker</td>
                  <td data-target="Relation">Friend</td>
                  <td data-target="Contact">
                    dylan.t.crocker@gmail.com<br />
                    (206) 708-0663
                  </td>
                </tr>
                <tr>
                  <td data-target="Name">Dylan Crocker</td>
                  <td data-target="Relation">Friend</td>
                  <td data-target="Contact">
                    dylan.t.crocker@gmail.com<br />
                    (206) 708-0663
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )