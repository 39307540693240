import React, {
  useMemo, useState, useEffect,
} from 'react';
import { Button} from 'semantic-ui-react';
import { ToastContainer, toast } from 'react-toastify';
import AccountingTable from './AccountingTable';
import { multipleColumnsSearch } from '../../utils/common';
import LedgerCreationAccounting from '../Modals/LedgerCreationAccounting';
import ViewLedgerDetails from '../Accounting/ViewLedgerDetails';
import 'react-toastify/dist/ReactToastify.css';


export function Table(props) {
  const [filterInput, setFilterInput] = useState('');
  const [toggled, viewToggle] = useState(false);
  const [viewLedgerData, viewData] = useState('');

  const handleFilterChange = (e) => {
    const value = e.target.value || '';
    const filteredData = multipleColumnsSearch(value, props.ledgerCache);
    setData(filteredData);
    setFilterInput(value);
  };

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    return '';
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  /* eslint-disable */
  const columns = useMemo(
    () => [
      {
        Header: 'Number',
        accessor: 'number',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Actions',
        accessor: (item) => (
          <>
            <LedgerCreationAccounting
              updateLedger={props.updateLedger}
              propertyId={props.propertyId}
              props={item}
              user={props.user}
              success={success}
            />
            {' '}
            {/* <Button icon="eye" onClick={viewLedger} props={item} /> */}
          </>
        ),
      },
    ],
    [],
  );
  /* eslint-enable */

  const viewLedger = (e, props) => {
    viewData(props);
    if (toggled) {
      viewToggle(false);
    }
    if (!toggled) {
      viewToggle(true);
    }
  };

  const [data, setData] = useState([]);
  const [lastPage, setLastPage] = useState();

  /* eslint-disable */
  useEffect(() => {
    const result = props.ledgerCache !== null ? props.ledgerCache.length / 30 : 0;
    setLastPage(Math.ceil(result));
    setData(props.ledgerCache);
  },[props.ledgerCache && props.ledgerCache]);

  const success = () => toast.success('Success!', {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  const failure = () => toast.error('Something went wrong!', {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  return (
    <>
          {/* eslint-disable */}
          <LedgerCreationAccounting
            dataPush={props.dataPush}
            updateLedger={props.updateLedger}
            ledgerCache={props.ledgerCache}
            propertyData={props.propertyData}
            success={success}
            user={props.user}
            success={failure}
            propertyId={
              props.propertyData.node && props.propertyData.node.id
            }
          />
          {/* eslint-enable */}
                    <Button style={{paddingTop:'0px', paddingBottom:"0px", height:"35px", marginTop:'20px'}} className="noPrint" onClick={() => window.print()}>Print</Button>

    <div className="table-container">
      {!toggled ? (
        <>

        {/* {props.ledgerCache
          && ( */}
          <AccountingTable
            value={filterInput}
            defaultColumn={defaultColumn}
            onChange={handleFilterChange}
            columns={columns}
            data={data?data:[]}
            getLedger={props.getLedger}
            pageCount={lastPage}
            lastPage={lastPage}
          />
          {/* )} */}
            <ToastContainer
              position="top-center"
              autoClose={2000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick={false}
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover={false}
            />
        </>
      ) : (
        <>
          <ViewLedgerDetails
            data={viewLedgerData.props}
            ledgerView={viewLedger}
          />
        </>
      )}
      </div>
    </>
  );
}
