import React, { Component } from 'react'
import {  Form,   Button, Modal , TextArea} from 'semantic-ui-react';
import { withApollo } from "react-apollo";

class ConditionsStatusChangeModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      conditions: '',
      modalOpen: false
    }
  }
  handleClose = () => this.setState({ modalOpen: false })

  handleChange = (name, e) => {
    const { value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  submitConditions = () => {
      this.props.approvalConditions(this.state.conditions, 'Approve w/Conditions')
  }

  inviteForm = () => (
    <Form >
      <TextArea
        className="invite-form-textarea"
        placeholder='Please let us know the conditions of approving the application.'
        name='conditions'
        onChange={(e) => this.setState({ conditions: e.target.value})}
      />
    </Form>
  )
  render() {
    return (
      <>
        <Modal
          className="semanticModal"
          onClose={() => this.handleClose()}
          onOpen={() => this.setState({ modalOpen: true })}
          open={this.state.modalOpen}
          size="tiny"
          trigger={<Button size='large' floated="right" className="dark-button">Approved with Conditions</Button> }
        >
          <Modal.Header textalign="center">Approval Conditions</Modal.Header>
          <Modal.Content >
            <this.inviteForm />
          </Modal.Content>
          <Modal.Actions>
            <Button className="modal-close-button" positive onClick={() => this.handleClose()}>
              Cancel
              </Button>
            <Button type='button' className="modal-save-button" positive content='Submit' onClick={this.submitConditions}>Submit</Button>
          </Modal.Actions>
        </Modal>
      </>
    )
  }
}
export default withApollo(ConditionsStatusChangeModal)
