import React from 'react';
import { pluralize } from '../../../utils/common';

const UnitListItem = ({ unit, unitImg, onClick }) => (
  <div className="card-row" onClick={onClick}>
    <div className="block img-box d-inline-flex">
      <img src={unitImg || 'assets/img/renter-bg.jpg'} alt="unit-img" />
    </div>
    <div className="block">
      <div className="card-titile">{`Unit No: ${unit.number}`}</div>
    </div>
    <div className="block card-aminites">
      <span>{`${Number(unit.bedrooms)} ${pluralize(unit.bedrooms, 'Bed')}`}</span>
      {' '}
      <div className="dot" />
      <span>{`${Number(unit.bathrooms)} ${pluralize(unit.bathrooms, 'Bath')}`}</span>
      {' '}
      <div className="dot" />
      {' '}
      <span>{`${Number(unit.sqft)} sq. ft.`}</span>
    </div>

    <div className="block">
      <div className="black-strip">
        <span>{`$${unit.price.toLocaleString()} /month`}</span>
      </div>
    </div>
  </div>
);
export default UnitListItem;
