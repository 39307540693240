import React from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  Button,
  Grid,
  Header,
  Modal,
} from 'semantic-ui-react';
import { capitalize, get } from 'lodash';
import { NUMERIC_REGEX, UNIT_TYPE_API } from '../../../utils/constants';
import PropertyImages from './PropertyImages';
import { getAuthToken } from '../../../store/auth/authUtility';
import { parseGraphQLErrors, toastFailMsg, toastSuccessMsg } from '../../../utils/common';

const AddFloorPlan = ({
  isOpen,
  onClose,
  onCreateNew,
  locationId,
}) => {
  const { register, handleSubmit, errors } = useForm({ mode: 'onChange' });
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [floorPlanImages, setFloorPlanImages] = React.useState([]);

  const onSubmit = (data) => {
    const {
      type,
      bathrooms,
      bedrooms,
      sqft,
    } = data;

    const formData = new FormData();
    formData.append('query', `mutation create{
        createUnitType(unitType: {
          type: "${type}",
          locationId : "${locationId}",
          bathrooms: ${bathrooms},
          bedrooms: ${bedrooms},
          sqft: ${sqft}
        }){
          response
          unitType{
            id,
            type,
            bathrooms,
            bedrooms,
            sqft,
            photos,
          }
        }
      }`);

    if (floorPlanImages.length) {
      floorPlanImages.forEach((item) => formData.append('', item));
    }

    const requestOptions = {
      method: 'POST',
      headers: { Authorization: getAuthToken() },
      body: formData,
    };

    setIsSubmitting(true);
    fetch(UNIT_TYPE_API, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.data) {
          const newUnitType = get(result, 'data.createUnitType.unitType');
          if (newUnitType) {
            onCreateNew(newUnitType);
            toastSuccessMsg(`${capitalize(type)} Floor Plan is created successfully.`);
          }
        } else if (result.errors) {
          toastFailMsg(result.errors[0].message);
        }
      })
      .catch((error) => toastFailMsg(parseGraphQLErrors(error)))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Modal
      className="semanticModal custom-modal add-floor-modal"
      onClose={onClose}
      open={isOpen}
      closeOnEscape={false}
      closeOnDimmerClick={false}
    >
      <Modal.Header style={{minWidth:'200px'}}>
        <Header>
          Add New Floor Plan
        </Header>
      </Modal.Header>
      <Modal.Content>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="form-group">
                <div className="has-float-label">
                  <input
                    className="form-control"
                    id="type"
                    name="type"
                    type="text"
                    placeholder="Name"
                    autoComplete="nope"
                    ref={
                      register({ required: 'Name is required.' })
                    }
                  />
                  <label htmlFor="type">Name</label>
                </div>
                <ErrorMessage errors={errors} name="type" as="span" className="error-msg" />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="form-group">
                <div className="has-float-label">
                  <input
                    className="form-control"
                    id="bedrooms"
                    name="bedrooms"
                    type="number"
                    placeholder="Bedrooms"
                    autoComplete="nope"
                    maxLength="2"
                    onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                    ref={
                      register({
                        required: 'No. of beds is required.',
                        valueAsNumber: true,
                        pattern: {
                          value: NUMERIC_REGEX,
                          valueAsNumber: true,
                          message: 'Please enter valid number.',
                        },
                      })
                    }
                  />
                  <label htmlFor="bedrooms">Bedrooms</label>
                </div>
                <ErrorMessage errors={errors} name="bedrooms" as="span" className="error-msg" />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="form-group">
                <div className="has-float-label">
                  <input
                    className="form-control"
                    id="bathrooms"
                    name="bathrooms"
                    type="number"
                    placeholder="Bathrooms"
                    autoComplete="nope"
                    max={99}
                    maxLength="2"
                    onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                    ref={
                      register({
                        required: 'No. of baths is required.',
                        valueAsNumber: true,
                        pattern: {
                          value: NUMERIC_REGEX,
                          message: 'Please enter valid number.',
                        },
                      })
                    }
                  />
                  <label htmlFor="name">Bathrooms</label>
                </div>
                <ErrorMessage errors={errors} name="bathrooms" as="span" className="error-msg" />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="form-group">
                <div className="has-float-label">
                  <input
                    className="form-control"
                    id="sqft"
                    name="sqft"
                    type="number"
                    placeholder="Size (Sq. Ft.)"
                    autoComplete="nope"
                    onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                    ref={register({
                      required: 'Size is required.',
                      valueAsNumber: true,
                    })}
                  />
                  <label htmlFor="sqft">Size (Sq. Ft.)</label>
                </div>
                <ErrorMessage errors={errors} name="sqft" as="span" className="error-msg" />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <PropertyImages title="Upload photos of your floor plan" images={floorPlanImages} setImages={setFloorPlanImages} isMultiple />
            </Grid.Column>
            <div className="btn-container">
              <Button type="button" positive className="modal-close-button" content="Close" onClick={onClose} />
              <Button type="submit" loading={isSubmitting} positive className="modal-save-button" content="Submit" />
            </div>
          </Grid>
        </form>
      </Modal.Content>
    </Modal>
  );
};

export default AddFloorPlan;
