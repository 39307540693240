import gql from 'graphql-tag';

export const leadsGql = gql`
query Lead($location: String!){
  lead(location: $location) {
    edges {
      node {
        firstName
        id
        lastName
        email
        comments
        waitlistedOn
        affordable
        assign {
          id
          firstName
          ndbId
          lastName
          phoneMobile
          email
        }
        phoneHome
        address{
          addressOne
          addressTwo
          city
          state
          postcode
          country
        }
        affordHousing
        preference {
          unitType {
            id
            type
            bedrooms
            bathrooms
          }
          unit {
            number
            id
            level
          }
          moveInDate
          adults
          children
          pets
          area{
            city
            state
          }
        }
        phoneMobile
        phoneHome
        created
        source
        status
        dateOfBirth
      }
    }
  }
}
`;

export const leadsListGql = gql`
query Lead($location: String!){
  lead(location: $location) {
    edges {
      node {
        firstName
        id
        lastName
        email
        waitlistedOn
        affordable
        assign {
          id
          firstName
          ndbId
          lastName
          phoneMobile
          email
        }
        preference {
          unitType {
            id
            type
            bedrooms
            bathrooms
          }
          unit {
            number
            id
            level
          }
        }
        affordHousing
        phoneMobile
        created
        source
        status
      }
    }
  }
}
`;

export const leadsTemplateGql = gql`
query Lead($location: String!){
  lead(location: $location) {
    edges {
      node {
        primaryLocation {
          email {
            templates
          }
        }
      }
    }
  }
}
`;
export const leadHistory = gql`
query Comments($leadId: String!){
  comments(leadId: $leadId){
    edges{
      node{
        id
        lead {
          id
          email
          lastName
          phoneHome
          firstName
          phoneMobile
          created
          updated
        }
        commentType
        person {
          id
          firstName
          lastName
          email
        }
        subject
        important
        message
        created
        updated
      }
    }
  }
}
`;

export const createComment = gql`
mutation CreateComment($input: CreateLeadCommentInput!) {
  createComment(input: $input) {
    response
    comment {
      id
    }
  }
}
`;

export const leadCommunication = gql`
mutation EmailLead($input: EmailLeadInput!) {
  emailLead(input: $input) {
      response
    
  }
}
`;

export const applicationCommunication = gql`
mutation emailApplication($input: EmailApplicationInput!) {
  emailApplication(input: $input) {
      response
  }
}
`;

export const deleteComment = gql`
mutation DeleteComment($input: DeleteLeadCommentInput!) {
  deleteComment(input: $input) {
    response
  }
}
`;

export const updateComment = gql`
mutation UpdateComment($input: UpdateLeadCommentInput!) {
  updateComment(input: $input) {
    response
    comment {
      id
    }
  }
}
`;

export const leadActivityGql = gql`
query LeadActivity($id: String!){
  leadActivity(id: $id) {
    edges {
      node {
        description
        ndbId
        created
        notes
      }
    }
  }
}`;

export const CreateLead = gql`
  mutation CreateLead($input: CreateLeadInput!) {
    createLead(input: $input) {
      response
      isLeadExistsWithUnit
      lead{
        id
      }
    }
  }
`;

export const UpdateLead = gql`
  mutation UpdateLead($input: UpdateLeadInput!) {
    updateLead(input: $input) {
      response
    }
  }
`;
export const LeadAction = gql`
  mutation LeadActions($input: LeadActionsInput!) {
    leadActions(input: $input) {
      response
    }
  }
`;
export const updateBulkEdit = gql`
  mutation bulkUpdateLead($input: BulkUpdateLeadInput!) {
    bulkUpdateLead(input: $input) {
      response
    }
  }
`;

export const createTour = gql`
mutation createTour($input:CreateLeadTourInput!){
  createTour(input:$input){
    response
  }
}
`;
export const createTourType = gql`
mutation createTourType($input:CreateTourTypeInput!){
  createTourType(input:$input) {
    response
  }
}
`;

export const TourLocation = gql`
query tours($location: String!){
  tours(location: $location){
    edges{
      node{
        id
        connectionInfo
        primaryLocation{
          name
          addresses {
            streetOne
            city
            zip
            state
          }
        }
        name
        lead {
          id
          firstName
        }
        status
        date
        time
      }
    }
  }
}
`;
export const TourSingleLocation = gql`
query tours($location: String!, $id:String!){
  tours(location: $location, id:$id){
    edges{
      node{
        id
        connectionInfo
        name
        lead {
          id
          firstName
        }
        status
        date
        time
      }
    }
  }
}
`;

export const AllTourLocation = gql `
query allTours($location: String!, $fromDate: String!, $toDate:String!){
  allTours(location: $location, fromDate: $fromDate, toDate:$toDate){
    edges{
      node{
        duration
        date
        time
        status
      }
    }
  }
}
`

export const TourTypes = gql`
query TourTypes($location: String!){
  tourTypes(location: $location) {
    edges {
      node {
       code
       id
       duration
       connectionInfo
       name
       selfBooking
       createdBy {
         firstName
         lastName
       }
       created
       updated
      }
    }
  }
}`;

export const updateTour = gql`
mutation updateTour($input:UpdateLeadTourInput!){
  updateTour(input:$input) {
    response
    tour{
        id
        name
        status
        date
        time
    }
  }
}
`;
export const deleteTourType = gql`
mutation deleteTourType($input:DeleteTourTypeInput!){
  deleteTourType(input:$input){
    response
  }
}
`;

export const applicationCreation = gql`
mutation sendApplicationInvite($input:SendApplicationInviteInput!){
  sendApplicationInvite(input:$input){
    response
  }
}
`;
