import React, { Component } from 'react';
import { Step } from 'semantic-ui-react'
import { PageLoader } from "../../components/Loader/PageLoader";
import { NavLink } from "react-router-dom";
import { Breadcrumb } from 'semantic-ui-react';
import 'react-toastify/dist/ReactToastify.css';
import ApplicationUnitInfo from './unitInfo';
import PrimaryApplicant from './PrimaryApplicant';
import CoApplicants from './Co-Applicants';
import Review from './Review';
import Occupants from './Occupants';
import { DATE_FORMAT, NUMBER_ZERO, EMAIL_REGEX, LOCATION_UTILS } from "../../utils/constants";
import {  parseGraphQLErrors } from "../../utils/common";
import moment from "moment";
import './Applications.scss';
import { createApplication, updateApplication } from '../../store/person/applications';
import { fetchBackgroundInfo } from '../../store/person/properties';
import toaster from '../../utils/toaster';
import { toast } from 'react-toastify';
import { getClient } from '../../init-apollo-googleFn';
import mixpanel from 'mixpanel-browser';
const BACKGROUND_INFO = getClient(LOCATION_UTILS);

const coapplicantData = {
  firstName: '',
  lastName: '',
  email: '',
  phoneMobile: '',
  drivingLicence: {
    number: '',
    idType: '',
    issuingState: ''
  },
  dateOfBirth: '',
  maritalStatus: '',
  rentalHistory: [
    {
      landlordName: '',
      landlordEmail: '',
      landlordPhone: '',
      isCurrentlyResiding: false,
      monthlyRent: '',
      startDate: '',
      endDate: '',
      address: {
        streetLine1: '',
        streetLine2: '',
        city: '',
        state: '',
        postcode: ''
      }
    }],
  employmentHistory: [{
    title: '',
    startDate: '',
    endDate: '',
    employerName: '',
    monthlyIncome: '',
    supervisorName: '',
    supervisorEmail: '',
    supervisorPhone: '',
    isCurrentlyWorking: false,
    address: {
      streetLine1: '',
      city: '',
      state: '',
      postcode: ''
    },
  }],
  otherIncomes: [{
    source: '',
    monthlyIncome: ''
  }],
  isFinanciallyResponsible: false,
};
const petsData = {
  name: '',
  petType: '',
  weight: '',
  color: '',
  breed: '',
  gender: '',
  age: '',
  service: false
}
const occupantsData = {
  firstName: '',
  lastName: '',
  dateOfBirth: ''
}

class ApplicationForm extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    /* eslint-disable */
    const data = props.location && props.location.data || JSON.parse(localStorage.getItem('appData'));
    const leadData = props.location && props.location.leadData || JSON.parse(localStorage.getItem('leadAppData'));

    localStorage.setItem('appData', JSON.stringify(data));
    localStorage.setItem('leadAppData', JSON.stringify(leadData));
    
    this.state = {
      loading: false,
      sending: false,
      backgroundCheckInfo: [],
      noOfCoApplicants: 0,
      date: '',
      leasingAgent: data && data.leasingAgent && data.leasingAgent.firstName || props.user &&
        props.user.firstName || '',  //TODO: props.user to be removed later after location api changes
      affordableText: data && data.affordableHousingProgram && data.affordableHousingProgram.name || leadData && leadData.affordable || '',
      unitTypeText: data && data.unitType && data.unitType.type || leadData && leadData.preference && leadData.preference.unitType && leadData.preference.unitType.type || '',
      unitNumberText: data && data.unitNumber && data.unitNumber.number || leadData && leadData.preference && leadData.preference.unit && leadData.preference.unit.number || '',
      bathrooms: data && data.unitType && data.unitType.bathrooms || leadData && leadData.preference && leadData.preference.unitType && leadData.preference.unitType.bathrooms,
      bedrooms: data && data.unitType && data.unitType.bedrooms || leadData && leadData.preference && leadData.preference.unitType && leadData.preference.unitType.bedrooms,
      level: data && data.unitNumber && data.unitNumber.level || leadData && leadData.preference && leadData.preference.unit && leadData.preference.unit.level || '',
      formSteps: {
        unitInfo: true,
        primaryApplicant: false,
        coApplicant: false,
        occupants: false,
        review: false
      },
      application: {
        locationId: data && data.location || leadData && leadData.location || '',
        targetMoveInDate: data && data.targetMoveInDate || leadData && leadData.preference && leadData.preference.moveInDate || '',
        leasingAgent: data && data.leasingAgent && data.leasingAgent.id || props.user && props.user.ndbId
          || '',       //TODO: props.user to be removed later after location api changes
        unitType: data && data.unitType && data.unitType.id || leadData && leadData.preference && leadData.preference.unitType && leadData.preference.unitType.id || '',
        unitNumber: data && data.unitNumber && data.unitNumber.id || leadData && leadData.preference && leadData.preference.unit && leadData.preference.unit.id || '',
        affordableHousingProgram: data && data.affordableHousingProgram &&
          data.affordableHousingProgram.code || leadData && leadData.affordable || '',
        children: (data && data.children) || (leadData && leadData.preference && leadData.preference.children) || 0,
        petsNumber: (data && data.petsNumber) || (leadData && leadData.preference && leadData.preference.pets) || 0,
        adults: (data && data.adults) || (leadData && leadData.preference && leadData.preference.adults) || 1,
        comment: leadData && leadData.comments || '',
        liquidFilledFurniture: data && data.liquidFilledFurniture,
        smoke: data && data.smoke,
        primaryApplicant: {
          // tenantAlert: false,
          firstName: data && data.primaryApplicant.firstName || leadData && leadData.firstName || '',
          lastName: data && data.primaryApplicant.lastName || leadData && leadData.lastName || '',
          email: data && data.primaryApplicant.email || leadData && leadData.email || '',
          phoneMobile: data && data.primaryApplicant.phoneMobile || leadData && leadData.phoneMobile || '',
          dateOfBirth: data && data.primaryApplicant.dateOfBirth || '',
          drivingLicence: {
            number: '',
            idType: '',
            issuingState: ''
          },
          maritalStatus: data && data.primaryApplicant.maritalStatus || '',
          otherIncomes: [{
            source: '',
            monthlyIncome: ''
          }],
          rentalHistory: [{
            monthlyRent: '',
            landlordName: '',
            landlordEmail: '',
            landlordPhone: '',
            isCurrentlyResiding: false,
            startDate: '',
            endDate: '',
            address: {
              streetLine1: '',
              streetLine2: '',
              city: '',
              state: '',
              postcode: ''
            }
          }],
          employmentHistory: [
            {
              title: '',
              employerName: '',
              monthlyIncome: '',
              startDate: '',
              endDate: '',
              supervisorName: '',
              supervisorEmail: '',
              supervisorPhone: '',
              isCurrentlyWorking: false,
              address: {
                streetLine1: '',
                city: '',
                state: '',
                postcode: ''
              }
            }
          ],
          emergencyContact: {
            name: '',
            email: '',
            relationship: '',
            mobilePhone: '',
            workPhone: '',
            homePhone: '',
            address: {
              streetLine1: '',
              city: '',
              state: '',
              postcode: ''
            }
          },
          backgroundChecks: {
            checks: [],
            comment: ''
          }
        },
        pets: [petsData],
        vehicles: [
          {
            make: '',
            model: '',
            color: '',
            year: '',
            licensePlate: '',
            state: ''
          }
        ],
        occupants: [occupantsData],
        coApplicants: [coapplicantData]
      },
      /* eslint-enable */
    }
  }

  componentDidMount() {
    const { location } = this.props;
    if (location.data || JSON.parse(localStorage.getItem('appData'))) {
      this.updateLocalState();
    }
    if (location.leadData || JSON.parse(localStorage.getItem('leadAppData'))) {
      this.updateLeadLocalState();
    }
    if (!location.backgroundData) {
      this.fetchBackgroundInfo();
    } else {
      this.setState({ backgroundCheckInfo: location.backgroundData });
    }
    mixpanel.track('Manager Page Load', {
      'sub': 'Application Form'
     });
  }

  componentWillUnmount() {
    localStorage.removeItem('appData');
    localStorage.removeItem('leadAppData');
  }

  // checkForTenantAlert = async (type, index) => {
  //   const { applicationManager } = this.props;
  //   const { application } = this.state;
  //   await applicationManager.query({
  //     query: tenantAlertCheck,
  //     variables: {
  //       email: type === 'coApplicants' ? application[type][index].email : application[type].email
  //     }
  //   }).then(res => {
  //     const value = (res.data.decisionSummaryCheck.edges[0].node && res.data.decisionSummaryCheck.edges[0].node.isBackgroundExpired) || false;
  //     if (index || index === 0) {
  //       const newArr = [...application[type]];
  //       newArr[index] = {...newArr[index], tenantAlert: value}
  //       this.setState({ application: {...application, [type]: newArr}}, () => {
  //         if (index === this.state.application.coApplicants.length - 1) {
  //           this.setState({ loading: false })
  //         }
  //       });
  //     } else {
  //       this.setState({ application: {...application, [type]: {...application[type],
  //         tenantAlert: value}}} );
  //       if (index === this.state.application.coApplicants.length - 1) {
  //         this.setState({ loading: false })
  //       }
  //     }
  //   }).catch(error => {
  //     this.setState({ loading: false });
  // })
  // }

  updateLocalState = () => {
    const data = this.props.location.data || JSON.parse(localStorage.getItem('appData'));
    const { application } = this.state;
    let rentalArr = data.primaryApplicant.rentalHistory;
    let employmentArr = data.primaryApplicant.employmentHistory;
    let otherIncomeArr = data.primaryApplicant.otherIncomes;
    let emergencyContactArr = data.primaryApplicant.emergencyContact;
    let drivingLicenceArr = data.primaryApplicant.drivingLicence;
    let backgroundInfo = data.primaryApplicant.backgroundChecks;
    let coApplicantsLocalArr = data.coApplicants;
    let petsLocalArr = data.pets;
    let childrenLocalArr = data.occupants;
    let vehiclesLocalArr = data.vehicles;
    coApplicantsLocalArr && coApplicantsLocalArr.forEach((ele, index) => {
      if (ele.rentalHistory === null || !ele.rentalHistory.length) {
        ele.rentalHistory = coapplicantData.rentalHistory;
      }
      if (ele.isFinanciallyResponsible && (ele.employmentHistory === null || !ele.employmentHistory.length)) {
        ele.employmentHistory = coapplicantData.employmentHistory;
      }
      if (ele.isFinanciallyResponsible && (ele.otherIncomes === null || !ele.otherIncomes.length)) {
        ele.otherIncomes = coapplicantData.otherIncomes;
      }
    })
    this.setState({
      application: {
        ...application, children: data.children, petsNumber: data.petsNumber, id: data.id, primaryApplicant: {
          ...application.primaryApplicant, emergencyContact: emergencyContactArr,
          rentalHistory: rentalArr, employmentHistory: employmentArr, otherIncomes: otherIncomeArr, backgroundChecks: backgroundInfo, drivingLicence: drivingLicenceArr
        },
        coApplicants: coApplicantsLocalArr, pets: petsLocalArr, occupants: childrenLocalArr, vehicles: vehiclesLocalArr
      }, noOfCoApplicants: data.adults - 1
    }, () => {
      // if (this.state.application.primaryApplicant.email.length > 0) {
      //   this.checkForTenantAlert('primaryApplicant');
      // }
      // this.state.application.coApplicants.map((ele, index) => {
      //     if (ele.email.length > 0) {
      //       this.setState({ loading: true })
      //       this.checkForTenantAlert('coApplicants', index)
      //     }
      // })
    });
  }

  updateLeadLocalState = () => {
    const data = this.props.location.leadData || JSON.parse(localStorage.getItem('leadAppData'));
    const { application } = this.state;
    const rentalArr = [...application.primaryApplicant.rentalHistory];
    rentalArr[0] = {
      ...rentalArr[0], address: {
        ...rentalArr[0].address, streetLine1: data.address && data.address.addressOne,
        streetLine2: data.address && data.address.addressTwo, city: data.address && data.address.city, state: data.address && data.address.state, postcode: data.address && data.address.postcode
      }
    }
    /* eslint-disable */
    this.setState({
      application: {
        ...application, children: data.preference && data.preference.children || 0, petsNumber: data.preference && data.preference.pets || 0, primaryApplicant: {
          ...application.primaryApplicant,
          rentalHistory: rentalArr
        }
      }
    })
    /* eslint-enable */
  }

  fetchBackgroundInfo = () => {
    const { selectedPrimary } = this.props;
    try {
      BACKGROUND_INFO.query({
        query: fetchBackgroundInfo,
        variables: {
          locationId: selectedPrimary && selectedPrimary.node.id,
          templateType: 'application'
        }
      }).then(res => {
        const appInfo = res.data.locationUtils && res.data.locationUtils.edges.length && res.data.locationUtils.edges[0].node.applicationInformation;
        this.setState({ backgroundCheckInfo: appInfo })
      })
    } catch (e) {
      console.log(e)
    }
  }

  setToOriginalState = (updatedArr, type) => {
    let newArr = [];
    switch (type) {
      case 'coApplicants':
        newArr = updatedArr.concat(coapplicantData)
        break;
      case 'occupants':
        newArr = updatedArr.concat(occupantsData)
        break;
      case 'pets':
        newArr = updatedArr.concat(petsData)
        break;
      default:
        break;
    }
    return newArr;
  }

  /* function to set the coapplicants, occupants and pets array
    based on the values entered in the unit info page */
  setArrayNumber = (application, type, originalArr, updatedArr) => {
    if (Number(application[type]) === NUMBER_ZERO) {
      updatedArr = [];
      this.setState({ application: { ...application, [originalArr]: [] } })
    }
    else {
      while (updatedArr.length < (type === 'adults' ? Number(application[type]) - 1 : Number(application[type]))) {
        updatedArr = this.setToOriginalState(updatedArr, originalArr)
      }
      while ((type === 'adults' ? Number(application[type]) - 1 : Number(application[type])) < updatedArr.length) {
        updatedArr.pop();
      }
    }
    return updatedArr;
  }

  /* function to set the co-applicants, children and pets array on the basis of the number of adults
    co-applicants is one less than the number of adults */
  setCoApplicantArray = () => {
    const { application } = this.state;
    const { selectedPrimary } = this.props;
    let coApplicantsArr = application.coApplicants ? [...application.coApplicants] : [];
    let childrenArr = application.occupants ? [...application.occupants] : [];
    let petsArr = application.pets ? [...application.pets] : [];
    coApplicantsArr = this.setArrayNumber(application, 'adults', 'coApplicants', coApplicantsArr)
    childrenArr = this.setArrayNumber(application, 'children', 'occupants', childrenArr)
    petsArr = this.setArrayNumber(application, 'petsNumber', 'pets', petsArr)
    this.setState({ application: { ...application, locationId: selectedPrimary && selectedPrimary.node.id, coApplicants: coApplicantsArr, occupants: childrenArr, pets: petsArr } })
  }

  /* function to update the active page of application by updating the formSteps local state */
  updateFormSteps = (number) => {
    const { formSteps } = this.state;
    switch (number) {
      case 0:
        this.setState({
          formSteps: {
            ...formSteps, unitInfo: true, primaryApplicant: false,
            coApplicant: false, occupants: false, review: false
          }
        })
        break;
      case 1:
        this.setState({
          formSteps: {
            ...formSteps, unitInfo: false, primaryApplicant: true,
            coApplicant: false, occupants: false, review: false
          }
        })
        this.setCoApplicantArray();
        break;
      case 2:
        this.setState({
          formSteps: {
            ...formSteps, unitInfo: false, primaryApplicant: false,
            coApplicant: true, occupants: false, review: false
          }
        })
        break;
      case 3:
        this.setState({
          formSteps: {
            ...formSteps, unitInfo: false, primaryApplicant: false,
            coApplicant: false, occupants: true, review: false
          }
        })
        break;
      case 4:
        this.setState({
          formSteps: {
            ...formSteps, unitInfo: false, primaryApplicant: false,
            coApplicant: false, occupants: false, review: true
          }
        })
        break;
      default:
        break;
    }
  };

  /* function to update the dropdown textual values
    on the basis of field values */
  updateDropdownText = (field, name) => {
    switch (field) {
      case 'affordableHousingProgram':
        this.setState({ affordableText: name })
        break;
      case 'unitType':
        this.state.unitTypeText !== name && this.setState({ unitNumberText: '', level: '', application: {...this.state.application, unitNumber: ''}})
        this.setState({ unitTypeText: name });
        break;
      case 'unitNumber':
        this.setState({ unitNumberText: name });
        break;
      default:
        break;
    }
  }

  updateDropdownValues = (event, { value }, id) => {
    const { application, bedrooms, bathrooms } = this.state;
    if (!id) {
      const { id, field, firstName, lastName, name, bedroom, bathroom, level } = value;
      firstName && lastName && this.setState({ leasingAgent: firstName + lastName });
      this.setState({ bedrooms: bedroom ? bedroom : bedrooms, bathrooms: bathroom? bathroom : bathrooms, level: level? level : this.state.level})
      this.updateDropdownText(field, name);
      this.setState({ application: { ...application, [field]: id } })
    } else {
      this.setState({ application: { ...application, unitType: id } })
    }
  }

  updateApplicantName = (e, field) => {
    const { application } = this.state;
    this.setState({ application: { ...application, primaryApplicant: { ...application.primaryApplicant, [field]: e } } })
  }

  updateApplicationState = (e, field, name) => {
    const { application } = this.state;
    this.setState({ application: { ...application, [name]: { ...application[name], [field]: e.target.textContent || e.target.value } } })
    if (field === 'email' && EMAIL_REGEX.test(e.target.textContent || e.target.value)) {
      setTimeout(() => {
        // this.checkForTenantAlert('primaryApplicant');
      }, 4000);
    }
  } 

  updateUnitInfoState = (e, field) => {
    const { application } = this.state;
    let value = e.target.value;
    switch (field) {
      case 'adults':
      case 'children':
      case 'petsNumber':
        value = !isNaN(Number(e.target.value)) ? Number(e.target.value) : e.target.value;
        break;
      case 'liquidFilledFurniture':
      case 'smoke':
        if (e.target.textContent === 'Yes') value = true;
        else if (e.target.textContent === 'No') value = false;
        break;
      default:
        value = e.target.value;
        break;
    }
    this.setState({ application: { ...application, [field]: value } });

  }

  updateNestedState = (e, field, nestedKey, parentKey) => {
    const { application } = this.state;
    let value;
    if (field === 'idType') {
      value = e;
    } else if (field === 'comment') {
      value = e.target.value;
    } else {
      value = e.target.textContent || e.target.value
    }
    this.setState({
      application: {
        ...application, [parentKey]: {
          ...application[parentKey],
          [nestedKey]: { ...application[parentKey][nestedKey], [field]: value }
        }
      }
    });
  }

  handleAddressChange = (field, value) => {
    const { application } = this.state;
    this.setState({
      application: {
        ...application, primaryApplicant: {
          ...application.primaryApplicant,
          address: {
            ...application.primaryApplicant.address,
            [field]: { ...application.primaryApplicant.address[field], streetLine1: value }
          }
        }
      },
    });
    if (field === 'addressOne') {
      this.setState({ address: value, addressV: value });
    }
  }

  /* function to set the co-applicants direct address parameters */
  setCoApplicantAddressFields = (field, value, index, parentKey, nestedKey) => {
    const { application } = this.state;
    const newArr = [...application.coApplicants];
    if (nestedKey) {
      newArr[index] = {
        ...newArr[index], [parentKey]: {
          ...newArr[index][parentKey],
          [nestedKey]: { ...newArr[index][parentKey][nestedKey], [field]: value }
        }
      }
    } else if (parentKey && !nestedKey) {
      newArr[index] = {
        ...newArr[index], [parentKey]: {
          ...newArr[index][parentKey],
          [field]: value
        }
      };
    } else {
      newArr[index] = { ...newArr[index], [field]: value }
    }
    this.setState({ application: { ...application, coApplicants: newArr } });
  }

  /* function to set the co-applicants direct parameters */
  setCoApplicantDirectFields = (field, value, index, type, parentKey, nestedKey) => {
    const { application } = this.state;
    const newArr = [...application[type]];
    if (nestedKey) {
      newArr[index] = {
        ...newArr[index], [parentKey]: {
          ...newArr[index][parentKey],
          [nestedKey]: { ...newArr[index][parentKey][nestedKey], [field]: value }
        }
      }
    } else if (parentKey && !nestedKey) {
      newArr[index] = {
        ...newArr[index], [parentKey]: {
          ...newArr[index][parentKey],
          [field]: value
        }
      };
    } else {
      if (field === 'isFinanciallyResponsible' && value === true && !newArr[index].employmentHistory.length) {
        newArr[index] = { ...newArr[index], employmentHistory: coapplicantData.employmentHistory, otherIncomes: coapplicantData.otherIncomes }
      }
      newArr[index] = { ...newArr[index], [field]: value }
    }
    this.setState({ application: { ...application, [type]: newArr } });
    if (field === 'email' && EMAIL_REGEX.test(value)) {
      setTimeout(() => {
        // this.checkForTenantAlert('coApplicants', index);
      }, 4000);
    }
  }

  setCoApplicantArrayFields = (field, value, parentIndex, nestedIndex, parentKey, nestedKey) => {
    const { application } = this.state;
    const newArr = [...application.coApplicants[parentIndex][parentKey]];
    const parentArr = [...application.coApplicants];
    if (nestedKey) {
      newArr[nestedIndex] = { ...newArr[nestedIndex], [nestedKey]: { ...newArr[nestedIndex][nestedKey], [field]: value } }
    } else {
      newArr[nestedIndex] = { ...newArr[nestedIndex], [field]: value };
      if ((field === 'isCurrentlyResiding' || field === 'isCurrentlyWorking') && field) {
        newArr[nestedIndex] = { ...newArr[nestedIndex], endDate: '' };
      }
    }
    parentArr[parentIndex] = { ...parentArr[parentIndex], [parentKey]: newArr }
    this.setState({ application: { ...application, coApplicants: parentArr } });
  }

  /* function to set the address parameters */
  setRenterHistory = (field, value, index, parentKey, nestedKey) => {
    const { application } = this.state;
    const newArr = [...this.state.application.primaryApplicant[parentKey]];
    if (nestedKey) {
      newArr[index] = { ...newArr[index], [nestedKey]: { ...newArr[index][nestedKey], [field]: value } };
    } else {
      newArr[index] = { ...newArr[index], [field]: value };
      if ((field === 'isCurrentlyResiding' || field === 'isCurrentlyWorking') && field) {
        newArr[index] = { ...newArr[index], endDate: '' };
      }
    }
    this.setState({
      application: {
        ...application, primaryApplicant: {
          ...application.primaryApplicant,
          [parentKey]: newArr
        }
      }
    });

  }

  /* function to set the address field parameters on the basis of the data type received
    calls specified to perform this action */
  setAddressFields = (data, index, parentKey, functionToCall, type) => {
    type ? functionToCall('city', '', index, parentKey, type, 'address') :
      functionToCall('city', '', index, parentKey, 'address');
    type ? functionToCall('state', '', index, parentKey, type, 'address') :
      functionToCall('state', '', index, parentKey, 'address');
    type ? functionToCall('postcode', '', index, parentKey, type, 'address') :
      functionToCall('postcode', '', index, parentKey, 'address');
    data.map(((ele) => {
      const longName = ele.long_name;
      switch (ele.types[0]) {
        case 'locality':
          if (type) {
            functionToCall('city', longName, index, parentKey, type, 'address');
          } else {
            functionToCall('city', longName, index, parentKey, 'address');
          }
          break;
        case 'administrative_area_level_1':
          type ? functionToCall('state', longName, index, parentKey, type, 'address') :
            functionToCall('state', longName, index, parentKey, 'address');;
          break;
        case 'postal_code':
          type ? functionToCall('postcode', longName, index, parentKey, type, 'address') :
            functionToCall('postcode', longName, index, parentKey, 'address');
          break;
        default:
          break;
      }
      return null;
    }));
  };

  handleNestedAddressChange = (field, value, index, parentKey, nestedKey) => {
    const { application } = this.state;
    this.setState({
      application: {
        ...application, primaryApplicant: {
          ...application.primaryApplicant,
          [parentKey]: {
            ...application.primaryApplicant[parentKey], [nestedKey]: {
              ...application.primaryApplicant[parentKey].address,
              [field]: value
            }
          }
        }
      }
    });
  }

  /* function to accepts the date selected and set it in local state after formatting change */
  handleDateChange = (event, field, parentKey, nestedKey) => {
    const { application } = this.state;
    if (event !== null) {
      const dateRes = moment(event).format(DATE_FORMAT);
      if (nestedKey) {
        this.setState({
          application: {
            ...application, [parentKey]: {
              ...application.parentKey, [nestedKey]: {
                ...application.parentKey.nestedKey,
                [field]: dateRes
              }
            }
          }, date: event._d
        })
      } else if (!nestedKey && parentKey) {
        this.setState({
          application: { ...application, [parentKey]: { ...application[parentKey], [field]: dateRes } }, date: event._d
        })
      } else {
        this.setState({
          application: { ...application, [field]: dateRes }, date: event._d
        })
      }
    } else {
      this.setState({
        application: { ...application, targetMoveInDate: '' }, date: ''
      });
    }
  }

  setSelectedBackground = (id) => {
    const { application } = this.state;
    let arr = [];
    if (application.primaryApplicant.backgroundChecks) {
      arr = [...application.primaryApplicant.backgroundChecks.checks];
    }
    const isPresent = arr.includes(id);
    let updatedArr = [];
    if (!isPresent) {
      updatedArr = arr.concat(id);
    } else {
      arr.splice(arr.indexOf(id), 1);
      updatedArr = arr;
    }
    this.setState({
      application: {
        ...application, primaryApplicant: {
          ...application.primaryApplicant,
          backgroundChecks: { ...application.primaryApplicant.backgroundChecks, checks: updatedArr }
        }
      }
    });
  }

  setUpdatedNumber = (number, type) => {
    const { application } = this.state;
    this.setState({ application: {...application, [type]: number }});
  }

  redirectToForm = () => {
    this.props.history.push({
      pathname: '/applications',
    })
  }

  updateApplication = async () => {
    const { applicationManager } = this.props;
    this.setState({ sending: true });
    const { application } = this.state;
    delete application.locationId;
    this.removeFields(application);
    const app = { application };
    mixpanel.track('Manager Application Action', {
      'sub': 'Update Application'
    });
    await applicationManager.mutate({
      mutation: updateApplication,
      variables: { input: app }
    }).then(res => {
      toast.success('Application updated successfully');
      this.redirectToForm();
      this.setState({ sending: false });
    }).catch(error => {
      toast.error(parseGraphQLErrors(error))
      this.setState({ sending: false });
    })
  }

  removeFields = (application) => {
    application.primaryApplicant.otherIncomes.forEach((ele) => {
      if (ele.monthlyIncome === '' || ele.monthlyIncome === null) {
        delete ele.monthlyIncome;
      }
    })
    application.primaryApplicant.rentalHistory.forEach((ele) => {
      if (ele.monthlyRent === '' || ele.monthlyRent === null) {
        delete ele.monthlyRent;
      }
      if (ele.isCurrentlyResiding) {
        delete ele.endDate;
      }
    })
    application.primaryApplicant.employmentHistory.forEach((ele) => {
      if (ele.isCurrentlyWorking) {
        delete ele.endDate;
      }
    })
    application.coApplicants.forEach((ele) => {
      ele.rentalHistory && ele.rentalHistory.forEach((item) => {
        if (item.monthlyRent === '' || item.monthlyRent === null) {
          delete item.monthlyRent;
        }
        if (item.isCurrentlyResiding) {
          delete item.endDate;
        }
      })
      ele.employmentHistory && ele.employmentHistory.forEach((item) => {
        if (item.isCurrentlyWorking) {
          delete item.endDate;
        }
      })
      ele.otherIncomes && ele.otherIncomes.forEach((item) => {
        if (item.monthlyIncome === '' || item.monthlyIncome === null) {
          delete item.monthlyIncome;
        }
      })
      if (!ele.isFinanciallyResponsible) {
        ele.employmentHistory = [];
        ele.otherIncomes = [];
      }
    })
    // if (application.primaryApplicant.noEmploymentHistory) {
    //   application.primaryApplicant.employmentHistory = [];
    // }
  }

  createApplication = async () => {
    const { applicationManager } = this.props;
    this.setState({ sending: true });
    const { application } = this.state;
    this.removeFields(application);
    const app = { application };
    mixpanel.track('Manager Application Action', {
      'sub': 'Submit Application Form Clicked'
    });
    await applicationManager.mutate({
      mutation: createApplication,
      variables: { input: app }
    }).then(res => {
      toast.success('Application created successfully');
      this.setState({ sending: false });
      this.redirectToForm();
    }).catch(error => {
      this.setState({ sending: false });
      toast.error(parseGraphQLErrors(error))
    })
  }

  render() {
    const { formSteps, application, leasingAgent, affordableText, unitTypeText, unitNumberText, sending,
      noOfCoApplicants, backgroundCheckInfo, bedrooms, bathrooms, level } = this.state;
    const { selectedPrimary, location, user, applicationManager } = this.props;
    const Bread = () => (
      <Breadcrumb className="custom-breadcrumb">
        <Breadcrumb.Section link>
          <NavLink exact to="/applications">
            Applications
        </NavLink>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section link>
          {location.data ? <NavLink exact to={`/viewApplication/location=${selectedPrimary &&
            selectedPrimary.node && selectedPrimary.node.id}/id=${location.data.id}`}>
            {location.data.primaryApplicant.lastName}
          </NavLink> : location.leadData ? <NavLink exact to={`/viewLead/location=${location.leadData.location}/id=${location.leadData.id}`}>
            {location.leadData.lastName}
          </NavLink> : ''}
        </Breadcrumb.Section>
      </Breadcrumb>
    )

    return (
      <>
        {
          this.state.loading ? <PageLoader text /> :
            <>
              <Bread />
              <div className="add-new-app-head d-flex align-items-center justify-content-between">
                <h4 className="page-head mb-0">{(this.props.location.data || JSON.parse(localStorage.getItem('appData')) || JSON.parse(localStorage.getItem('leadData'))) ? 'Edit New Application' : 'Add New Application'}</h4>
                {!this.props.location.data && <p>Applications for existing leads must be created from the lead</p>}
              </div>

              <div className="application-step-wrapper">
                <Step.Group className="w-100">
                  <Step
                    active={formSteps.unitInfo}
                    description='1. Unit Info'
                  />
                  <Step
                    active={formSteps.primaryApplicant}
                    description='2. Primary Applicant'
                  />
                  <Step
                    active={formSteps.coApplicant}
                    description='3. Co-Applicant'
                  />
                  <Step
                    active={formSteps.occupants}
                    description='4. Occupant'
                  />
                  <Step
                    active={formSteps.review}
                    description='5. Review'
                  />
                </Step.Group>
              </div>

              {formSteps.unitInfo &&
                <ApplicationUnitInfo
                  updateFormSteps={this.updateFormSteps}
                  affordableHousing={this.props.affordableHousingClient}
                  application={application}
                  updateApplication={this.updateUnitInfoState}
                  updateApplicantName={this.updateApplicantName}
                  handleDateChange={this.handleDateChange}
                  customId={selectedPrimary && selectedPrimary.node && selectedPrimary.node.id}
                  propertyManagers={selectedPrimary && selectedPrimary.node && selectedPrimary.node.propertyManagers}
                  updateDropdownValues={this.updateDropdownValues}
                  leasingAgentStateValue={leasingAgent}
                  affordableText={affordableText}
                  unitTypeText={unitTypeText}
                  unitNumberText={unitNumberText}
                  selectedPrimary={selectedPrimary}
                  showComment={!location.data}
                  applicationManager={applicationManager}
                  bedrooms={bedrooms}
                  bathrooms={bathrooms}
                  level={level}
                  user={user} //TODO: only passed as a replacement of propertymanager info as of now, to be removed later on
                />}
              {formSteps.primaryApplicant &&
                <PrimaryApplicant
                  updateFormSteps={this.updateFormSteps}
                  numberOfAdults={application.adults}
                  application={application.primaryApplicant}
                  updateApplication={this.updateApplicationState}
                  updateNestedState={this.updateNestedState}
                  setRenterHistory={this.setRenterHistory}
                  handleDateChange={this.handleDateChange}
                  setAddressFields={this.setAddressFields}
                  updateAddressChange={this.handleAddressChange}
                  handleNestedAddressChange={this.handleNestedAddressChange}
                  backgroundInfo={backgroundCheckInfo}
                  setBackgroundSelected={this.setSelectedBackground}
                  updateApplicantName={this.updateApplicantName}
                  updateState={location.data ? true : false}
                />}
              {formSteps.coApplicant &&
                <CoApplicants
                  updateFormSteps={this.updateFormSteps}
                  application={application.coApplicants}
                  setCoApplicantDirectFields={this.setCoApplicantDirectFields}
                  setCoApplicantArrayFields={this.setCoApplicantArrayFields}
                  setAddressFields={this.setAddressFields}
                  noOfCoApplicants={noOfCoApplicants}
                  setCoAppNumber={this.setUpdatedNumber}
                  adults={application.adults}
                />}
              {formSteps.occupants &&
                <Occupants
                  updateFormSteps={this.updateFormSteps}
                  application={application}
                  setCoApplicantDirectFields={this.setCoApplicantDirectFields}
                  numberOfAdults={application.adults}
                  setPetNumber={this.setUpdatedNumber}
                />}
              {formSteps.review &&
                <Review
                  updateFormSteps={this.updateFormSteps}
                  application={application}
                  leasingAgentStateValue={leasingAgent}
                  unitType={unitTypeText}
                  unitNumber={unitNumberText}
                  affordableText={affordableText}
                  backgroundInfo={backgroundCheckInfo}
                  createApplication={location.data || JSON.parse(localStorage.getItem('appData')) || JSON.parse(localStorage.getItem('leadData')) ? this.updateApplication : this.createApplication}
                  sending={sending}
                  bedrooms={bedrooms}
                  bathrooms={bathrooms}
                  level={level}
                />
              }
              {toaster()}
            </>
        }
      </>
    )
  }
}
export default (ApplicationForm);
