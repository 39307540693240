import React, {
    Component
  } from "react";
  import {
    withApollo
  } from "react-apollo";
  import mixpanel from 'mixpanel-browser';
  import {
    trialGql
  } from "../../store/person/accounting";
  import {
    getClient
  } from "../../init-apollo-googleFn";
  import {
   Button, Grid, Dropdown
  } from "semantic-ui-react";
  import {
    Table
  } from "../../components/Tables/TrialData";
  import {
    ACCOUNTING
  } from "../../utils/constants";
  import "react-toastify/dist/ReactToastify.css";
  import "./Accounting.scss"
  import "react-datetime/css/react-datetime.css";
  import { Loader } from '../../components/Loader/Loader';
  
  
  const invoiceClient = getClient(ACCOUNTING);
  
  class TrialReport extends Component {
    constructor(props) {
      super(props);
      this.state = {
        primary: this.props.selectedPrimary ?
          this.props.selectedPrimary.node : "",
        loading: false,
        trialsCache: [],
        columnLimit:[],
        dateType:'Monthly',
        numType:'1',
        reportType:'posted'
      };
    }
  
    componentDidMount() {
      const result = this.state.trialsCache.length / 30;
      this.setState({lastPage: Math.ceil(result)});
      if(window.document.getElementById('getLedger')) {
        window.document.getElementById('getLedger').click()
      }
      mixpanel.track('Manager Page Load', {
        'sub': 'Trial Report'
       });

    }

    componentWillReceiveProps(prevProps) {
      const nodeData = prevProps.selectedPrimary ?
        prevProps.selectedPrimary.node :
        "";
      if (nodeData !== this.state.primary) {
        this.setState({
          primary: nodeData
        }, () => {
          this.setState({
            selectedMonthEvents: {
              ...this.state.selectedMonthEvents,
              montEvents: [],
            },
          });
        });
      }
    }
  
    getLedger = () => {
      this.getColumnLimit()
      try {
        this.setState({
          loading: true
        });
        invoiceClient
          .query({
            query: trialGql,
            variables: {
              location: this.props.selectedPrimary.node.id,
              dateType: this.state.dateType,
              numType: Number(this.state.numType),
              journalStatus: this.state.reportType
            },
          })
          .then((res) => {
            const arrayConv =[]
            const trialsCache = res.data.slLocationTrialBalance
              Object.entries(trialsCache).forEach((file) => {
                const newObj = {
                  name:file[0],
                  data:file[1]
                }
                arrayConv.push(newObj)
              }
              )

            this.setState({trialsCache:arrayConv})
            this.setState({
              loading: false
            });
          })
          .catch((error) => {
            this.setState({
              loading: false
            });
          });
  
      } catch (e) {
        this.setState({
          loading: false
        });
      }
    };
    getColumnLimit = () => {
      const start = Number(this.state.numType)
      const stop = 12
      const step = 1
      const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
      const array = range(start, stop, step)
      array.shift()
      const newArr = array.map(item => {
        item = "data."+item
        return item
      })
      this.setState({columnLimit:newArr})
    }
  
  
  
    dataPush = (response) => {
      this.setState((prevState) => ({
        financialsCache: [
          response.data.createSlInvoice.slInvoice,
          ...prevState.financialsCache,
        ],
      }));
    };
    updateInvoice = (status, invoiceId) => {
      const {
        financialsCache
      } = this.state;
      const array = [];
      if (status === "Delete") {
        this.setState({
          financialsCache: financialsCache.filter(invoice => invoice._id !== invoiceId)
        })
      } else {
        financialsCache.forEach((invoice) => {
          if (invoice._id === invoiceId) {
            invoice.status = status;
            array.push(invoice);
          } else {
            array.push(invoice);
          }
        });
        this.setState({
          financialsCache: array
        });
      }
    };
  
    handleDateClick = (event, { value }) => {
     this.setState({dateType:value})
    };
    handleNumberClick = (event, { value }) => {
      this.setState({numType:value})
    };
  
    handleReportChange= (event, { value }) => {
      this.setState({reportType:value})
    }
  
    render() {
      const {
        loading,
        numType,
        dateType,
        reportType
      } = this.state;
      const financials = [
        {
          key: 'Posted',
          text: 'Posted',
          value: 'posted',
        },
        {
          key: 'Draft',
          text: 'Draft',
          value: 'draft',
        },
      ]
      const financialsType = [
        {
          key: 'Monthly',
          text: 'Monthly',
          value: 'Monthly',
        },
        {
          key: 'Yearly',
          text: 'Yearly',
          value: 'Yearly',
        },
      ]
      const financialsNumber = [
        {
          key: '1',
          text: '1',
          value: '1',
        },
        {
          key: '2',
          text: '2',
          value: '2',
        },
        {
          key: '3',
          text: '3',
          value: '3',
        },
        {
          key: '4',
          text: '4',
          value: '4',
        },
        {
          key: '5',
          text: '5',
          value: '5',
        },
        {
          key: '6',
          text: '6',
          value: '6',
        },
        {
          key: '7',
          text: '7',
          value: '7',
        },
        {
          key: '8',
          text: '8',
          value: '8',
        },
        {
          key: '9',
          text: '9',
          value: '9',
        },
        {
          key: '10',
          text: '10',
          value: '10',
        },
        {
          key: '11',
          text: '11',
          value: '11',
        },
        {
          key: '12',
          text: '12',
          value: '12',
        },
      ]

      return ( 
        loading ? (
          <Loader text inTable/>
        ) : ( 
          <main className="main-content" role="main">
            <div className="roll-container">
              <Grid columns="4">
                <div>
                  Journal Status
                  <br></br>
                  <Dropdown
                    style={{marginTop:"5px"}}
                    selection
                    defaultValue={'posted'}
                    selectOnBlur={false}
                    options={financials}
                    onChange={this.handleReportChange}
                  />
                </div>
                <div>
                  Date Type
                  <br></br>
                  <Dropdown
                    style={{marginTop:"5px"}}
                    selection
                    defaultValue={'Monthly'}
                    selectOnBlur={false}
                    options={financialsType}
                    onChange={this.handleDateClick}
                  />
                </div>
                <div>
                  {this.state.dateType === "Monthly" && "# Months"}
                  {this.state.dateType === "Yearly" && "# Years"}
                  <br></br>
                  <Dropdown
                    style={{marginTop:"5px"}}
                    selection
                    defaultValue={'1'}
                    selectOnBlur={false}
                    options={financialsNumber}
                    onChange={this.handleNumberClick}
                  />
                </div>
                <div>
                  <Button id="getLedger" disabled={!dateType||!numType ||!reportType} compact style={{paddingTop:'0px', paddingBottom:"0px", height:"46.17px", marginTop:'20px'}} onClick={this.getLedger}>Get Report</Button>
                </div>
                <div>
                  <Button style={{paddingTop:'0px', paddingBottom:"0px", height:"46.17px", marginTop:'20px'}} className="noPrint" onClick={() => window.print()}>Print</Button>
                </div>
              </Grid>
              <br></br> 
              <Table lastPage={this.state.lastPage} columnLimit={this.state.columnLimit} monthYear={this.state.dateType} trialsCache={this.state.trialsCache}/>
            </div>
          </main>
        )
      );
    }
  }
  
  export default withApollo(TrialReport);