import React, { Component } from "react";
import { withApollo} from "react-apollo";
import { AccountingMetrics } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import "./Accounting.scss"
import _ from "lodash";
import {
  Card,
  Header,
  Image,
  Grid,
} from "semantic-ui-react";
import { ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import { Line, Bar } from "react-chartjs-2";
import mixpanel from 'mixpanel-browser';


const ledgerClient = getClient(ACCOUNTING);

class Ledger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
    };
  }

  componentDidMount() {
    this.getLedger();
    mixpanel.track('Manager Page Load', {
      'sub': 'Accounting'
     });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.getLedger();
      });
    }
  }

  getLedger = async () => {
    this.setState({ loading: true });
    const Metrics = await ledgerClient.query({
      query: AccountingMetrics,
      variables: {
        location:
          this.props.selectedPrimary.node && this.props.selectedPrimary.node.id,
      },
    });
    if (Metrics.data.slMetrics&&Metrics.data.slMetrics.edges.length !== 0){
      const Value = Metrics.data && Metrics.data.slMetrics && Metrics.data.slMetrics.edges && Metrics.data.slMetrics.edges[0] && Metrics.data.slMetrics.edges[0].node;
      const exp = Value && Value.expensesTotalGraph
      const losses = {}
      if (typeof exp == "object") {
        for (const [key, value] of Object.entries(exp)) {
          const newa = value * -1
          losses[key] = newa;
        }
      }
      // else return
      this.setState({ Metrics: Value, Losses: losses }, () => {
        this.setState({ loading: false });
      });
    }
    else {
      this.setState({Metrics:null})
    }
  };

  render() {
    const { Metrics, Losses } = this.state;

    return (
      <div className="accounting-wrapper">
        <div className="top-head">
          <h4>Accounting</h4>
          <h6>Here’s the overview of your accounts</h6>
          
        </div>
        <div className="table-rw">
          {/*At a Glance */}
          <span>

            <Card fluid className="banner-image-wrap">
              <Image
                centered
                fluid
                size="big"
                src="/assets/img/renter-bg.jpg"
              />
              <Card.Content textAlign="center">
                <Card.Header>
                  New COVID-19 tax credits
                </Card.Header>
                <Card.Description>
                  Keep more money in your pocket with new COVID-19 tax credits
                </Card.Description>
                <img src="assets/img/icons-close.svg" alt="cross-icon"></img>
              </Card.Content>
            </Card>
            {
              Metrics !== null ?

           <>
            <Card fluid className="assets-wrapper">
              {Metrics && (
                <>
                  <Card.Content>
                    <Grid className="left-wrap">
                      <Card.Header>Total Assets</Card.Header>
                    </Grid>


                    <Grid className="right-wrap">
                      <Grid.Column>
                        <Header>
                          $
                          {(Math.round(
                            (Metrics && Metrics.assetsTotal30 + Number.EPSILON) * 100
                          ) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Header>
                        Last 30 Days
                      </Grid.Column>
                      <span className="line-seperator"></span>
                      <Grid.Column>
                        <Header>
                          $
                          {(Math.round(
                            (Metrics && Metrics.assetsTotal365 + Number.EPSILON) * 100
                          ) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Header>
                        Last 365 Days
                      </Grid.Column>
                    </Grid>
                  </Card.Content>
                </>
              )}
            </Card>
            <Card.Group
              itemsPerRow={2}
              stackable
              className="graph-data-wrapper"
            >
              <Card>
                {Metrics && (
                  <Card.Content>
                    <Card.Header>
                      <div className="left-col">
                        <h4>Income</h4>
                      </div>
                      <div className="right-col">
                      </div>
                    </Card.Header>
                    <Grid columns="3" padded className="stripe-data">
                      <Grid.Column>
                        <span>${Metrics.incomeTotal30.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                        <label>Last 30 days</label>                  
                        </Grid.Column>
                      <Grid.Column>
                        <span> ${Metrics.incomeTotal365.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                        <label>Last 365 days</label>
                      </Grid.Column>
                    </Grid>
                    <Line
                      data={{
                        labels: Object.keys(Metrics.incomeTotalGraph),
                        datasets: [
                          {
                            label: "$",
                            fill: true,
                            data: Object.values(Metrics.incomeTotalGraph),

                            backgroundColor: [
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                            ],
                          },
                        ],
                      }}
                      width={150}
                      height={75}
                      options={{
                        maintainAspectRatio: true,
                        legend: {
                          display: false,
                        },
                        scales: {
                          xAxes: [
                            {
                              gridLines: {
                                color: "rgba(0, 0, 0, 0)",
                                drawOnChartArea: false,
                              },

                            },

                          ],
                          yAxes: [
                            {
                              afterTickToLabelConversion: function (q) {
                                for (var tick in q.ticks) {
                                  q.ticks[tick] = "$" + q.ticks[tick].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                                }
                              },
                              ticks: {
                                beginAtZero: true,
                                maxTicksLimit: 10,
                                suggestedMax: 100,
                                max: Math.ceil((_.max(
                                  Object.values(Metrics.incomeTotalGraph),
                                  function (o) {
                                    return Metrics.incomeTotalGraph[o];
                                  }
                                ) * 1.5) / 1000) * 1000,
                              },
                              gridLines: {
                                color: "rgb(220,220,220)",
                              },
                            },
                          ],
                        },
                      }}
                    ></Line>
                  </Card.Content>
                )}
              </Card>
              <Card>
                {Losses && (
                  <Card.Content>
                    <Card.Header>
                      <div className="left-col">
                        <h4>Profit and Loss</h4>
                      </div>
                      <div className="right-col">
                      </div>
                    </Card.Header>
                    <Grid columns="3" padded className="stripe-data">
                      <Grid.Column>
                        <span>${Metrics && Metrics.incomeTotal365.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                        <label>Total Income</label>
                      </Grid.Column>
                      <Grid.Column>
                        <span>${Metrics && Metrics.expensesTotal365.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                        <label>Total Losses</label>
                      </Grid.Column>
                    </Grid>
                    <br />
                    <Line
                      data={{
                        labels: Object.keys(Losses),
                        datasets: [
                          {
                            label: "Income($)",
                            fill: true,
                            data: Metrics && Object.values(Metrics && Metrics.incomeTotalGraph),

                            backgroundColor: [
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                            ],
                          },
                          {
                            label: "Losses($)",
                            fill: true,
                            data: Object.values(Losses),

                            backgroundColor: 
                              "#ff525266",
                           
                          },
                        ],
                      }}
                      width={150}
                      height={75}
                      options={{
                        maintainAspectRatio: true,
                        legend: {
                          display: false,
                        },
                        scales: {
                          xAxes: [
                            {
                              gridLines: {
                                color: "rgba(0, 0, 0, 0)",
                                drawOnChartArea: false,
                              },
                            },
                          ],
                          yAxes: [
                            {
                              afterTickToLabelConversion: function (q) {
                                for (var tick in q.ticks) {
                                  q.ticks[tick] = "$" + q.ticks[tick].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                                }
                              },
                              ticks: {
                                beginAtZero: true,
                                max: Math.ceil((_.max(
                                  Metrics && Object.values(Metrics && Metrics.incomeTotalGraph),
                                  function (o) {
                                    return Metrics && Metrics.incomeTotalGraph[o];
                                  }
                                ) * 1.5) / 1000) * 1000,
                              },
                              gridLines: {
                                color: "rgb(220,220,220)",
                              },
                            },
                          ],
                        },
                      }}
                    ></Line>
                  </Card.Content>
                )}
              </Card>
            </Card.Group>
            {Metrics && (
              <Card.Group
                itemsPerRow={2}
                stackable
                className="graph-data-wrapper"
              >
                <Card>
                  <Card.Content>
                    <Card.Header>
                      <div className="left-col">
                        <h4>Expenses</h4>
                      </div>
                      <div className="right-col">
                      </div>
                    </Card.Header>
                    <Grid columns="3" padded className="stripe-data">
                      <Grid.Column>
                        <span>${Metrics.expensesTotal30.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                        <label>Last 30 days</label>
                      </Grid.Column>
                      <Grid.Column>
                        <span>${Metrics.expensesTotal365.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                        <label>Last 365 days</label>
                      </Grid.Column>
                    </Grid>
                    <Bar
                      data={{
                        labels: Object.keys(Metrics.expensesTotalGraph),
                        datasets: [
                          {
                            label: "$",
                            fill: false,
                            data: Object.values(Metrics.expensesTotalGraph),

                            backgroundColor: [
                              "#97399933", // $qp-color-purple
                              "#97399944", // $qp-color-purple
                              "#97399944", // $qp-color-purple
                              "#97399955", // $qp-color-purple
                              "#97399955", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399977", // $qp-color-purple
                              "#97399977", // $qp-color-purple
                              "#97399988", // $qp-color-purple
                              "#97399999", // $qp-color-purple
                              "#97399999", // $qp-color-purple
                            ],
                          },
                        ],
                      }}
                      width={150}
                      height={75}
                      options={{
                        maintainAspectRatio: true,
                        legend: {
                          display: false,
                        },
                        scales: {
                          xAxes: [
                            {
                              gridLines: {
                                color: "rgba(0, 0, 0, 0)",
                                drawOnChartArea: false,
                              },
                            },
                          ],
                          yAxes: [
                            {
                              afterTickToLabelConversion: function (q) {
                                for (var tick in q.ticks) {
                                  q.ticks[tick] = "$" + q.ticks[tick].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                                }
                              },
                              ticks: {
                                beginAtZero: true,
                                steps: 10,
                                stepValue: 5,
                                max: Math.ceil((_.max(
                                  Object.values(Metrics.expensesTotalGraph),
                                  function (o) {
                                    return Metrics.expensesTotalGraph[o];
                                  }
                                ) * 1.5) / 1000) * 1000,
                              },
                              gridLines: {
                                color: "rgb(220,220,220)",
                              },
                            },
                          ],
                        },
                      }}
                    ></Bar>
                    <br />
                  </Card.Content>
                </Card>
                <Card>
                  <Card.Header>
                    <div className="left-col">
                      <h4>Invoices</h4>
                    </div>
                    <div className="right-col">
                    </div>
                  </Card.Header>
                  <Grid columns="3" padded className="stripe-data">
                    <Grid.Column>
                    <span>$
                        {(Math.round(
                          (Metrics.invoicesTotal30 + Number.EPSILON) * 100
                        ) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                      <label>Last 30 days</label>
                      
                    </Grid.Column>
                    <Grid.Column>
                    <span>$
                        {(Math.round(
                          (Metrics.invoicesTotal365 + Number.EPSILON) * 100
                        ) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                      <label>Last 365 days</label>
                      
                    </Grid.Column>
                  </Grid>
                  <Bar
                    data={{
                      labels: Object.keys(Metrics.invoicesTotalGraph),
                      datasets: [
                        {
                          label: "$",
                          fill: false,
                          data: Object.values(Metrics.invoicesTotalGraph),

                          backgroundColor: [
                            "#97399933", // $qp-color-purple
                              "#97399944", // $qp-color-purple
                              "#97399944", // $qp-color-purple
                              "#97399955", // $qp-color-purple
                              "#97399955", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399966", // $qp-color-purple
                              "#97399977", // $qp-color-purple
                              "#97399977", // $qp-color-purple
                              "#97399988", // $qp-color-purple
                              "#97399999", // $qp-color-purple
                              "#97399999", // $qp-color-purple
                          ],
                        },
                      ],
                    }}
                    width={150}
                    height={75}
                    options={{
                      maintainAspectRatio: true,
                      legend: {
                        display: false,
                      },
                      scales: {
                        xAxes: [
                          {
                            gridLines: {
                              color: "rgba(0, 0, 0, 0)",
                              drawOnChartArea: false,
                            },
                          },
                        ],
                        yAxes: [
                          {
                            afterTickToLabelConversion: function (q) {
                              for (var tick in q.ticks) {
                                q.ticks[tick] = "$" + q.ticks[tick].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                              }
                            },
                            ticks: {
                              beginAtZero: true,
                              maxTicksLimit: 12,
                              stepSize: 1000,
                              precision: 1,
                              max: Math.ceil((_.max(
                                Object.values(Metrics.invoicesTotalGraph),
                                function (o) {
                                  return Metrics.invoicesTotalGraph[o];
                                }
                              ) * 1.5) / 1000) * 1000,


                            },
                            gridLines: {
                              color: "rgb(220,220,220)",
                            },
                          },
                        ],
                      },
                    }}
                  ></Bar>
                </Card>
              </Card.Group>
            )}
              </>
              :
              <>
              <div>

              <Image src='/assets/icons/NoData.svg' size='medium' centered />
              <Header as='h3' size="large" style={{justifyContent:"center"}}>
                No Data
              </Header>
              </div>
            </>
            }
          </span>
        </div>
      </div>
    );
  }
}

export default withApollo(Ledger);
