import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Button, Dropdown, Grid, Icon } from 'semantic-ui-react';
import camelCase from 'lodash/camelCase';
import PropertyAddress from './PropertyAddress';
import { EMAIL_REGEX, ORGANIZATION_API, VENDOR_SERVICES } from '../../../utils';
import { getClient } from '../../../init-apollo-googleFn';
import { CreateOrganisation, UpdateOrganisation } from '../../../store/organization/organization';
import { parseAddresses, parseGraphQLErrors, toastFailMsg } from '../../../utils/common';

const organizationClient = getClient(ORGANIZATION_API);

const transformAddressFields = (address) => {
  const updatedAddress = {};
  if (address) {
    Object.entries(address).forEach(([k, data]) => {
      if (k === 'street_one') updatedAddress.streetOne = data;
      else updatedAddress[camelCase(k)] = data;
    });
  }
  return updatedAddress;
};

const VendorSetup = ({
  control,
  register,
  errors,
  onAddField,
  onRemoveField,
  serviceOptions,
  onRemoveVendors,
  contacts,
}) => (
  <div className="grey-block">
    <div className="head align-items-center d-flex justify-content-between">
      Vendor Setup
      <Button
        type="button"
        className="cursor-pointer link-btn"
        icon
        onClick={() => onRemoveVendors(false)}
      >
        <Icon name="cancel" className="text-danger" />
        <span className="text-danger">Remove</span>
      </Button>
    </div>
    <p>Please add your vendors in the system</p>
    {contacts.map((contact, index) => (
      <div className="form-row" key={index.toString()}>
        <div className="input-section">
          <div className="select-box">
            <div className="input-box">
              <input
                className="form-control"
                type="text"
                name={`contacts[${index}].name`}
                placeholder="Vendor Name"
                autoComplete="nope"
                defaultValue={contact.name}
                ref={register({ required: 'Vendor Name is required.' })}
              />
            </div>
            <ErrorMessage errors={errors} name={`contacts[${index}].name`} as="span" className="error-msg" />
          </div>
          <div className="select-box">
            <div className="input-box">
              <input
                className="form-control"
                type="text"
                name={`contacts[${index}].email`}
                placeholder="Vendor Email"
                autoComplete="nope"
                defaultValue={contact.email}
                ref={register({
                  required: 'Vendor Email is required.',
                  pattern: {
                    value: EMAIL_REGEX,
                    message: 'Please enter valid email.',
                  },
                })}
              />
            </div>
            <ErrorMessage errors={errors} name={`contacts[${index}].email`} as="span" className="error-msg" />
          </div>
          <div className="select-box">
            <div className="input-box">
              <input
                className="form-control"
                type="number"
                name={`contacts[${index}].phone`}
                placeholder="Vendor Phone Number"
                autoComplete="nope"
                maxLength={10}
                defaultValue={contact.phone}
                ref={register({ required: 'Vendor Phone Number is required.' })}
              />
            </div>
            <ErrorMessage errors={errors} name={`contacts[${index}].phone`} as="span" className="error-msg" />
          </div>
          <div className="select-box">
            <Controller
              name={`contacts[${index}].services`}
              control={control}
              defaultValue={contact.services}
              rules={{
                required: 'Please select type of services',
                validate: (value) => value.length || 'Please select type of services',
              }}
              render={({ value, onChange }) => (
                <Dropdown
                  fluid
                  selection
                  multiple
                  options={serviceOptions}
                  selectOnBlur={false}
                  placeholder="Service"
                  value={value}
                  onChange={(e, data) => onChange(data.value)}
                />
              )}
            />
            <ErrorMessage errors={errors} name={`contacts[${index}].services`} as="span" className="error-msg" />
          </div>
        </div>
        {/* eslint-disable */}
        {(index === contacts.length - 1)
          ? (
            <a className="delete-btn" onClick={onAddField}>
              <img src="assets/img/icons-plus.svg" alt="icons-plus" />
            </a>
          ) : (
            <a className="delete-btn" onClick={() => onRemoveField(index)}>
              <img src="assets/img/black-icons-delete.svg" alt="black-icons-delete" />
            </a>
          )}
        {/* eslint-enable */}
      </div>
    ))}
  </div>
);

const CompanyInfoForm = ({
  locationId,
  onNextStep,
  handleSubmitting,
  orgDetails,
  onCancelClick,
}) => {
  const [addressDetails, setAddressDetails] = React.useState();
  const formMethods = useForm({ mode: 'onChange' });
  const {
    register, handleSubmit, errors, control, setValue, unregister,
  } = formMethods;

  const [addingVendors, setAddingVendors] = React.useState(false);
  const [contacts, setContacts] = React.useState([{
    name: '',
    email: '',
    phone: '',
    services: [],
  }]);

  const serviceOptions = React.useMemo(() => VENDOR_SERVICES.map((v) => ({
    key: v,
    text: v,
    value: v,
  })), []);

  /* eslint-disable */
  React.useEffect(() => {
    if (orgDetails) {
      Object.entries(orgDetails).forEach(([key, value]) => {
        switch (key) {
          case 'name':
            setValue('companyName', value);
            break;
          case 'address': {
            const updatedAddress = transformAddressFields(value);
            setAddressDetails(updatedAddress);
            setValue(key, parseAddresses(JSON.stringify([updatedAddress])));
            break;
          }
          case 'businessEmail':
            setValue('companyEmail', value);
            break;
          case 'phone':
            setValue('companyPhone', value);
            break;
          case 'contacts':
            if (Array.isArray(value)) {
              setAddingVendors(Boolean(value.length));
              value.forEach((item, index) => {
                Object.entries(item).forEach(([field, val]) => {
                  const fieldName = `contacts[${index}].${field}`;
                  setValue(fieldName, val);
                });
              });
              setContacts(value);
            }
            break;
          default:
            setValue(key, value);
            break;
        }
      });
    }
  }, [orgDetails]);
  /* eslint-enable */

  const onAddField = () => {
    const newField = {
      name: '',
      email: '',
      phone: '',
      services: [],
    };
    setContacts((prev) => ([...prev, newField]));
  };

  const onRemoveField = (index) => {
    unregister(`contacts[${index}]`);
    setContacts((prev) => [...prev].filter((_, i) => i !== index));
  };

  const onSubmit = (data) => {
    const {
      orgRegistrationId,
      companyName,
      companyPhone,
      companyEmail,
    } = data;
    const address = { ...addressDetails };
    delete address.googlePlaceId;
    const variables = {
      input: {
        locationId,
        orgRegistrationId,
        name: companyName,
        type: 'Property Management',
        phone: companyPhone,
        businessEmail: companyEmail,
        contacts: addingVendors ? (data.contacts || []) : [],
        address,
      },
    };
    const isUpdating = orgDetails.id;
    const query = isUpdating ? UpdateOrganisation : CreateOrganisation;
    if (isUpdating) variables.input.id = orgDetails.id;

    handleSubmitting(true);
    organizationClient.query({ query, variables })
      .then((response) => {
        if (response.data) onNextStep();
      })
      .catch((e) => toastFailMsg(parseGraphQLErrors(e)))
      .finally(() => handleSubmitting(false));
  };

  const onToggleVendors = (status) => {
    if (status) {
      onAddField();
      setAddingVendors(true);
    } else {
      setContacts([]);
      setAddingVendors(false);
    }
  };

  return (
    <>
      <div className="form-section m-t-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="form-group">
                <div className="has-float-label">
                  <input
                    className="form-control"
                    type="text"
                    id="orgRegistrationId"
                    name="orgRegistrationId"
                    placeholder="TIN or SSN"
                    autoComplete="nope"
                    ref={register({ required: 'TIN or SSN is required' })}
                  />
                  <label htmlFor="orgRegistrationId">TIN or SSN</label>
                </div>
                <ErrorMessage errors={errors} name="orgRegistrationId" as="span" className="error-msg" />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="form-group">
                <div className="has-float-label">
                  <input
                    className="form-control"
                    type="text"
                    id="companyName"
                    name="companyName"
                    placeholder="Company Name"
                    autoComplete="nope"
                    ref={register({ required: 'Company Name is required.' })}
                  />
                  <label htmlFor="companyName">Company Name</label>
                </div>
              </div>
              <ErrorMessage errors={errors} name="companyName" as="span" className="error-msg" />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="form-group">
                <div className="has-float-label">
                  <PropertyAddress
                    register={register}
                    setValue={setValue}
                    control={control}
                    setAddressDetails={setAddressDetails}
                    placeholder="Company Address"
                    value={parseAddresses(JSON.stringify([transformAddressFields(orgDetails.address)]))}
                  />
                </div>
                <ErrorMessage errors={errors} name="address" as="span" className="error-msg" />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="form-group">
                <div className="has-float-label">
                  <input
                    className="form-control"
                    type="number"
                    id="companyPhone"
                    name="companyPhone"
                    placeholder="Company Phone Number"
                    autoComplete="nope"
                    ref={register({ required: 'Company Phone Number is required.' })}
                  />
                  <label htmlFor="companyPhone">Company Phone Number</label>
                </div>
                <ErrorMessage errors={errors} name="companyPhone" as="span" className="error-msg" />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="form-group">
                <div className="has-float-label">
                  <input
                    className="form-control"
                    type="text"
                    id="companyEmail"
                    name="companyEmail"
                    placeholder="Company Email"
                    autoComplete="nope"
                    ref={register({
                      required: 'Company Email is required.',
                      pattern: {
                        value: EMAIL_REGEX,
                        message: 'Please enter valid Company Email.',
                      },
                    })}
                  />
                  <label htmlFor="companyEmail">Company Email</label>
                </div>
                <ErrorMessage errors={errors} name="companyEmail" as="span" className="error-msg" />
              </div>
            </Grid.Column>

            <Grid.Column mobile={16} tablet={16} computer={16}>
              {addingVendors && contacts.length ? (
                <VendorSetup
                  {...formMethods}
                  onAddField={onAddField}
                  onRemoveField={onRemoveField}
                  serviceOptions={serviceOptions}
                  contacts={contacts}
                  onRemoveVendors={onToggleVendors}
                />
              ) : (
                <div className="d-flex">
                  <Button
                    type="button"
                    className="cursor-pointer link-btn"
                    icon
                    onClick={() => onToggleVendors(true)}
                  >
                    <Icon name="add" className="text-priamry" />
                    <span className="text-priamry">Add Vendors</span>
                  </Button>
                </div>
                
              )}
            </Grid.Column>

            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="btn-block">
                <div>
                  <button type="button" className="btn cancel-btn" onClick={onCancelClick}>Cancel</button>
                </div>
                <div>
                  <button type="submit" className="btn next-btn">Proceed to step 5</button>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default CompanyInfoForm;
