import React from 'react';
import { ToastContainer } from 'react-toastify';

const toaster = () => (
  <ToastContainer
    position="top-center"
    autoClose={2000}
    hideProgressBar
    newestOnTop={false}
    closeOnClick={false}
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover={false}
  />
);

export default toaster;
