  
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getAuthToken } from '../store/auth';
import { getCookie } from '../store/auth/authUtility';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
    const cookie = getCookie()
    return (
        <Route {...rest} render={props => (
            ((Boolean(getAuthToken()) && cookie.renter === "true" && restricted) || (!Boolean(getAuthToken()) && restricted))
                ? <Component {...props} {...rest}/> :
                <Redirect to="/dashboard" />)} />
    )
}


export default PublicRoute;