import React from 'react';
import { Loader } from '../../Loader/Loader';

const SAVING_MESSAGES = {
  1: 'Property information',
  2: 'Units and sizes',
  3: 'Payments and policies',
  4: 'Company information',
  5: 'Marketing',
  6: 'Publishing your listing',
};

function PropertiesStepLoader({
  activeFormStep,
  isSubmitting
}) {
  return (
    isSubmitting &&
    <div className="menu-container">
      <div className="loader-block">
        <div className="loader-text">
          <span>{`Step ${activeFormStep}: ${SAVING_MESSAGES[activeFormStep]} looks great!`}</span>
          {' '}
          Saving the information and next step is coming up.
        </div>
        <Loader stepper active inline className="spinner" size="small" />
      </div>
      <div className="loader-overlay" />
    </div>
  );
}

export default PropertiesStepLoader;