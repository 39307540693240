import React, { useEffect } from 'react';
import { Dropdown, Grid, Button, Modal } from 'semantic-ui-react';
import { useState } from 'react';

const templates = [
  {
    name: "ThemeForest",
    templateId: 1,
    type: "apartment",
    typeId: "TG9jYXRpb25UeXBlOjM=",
    path: "./MicrositeTemplates/Apartment/ThemeForest/src/preview.png"
  },
];

const Marketing = ({
  locationId,
  locationTypeId,
  setTemplateMutation,
}) => {
  const filteredTemplates = templates.filter(t => t.typeId === locationTypeId);
  const [selectedTemplate, setSelectedTemplate] = React.useState("");
  const [modelOpen, setModelOpen] = React.useState(false);
  const [closeBtn, setCloseBtn] = useState(false);

  const templateOptions = filteredTemplates.map((t, index) => ({
    key: index,
    text: t.name,
    value: t.name
  }))

  const handleOnChange = (e, { value }) => {
    e.preventDefault();
    setSelectedTemplate(value);
  };

  const handleSetTemplate = () => {
    setTemplateMutation(filteredTemplates.filter(t => t.name === selectedTemplate)[0].templateId);
    setModelOpen(false);
  }

  /* eslint-disable */
  useEffect(() => {
    if (selectedTemplate !== "") {
      setTemplateMutation(filteredTemplates.filter(t => t.name === selectedTemplate)[0].templateId, true);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (closeBtn === true) {
      setCloseBtn(false);
      setModelOpen(false);
    }
  }, [closeBtn]);
  /* eslint-enable */

  return (
    <>
      <div className="property-manager-wrapper">
        <Grid divided="half-image-section">
          <Grid.Row>
            <Grid.Column className="microsites-modal-col-spacing">
              <h3>Marketing with Microsites!</h3>
              <p className="text">
                We've removed the hassle of setting up a website for your properties. With our microsite option, we'll take data you've already input when creating your properties and create a website for you to generate leads with. Select one of our templates and we will handle the rest!
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div className="white-block csv-block">
        <h4 className="microsites-modal-templates">{filteredTemplates.length} {filteredTemplates.length === 1 ? "Template" : "Templates"}</h4>
        <Dropdown 
          placeholder="Select a Template" 
          search 
          selection
          className="microsites-modal-dropdown"
          options={templateOptions}
          onChange={handleOnChange}
          value={selectedTemplate}
        />
      </div>
      <div className="white-block modal-block">
        {selectedTemplate === "" 
          ? 
          <p className="microsites-modal-not-selected">No template selected</p>
          : 
          <div>
            <Modal
              centered
              size="fullscreen"
              onClose={() => setModelOpen(false)}
              onOpen={() => setModelOpen(true)}
              open={modelOpen}
              trigger={
                <Button className="microsites-modal-trigger-button">Show Template</Button>
              }
            >
              <Modal.Content>
                <div className="iframe-container">
                  <iframe title='template-preview' src={`https://leasera-template-1.web.app/1/${locationId}`}/>
                </div>
              </Modal.Content>
              <Modal.Actions className="microsites-modal-actions">
                <Button color='black' onClick={()=>console.log("nice")}>
                  Close
                </Button>
              </Modal.Actions>
            </Modal>
            <Button className="microsites-modal-set-template" onClick={() => handleSetTemplate()}>Set Template</Button>
          </div>
        }
      </div>
    </>
  );
};


export default Marketing;