
import React from 'react'

export const EvictionHistory = ({

  incompleteIcon = <span className="text-danger"><i className="fa fa-times-circle"></i></span>

}) => (
    <div className="card card-md applicant-history-card">
      <div className="applicant-history-card-title">
        Eviction History {incompleteIcon}
        <a
          className="validations-link"
          data-toggle="collapse"
          href="#eviction-validations"
          aria-expanded="false"
          aria-controls="validations"
        >
          See Validations
        </a>
        <div className="collapse" id="eviction-validations">
          <div className="validations-container">
            <div className="validations">
              <span className="validations-item">
                Questionnaire: {incompleteIcon}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="card-media-list" id="evictions">
        <div className="responsive-table evictions-table-container">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Address</th>
                <th>Plaintiff/Amount</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-target="Address">1234 Main St <br /> Seattle, WA</td>
                <td data-target="Plaintiff/Amount">John Smith <br /> $420.69</td>
                <td data-target="Date">12/31/1999</td>
              </tr>
              <tr>
                <td data-target="Address">1234 Main St <br /> Seattle, WA</td>
                <td data-target="Plaintiff/Amount">John Smith <br /> $420.69</td>
                <td data-target="Date">12/31/1999</td>
              </tr>
              <tr>
                <td data-target="Address">1234 Main St <br /> Seattle, WA</td>
                <td data-target="Plaintiff/Amount">John Smith <br /> $420.69</td>
                <td data-target="Date">12/31/1999</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )