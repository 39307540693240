import gql from 'graphql-tag'

export const personPointsTransactionGql = gql`
  query locationLedger ($locationId: String!){
    locationLedger (locationId: $locationId,){
      edges {
        node {
          id
          entityType
          entityId
          managerId
          renterId
          transactionType
          points
          actionType
          customMessage
          createdAt
        }
      }
    }
  }
`

export const personPointsPackagesGql = gql`
  query packages {
    packages {
      edges {
        node {
          id
          name
          price
          points
          isDelete
        }
      }
    }
  }
`

export const purchasePointsPackageGql = gql`
  mutation AddPointsLocationWallet($package: AddPointsLocationWalletInput!) {
    addPointsLocationWallet(package: $package) {
      response
    }
  }
`;

export const sendLoyaltyPointGql = gql`
  mutation AddPointsLocationWallet($package: SendLoyaltyPointInput!) {
    sendLoyaltyPoint(package: $package) {
      response
    }
  }
`;