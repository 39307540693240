import React, { useMemo, useState, useEffect, } from "react";
import { ToastContainer, } from "react-toastify";
import SimpleTable from "./SimpleReportsTable";
import { multipleColumnsSearch } from "../../utils/common";
import "react-toastify/dist/ReactToastify.css";


const TransParse = ({ values }) => {
  const parsed = JSON.parse(values)
  
  const newCol = parsed.map(item => {
    return "\n" +item
  })

  return newCol
};

  const TransParse3 = ({ values }) => {
    return values + `${values}\n0.00\n0.00`;
  };

   const TransParse2 = ({ values }) => {
    const parsed = JSON.parse(values)
   const newCol = Object.values(parsed);
   const finalReturn = newCol.map(item => {
     return "\n" +item
   })
   return finalReturn
   };

export function Table(props) {
  const [filterInput, setFilterInput] = useState("");
  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    const filteredData = multipleColumnsSearch(
      value,
      props.rollCache && props.rollCache
    );
    setData(filteredData);
    setFilterInput(value);
  };




  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      // Filter: DefaultColumnFilter,
    }),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "Unit",
        accessor: "Unit",
        maxWidth: 50,
        minWidth: 50,
        width: 50,
      },
      {
        Header: "Floorplan",
        accessor: "Floorplan",
        maxWidth: 50,
        minWidth: 50,
        width: 50,
      },
      {
        Header: "SQFT",
        accessor: "SQFT",
        maxWidth: 50,
        minWidth: 50,
        width: 50,
      },
      {
        Header: "Status",
        accessor: "Unit/Lease Status",
      },
      {
        Header: "Name",
        accessor: "Name",
      },
      {
        Header: "Move In",
        accessor: "Move-In",
      }, 
      {
        Header: "Lease Start",
        accessor: "Lease Start",
      }, 
      {
        Header: "Lease End",
        accessor: "Lease End",
      }, 
      {
        Header: "Market",
        accessor: "Market Rent",
        Cell: ({ cell: { value } }) => value && "$" + value ,
      }, 
      {
        Header: "Trans Code",
        accessor: "Trans Code",
        Cell: ({ value }) => (value ? <TransParse values={value} /> : ''),
      }, 
      {
        Header: "Amount",
        accessor: "Lease Rent",
        Cell: ({ value }) => (value ? <TransParse3 values={value} /> : ''),
      }, 
      {
        Header: "Other",
        accessor: "Other Charges/Credits",
        Cell: ({ value }) => (value ? <TransParse2 values={value} /> : ''),
      }, 
      {
        Header: "Total Bill",
        accessor: "Total Billing",
        Cell: ({ cell: { value } }) => value && "$" + value ,
      }, 
      {
        Header: "Dep On Hand",
        accessor: "Deposit On Hand",
        Cell: ({ cell: { value } }) => value && "$" + value ,
      }, 
      {
        Header: "Balance",
        accessor: "Balance",
        Cell: ({ cell: { value } }) => value && "$" + value ,
      }, 
    ],
    []
  );
  


  const [data, setData] = useState([]);
  const [lastPage, setLastPage] = useState();

  /* eslint-disable */
  useEffect(() => {
    const result = props.rollCache.length / 30;
    setLastPage(Math.ceil(result));
    setData(props.rollCache && props.rollCache);
  }, [props.rollCache && props.rollCache]);
  /* eslint-enable */

  return (
    <>
       <main className="main-content" role="main">
        <div className="reconcile-container">
      <div className="table-container">

          <SimpleTable
            value={filterInput}
            defaultColumn={defaultColumn}
            onChange={handleFilterChange}
            columns={columns}
            data={data}
            pageCount={lastPage}
            lastPage={lastPage}
            totalItems={data.length}
          />
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
          />
        </div>
        </div>
        </main>
    </>
  );
}