import gql from 'graphql-tag';

export const CreateOrganisation = gql`
  mutation CreatOrg($input: CreateOrganizationInput!){
    createOrganization(organization: $input){
      response
      organization{
        id
      }
    }
  }
`;

export const UpdateOrganisation = gql`
  mutation updateOrg($input: UpdateOrganizationInput!){
    updateOrganization(organization: $input){
      response
    }
  }
`;

export const GetOrganisations = gql`
  query{
    organization{
      edges{
        node{
          id
          name
          orgRegistrationId
          businessEmail
          phone
          address
          metaData
          contacts
        }
      }
    }
  }
`;

export const GetOrganisationDetails = gql`
  query organization($id: String! ){
    organization(id: $id){
      edges{
        node{
          id
          name
          orgRegistrationId
          businessEmail
          phone
          address
          metaData
          contacts
        }
      }
    }
  }
`;
