import React, {
  Component
} from "react";
import {
  withApollo
} from "react-apollo";
import mixpanel from 'mixpanel-browser';
import {
  financialsGql
} from "../../store/person/accounting";
import moment from "moment";
import {
  getClient
} from "../../init-apollo-googleFn";
import get from "lodash/get";
import {
  Button, Grid, Dropdown
} from "semantic-ui-react";
import {
  Table,
  RollTable
} from "../../components/Tables/FinancialsData";
import { Loader } from '../../components/Loader/Loader';

import {
  ACCOUNTING
} from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import "./Accounting.scss"
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";


const invoiceClient = getClient(ACCOUNTING);

class JournalReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary ?
        this.props.selectedPrimary.node : "",
      loading: false,
      financialsCache: [],
      rentRoll:[],
      scheduledStartDate:moment().startOf('month'),
      scheduledEndDate:moment().endOf('month'),
      report:'rent_roll'
    };
  }

  componentDidMount() {
    if(window.document.getElementById('getLedger')) {
      window.document.getElementById('getLedger').click()
    }
    mixpanel.track('Manager Page Load', {
      'sub': 'Financials'
     });

  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary ?
      prevProps.selectedPrimary.node :
      "";
    if (nodeData !== this.state.primary) {
      this.setState({
        primary: nodeData
      }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
      });
    }
  }

  getLedger = () => {
    this.setState({
      financialsCache: [],
      rentRoll:[]
    })
    try {
      this.setState({
        loading: true
      });
      invoiceClient
        .query({
          query: financialsGql,
          variables: {
            location: this.props.selectedPrimary.node.id,
            start: this.state.scheduledStartDate,
            end: this.state.scheduledEndDate,
            reportType: this.state.report
          },
        })
        .then((res) => {
          const finance = get(res, "data.slLocationFinancials.data")
          if(finance !==undefined) {
            const newFinance = [{
              name: finance.name,
              expanded:true,
              subRows: finance.children && finance.children.map(mapping => {
                return ({
                  name: mapping.name,
                  value: mapping.value,
                  expanded:true,
                  subRows: mapping.children && mapping.children.map(mapping => {
                    return ({
                      name: mapping.name,
                      value: mapping.value,
                      expanded:true,
                      subRows: mapping.children && mapping.children.map(mapping => {
                        return ({
                          name: mapping.name,
                          value: mapping.value,
                          expanded:true,
                          subRows: mapping.children && mapping.children.map(mapping => {
                            return ({
                              name: mapping.name,
                              value: mapping.value,
                              expanded:true,
                              subRows: mapping.children && mapping.children.map(mapping => {
                                return ({
                                  name: mapping.name,
                                  value: mapping.value,
                                  expanded:true,
                                  subRows: mapping.children && mapping.children.map(mapping => {
                                    return ({
                                      name: mapping.name,
                                      value: mapping.value,
                                      expanded:true,
                                      subRows: mapping.children && mapping.children.map(mapping => {
                                        return ({
                                          name: mapping.name,
                                          value: mapping.value,
                                          expanded:true,
                                          subRows: mapping.children && mapping.children.map(mapping => {
                                            return ({
                                              name: mapping.name,
                                              value: mapping.value,
                                              expanded:true,
                                            })
                                          })
                                        })
                                      })
                                    })
                                  })
                                })
                              })
                            })
                          })
                        })
                      })
                    })
                  })
                })
              })
            }]
            if (res.data) {
              this.setState({
                financialsCache: newFinance,
                totalItems: get(res, "data.slLocationFinancials.totalItems"),
              });
            }
            this.setState({
              loading: false
            });
            document.getElementById("allExpand").click();
          }
          else {
            const finance = get(res, "data.slLocationFinancials")
            this.setState({loading: false});
            this.setState({rentRoll:finance})
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            loading: false
          });
        });
    } catch (e) {
      this.setState({
        loading: false
      });
    }
  };



  dataPush = (response) => {
    this.setState((prevState) => ({
      financialsCache: [
        response.data.createSlInvoice.slInvoice,
        ...prevState.financialsCache,
      ],
    }));
  };
  updateInvoice = (status, invoiceId) => {
    const {
      financialsCache
    } = this.state;
    const array = [];
    if (status === "Delete") {
      this.setState({
        financialsCache: financialsCache.filter(invoice => invoice._id !== invoiceId)
      })
    } else {
      financialsCache.forEach((invoice) => {
        if (invoice._id === invoiceId) {
          invoice.status = status;
          array.push(invoice);
        } else {
          array.push(invoice);
        }
      });
      this.setState({
        financialsCache: array
      });
    }
  };

  handleStartClick = (date) => {
   this.setState({scheduledStartDate:moment(date).format('YYYY-MM-DD')})
  };
  handleEndClick = (date) => {
    this.setState({scheduledEndDate:moment(date).format('YYYY-MM-DD')})
  };

  handleReportChange= (event, { value }) => {
    this.setState({report:value})
  }

  render() {
    const {
      loading,
      scheduledEndDate,
      scheduledStartDate,
      report,
      rentRoll
    } = this.state;
    const financials = [
      // {
      //   key: 'Rent Roll',
      //   text: 'Rent Roll',
      //   value: 'rent_roll',
      // },
      {
        key: 'Income Statement',
        text: 'Income Statement',
        value: 'income_statement',
      },
      {
        key: 'Balance Sheet',
        text: 'Balance Sheet',
        value: 'balance_sheet',
      },
      {
        key: 'Profit/Loss',
        text: 'Profit/Loss',
        value: 'profit_loss',
      },
    ]

    return ( 
      loading ? ( <Loader text inTable />
        ) : (
          <>
            <Grid columns="5">
              <div className="Finance">
                Report Type
                <br></br>
                <Dropdown
                  style={{marginTop:"5px"}}
                  selection
                  defaultValue={financials[0].value}
                  selectOnBlur={false}
                  options={financials}
                  onChange={this.handleReportChange}
                />
              </div>
              <>
                <div>
                  Start Date
                  <Datetime defaultValue={moment().startOf('month')} timeFormat={false} onChange={this.handleStartClick}/>
                </div>
                <div>
                  End Date
                  <Datetime  defaultValue={moment().endOf('month')} timeFormat={false} onChange={this.handleEndClick}/>
                </div>
              </>
              <div>
                <Button id="getLedger" disabled={!scheduledStartDate||!scheduledEndDate ||!report} compact style={{paddingTop:'0px', paddingBottom:"0px", height:"46.17px", marginTop:'20px'}} className="noPrint" onClick={this.getLedger}>Get Report</Button>
              </div>
              <div>
                <Button style={{paddingTop:'0px', paddingBottom:"0px", height:"46.17px", marginTop:'20px'}} className="noPrint" onClick={() => window.print()}>Print</Button>
              </div>
            </Grid>
            <br></br>
            {
              <>
                {
                  rentRoll && rentRoll.length > 0 ?
                    <RollTable
                      financialsCache = {rentRoll }
                    />
                  :
                    <Table updateInvoice = {
                      this.updateInvoice
                    }
                    getNewData = {
                      this.getNewData
                    }
                    property = {
                      this.props.selectedPrimary && this.props.selectedPrimary.node && this.props.selectedPrimary.node.customId
                    }
                    dataPush = {
                      this.dataPush
                    }
                    getLedger = {
                      this.getLedger
                    }
                    financialsCache = {
                      this.state.financialsCache && this.state.financialsCache
                    }
                    totalItems = {
                      this.state.totalItems && this.state.totalItems
                    }
                    propertyId = {
                      this.props.selectedPrimary.node && this.props.selectedPrimary.node.id
                    }
                  />
                }
              </>
            } 
          </>
      )
    );
  }
}

export default withApollo(JournalReport);