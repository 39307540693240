import React from "react";
import "./ListItems.scss";
import { Icon, Input, Card } from "semantic-ui-react";
import moment from "moment";
export function ListItemsToDo(props) {
  const items = props.items;
  const listItems = items && items.map((item) => {
    return (
      <div className={`list ${item.node.priority === "low" && "lowP"} ${item.node.priority === "medium" && "medP"} ${item.node.priority === "urgent" && "higP"}`} key={item.node.id} >
        <Card fluid>
        <Icon name="remove"  onClick={() => {props.deleteItem(item, item.node.id);}}/>
          <img src="/assets/img/icons-radio.svg" alt="icon" onClick={() => {props.moveItem(item, item.node.id);}}/>
          <Input
            readonly
            fluid
            type="text"
            id={item.node.id}
            value={item.node.description}
          />
          {
            item.node.created &&
          <span class="created-date">
          Created on:  {moment(item.node.created).format("l")}
          </span>
          }
        </Card>
      </div>
    );
  });
  return (
    <div>
      {listItems}
    </div>
  );
}

export function ListItemsCompleted(props) {
  const itemsDone = props.itemsDone;
  const listItems = itemsDone && itemsDone.map((item) => {
    return (
      <div className={`list completed ${item.node.priority === "low" && "lowP"} ${item.node.priority === "medium" && "medP"} ${item.node.priority === "urgent" && "higP"}`} key={item.node.id} >
        <Card fluid>
        <Icon name="remove"  onClick={() => {props.deleteItem(item, item.node.id);}}/>
        <img src="/assets/img/icons-checkbox-circle.svg" alt="icon"  onClick={() => {props.moveItem(item, item.node.id);}}/>
          {/* eslint-disable */}
          <Input
            fluid
            disabled
            type="text"
            id={item.node.id}
            value={"Completed"+  " - " + item.node.description}
          />
          {/* eslint-enable */}
         {
          item.node.completed &&
          <span class="created-date">
              Completed on:  {moment(item.node.completed).format("l")}
          </span>
         }
        </Card>
      </div>
    );
  });
  return <div>{listItems}</div>;
}
