import React, { useEffect } from 'react';
import {
  useTable, useFilters, useSortBy, usePagination,
} from 'react-table';

export default function ApplicationTable({
  columns,
  data,
  filteredValue,
  defaultColumn,
  onChange,
  loading,
  fetchData,
  filterTypes,
  calculateLastPage,
  pageCount: controlledPageCount,
}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable({
    autoResetPage: false, // If this works for you this was the fix
    columns,
    filterTypes,
    data,
    defaultColumn,
    initialState: {
      sortBy: [
      ],
    },
    manualPagination: true,
    pageCount: controlledPageCount,
  },
  useFilters,
  useSortBy,
  usePagination);

  /* eslint-disable */
  useEffect(() => {
    fetchData({ pageIndex, pageSize, filteredValue });
  }, [fetchData, pageIndex, pageSize]);

  useEffect(() => {
    calculateLastPage({ pageSize, controlledPageCount });
  }, [calculateLastPage, pageSize, controlledPageCount]);
  /* eslint-enable */

  // Render the UI for your table

  return (
    <table
      className="lead-table-list"
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} className="d-table-row">
            {headerGroup.headers.map((column) => (
              <th
              className="table-header lease-list-th"
                {...column.getHeaderProps()}
              >
                <div className="header-filter-wrapper">
                  <span
                    {...column.getSortByToggleProps()}
                    className={
                  column.isSorted
                    ? column.isSortedAsc
                      ? 'table-sorting sort-asc'
                      : 'table-sorting sort-desc'
                    : 'table-sorting'
                }
                  >
                    {column.render('Header')}
                  </span>
                  {column.canFilter ? column.render('Filter') : null}
                </div>

              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.length === 0 ? (
          <td colSpan="10" className="text-center">No Results Found</td>
        ) : page.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
