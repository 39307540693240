import React from 'react';
import { Loader } from '../../Loader/Loader';

const SAVING_MESSAGES = {
  1: 'Property information',
  2: 'Units and sizes',
  3: 'Payments and policies',
  4: 'Company information',
  5: 'Marketing',
  6: 'Publishing your listing',
};

const AddPropertyStepper = ({
  title,
  activeFormStep,
  isSubmitting,
  onStepClick,
  onGoBack,
}) => (
  <>
    <div className="header">
      <div>
        {/* eslint-disable */}
        <a className="back-btn" onClick={onGoBack}>
          <img src="assets/img/left-arrow-small.svg" alt="arrow-down-small" />
        </a>
        {/* eslint-enable */}
        {title}
      </div>
    </div>
    <div className="menu-container">
      {!isSubmitting ? (
        <ul className="list-container">
          <li className={activeFormStep >= 1 ? 'active' : ''} onClick={() => onStepClick(1)}>
            <span>1</span>
            {' '}
            Property Information
          </li>
          <li className={activeFormStep >= 2 ? 'active' : ''} onClick={() => onStepClick(2)}>
            <span>2</span>
            {' '}
            Units and Sizes
          </li>
          <li className={activeFormStep >= 3 ? 'active' : ''} onClick={() => onStepClick(3)}>
            <span>3</span>
            {' '}
            Payments and Policies
          </li>
          <li className={activeFormStep >= 4 ? 'active' : ''} onClick={() => onStepClick(4)}>
            <span>4</span>
            {' '}
            Company Information
          </li>
          <li className={activeFormStep >= 5 ? 'active' : ''} onClick={() => onStepClick(5)}>
            <span>5</span>
            {' '}
            Marketing
          </li>
          <li className={activeFormStep >= 6 ? 'active' : ''} onClick={() => onStepClick(6)}>
            <span>6</span>
            {' '}
            Review Property
          </li>
        </ul>
      ) : (
        <div className="loader-block">
          <div className="loader-text">
            <span>{`Step ${activeFormStep}: ${SAVING_MESSAGES[activeFormStep]} looks great!`}</span>
            {' '}
            Saving the information and next step is coming up.
          </div>
          <Loader stepper/>
        </div>
      )}
    </div>
    {isSubmitting && <div className="loader-overlay" />}
  </>
);

export default AddPropertyStepper;
