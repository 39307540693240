import gql from 'graphql-tag'

const requestfields = `{
  priority,
  category,
  id,
  assigned {
    id,
    firstName,
    lastName
  },
  status,
  requestedByDesc,
  permissionToEnter,
  fileUrls,
  location,
  created
  scheduledStart,
  scheduledEnd,
  enteredDate,
  completedDate,
  unit,
  logs,
  staffNotes,
  accessNotes,
  nte,
  pet,
  petType,
  updated,
  primaryLocation {
    serviceProfessionals {
      firstName,
      lastName,
      email,
      phoneMobile
      id
    }
  }
  requestedBy {
    firstName,
    lastName,
    email,
    phoneMobile
  }
}`

export const maintenanceGql = gql`
query maintenanceRequest($role: String!, $status: [String!], $location: String!) {
  maintenanceRequest(role: $role, status:$status,location: $location) {
    edges {
      node
        ${requestfields}
    }
  }
}
`
export const singleMaintenanceGql = gql`
query maintenanceRequest($role: String!, $status: [String!], $location: String!, $id:String!) {
  maintenanceRequest(role: $role, status:$status,location: $location, id:$id) {
    edges {
      node
        ${requestfields}
    }
  }
}
`
export const updateMaintenance = gql`
mutation UpdateMaintenanceRequestt($input:UpdateMaintenanceRequesttInput!) {
  updateMaintenanceRequestt(input: $input){ 
    response
  }
}
`

export const maintenanceActivity = gql `
query MaintenanceActivity($maintenanceRequestId: String!) {
  maintenanceActivity(maintenanceRequestId: $maintenanceRequestId) {
    edges {
      node {
        id
        created
        updated
        subject
        message
        attribute
        prevValue
        newValue
      }
    }
  }
}`

export const cancelMaintenanceRequest = gql`
  mutation cancelMaintenanceRequest($input: CancelMaintenanceRequestInput!) {
    cancelMaintenanceRequest(input: $input) {
      maintenanceRequest
    }
  }
`


export const maintenanceLocationGql = gql`
query {
  primaryLocations{
    edges{
      node{
        ndbId
        name
        address{
          unitNum
        }
        properties{
          ndbId
          location{
            name
            city
            state
            unitNum
          }
        }
      }
    }
  }
}
`

export const inviteMaintenanceWorker = gql`
  mutation inviteMaintenanceWorker($input: InviteMaintenanceWorkerInput!) {
    inviteMaintenanceWorker(input: $input) {
      response
    }
  }
`
export const maintenancePropertiesGql = gql`
query MaintenanceProperties($role: RoleType, $primaryLocationId: String!) {
  maintenanceProperties(role: $role, primaryLocationId: $primaryLocationId) {
    edges {
      node {
        ndbId
        name
        location {
          unitNum
        }
      }
    }
  }
}
`

export const CreateMaintenance = gql`
  mutation CreateMaintenanceRequest($input: CreateMaintenanceRequestInput!) {
    createMaintenanceRequest(input: $input) {
      response
      
    }
  }
`

export const maintenanceRequestGql = gql`
query MaintenanceRequest($role: RoleType,$id: String){
  maintenanceRequest( role: $role, id: $id) {
    edges {
      node
      ${requestfields}
    }
  }
}`

export const serviceProfessionalsGql = gql`
query ServiceProfessionals($location:String!){
  serviceProfessionals(location: $location) {
    edges {
      node {
        id
        person {
          id
          firstName
          lastName
        }
      }
    }
  }
}`


export const CreateWorkOrderGql = gql`
  mutation CreateWorkOrder($input: CreateWorkOrderInput!) {
    createWorkOrder(input: $input) {
      response
    }
  }
`
export const workOrderGql = gql`
query WorkOrder($filter: WorkOrderFilters) {
  workOrder(filter: $filter) {
    edges {
      node {
        ndbId,
        serviceProfessional {
          firstName,
          lastName
        },
        nte,
        nteIncreaseApprovalDescription,
        nteIncreaseApprovalAmount,
        nteIncreaseDecisionDesc,
        contacted,
        dueDate,
        scheduledDatetime,
        nteIncreaseApproval,
        fileUrls,
        status
      }
    }
  }
}`

export const WorkOrderActionGql = gql`
  mutation WorkOrderAction($input: WorkOrderActionInput!) {
    workOrderAction(input: $input) {
      response
    }
  }
`

export const NTEActionGql = gql`
  mutation NTEAction($input: NTEActionInput!) {
    nteAction(input: $input) {
      response
    }
  }
 `
