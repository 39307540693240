/* eslint-disable */
import React, { Component } from 'react';
import  { Editor } from '@tinymce/tinymce-react';
import { withApollo } from "react-apollo";

class TextEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorKey: new Date().getTime(),
    }
  }
  componentDidMount = () => {
  }
  newChange = (content, editor) => {
this.props.handleEditorChange(content); 
  }


  render() {
    return (
      <Editor
        id="mytextarea"
        initialValue={this.props.template? this.props.template:"Start from scratch or select a template!"}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help'
        }}
        onEditorChange={this.newChange}
      />
    );
  }
}

export default withApollo(TextEditor)