import React from 'react'

export const Employment = ({

  verifiedIcon = <span className="text-success"><i className="fa fa-check-circle"></i></span>

}) => (
    <div className="card applicant-history-card">
      <div className="applicant-history-card-title">
        Employment {verifiedIcon}
        <a
          className="validations-link"
          data-toggle="collapse"
          href="#employment-validations"
          aria-expanded="false"
          aria-controls="validations"
        >
          See Validations
        </a>
        <div className="collapse" id="employment-validations">
          <div className="validations-container">
            <div className="validations">
              <span className="validations-item">
                Employment: {verifiedIcon}
              </span>
              <span className="validations-item">
                Identification: {verifiedIcon}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="card-media-list" id="employment">
        <div className="media employment-table-container">
          <div className="media-body">
            <div className="responsive-table">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Employer</th>
                    <th>Salary</th>
                    <th>Validations</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-target="Employer">McDonald's</td>
                    <td data-target="Salary">$15/hr</td>
                    <td data-target="Validations">
                      Employment {verifiedIcon}<br />
                      Identification {verifiedIcon}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )