import React from 'react'

export const Credit = ({

  verifiedIcon = <span className="text-success"><i className="fa fa-check-circle"></i></span>,
  needsAttentionIcon = <span className="text-warning"><i className="fa fa-info-circle"></i></span>

}) => (
    <div className="card applicant-history-card">
      <div className="applicant-history-card-title">
        Credit {needsAttentionIcon}
        <a
          className="validations-link"
          data-toggle="collapse"
          href="#credit-validations"
          aria-expanded="false"
          aria-controls="validations"
        >
          See Validations
        </a>
        <div className="collapse" id="credit-validations">
          <div className="validations-container">
            <div className="validations">
              <span className="validations-item">
                Positive Accounts: {verifiedIcon}
              </span>
            </div>
            <div className="validations">
              <span className="validations-item">
                Name Match: {verifiedIcon}
              </span>
            </div>
            <div className="validations">
              <span className="validations-item">
                Unpaid Collections: {needsAttentionIcon}
              </span>
            </div>
            <div className="validations">
              <span className="validations-item">
                Bankruptcies: {verifiedIcon}
              </span>
            </div>
            <div className="validations">
              <span className="validations-item">
                FICO Score: {verifiedIcon}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-sm-12 mb-4">
            <div
              id="fico-dial-chart"
              className="chart chart-epc"
              data-percent={800 / 8.5} // Temp 720 should be the applicant FICO
              // data-percent={{userFico} / 8.5}
              data-chart-color-1="#0ad251"
              data-chart-color-2="#07a7e3"
              data-chart-color-3="#fce418"
              data-chart-color-4="#f48e3a"
              data-chart-color-5="#f43a59"
            >
              <span className="score">FICO</span>
              <span>FICO</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )