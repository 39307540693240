import React from 'react'
import { Button, Image, Modal, Form } from 'semantic-ui-react'
import { LOYALTY  } from "../../utils/constants";
import { sendLoyaltyPointGql } from "../../store/person/loyalty"
import { getClient } from "../../init-apollo-googleFn";
import mixpanel from 'mixpanel-browser';
import { toastFailMsg,toastSuccessMsg } from "../../utils/common";
import {
  getEmitter
} from '../../utils'
import pointsIcon from '../../assets/images/points-icon.svg';
import giftIcon from '../../assets/images/gift-points-icon.svg';
const eventEmit = getEmitter();

const loyaltyClient = getClient(LOYALTY);
function GiftPointsModal(prop) {
  const [open, setOpen] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [amount, setAmount] = React.useState(false)

 
 const sendGift = () => {
    mixpanel.track('Manager Loyalty Action', {
        'sub': 'Gift Points to User'
    });
    loyaltyClient.mutate({
      mutation: sendLoyaltyPointGql,
      variables: {
        package: {
            renterEmail: prop.recipient,
            locationId:prop.location,
            points:amount,
            message:message.slice(0, 155)
          }
      },
    }).then((res) => {
      toastSuccessMsg("Successfully gifted points to user")
      setOpen(false)
      eventEmit.emit("PurchasePoints");
    }).catch(() => {
      toastFailMsg("Trouble processing request, please refer to system admin")
      setOpen(false)
    });
  }

  return (
    <Modal
      className="semanticModal loyalty-points-modal"
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={prop.from !== 'ViewLead' ?
        <Button className='btn-gradient'> <Image src={pointsIcon} alt="points" /> Gift Loyalty Points</Button>
      :
        <div>Gift Loyalty Points</div>
      }
    >
      {/* <Modal.Header>Gift Loyalty Points</Modal.Header> */}
      <Modal.Content>
        <Modal.Description>
          <div className='points-icon'><Image src={giftIcon} alt="points" /></div>
          <div className='points-modal-title'>Gift Loyalty Points</div>
          <div className='points-modal-description'>Say thank you by gifting loyalty points.</div>
          <Form>
            <Form.Input
              label='Points'
              placeholder='Points'
              type="number"
              name='name'
              onChange={({ target: { value } }) => {
                setAmount(value);
              }}
            />
            {/* <div className='points-balance'>Points Balance: 500</div> */}
            <Form.TextArea 
              label='Personalized Message' 
              placeholder='Input Personalize Message Here' 
              value={message.slice(0, 155)}
              onChange={({ target: { value } }) => {
                setMessage(value);
              }}
            />
            <p>Limit 155 characters</p>
            <Button fluid content='Gift Now' className='btn-gradient' onClick={() => sendGift()}></Button>
          </Form>
        </Modal.Description>
      </Modal.Content>
     
    </Modal>
  )
}

export default GiftPointsModal