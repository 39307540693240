import React, { Component } from 'react'
import { Icon, Form,  Button, Modal, Header } from 'semantic-ui-react';
import { withApollo } from "react-apollo";
import { inviteMaintenanceWorker } from "../../store/person/maintenance"
import { MAINTENANCE_URL } from "../../utils/constants";
import { getClient } from "../../init-apollo-googleFn";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toastFailMsg,toastSuccessMsg } from "../../utils/common";
import mixpanel from 'mixpanel-browser';
const maintenanceClient = getClient(MAINTENANCE_URL);

class MInviteModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading:false,
      modalOpen: false
    }
  }
  handleClose = () => this.setState({ modalOpen: false, email:'', lastName:'', firstName:'' })

  success = () => toast.success('Email Sent!', {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });


  handleChange = (name, e) => {
    const { value } = e.target;
    this.setState({
      [name]: value,
    });
  }
  validateAndSubmit = (e) => {
    e.preventDefault();
    this.handleInviteSubmit(this.state);
    
  }
  // Sends the Maintenance Worker Invite
  handleInviteSubmit = () => {
    this.setState({loading:true})
    const { firstName, lastName, email} = this.state
    try {
      mixpanel.track('Manager Maintenance Action', {
        'sub': 'Invite Maintenance Tech'
      });
      maintenanceClient.mutate({
        mutation: inviteMaintenanceWorker,
        variables: {
          input: {
            firstName:firstName,
            lastName:lastName,
            email: email,
            locationId:this.props.primary.id
          }
        }
      }).then(response => {
        toastSuccessMsg(response.data.inviteMaintenanceWorker.response)
        this.setState({loading:false})
        this.handleClose()
      }).catch(error => {
        console.log(error)
        this.setState({loading:false})
        toastFailMsg(error &&error.toString().split(/error: (.+)/)[1])
      });
    } catch (e) {
    }
  }

  inviteForm = () => (
    <Form width="equal">
      <Form.Input
       placeholder='First Name'
       name='firstName'
       onChange={(event) => { this.handleChange('firstName', event) }}
       className="height-38"
     />
      <Form.Input
       placeholder='Last Name'
       name='lastName'
       onChange={(event) => { this.handleChange('lastName', event) }}
       className="height-38"
     />
      <Form.Input
        placeholder='Email'
        name='email'
        onChange={(event) => { this.handleChange('email', event) }}
        className="height-38"
      />
    </Form>
  )
  render() {
    const { loading} = this.state
    return (
      <>
        <Modal
          className="semanticModal modal-radius"
          onClose={() => this.handleClose()}
          onOpen={() => this.setState({ modalOpen: true })}
          open={this.state.modalOpen}
          size="tiny"
          trigger={<Button disabled style={{ background: "#343c49", color: '#F2FCFB' }} compact icon  labelPosition='left'><Icon name='mail' style={{paddingTop:"15px"}}/>Maintenance Invite</Button>}
        >
          <Modal.Header textalign="left" className="modal-header-bg">
            <Header textalign="left" className="modal-heading-custom">Send Invite</Header>
          </Modal.Header>
          <Modal.Content > 
            <this.inviteForm />
          </Modal.Content>
          <Modal.Actions>
            <Button className="modal-close-button" positive onClick={() => this.handleClose()}>
              Cancel
              </Button>
              {
                loading?
                <Button className="modal-save-button" disabled positive loading>Loading</Button>:
                <>
                  {/* eslint-disable */}
                  <Button type='button' className="modal-save-button" positive disabled={!this.state.firstName|| !this.state.lastName ||!this.state.email || this.state.email&& !this.state.email.trim()||this.state.firstName&& !this.state.firstName.trim()||this.state.lastName&& !this.state.lastName.trim()} content='Submit' onClick={() =>this.handleInviteSubmit()}>Submit</Button>
                  {/* eslint-enable */}
                </>
              }
          </Modal.Actions>
        </Modal>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}

        />
      </>
    )
  }
}
export default withApollo(MInviteModal)
