import React, { Component } from "react";
import get from 'lodash/get';
import { withApollo } from "react-apollo";
import  Table from "../../components/Application/ApplicationData";
import { pmApplicationsGql, updateBulkEdit } from "../../store/person/applications"
import { qpDatatables, qpTooltipPopover } from "../../utils/misc";
import { Button, Card, } from 'semantic-ui-react';
import { FAKE_APPLICANT, APPLICATION_MANAGER, ADD_APPLICATION_STATUS } from "../../utils/constants";
import FlashMessage from "../../components/Messages/FlashMessage";
import "./Applications.scss"
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { getClient } from "../../init-apollo-googleFn";
import { PageLoader } from "../../components/Loader/PageLoader";
import { parseGraphQLErrors } from "../../utils/common";
import BulkEditModal from "../../components/Modals/BulkEditModal";
import mixpanel from 'mixpanel-browser';

const newClient = getClient(APPLICATION_MANAGER)

class Applications extends Component {
  constructor(props) {
    super(props)
    this.state = {
      applicant: FAKE_APPLICANT,
      node: {status: ''},
      appId: null,
      isError: false,
      message: "",
      applications: [],
      loading: false,
      signLoader: false,
      index: 0,
      preLease: false,
      selectedAppsForBulkEdit: [],
      disableBulkEdit: true,
      sending:false,
      selectedPrimary: this.props.selectedPrimary ? this.props.selectedPrimary.node : '',
    }
  }

  componentDidMount() {
    qpDatatables();
    qpTooltipPopover();
    this.props.selectedPrimary.node && this.props.selectedPrimary.node.customId && this.getApplications();
    mixpanel.track('Manager Page Load', {
      'sub': 'Applications'
     });
  }


  static getDerivedStateFromProps(props, state) {
    const newState = {};
    if (props.primaryLocations) {
      const nodeData = props.selectedPrimary.node;
      if (nodeData !== state.selectedPrimary) {
        newState['loading'] = true;
        newState['selectedPrimary'] = nodeData;
      }
    }
    return newState;
  }

  componentDidUpdate(props) {
    if (props.selectedPrimary.node !== this.state.selectedPrimary) {
      this.setState({ selectedAppsForBulkEdit: [] });
      this.getApplications();
    }
  }

  setMessage = (message, isError=false) => {
    this.setState({message, isError})
  }

  closeMessage = () => {
    this.setState({message: ''})
  }

  redirectToView = (item) => {
    const { customId } = this.props.selectedPrimary.node
    const { id } = item.node
    mixpanel.track('Manager Application Action', {
      'sub': 'Application View Details'
    });
    this.props.history.push({
      pathname: '/viewApplication/location=' + customId + '/id=' + id,
      state: { item },
      data: this.state.applications
    })
  }

  getApplications = () => {
    this.setState({ loading: true })
    try {
      newClient.query({
        query: pmApplicationsGql,
        variables: { locationId: this.state.selectedPrimary.id}
      }).then(response => {
        this.setState({ loading: false, disableBulkEdit: true });
        const applications = get(response, 'data.applications.edges', [])
        const filterLease = applications.filter(applicant => applicant.node.isLeaseCreated !== true)
        this.setState({ applications: filterLease });
      }).catch(error => {
        this.setState({ loading: false })
        this.setMessage(parseGraphQLErrors(error), true)
      });
    } catch (e) {
      console.log(e);
    }
  }

  openApplicationForm = () => {
    mixpanel.track('Manager Application Action', {
      'sub': 'Application Add New Applicant Click'
    });
    this.props.history.push({
      pathname: '/applicationForm',
    })  
  };

  /* function to delete the unchecked leads from the selectedAppsForBulkEdit array */
  removeUncheckedLeads = (data, id) => {
    const tempArr = [...data];
    const index = tempArr.indexOf(id);
    if (index !== -1) {
      tempArr.splice(index, 1);
      this.setState({ selectedAppsForBulkEdit: tempArr }, () => {
        if (this.state.selectedAppsForBulkEdit.length === 0) {
          this.setState({ disableBulkEdit: true });
        }
      });
    }
  }

  /* function to add checked leads in the selectedAppsForBulkEdit Array */
  addCheckedLeads = (data, id) => {
    const joined = data.concat(id);
    this.setState({
      selectedAppsForBulkEdit: joined,
      disableBulkEdit: false
    });
  }

  /* function called on the click of the select checkbox for leads
    checks if the lead is already checked, if yes, calls removeUncheckedLeads,
    if not, calls addCheckedLeads and add the lead in the array */
  handleMultipleCheckboxSelection = (item, e) => {
    const { selectedAppsForBulkEdit } = this.state;
    const isPresent = selectedAppsForBulkEdit.includes(item.node.id);
    if (isPresent) {
      this.removeUncheckedLeads(selectedAppsForBulkEdit, item.node.id)
    } else {
      this.addCheckedLeads(selectedAppsForBulkEdit, item.node.id)
    }
  }

   /* function called on the selection of the changed status from the bulk edit dropdown 
    calls updateBulkEdit mutation */
    updateBulkEdit = async (value) => {
      this.setState({sending:true, loading: true})
      try {
        mixpanel.track('Manager Application Action', {
          'sub': 'Bulk Edit'
        });
        await newClient.mutate({
          mutation: updateBulkEdit,
          variables: {
            input: {
              application: {
                ids: this.state.selectedAppsForBulkEdit, status: value,
                locationId: this.props.selectedPrimary && this.props.selectedPrimary.node.id
              }
            }
          }
        }).then(res => {
          this.setState({ loading: true, selectedAppsForBulkEdit: [] });
          this.setState({sending:false})
          setTimeout(() => {
            this.getApplications();
          }, 200)
        })
      }
      catch (e) {
        alert(e)
        this.setState({sending:false, loading: false})
        console.log(e)
      }
    }

    // Button Data -------------------------------------------------------
  ButtonGroup = () => (
    <div className="button-group-row mt-5 mb-4">
      <BulkEditModal values={ADD_APPLICATION_STATUS} sending={this.state.sending} showButton={this.state.disableBulkEdit} updateBulkEdit={this.updateBulkEdit} />
      <div className={`lead-action-wrapper ${ this.state.applications.length === 0 && 'pr-0' }`}>
        <Button className="btn-primary-light mr-3 btn-med">Export</Button>
        <Button className="btn-gradient btn-med" onClick={this.openApplicationForm}>Add Applicant</Button>
      </div>
    </div>
    )
    

  render() {
    const { message, isError, applications, loading } = this.state;
    const LeadsCard = () => (
      <div className="card-body">
        <div className="card-column">
          <div className="card-label">Needing Attention</div>
          <div className="card-value">{applications && applications.filter((obj) => obj.node.status === "New").length}</div>
        </div>
        <div className="card-column">
          <div className="card-label">In Progress</div>
          <div className="card-value">{applications && applications.filter((obj) => obj.node.status === "Approved" ||obj.node.status ===  "Approve w/Conditions" ||obj.node.status ===  "Under Review").length}</div>
        </div>
        {/* <Grid celled="internally" centered verticalAlign='middle' textAlign="center" >
          <Grid.Column textAlign="center" width={16}>
            <Header as="h4" className="mbottom-10">Active</Header>
            <Header as="h1" className="mtop-0">{applications && applications.filter((obj) => obj.node.status !== "Waitlist").length}
            </Header>
          </Grid.Column>
        </Grid> */}
      </div>
    )

    const dataDate = {
      First: 0,
      Second: 0,
      Third: 0,
      Fourth: 0,
      Fifth: 0,
      Sixth: 0,
      Seven: 0
    }
    applications && applications.forEach(applicant => {
      const value = moment(applicant.node.created).format("MM/DD/YYYY")
      if (value === moment().format("MM/DD/YYYY")) {
        return dataDate.First++;
      }
      if (value === moment().subtract(1, 'day').format("MM/DD/YYYY")) {
        return dataDate.Second++;
      }
      if (value === moment().subtract(2, 'day').format("MM/DD/YYYY")) {
        return dataDate.Third++;
      }
      if (value === moment().subtract(3, 'day').format("MM/DD/YYYY")) {
        return dataDate.Fourth++;
      }
      if (value === moment().subtract(4, 'day').format("MM/DD/YYYY")) {
        return dataDate.Fifth++;
      }
      if (value === moment().subtract(5, 'day').format("MM/DD/YYYY")) {
        return dataDate.Sixth++;
      }
      if (value === moment().subtract(6, 'day').format("MM/DD/YYYY")) {
        return dataDate.Seven++;
      }
    })
    //  Chart Data ---------------------------------------------------

    const leadSevenDays = {
      labels: [
        moment().subtract(6, 'day').format("MMM Do"),
        moment().subtract(5, 'day').format("MMM Do"),
        moment().subtract(4, 'day').format("MMM Do"),
        moment().subtract(3, 'day').format("MMM Do"),
        moment().subtract(2, 'day').format("MMM Do"),
        moment().subtract(1, 'day').format("MMM Do"),
        moment().format("MMM Do")
      ],
      type: "line",
      datasets: [
        {
          data: [
            dataDate.Seven,
            dataDate.Sixth,
            dataDate.Fifth,
            dataDate.Fourth,
            dataDate.Third,
            dataDate.Second,
            dataDate.First,
          ],

          backgroundColor: [
            '#4C158A', // $qp-color-primary
            '#fc913166', // $qp-color-orange
            '#97399966', // $qp-color-purple
          ],
          label: [
            "Applications Created",
          ]
        }
      ]
    }
    const leadsLegend = {
      display: true,
      position: 'right',
      fullWidth: true,
      reverse: false,
      label: 'Lead Source', // for legend
      labels: {
        fontColor: '$qp-color-darkblue',
      }
    }

    return (
      loading ? (
        <PageLoader text size="massive" active inline="centered"/>
      ) : (
        <main className="main-content" role="main">
          <div className="roll-container">
            <div className="page-header mb-4">
              <div className="d-flex align-items-center">
                <div>
                  <div className="page-header-title">Applications</div>
                  <div className="page-subtitle-light">Here's the overview of your applications</div>
                </div>
              </div>
            </div>
            <Card.Group itemsPerRow={2} stackable className="primary-cards leads-card mtop-0">
              <Card fluid className="card">
                <Card.Content className="card-content">
                  <Card.Header as="a" href="/#/leads" className="card-header">
                    Applications
                  </Card.Header>
                  <Card.Content className="card-content">
                    <LeadsCard />
                  </Card.Content>
                </Card.Content>
              </Card>
              {/* <Card fluid className="card">
                <Card.Content className="card-content">
                  <Card.Header as="a" href="/#/leads" className="card-header">
                    Applications In Progress
                    <Icon
                      className="card-icon"
                      size='large'
                      name="chart line"
                    />
                  </Card.Header>
                  <Card.Content className="card-content card-content-height">
                    <LeadsCard />
                  </Card.Content>
                </Card.Content>
              </Card> */}
              <Card fluid className="card">
                <Card.Content className="card-content">
                  <Card.Header as="a" href="/#/leads" className="card-header">
                    Active Last 7 Days
                  </Card.Header>
                  <Card.Content className="card-content card-content-height">
                    {/* eslint-disable */}
                    <Line
                      height={16}
                      width={100}
                      legend={leadsLegend}
                      data={leadSevenDays}
                      options={{ maintainAspectRatio: true }, { responsive: true }}
                    />
                    {/* eslint-enable */}
                  </Card.Content>
                </Card.Content>
              </Card>
            </Card.Group>
            <this.ButtonGroup />
            { applications.length > 0 ?
              <Table 
                applications={applications}
                multipleHandling={this.handleMultipleCheckboxSelection}
                view={this.redirectToView}
                selectedAppsForBulkEdit={this.state.selectedAppsForBulkEdit}
                />
              :
              <div className="no-results-text-application text-center py-5">
                <span className="not-found-icon"></span>
                <p className="no-results-text">
                  You don't have any application for the selected property. Click 'Add Applicant' to create new application or create application from lead details page.
                </p>
              </div>
            }
            <FlashMessage message={message} isError={isError} closeMessage={this.closeMessage}/>
          </div>
        </main>
      )
    );
  }
}

export default withApollo(Applications)
