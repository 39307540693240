import React, { useMemo, useState, useEffect, } from "react";
import { ToastContainer, } from "react-toastify";
import AccountingTable from "./AccountingTable";
import { ACCOUNTING } from "../../utils/constants";
import { multipleColumnsSearch } from "../../utils/common";
import { getClient } from "../../init-apollo-googleFn";
import ViewInvoiceDetails from "../Accounting/ViewInvoiceDetails";
import { InvoiceInfoAccounting } from "../../store/person/accounting";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
const invoiceClient = getClient(ACCOUNTING);


const Status = ({ values }) => {
  if(values){
    return <p style={{textTransform: 'capitalize'}}>{values}</p>
  }
};

export function Table(props) {
  const [filterInput, setFilterInput] = useState("");
  const [toggled, viewChange] = useState(false);
  const [viewInvoiceData, viewDataInvoice] = useState("");
  const [viewInvoicesData, viewDataInvoices] = useState("");
  const locationId = props.propertyId && props.propertyId;
  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    const filteredData = multipleColumnsSearch(
      value,
      props.journalCache && props.journalCache
    );
    setData(filteredData);
    setFilterInput(value);
  };

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    return "";
  }
  const DateFormat = ({ values }) => {
    return moment(values).format('l')
     };

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "Number",
        accessor: "Journal.number",
      },
      {
        Header: "Status",
        accessor: "Journal.status",
        Cell: ({ cell: { value } }) => (value ? <Status values={value} /> : ''),
      },
      {
        Header: "Ledger",
        accessor: "Journal.sourceLedger",
      },
      {
        Header: "Account Number",
        accessor: "LedgerAccount_number",
      },
      {
        Header: "Account Type",
        accessor: "LedgerAccount_type",
      },
      {
        Header: "Account SubType",
        accessor: "LedgerAccount_subtype",
      },
      {
        Header: "Post Date",
        accessor: "postedDate",
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : ''),
      },
      {
        Header: "Reference",
        accessor: "Journal.reference",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Debit",
        accessor: "debit",
        Cell: ({ cell: { value } }) => value && "$" + value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
      },
      {
        Header: "Credit",
        accessor: "credit",
        Cell: ({ cell: { value } }) => value && "$" + value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
      },
      
    ],
    []
  );
  const viewInvoice = (e, props) => {
    viewDataInvoices(props);
    if (toggled) {
      viewChange(false);
    }
    if (!toggled) {
      getInvoice(props);
    }
  };

  const getInvoice = (props) => {
    try {
      invoiceClient
        .query({
          query: InvoiceInfoAccounting,
          variables: {
            location: locationId,
            id: props.props._id,
          },
        })
        .then((res) => {
          if (res.data) {
            const data = res.data.slLocationInvoice
            /* eslint-disable */
            const Check = data.payments.map((payment) => {
              const check = data.payments.filter(
                (pay) => pay.externalCheckBankId === payment._id
              );
            /* eslint-enable */
              if (check.length > 0) {
                return payment.status = "refund";
              }
            });
            viewDataInvoice(
              res.data.slLocationInvoice && res.data.slLocationInvoice
            );
            viewChange(true);
          }
        })
        .catch((error) => {
          console.log(error)
        });
    } catch (e) {
    }
  };

  // const download = (e, props) => {
  //   const url = new URL(ACCOUNTING);
  //   url.searchParams.append("invoice_id", props.props._id);
  //   url.searchParams.append("action", "invoice");
  //   axios({
  //     method: "get",
  //     url: url.href,
  //     headers: {
  //       authorization: getAuthToken(),
  //     },
  //     responseType: "blob", // important
  //   })
  //     .then((response) => {
  //       // Create a Blob from the PDF Stream
  //       const file = new Blob([response.data], { type: "application/pdf" });
  //       // Build a URL from the file
  //       const fileURL = URL.createObjectURL(file);
  //       // Open the URL on new Window
  //       window.open(fileURL);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const [data, setData] = useState([]);
  const [lastPage, setLastPage] = useState();

    /* eslint-disable */
  useEffect(() => {
    const result = props.totalItems / 30;
    setLastPage(Math.ceil(result));
    setData(props.journalCache && props.journalCache);
  }, [props.journalCache && props.journalCache]);
  /* eslint-enable */

  return (
    <>
    <div className="table-container">
      {!toggled ? (
        <>
          <AccountingTable
            value={filterInput}
            defaultColumn={defaultColumn}
            user={props.user}
            onChange={handleFilterChange}
            columns={columns}
            data={data}
            getLedger={props.getLedger}
            totalItems={props.totalItems}
            pageCount={lastPage}
            lastPage={lastPage}
            // update={fetchTransData}
          />
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
          />
        </>
        
      ) : (
        <ViewInvoiceDetails
          property={props.property}
          user={props.user}
          invoice={viewInvoiceData}
          invoices={viewInvoicesData}
          ledgerView={viewInvoice}
        />
      )}
      </div>
    </>
  );
}
